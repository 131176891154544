import Icon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog } from '../../../controllers';
import { NumberInput, TeamMemberSelectInput } from '../../../inputs';

export default {
    Icon,
    dialogs: {
        edit: props => (
          <EditDialog
            {...props}
          >
            <NumberInput
              source="position"
            />
          </EditDialog>
        ),
        create: props => (
          <CreateButton
            {...props}
            fixedValue={{
                    user_id: props.parentResource.id
            }}
          >
            <TeamMemberSelectInput
              label="Team Member"
              source="guide_id"
            />
            <NumberInput
              source="position"
            />
          </CreateButton>
        ),
    },
    routes: {
    },
    components: {
    },
    resources: {
    },
};
