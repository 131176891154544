import Icon from '@material-ui/icons/Receipt';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { Filter, ResourceShow } from '../../../../controllers';
import { NumberField, TextField } from '../../../../fields';
import { BooleanInput, ReferenceInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ invoice_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput
          filterToQuery={searchText => ({ q: searchText })}
          optionText={coupons => `${coupons.name} - ${coupons.description}`}
          perPage={50}
          source="coupon_id"
        />
      </CreateButton>
    ),
  },
  routes: {
    show: props => (
      <ResourceShow
        {...props}
      >
        <NumberField
          label="Amount"
          source="final_amount"
        />
        <TextField
          source="name"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
