export async function query(query) {
  const current_user_id = localStorage.getItem('user_id');
  const access_token = localStorage.getItem('access_token');
  // Get a client token for authorization from your server
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL || 'https'}://${process.env.REACT_APP_API_URL}/graphql?current_user_id=${current_user_id}&access_token=${access_token}&query=${query}`, {
    headers: {
      Accept: 'application/json',
    },
  });
  return await response.json(); // If returned as JSON string
}
