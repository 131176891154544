import AssignIcon from '@material-ui/icons/Face';
import Icon from '@material-ui/icons/Phone';
import React from 'react';
import { ChipField, SimpleShowLayout, SingleFieldList } from 'react-admin';
import PhoneCallCard from '../../components/PhoneCallCard';
import { EditDialog, Filter, ResourceList } from '../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, NumberField, PhoneCallPartiesField, ReferenceManyField, TextField } from '../../fields';
import { BooleanInput, EnumInput, StaffTeamSelectInput } from '../../inputs';

const HandledRowStyle = record => ({
  opacity: record.handled_at ? '0.3' : '1',
});

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'handled'
    },
    {
      type: 'toggle',
      attribute: 'claimed'
    },
    {
      name: 'Assign',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <StaffTeamSelectInput
            source="staff_team_id"
          />
        </EditDialog>
      ),
    },
  ],
  dialogs: {
  },
  routes: {
    list: {
      default: {
        title: 'Unhandled Phone Calls',
        Controller: props => (
          <ResourceList
            RowStyle={HandledRowStyle}
            filter={{ 
              leg: 'inbound', 
              handled: false,
              claimed: false
            }}
            {...props}
          >
            <EnumField
              sortable={false}
              source="status"
            />
            <LinkField
              reference="call_queues"
              source="call_queue_id"
            />
            <DateTimeField
              label="Received"
              source="created_at"
              sortable
            />
            <TextField
              sortable={false}
              source="caller_name"
            />
            <PhoneCallPartiesField
              sortable={false}
            />
            <ReferenceManyField
              label="Org Status"
              reference="organizations"
              sortable={false}
              target="phone_calls"
            >
              <SingleFieldList
                linkType="show"
              >
                <ChipField
                  source="status"
                />
              </SingleFieldList>
            </ReferenceManyField>
            <NumberField
              sortable={false}
              source="duration"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
      },
      claimed: {
        title: 'Claimed Phone Calls',
        Controller: props => (
          <ResourceList
            filter={{
              leg: 'inbound',
              handled: false,
              claimed: true
            }}
            {...props}
          >
            <EnumField
              sortable={false}
              source="leg"
            />
            <LinkField
              reference="call_queues"
              source="call_queue_id"
            />
            <EnumField
              sortable={false}
              source="status"
            />
            <DateTimeField
              source="created_at"
            />
            <TextField
              sortable={false}
              source="caller_name"
            />
            <PhoneCallPartiesField
              sortable={false}
            />
            <NumberField
              sortable={false}
              source="duration"
            />
            <BooleanField
              source="handled"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
      },
      all: {
        title: 'All Phone Calls',
        Controller: props => (
          <ResourceList
            {...props}
          >
            <EnumField
              sortable={false}
              source="leg"
            />
            <LinkField
              reference="call_queues"
              source="call_queue_id"
            />
            <EnumField
              sortable={false}
              source="status"
            />
            <DateTimeField
              source="created_at"
            />
            <TextField
              sortable={false}
              source="caller_name"
            />
            <PhoneCallPartiesField
              sortable={false}
            />
            <NumberField
              sortable={false}
              source="duration"
            />
            <BooleanField
              source="handled"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
        Filter: (props) => {
          return (
            <Filter
              {...props}
            >
              <StaffTeamSelectInput
                label="Assigned to"
                source="staff_team_id"
              />
              <EnumInput
                filterDefaultValue="received"
                source="status"
              />
              <EnumInput
                source="leg"
              />
              <BooleanInput
                source="handled"
              />
              <BooleanInput
                source="claimed"
              />
            </Filter>
          );
        },
      },
    },
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <StaffTeamSelectInput
          label="Assigned to"
          source="staff_team_id"
        />
        <EnumInput
          
          source="status"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <ReferenceManyField
          label="Organizations"
          reference="organizations"
          sortable={false}
          target="phone_calls"
        >
          <SingleFieldList
            linkType="show"
          >
            <ChipField
              source="name"
            />
          </SingleFieldList>
        </ReferenceManyField>
        <PhoneCallCard
          id={props.id}
          inbound={props.record.leg === 'inbound'}
          record={props.record}
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
