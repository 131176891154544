import Icon from '@material-ui/icons/Map';
import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { BooleanField, EnumField, JsonField, KidScriptField, LinkField, NumberField, TextField } from '../../fields';
import { BooleanInput, EnumInput, JsonInput, KidScriptEventValidationRulesInput, KidScriptInput, KidScriptValidationRulesInput, LongTextInput, NumberInput, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import curriculum_activity_skills from './curriculum_activity_skills';

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'published'
    }
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <EnumInput
          source="processor"
        />
        <FormDataConsumer>
          {({ formData }) => formData.processor && (formData.processor === 'mission_event' || formData.processor === 'mission_kidscript') && (
            <ReferenceInput
              source="mission_id"
            />
          )}
        </FormDataConsumer>
        <LongTextInput
          source="notes"
        />
        <LongTextInput
          source="hint"
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="minutes"
          required
        />
        <BooleanInput
          source="awards_points"
        />
        <BooleanInput
          source="awards_badge_points"
        />
        <BooleanInput
          source="awards_mission_points"
        />
        <NumberInput
          source="coins"
        />
        <BooleanInput
          source="published"
        />
        <FormDataConsumer>
          {({ formData }) => formData.processor && formData.processor != 'manual' && (
            <>
              {(formData.processor != 'message_bus' &&
                <KidScriptInput
                  source="test_kidscript"
                />
              )}
              {(formData.processor === 'event' || formData.processor === 'project_event' || formData.processor === 'mission_event') && (
                <KidScriptEventValidationRulesInput
                  kidScript={formData.test_kidscript}
                  rules={formData.validation_rules}
                  source="validation_rules"
                />
              ) || (formData.processor === 'kidscript' || formData.processor === 'project_kidscript' || formData.processor === 'mission_kidscript') && (
                <KidScriptValidationRulesInput
                  kidScript={formData.test_kidscript}
                  rules={formData.validation_rules}
                  source="validation_rules"
                />
              ) || (
                <JsonInput
                  kidScript={formData.test_kidscript}
                  rules={formData.validation_rules}
                  source="validation_rules"
                />
              )}
            </>
          )}
        </FormDataConsumer>

      </EditDialog>
    ),
    create: props => {

      return (
        <CreateButton
          {...props}
        >
          <TextInput
            source="name"
            required
          />
          <EnumInput
            source="processor"
          />
          <FormDataConsumer>
            {({ formData }) => formData.processor && (formData.processor === 'mission_event' || formData.processor === 'mission_kidscript') && (
              <ReferenceInput
                source="mission_id"
              />
            )}
          </FormDataConsumer>
          <LongTextInput
            source="notes"
          />
          <LongTextInput
            source="hint"
          />
          <EnumInput
            source="difficulty_level"
            required
          />
          <NumberInput
            source="minutes"
            required
          />
          <BooleanInput
            source="awards_points"
          />
          <BooleanInput
            source="awards_badge_points"
          />
          <BooleanInput
            source="awards_mission_points"
          />
          <NumberInput
            source="coins"
          />
          <BooleanInput
            source="published"
          />
          <FormDataConsumer>
            {({ formData }) => formData.processor && formData.processor != 'manual' && (
              <>
                {(formData.processor != 'message_bus' &&
                  <KidScriptInput
                    source="test_kidscript"
                  />
                )}
                {(formData.processor === 'event' || formData.processor === 'project_event' || formData.processor === 'mission_event') && (
                  <KidScriptEventValidationRulesInput
                    kidScript={formData.test_kidscript}
                    rules={formData.validation_rules}
                    source="validation_rules"
                  />
                ) || (formData.processor === 'kidscript' || formData.processor === 'project_kidscript' || formData.processor === 'mission_kidscript') && (
                  <KidScriptValidationRulesInput
                    kidScript={formData.test_kidscript}
                    rules={formData.validation_rules}
                    source="validation_rules"
                  />
                ) || (
                  <JsonInput
                    kidScript={formData.test_kidscript}
                    rules={formData.validation_rules}
                    source="validation_rules"
                  />
                )}
              </>
            )}
          </FormDataConsumer>
        </CreateButton>
    )},
  },
  routes: {
    list: props => (
      <ResourceList
        filter={{ include_unpublished: true }}
        {...props}
      >
        <TextField
          source="name"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="minutes"
        />
        <NumberField
          source="points"
        />
        <EnumField
          source="processor"
        />
        <BooleanField
          source="awards_points"
        />
        <BooleanField
          source="awards_badge_points"
        />
        <BooleanField
          source="awards_mission_points"
        />
        <NumberField
          source="coins"
        />
        <BooleanField
          source="published"
        />
        <TextField
          source="notes"
        />
          
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="notes"
        />
        <TextField
          source="hint"
        />
        <JsonField
          source="validation_rules"
        />
        <KidScriptField
          source="test_kidscript"
        />
        <EnumField
          source="processor"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="minutes"
        />
        <NumberField
          source="points"
        />
        <BooleanField
          source="awards_points"
          addLabel
        />
        <BooleanField
          source="awards_badge_points"
          addLabel
        />
        <BooleanField
          source="awards_mission_points"
          addLabel
        />
        <NumberField
          source="coins"
        />
        <BooleanField
          source="published"
          addLabel
        />
        <NestedResourceList
          reference="curriculum_activity_skills"
        >
          <LinkField
            sortable={false}
            source="skill_id"
          />
          <NumberField
            source="ratio"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <EnumInput
          source="processor"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="hint"
        />
        <NestedResourceList
          reference="curriculum_activity_skills"
        >
          <LinkField
            sortable={false}
            source="skill_id"
          />
          <NumberField
            source="ratio"
          />
        </NestedResourceList>

      </SimpleShowLayout>
    ),
  },
  resources: {
    curriculum_activity_skills,
  },
};
