import Icon from '@material-ui/icons/Loyalty';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, ResourceList } from '../../../controllers';
import { TextField } from '../../../fields';
import { EnumInput, MoneyInput, NumberInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <MoneyInput
          source="amount"
        />
        <MoneyInput
          source="additional_member_amount"
        />
        <NumberInput
          source="number_of_billing_cycles"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ coupon_id: props.parentResource.id }}
        {...props}
      >
        <MoneyInput
          source="amount"
        />
        <MoneyInput
          source="additional_member_amount"
        />
        <NumberInput
          source="number_of_billing_cycles"
        />
        <EnumInput
          label="Which Product"
          source="target"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          sortable={false}
          source="name"
        />
      </ResourceList>
    ),
  },
  components: {
  },
  resources: {
  },
};
