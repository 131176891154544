import Icon from '@material-ui/icons/FormatAlignLeft';
import RecoverIcon from '@material-ui/icons/Undo';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter, FormDataConsumer, NestedResourceList, ResourceList, ResourceShow } from '../../../../controllers';
import { BooleanField, CurrentVersionField, DateTimeField, EditableProjectLinkField, EnumField, ImageField, KidScriptField, LinkField, ShareableProjectLinkField, TextField, VersionField } from '../../../../fields';
import { BooleanInput, EnumInput, ImageInput, NestedReferenceInput, ReferenceInput, SearchInput, TextInput } from '../../../../inputs';
import documents from './documents';
import PreviewGame from './PreviewGame';
import versions from './versions';
import version_flags from './version_flags';
import version_stars from './version_stars';


export default {
  Icon,
  menuItems: [
    {
      name: 'Recover Version',
      type: 'component',
      icon: RecoverIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <NestedReferenceInput
            optionText={version => `v${version.major_version}.${version.minor_version}.${version.patch_version} (${moment(version.created_at).fromNow()})`}
            sort={{ field: 'created_at', order: 'DESC' }}
            source='version_id'
          />
          <FormDataConsumer>
            {({ formData }) => formData.version_id && (
              <PreviewGame
                id={formData.version_id}
              />
            )}
          </FormDataConsumer>
        </EditDialog>
      ),
    },
    {
      type: 'toggle',
      attribute: 'archived'
    }
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <TextInput
          source="description"
        />
        <ImageInput
          source="screenshot"
        />        
        <EnumInput
          source="visibility"
        />
        <ReferenceInput
          reference="project_categories"
          source="project_category_id"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <TextInput
          source="description"
        />
        <ImageInput
          source="screenshot"
        />
        <ReferenceInput
          reference="project_categories"
          source="project_category_id"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <DateTimeField
          source="created_at"
        />
        <ImageField
          source="screenshot_2x"
        />
        <TextField
          source="current_kidscript_version"
        />
        <CurrentVersionField />
        <EnumField
          source="visibility"
        />
        <BooleanField
          source="archived"
        />
        <EditableProjectLinkField />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <DateTimeField
          source="created_at"
          addLabel
        />
        <TextField
          source="current_kidscript_version"
        />
        <EnumField
          source="visibility"
        />
        <DateTimeField
          source="archived_at"
        />
        <ImageField
          source="screenshot_2x"
        />
        <EditableProjectLinkField
          label="Editable Link"
          fullUrl
        />
        <ShareableProjectLinkField
          label="Shareable Link"
          fullUrl
        />

        <NestedResourceList
          reference="documents"
        >
          <TextField
            source="filename"
          />
          <KidScriptField
            source="kidscript"
          />
          <DateTimeField
            source="created_at"
          />
        </NestedResourceList>

        <NestedResourceList
          expand={<PreviewGame />}
          reference="versions"
        >
          <VersionField />
          <BooleanField
            source="published"
          />
          <EnumField
            source="status"
          />
          <LinkField
            label="Project Category"
            reference="project_categories"
            source="project_category_id"
          />
          <DateTimeField
            source="created_at"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="version_flags"
        >
          <LinkField
            label="Flagged By"
            source="user_id"
          />
          <BooleanField
            source="agreed"
          />
          <BooleanField
            source="disagreed"
          />
          <LinkField
            reference='users'
            source="reviewer_id"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="version_stars"
        >
          <LinkField
            label="Starred By"
            source="user_id"
          />
          
        </NestedResourceList>
        
        
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    documents,
    versions,
    version_flags,
    version_stars
  },
};
