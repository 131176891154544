import Icon from '@material-ui/icons/Videocam';
import React from 'react';
import { NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout, Tab, TabbedShowLayout } from '../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, TextField } from '../../fields';
import participations from './participations';

export default {
  Icon,
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="status"
        />
        <EnumField
          source="room_type"
        />
        <DateTimeField
          source="start_at"
        />
        <DateTimeField
          source="opened_at"
          addLabel
        />
        <DateTimeField
          source="end_at"
        />
        <LinkField
          source="occasion_id"
        />
        <TextField
          source="composition_url"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>

          <Tab
            label="Meeting Summary"
          >
            <TextField
              source="id"
            />
            <TextField
              source="twilio_sid"
            />
            <LinkField
              label="Guide"
              source="user_id"
            />
            <LinkField
              source="occasion_id"
            />
            <EnumField
              label="Meeting Status"
              source="status"
            />
            <EnumField
              source="room_type"
            />
            <DateTimeField
              label="Scheduled Start"
              source="start_at"
              addLabel
            />
            <DateTimeField
              label="Scheduled End"
              source="end_at"
              addLabel
            />
            <DateTimeField
              label="Actual Start"
              source="started_at"
              addLabel
            />
            <DateTimeField
              label="Opend At"
              source="opened_at"
              addLabel
            />
            <DateTimeField
              label="Actual End"
              source="ended_at"
              addLabel
            />
            <DateTimeField
              source="abandoned_at"
              addLabel
            />
            <TextField
              source="composition_url"
            />
            <NestedResourceList
              reference="participations"
            >
              <LinkField
                reference="users"
                source="user_id"
              />
              <DateTimeField
                source="first_joined_at"
              />
              <DateTimeField
                source="last_left_at"
              />
              <EnumField
                source="status"
              />
              <EnumField
                source="scope"
              />
              <DateTimeField
                source="abandoned_at"
              />
              <EnumField
                source="abandoner"
              />
            </NestedResourceList>

          </Tab>

          <Tab
            label="Guide Survey"
          >
            <EnumField
              source="sentiment"
            />
            <TextField
              source="comments"
            />
            
            <BooleanField
              source="kid_concerns"
              addLabel
            />
            <BooleanField
              source="technology_issues"
              addLabel
            />
            <BooleanField
              source="parent_concerns"
              addLabel
            />
            <TextField
              source="other_concerns"
            />
        
            <BooleanField
              source="technology_logging_in_issues"
              addLabel
            />
            <BooleanField
              source="technology_audio_video_issues"
              addLabel
            />
            <BooleanField
              source="technology_code_ide_issues"
              addLabel
            />
            <TextField
              source="other_issues"
            />
        
          </Tab>

        </TabbedShowLayout>
      </ResourceShow>
    ),

  },
  components: {
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >

        <NestedResourceList
          reference="participations"
        >
          <LinkField
            reference="users"
            source="user_id"
          />
          <DateTimeField
            source="first_joined_at"
          />
          <DateTimeField
            source="last_left_at"
          />
        </NestedResourceList>

      </SimpleShowLayout>
    ),
  },
  resources: {
    participations
  },
};
