import React from 'react';
import { CreateButton } from '../../../buttons';
import { ReferenceInput } from '../../../inputs';

export default {
  dialogs: {
    create: props => (<CreateButton
      fixedValue={{ user_id: props.parentResource.id }}
      {...props}
                      >
      <ReferenceInput
        source="library_keyring_id"
        required
      />
    </CreateButton>
    ),
  },
  routes: {},
  components: {},
  resources: {},
};
