import { withStyles } from '@material-ui/core/styles';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Children } from 'react';
import { ReferenceManyField } from 'react-admin';
import { connect } from 'react-redux';
import { ActionsButton } from '../buttons';
import Datagrid from '../overrides/Datagrid';
import NestedResourceListToolbar from './NestedResourceListToolbar';
import { PaginationIfAvailable } from './PaginationIfAvailable';

const styles = theme => ({
  root: {
    marginTop: '20px',
    marginBottom: '20px',
    background: '#fafafa',
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    borderBottom: '1px solid #888',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  rowCell: {
    verticalAlign: 'top',
    padding: '12px',
  },
  actionButtonCell: {
    textAlign: 'right',
    verticalAlign: 'middle',
  },
});

// note, we use onRowClick instead of rowClick so it one table doesnt propogate this value down to nested components
const NestedResourceList = ({
  children,
  parentResource,
  mostRecentCreated,
  classes,
  RowStyle,
  resources,
  onRowClick,
  ...props
}) => {

  const basePath = `${props.basePath}/${props.record.id}/${props.reference}`;
  const resourceSingular = pluralize.singular(props.resource);
  const target = `${resourceSingular}_id`;
  const resource = resources[props.reference];

  const rowClick = (id) => {
    if (props.rowClick) {
      return props.rowClick();
    }
    const routes = resource.routes || {};
    if (routes.show) {
      return `${basePath}/${id}/show`;
    } else if (props.expand && 'expand') {
      return 'expand';
    }
  };
  const DefaultRowStyle = (record) => {
    if (record.deleted_at) {
      return {
        backgroundColor: '#FFE6E5',
        textDecoration: 'line-through',
      }
    }
    else if (mostRecentCreated && record.id == mostRecentCreated.id && (Date.now() - mostRecentCreated.timestamp) / 1000 < 5) {
      return {
        backgroundColor: '#e5e3ff',
        textDecoration: 'inherit',
      }
    }
    return {
      backgroundColor: 'white',
      textDecoration: 'inherit',
    }
  }
  return (
    <div
      className={classes.root}
    >
      <NestedResourceListToolbar
        {...props}
        CreateButton={resource && resource.dialogs && resource.dialogs.create &&
          <resource.dialogs.create
            basePath={basePath}
            label="New"
            parentDeleted={props.record.deleted_at}
            parentResource={parentResource}
            resource={props.reference}
          />}
      />
      <ReferenceManyField
        {...props}
        pagination={<PaginationIfAvailable />}
        perPage={props.perPage || 50}
        target={target}
      >
        <Datagrid
          basePath={basePath}
          classes={{ rowCell: classes.rowCell }}
          expand={props.expand && (
            <AddParentResource>
              <div>
                {props.expand}
              </div>
            </AddParentResource>
          )}
          rowClick={onRowClick || rowClick}
          rowStyle={RowStyle || DefaultRowStyle}
        >
          {Children.toArray(children).map((child, i) => (
            // note, we have to peek inside and pull out these props for the table headers
            <AddParentResource
              addLabel={child.props.addLabel}
              key={`col-${i}`}
              label={child.props.label}
              sortable={!!child.props.sortable}
              source={child.props.source}
            >
              {child}
            </AddParentResource>
          ))}
          {resource && (
            <ActionsButton
              cellClassName={classes.actionButtonCell}
              dialogs={resource.dialogs}
              menuItems={resource.menuItems}
              parentResource={parentResource}
              textAlign="right"
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

const AddParentResource = ({ children, parentResource,...props}) => {
  const newParentResource = {
    basePath: props.basePath,
    resource: props.resource,
    id: props.record.id,
    record: props.record,
    parentResource,
  }
  return React.cloneElement(Children.only(children), { parentResource: newParentResource, ...props })
}


NestedResourceList.propTypes = {
  resources: PropTypes.object.isRequired,
  reference: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  return {
    mostRecentCreated: state.mostRecentCreated || {}
  };
}

const mapDispatchToProps = {
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(
  NestedResourceList,
));


