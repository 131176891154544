import Icon from '@material-ui/icons/Receipt';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { Filter, ResourceShow } from '../../../../controllers';
import { EnumField, LinkField, NumberField, TextField } from '../../../../fields';
import { BooleanInput, EnumInput, NestedReferenceInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ invoice_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          filter={{
            // TODO: scope filter to invoiceable bookings
            // statuses: ['deposit_invoiced', 'held'],
            
          }}
          resourcePath={`/${props.parentResource.parentResource.resource}/${props.parentResource.parentResource.id}`}
          source="booking_id"
        />
        <EnumInput
          source="allocation"
        />
      </CreateButton>
    ),
  },
  routes: {
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <LinkField
          source="booking_id"
        />
        <NumberField
          source="amount"
        />
        <EnumField
          source="allocation"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
