import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
  root: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display: 'inline-table',
  },
}

class DateTimeField extends Component {
  static propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
  };
  state = {
    showDateTime: false,
    hover: false,
  };

  handleMouseEnter = (event) => {
    this.setState({ hover: true })
  }

  handleMouseLeave = (event) => {
    this.setState({ hover: false })
  }

  getFormatted(datetime) {
    if (this.state.showDateTime) {
      return moment(datetime).format('llll');
    } else {
      return moment(datetime).fromNow();
    }
  }

  // the tool tip shows the inverse
  getToolTopContent(datetime) {
    if (this.state.showDateTime) {
      return moment(datetime).fromNow();
    } else {
      return moment(datetime).format('llll');
    }
  }

  render() {
    const {
      source,
      classes,
      record,
    } = this.props;

    return (
      <Tooltip
        title={this.getToolTopContent(record[source])}
      >
        <Typography
          className={classes.root}
          variant="body1"
          onClick={(event) => {
            this.setState({
              showDateTime: !this.state.showDateTime,
            });
            event.stopPropagation();
          }}
        >
          {record[source] && this.getFormatted(record[source])}
        </Typography>
      </Tooltip>
    );
  }
}

DateTimeField.defaultProps = {
  addLabel: true,
};

DateTimeField.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default withStyles(style)(DateTimeField);
