import PropTypes from 'prop-types';
import React from 'react';
import { TextInput as ReactAdminTextInput, required as requiredFn } from 'react-admin';

export const TextInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminTextInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

TextInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

TextInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true,
  multiline: true,
  fullWidth: true
};

export default TextInput;

