import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const MeetingStartTimeField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {moment(record.meeting_start_at).tz('America/Chicago').format('llll')}
    {' '}
    CT
  </Typography>
);

MeetingStartTimeField.propTypes = {
  record: PropTypes.object,
};

MeetingStartTimeField.defaultProps = {
  addLabel: true,
  label: 'Session Time',
};

export default MeetingStartTimeField;

