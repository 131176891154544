import Icon from '@material-ui/icons/Map';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { BooleanField, EnumField, FileField, ImageField, LinkField, NumberField, TextField } from '../../fields';
import { BooleanInput, EnumInput, ImageInput, InputGroup, KidScriptInput, NumberInput, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import mission_objects from './mission_objects';
import mission_skills from './mission_skills';
import mission_steps from './mission_steps';

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'published'
    },
    {
      type: 'toggle',
      attribute: 'hidden'
    }
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="minutes"
          required
        />
        <NumberInput
          source="coins"
        />
        <NumberInput
          source="required_multiple_choice_question_count"
        />
        <ImageInput
          label="Icon"
          source="icon"
          required
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <EnumInput
          source="series"
          required
        />
        <BooleanInput
          source="published"
        />
        <BooleanInput
          source="hidden"
        />
        <BooleanInput
          source="guide_only"
        />
        <NumberInput
          source="position"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="General"
        >
          <TextInput
            source="name"
          />
          <TextInput
            source="description"
          />
        </InputGroup>

        <InputGroup
          header="Mission Details"
        >

          <NumberInput
            source="position"
          />
          <EnumInput
            source="difficulty_level"
            required
          />
          <NumberInput
            source="minutes"
            required
          />
          <NumberInput
            source="coins"
          />
          <ReferenceInput
            source="library_keyring_id"
          />
          <EnumInput
            source="series"
            required
          />
          <BooleanInput
            source="digital"
          />
          <BooleanInput
            source="published"
          />
          <BooleanInput
            source="hidden"
          />
          <BooleanInput
            source="guide_only"
          />
        </InputGroup>

        <InputGroup
          header="Imagery"
        >
          <ImageInput
            label="Icon"
            source="icon"
            required
          />
        </InputGroup>
        <InputGroup
          header="Optional"
        >

          <NumberInput
            source="required_multiple_choice_question_count"
          />

        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
        filterDefaultValues={{include_unpublished: true}}
      >
        <NumberField
          source="position"
        />
        <TextField
          source="name"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="coins"
        />
        <LinkField
          source="library_keyring_id"
        />
        <ImageField
          label="Icon"
          source="icon"
          required
        />
        <BooleanField
          source="published"
        />
        <BooleanField
          source="hidden"
        />
        <BooleanField
          source="guide_only"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="minutes"
        />
        <NumberField
          source="coins"
        />
        <LinkField
          source="library_keyring_id"
        />
        <NumberField
          source="required_multiple_choice_question_count"
        />
        <ImageField
          label="Icon"
          source="icon"
        />
        <FileField
          label="Asset"
          source="asset"
        />
        <EnumField
          source="series"
        />
        <BooleanField
          source="published"
          addLabel
        />
        <BooleanField
          source="hidden"
          addLabel
        />
        <BooleanField
          source="guide_only"
          addLabel
        />
        <NumberField
          source="position"
        />
        <NestedResourceList
          reference="mission_steps"
        >
          <EnumField
            source="boilerplate"
          />
          <TextField
            source="header"
          />
          <NumberField
            source="position"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="mission_skills"
        >
          <LinkField
            sortable={false}
            source="skill_id"
          />
          <NumberField
            source="ratio"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="mission_objects"
        >
          <TextField
            source="object_id"
          />
        </NestedResourceList>


      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <EnumInput
          source="difficulty_level"
        />
        <BooleanInput
          source="hidden"
        />
        <BooleanInput
          source="guide_only"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
        <BooleanInput
          source="include_unpublished"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="id"
        />
        <NestedResourceList
          reference="mission_steps"
        >
          <EnumField
            source="boilerplate"
          />
          <TextField
            source="header"
          />
          <NumberField
            source="position"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="mission_skills"
        >
          <LinkField
            sortable={false}
            source="skill_id"
          />
          <NumberField
            source="ratio"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="mission_objects"
        >
          <TextField
            source="object_id"
          />
        </NestedResourceList>

      </SimpleShowLayout>
    ),
  },
  resources: {
    mission_skills,
    mission_objects,
    mission_steps,
  },
};
