import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Labeled } from 'react-admin';
import NumberFormat from 'react-number-format';
import { Field } from 'redux-form';
import titleCase from 'title-case';

function MyNumberFormat({...props }) {
  const { input, input: { onChange, value }, meta, ...other } = props;
  const { touched, error } = meta;
  return (
    <NumberFormat
      {...other}
      decimalScale="2"
      error={!!(touched && error)}
      getInputRef={() => input}
      helperText={touched && error}
      prefix="$"
      value={value}
      fixedDecimalScale
      thousandSeparator
      onValueChange={({ value }) => onChange(value)}
    />
  );
}

MyNumberFormat.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
}

const MoneyInput = ({ source, label, record}) => (
  <Labeled
    label={label || titleCase(source)}
  >
    <span>
      <Field
        component={MyNumberFormat}
        customInput={TextField}
        name={source}
        value={record && record[source]}
      />
    </span>
  </Labeled>
);

MoneyInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};

MoneyInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default MoneyInput;
