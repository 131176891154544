import Icon from '@material-ui/icons/CreditCard';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { ResourceList } from '../../../controllers';
import { TextField } from '../../../fields';
import { CreditCardInput, NestedReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          filter={{
            scope: 'account' || 'administrator',
          }}
          label="Cardholder"
          source="user_id"
          required
        />

        <CreditCardInput />
      </CreateButton>
    ),
  },
  routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <TextField
              source="name"
            />
            <TextField
              source="default"
            />
          </ResourceList>
    ),
  },
  components: {
  },
  resources: {
  },
};
