import Icon from '@material-ui/icons/Event';
import GradeIcon from '@material-ui/icons/Grade';
import UndeleteIcon from '@material-ui/icons/Undo';
import React from 'react';
import { NoteCard } from '../../components';
import { CreateDialog, EditDialog, Filter, NestedResourceList, ResourceList, SimpleShowLayout } from '../../controllers';
import { BooleanField, DateField, DateTimeField, EnumField, LinkField, ReferenceField, StartAndEndTimeField, TextField } from '../../fields';
import { BooleanInput, DateInput, EnumInput, NestedReferenceInput, NumberInput, ReferenceInput } from '../../inputs';
import curriculum_accomplishments from './curriculum_accomplishments';
import notes from './notes';

export default {
    Icon,
    menuItems: [
        {
            type: 'update',
            name: 'Revert',
            icon: UndeleteIcon,
            if: attendance => attendance.status === 'checked_in' || attendance.status === 'completed',
            updateAttributes: { status: 'pending' },
            confirmation: attendance => `Are you sure you want to revert this check-in${attendance.status === 'completed' ? ' and check-out' : ''}?`,
            requireComment: true,
        },
        {
            name: 'New Accomplishment',
            type: 'component',
            icon: GradeIcon,
            Component: props => (
              <CreateDialog
                {...props}
                fixedValue={{
                        user_id: props.record._meta_user_id,
                        organization_id: props.record._meta_organization_id,
                }}
                resource="curriculum_accomplishments"
              >
                <ReferenceInput
                  filter={{ processor: 'manual' }}
                  source="curriculum_activity_id"
                  required
                />
                <NumberInput
                  source="count"
                  required
                />
              </CreateDialog>
            ),
        },
        {
            name: 'New Mission Achievement',
            type: 'component',
            if: user => user.scope === 'child',
            icon: GradeIcon,
            Component: props => (
              <CreateDialog
                {...props}
                fixedValue={{
                        user_id: props.record._meta_user_id,
                        organization_id: props.record._meta_organization_id,
                }}
                resource="mission_achievements"
              >
                <ReferenceInput
                  source="mission_id"
                  required
                />
                <NestedReferenceInput
                  resourcePath={`/users/${props.record._meta_user_id}`}
                  source="project_id"
                  required
                />
                <BooleanInput
                  source="completed"
                  defaultValue
                />
              </CreateDialog>
            ),
        },
    ],
    dialogs: {
        edit: props => (
          <EditDialog
            {...props}
          >
            <EnumInput
              source="status"
              required
            />
          </EditDialog>
        ),
    },
    routes: {
        list: {
          default: {
            title:  'All',
              Controller: props => (
                <ResourceList
                  filterDefaultValues={{ date: new Date().toISOString().substr(0, 10)}}
                  sort={{ field: 'check_in_at', order: 'desc' }}
                  {...props}
                >
                  <DateField
                    source="date"
                  />
                  <ReferenceField
                    label="Time"
                    linkType={false}
                    reference="bookings"
                    source="booking_id"
                  >
                    <StartAndEndTimeField />
                  </ReferenceField>
                  <ReferenceField
                    label="Occasion"
                    linkType={false}
                    reference="occasions"
                    source="_meta_occasion_id"
                  >
                    <TextField
                      source="name"
                    />
                  </ReferenceField>

                  <ReferenceField
                    label="Location"
                    linkType={false}
                    reference="locations"
                    source="_meta_location_id"
                  >
                    <TextField
                      source="name"
                    />
                  </ReferenceField>

                  <LinkField
                    label="Child"
                    parentReference="organizations"
                    parentSource="_meta_organization_id"
                    reference="users"
                    source="_meta_user_id"
                  />
                  <LinkField
                    label="Organization"
                    reference="organizations"
                    source="_meta_organization_id"
                  />

                  <EnumField
                    source="status"
                  />
                  <DateTimeField
                    label="Checked In"
                    source="check_in_at"
                  />
                </ResourceList>    
            ),
            Filter: props => (
              <Filter
                {...props}
              >
                <DateInput
                  label="Date"
                  source="date"
                />
                <NumberInput
                  label="Start Hour"
                  source="start_hour"
                />
                <ReferenceInput
                  source="occasion_id"
                />
                <ReferenceInput
                  source="location_id"
                />
                <EnumInput
                  label="Status"
                  source="status"
                />
              </Filter>
            ),
          },
          checked_in: {
            title: 'Completed',
            Controller: props => (
              <ResourceList
                filter={{status: 'completed'}}
                filterDefaultValues={{ date: new Date().toISOString().substr(0, 10) }}
                sort={{ field: 'check_in_at', order: 'desc' }}
                {...props}
              >
                <DateField
                  source="date"
                />
                <ReferenceField
                  label="Time"
                  linkType={false}
                  reference="bookings"
                  source="booking_id"
                >
                  <StartAndEndTimeField />
                </ReferenceField>
                <ReferenceField
                  label="Occasion"
                  linkType={false}
                  reference="occasions"
                  source="_meta_occasion_id"
                >
                  <TextField
                    source="name"
                  />
                </ReferenceField>
                <ReferenceField
                  label="Location"
                  linkType={false}
                  reference="locations"
                  source="_meta_location_id"
                >
                  <TextField
                    source="name"
                  />
                </ReferenceField>

                <LinkField
                  label="Child"
                  parentReference="organizations"
                  parentSource="_meta_organization_id"
                  reference="users"
                  source="_meta_user_id"
                />
                <LinkField
                  label="Organization"
                  reference="organizations"
                  source="_meta_organization_id"
                />

                <EnumField
                  source="status"
                />
                <DateTimeField
                  label="Checked In"
                  source="check_in_at"
                />
              </ResourceList>
            ),
          },
          no_show: {
            title: 'No Show',
            Controller: props => (
              <ResourceList
                filter={{status: 'no_show'}}
                filterDefaultValues={{ date: new Date().toISOString().substr(0, 10) }}
                sort={{ field: 'check_in_at', order: 'desc' }}
                {...props}
              >
                <DateField
                  source="date"
                />
                <ReferenceField
                  label="Time"
                  linkType={false}
                  reference="bookings"
                  source="booking_id"
                >
                  <StartAndEndTimeField />
                </ReferenceField>
                <ReferenceField
                  label="Occasion"
                  linkType={false}
                  reference="occasions"
                  source="_meta_occasion_id"
                >
                  <TextField
                    source="name"
                  />
                </ReferenceField>

                <LinkField
                  label="Child"
                  parentReference="organizations"
                  parentSource="_meta_organization_id"
                  reference="users"
                  source="_meta_user_id"
                />
                <LinkField
                  label="Organization"
                  reference="organizations"
                  source="_meta_organization_id"
                />

                <EnumField
                  source="status"
                />
                <DateTimeField
                  label="Checked In"
                  source="check_in_at"
                />
              </ResourceList>
            ),
          },
        }
    },
    components: {
        Filter: props => (
          <Filter
            {...props}
          >
            <DateInput
              label="Date"
              source="date"
            />
            <NumberInput
              label="Start Hour"
              source="start_hour"
            />
            <ReferenceInput
              source="occasion_id"
            />
            <ReferenceInput
              source="location_id"
            />
          </Filter>
        ),
        Expand: props => {
            return (
              <SimpleShowLayout
                {...props}
              >
                <LinkField
                  displaySource="email"
                  label="Email"
                  parentReference="organizations"
                  parentSource="_meta_organization_id"
                  reference="users"
                  source="_meta_user_id"
                />
                <NestedResourceList
                  expand={<NoteCard />}
                  reference="notes"
                >
                  <TextField
                    source="topic"
                  />
                  <TextField
                    source="heading"
                  />
                  <DateTimeField
                    source="created_at"
                  />
                  <BooleanField
                    source="pinned"
                  />
                </NestedResourceList>
              </SimpleShowLayout>
            )
        },
    },
    resources: {
        notes,
        curriculum_accomplishments,
    },
};
