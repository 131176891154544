import ReplyIcon from '@material-ui/icons/Reply';
import PropTypes from 'prop-types';
import React from 'react';
import { CreateButton } from '../buttons';
import { EnumInput, RichTextInput, TextInput } from '../inputs';

const EmailReplyButton = ({ template, userId, organizationId, emailId }) => (
  <CreateButton
    fixedValue={{
        user_id: userId,
        'replying_to_id-as-emails': emailId,
    }}
    icon={<ReplyIcon />}
    label="Reply"
    resource="emails"
  >
    <EnumInput
    // override choices to only allow user-editable outbound emails
      choices={[
        { id: 'support_conversation', name: 'support@codeverse.com' },
        { id: 'guide_support_conversation', name: 'guide-support@codeverse.com' },
        { id: 'certification_support_conversation', name: 'certification-support@codeverse.com' },
        { id: 'influencer_conversation', name: 'influencers@codeverse.com'},
      ]}
      defaultValue="support_conversation"
      label="From Address"
      source="template"
    />
    <TextInput
      source="subject"
    />
    <RichTextInput
      label="Message"
      source="body"
      required
    />
  </CreateButton>

);

EmailReplyButton.propTypes = {
  record: PropTypes.object,
  template: PropTypes.string,
  userId: PropTypes.string,
  organizationId: PropTypes.string,
  emailId: PropTypes.string,
};

export default EmailReplyButton;
