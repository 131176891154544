export default {
  choices: [
    { id: 'AED', name: 'AED' },
    { id: 'AFN', name: 'AFN' },
    { id: 'ALL', name: 'ALL' },
    { id: 'AMD', name: 'AMD' },
    { id: 'ANG', name: 'ANG' },
    { id: 'AOA', name: 'AOA' },
    { id: 'ARS', name: 'ARS' },
    { id: 'AUD', name: 'AUD' },
    { id: 'AWG', name: 'AWG' },
    { id: 'AZN', name: 'AZN' },
    { id: 'BAM', name: 'BAM' },
    { id: 'BBD', name: 'BBD' },
    { id: 'BDT', name: 'BDT' },
    { id: 'BGN', name: 'BGN' },
    { id: 'BHD', name: 'BHD' },
    { id: 'BIF', name: 'BIF' },
    { id: 'BMD', name: 'BMD' },
    { id: 'BND', name: 'BND' },
    { id: 'BOB', name: 'BOB' },
    { id: 'BRL', name: 'BRL' },
    { id: 'BSD', name: 'BSD' },
    { id: 'BTN', name: 'BTN' },
    { id: 'BWP', name: 'BWP' },
    { id: 'BYN', name: 'BYN' },
    { id: 'BYR', name: 'BYR' },
    { id: 'BZD', name: 'BZD' },
    { id: 'CAD', name: 'CAD' },
    { id: 'CDF', name: 'CDF' },
    { id: 'CHF', name: 'CHF' },
    { id: 'CLF', name: 'CLF' },
    { id: 'CLP', name: 'CLP' },
    { id: 'CNY', name: 'CNY' },
    { id: 'COP', name: 'COP' },
    { id: 'CRC', name: 'CRC' },
    { id: 'CUC', name: 'CUC' },
    { id: 'CUP', name: 'CUP' },
    { id: 'CVE', name: 'CVE' },
    { id: 'CZK', name: 'CZK' },
    { id: 'DJF', name: 'DJF' },
    { id: 'DKK', name: 'DKK' },
    { id: 'DOP', name: 'DOP' },
    { id: 'DZD', name: 'DZD' },
    { id: 'EGP', name: 'EGP' },
    { id: 'ERN', name: 'ERN' },
    { id: 'ETB', name: 'ETB' },
    { id: 'EUR', name: 'EUR' },
    { id: 'FJD', name: 'FJD' },
    { id: 'FKP', name: 'FKP' },
    { id: 'GBP', name: 'GBP' },
    { id: 'GEL', name: 'GEL' },
    { id: 'GHS', name: 'GHS' },
    { id: 'GIP', name: 'GIP' },
    { id: 'GMD', name: 'GMD' },
    { id: 'GNF', name: 'GNF' },
    { id: 'GTQ', name: 'GTQ' },
    { id: 'GYD', name: 'GYD' },
    { id: 'HKD', name: 'HKD' },
    { id: 'HNL', name: 'HNL' },
    { id: 'HRK', name: 'HRK' },
    { id: 'HTG', name: 'HTG' },
    { id: 'HUF', name: 'HUF' },
    { id: 'IDR', name: 'IDR' },
    { id: 'ILS', name: 'ILS' },
    { id: 'INR', name: 'INR' },
    { id: 'IQD', name: 'IQD' },
    { id: 'IRR', name: 'IRR' },
    { id: 'ISK', name: 'ISK' },
    { id: 'JMD', name: 'JMD' },
    { id: 'JOD', name: 'JOD' },
    { id: 'JPY', name: 'JPY' },
    { id: 'KES', name: 'KES' },
    { id: 'KGS', name: 'KGS' },
    { id: 'KHR', name: 'KHR' },
    { id: 'KMF', name: 'KMF' },
    { id: 'KPW', name: 'KPW' },
    { id: 'KRW', name: 'KRW' },
    { id: 'KWD', name: 'KWD' },
    { id: 'KYD', name: 'KYD' },
    { id: 'KZT', name: 'KZT' },
    { id: 'LAK', name: 'LAK' },
    { id: 'LBP', name: 'LBP' },
    { id: 'LKR', name: 'LKR' },
    { id: 'LRD', name: 'LRD' },
    { id: 'LSL', name: 'LSL' },
    { id: 'LTL', name: 'LTL' },
    { id: 'LVL', name: 'LVL' },
    { id: 'LYD', name: 'LYD' },
    { id: 'MAD', name: 'MAD' },
    { id: 'MDL', name: 'MDL' },
    { id: 'MGA', name: 'MGA' },
    { id: 'MKD', name: 'MKD' },
    { id: 'MMK', name: 'MMK' },
    { id: 'MNT', name: 'MNT' },
    { id: 'MOP', name: 'MOP' },
    { id: 'MRO', name: 'MRO' },
    { id: 'MUR', name: 'MUR' },
    { id: 'MVR', name: 'MVR' },
    { id: 'MWK', name: 'MWK' },
    { id: 'MXN', name: 'MXN' },
    { id: 'MYR', name: 'MYR' },
    { id: 'MZN', name: 'MZN' },
    { id: 'NAD', name: 'NAD' },
    { id: 'NGN', name: 'NGN' },
    { id: 'NIO', name: 'NIO' },
    { id: 'NOK', name: 'NOK' },
    { id: 'NPR', name: 'NPR' },
    { id: 'NZD', name: 'NZD' },
    { id: 'OMR', name: 'OMR' },
    { id: 'PAB', name: 'PAB' },
    { id: 'PEN', name: 'PEN' },
    { id: 'PGK', name: 'PGK' },
    { id: 'PHP', name: 'PHP' },
    { id: 'PKR', name: 'PKR' },
    { id: 'PLN', name: 'PLN' },
    { id: 'PYG', name: 'PYG' },
    { id: 'QAR', name: 'QAR' },
    { id: 'RON', name: 'RON' },
    { id: 'RSD', name: 'RSD' },
    { id: 'RUB', name: 'RUB' },
    { id: 'RWF', name: 'RWF' },
    { id: 'SAR', name: 'SAR' },
    { id: 'SBD', name: 'SBD' },
    { id: 'SCR', name: 'SCR' },
    { id: 'SDG', name: 'SDG' },
    { id: 'SEK', name: 'SEK' },
    { id: 'SGD', name: 'SGD' },
    { id: 'SHP', name: 'SHP' },
    { id: 'SKK', name: 'SKK' },
    { id: 'SLL', name: 'SLL' },
    { id: 'SOS', name: 'SOS' },
    { id: 'SRD', name: 'SRD' },
    { id: 'SSP', name: 'SSP' },
    { id: 'STD', name: 'STD' },
    { id: 'SVC', name: 'SVC' },
    { id: 'SYP', name: 'SYP' },
    { id: 'SZL', name: 'SZL' },
    { id: 'THB', name: 'THB' },
    { id: 'TJS', name: 'TJS' },
    { id: 'TMT', name: 'TMT' },
    { id: 'TND', name: 'TND' },
    { id: 'TOP', name: 'TOP' },
    { id: 'TRY', name: 'TRY' },
    { id: 'TTD', name: 'TTD' },
    { id: 'TWD', name: 'TWD' },
    { id: 'TZS', name: 'TZS' },
    { id: 'UAH', name: 'UAH' },
    { id: 'UGX', name: 'UGX' },
    { id: 'USD', name: 'USD' },
    { id: 'UYU', name: 'UYU' },
    { id: 'UZS', name: 'UZS' },
    { id: 'VEF', name: 'VEF' },
    { id: 'VND', name: 'VND' },
    { id: 'VUV', name: 'VUV' },
    { id: 'WST', name: 'WST' },
    { id: 'XAF', name: 'XAF' },
    { id: 'XAG', name: 'XAG' },
    { id: 'XAU', name: 'XAU' },
    { id: 'XBA', name: 'XBA' },
    { id: 'XBB', name: 'XBB' },
    { id: 'XBC', name: 'XBC' },
    { id: 'XBD', name: 'XBD' },
    { id: 'XCD', name: 'XCD' },
    { id: 'XDR', name: 'XDR' },
    { id: 'XOF', name: 'XOF' },
    { id: 'XPD', name: 'XPD' },
    { id: 'XPF', name: 'XPF' },
    { id: 'XPT', name: 'XPT' },
    { id: 'XTS', name: 'XTS' },
    { id: 'YER', name: 'YER' },
    { id: 'ZAR', name: 'ZAR' },
    { id: 'ZMK', name: 'ZMK' },
    { id: 'ZMW', name: 'ZMW' },
    { id: 'BTC', name: 'BTC' },
    { id: 'JEP', name: 'JEP' },
    { id: 'GGP', name: 'GGP' },
    { id: 'IMP', name: 'IMP' },
    { id: 'XFU', name: 'XFU' },
    { id: 'GBX', name: 'GBX' },
    { id: 'CNH', name: 'CNH' },
    { id: 'EEK', name: 'EEK' },
    { id: 'GHC', name: 'GHC' },
    { id: 'MTL', name: 'MTL' },
    { id: 'TMM', name: 'TMM' },
    { id: 'YEN', name: 'YEN' },
    { id: 'ZWD', name: 'ZWD' },
    { id: 'ZWL', name: 'ZWL' },
    { id: 'ZWN', name: 'ZWN' },
    { id: 'ZWR', name: 'ZWR' },
  ],
};
