import Icon from '@material-ui/icons/Done';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { Filter, ResourceList } from '../../../../controllers';
import { EnumField, LinkField } from '../../../../fields';
import { BooleanInput, EnumInput } from '../../../../inputs';



export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ streaming_chat_message_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          source="status"
          required
        />
      </CreateButton>
    ),

  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="status"
        />
        <LinkField
          reference="users"
          source="reviewer_id"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
