import Icon from '@material-ui/icons/SupervisorAccount';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { NestedReferenceInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          filterToQuery={searchText => ({ name: searchText })}
          source="user_id"
        />
        <TextInput
          source="username"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
