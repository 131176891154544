import UpdateIcon from '@material-ui/icons/Clear';
import Icon from '@material-ui/icons/Event';
import React from 'react';
import { SlotCalendar } from '../../components';
import { Filter, ResourceList, ResourceListPage } from '../../controllers';
import { BookingStatusField, DateField, EnumField, LinkField, StartAndEndTimeField } from '../../fields';
import { DateInput, EnumInput, NumberInput, ReferenceInput } from '../../inputs';

export default {
    Icon,
    menuItems: [
      {
        type: 'update',
        name: 'Mark Attended',
        icon: UpdateIcon,
        if: booking => booking.completed_reason && booking.completed_reason != 'completed',
        updateAttributes: { completed_reason: 'completed' },
      },
    ],
    dialogs: {
    },
    routes: {      
      list: {
        default: {
          title: 'Calendar View',
          Controller: props => (
            <ResourceListPage
              {...props}
            >
              <SlotCalendar />
            </ResourceListPage>
          ), 
        },
        today: {
          title: `Today's Bookings`,
          Controller:  props => (
            <ResourceList
              {...props}
              filter={{ date: new Date().toISOString().substr(0, 10)}}
            >
              <LinkField
                source="organization_id"
              />
              <LinkField
                source="user_id"
              />
              <LinkField
                source="occasion_id"
              />
              <DateField
                source="first_visit_at"
              />
              <DateField
                source="last_visit_at"
              />
              <EnumField
                source="weekday_name"
              />
              <StartAndEndTimeField />
              <BookingStatusField />
            </ResourceList>
          ),   
          Filter:  props => (
            <Filter
              {...props}
            >
              <ReferenceInput
                filter={{in_person: false}}
                reference="occasions"
                source="occasion_id"
              /> 
              <NumberInput
                source="start_hour"
              />
              <EnumInput
                source="status"
              />
            </Filter>
          )          
        },
        all: {
          title: 'All Bookings',
          Controller:  props => (
            <ResourceList
              {...props}
            >
              <LinkField
                source="organization_id"
              />
              <LinkField
                source="user_id"
              />
              <LinkField
                source="occasion_id"
              />
              <DateField
                source="first_visit_at"
              />
              <DateField
                source="last_visit_at"
              />
              <EnumField
                source="weekday_name"
              />
              <StartAndEndTimeField />
              <BookingStatusField />
            </ResourceList>
          ),   
          Filter:  props => (
            <Filter
              {...props}
            >
              <ReferenceInput
                filter={{in_person: false}}
                reference="occasions"
                source="occasion_id"
              /> 
              <DateInput 
                source="date"
              />
              <EnumInput
                source="weekday_name"
              />
              <NumberInput
                source="start_hour"
              />
              <EnumInput
                source="status"
              />
            </Filter>
          )
        },
      }
    },
    components: {
    },
    resources: {
    },
};
