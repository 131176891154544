import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { query } from '../lib/graphql';

const style = {
  method: {
    size: '12px',
    position: 'relative',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  name: {
    position: 'relative',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    left: '30px',
    marginBottom: 0,
  },
  description: {
    position: 'relative',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    left: '30px',
    marginTop: '4px',
  },
  checkbox: {
    position: 'absolute',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    top: '2px',
  },
}

class ChecklistList extends Component {

  componentDidMount() {
    // only run this the first time (to avoid an infinite loop)
    if (typeof this.props.input.value === 'string') {
      const values = Object.values(this.props.methods).map(m => {
        return {
          name: m.name,
          associated: m.associated
        }
      })
      this.props.input.onChange(values)
    }
  }

  onChange(name, associated) {
    const values = this.props.input.value.map(m => {
      if (m.name == name) {
        return {
          name,
          associated
        }
      }
      return {
        name: m.name,
        associated: m.associated
      }
    })
    this.props.input.onChange(values)
  }

  checkAll() {
    const values = this.props.input.value.map(m => {
      return {
        name: m.name,
        associated: true
      }
    })
    this.props.input.onChange(values)
  }

  isAssociated(name) {
    for (let i = 0; i < this.props.input.value.length; i++) {
      const m = this.props.input.value[i];
      if (m.name == name) {
        return m.associated
      }
    }
    throw new Error(`method ${name} not found`)
  }

  render() {
    const { classes, methods, input } = this.props;

    if (this.props.input.value) {
      return (
        <>
          <button
            onClick={() => { this.checkAll() }}
          >
            Check All
          </button>
          {Object.values(methods).map(m => (
            <div
              className={classes.method}
              key={m.name}
            >
              <h3
                className={classes.name}
              >
                {m.name}
                {' '}
                (from
                {' '}
                {m.engineName}
                {' '}
                engine)
              </h3>
              <p
                className={classes.description}
              >
                {m.description}
              </p>
              <input
                checked={this.isAssociated(m.name)}
                className={classes.checkbox}
                name={m.name}
                type="checkbox"
                onChange={(e) => { this.onChange(m.name, e.target.checked) }}
              />

            </div>
          ))}
        </>
      )
    }
    else {
      return (
        <div>
          <h1>
            Creating...
          </h1>
        </div>
      )
    }

  }
}


export default class BulkEngineMethodLink extends Component {
  state = {
    methods: null,
  };

  componentDidMount() {
    const { record } = this.props;

    // Get a client token for authorization from your server
    query(`{
  libraryObject(id: "${record.id}") {
    libraryEngineVersion {
      libraryEngineMethods {
        name
        description
        libraryEngine {
          name
        }
      }
    }
    libraryEngineMethods {
      name
      libraryEngine {
        name
      }
    }
  }
}`
    ).then((response) => {
      const methods = {}
      response.data.libraryObject.libraryEngineVersion.libraryEngineMethods.forEach(m => {
        methods[m.name] = {
          name: m.name,
          description: m.description,
          engineName: m.libraryEngine.name,
          associated: false
        }
      });

      response.data.libraryObject.libraryEngineMethods.forEach(m => {
        methods[m.name].associated = true
      });

      this.setState({
        methods,
      });

    })

  }
  render() {
    const { methods } = this.state
    if (methods) {
      return (
        <Field
          component={withStyles(style)(ChecklistList)}
          methods={methods}
          name="engine_method_associations"
        />
      )
    } else {
      return (
        <div>
          <h1>
            Loading...
          </h1>
        </div>
      );
    }
  }
}

