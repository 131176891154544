import { withStyles } from '@material-ui/core/styles';
import IconContentAdd from '@material-ui/icons/Add';
import pluralize from 'pluralize';
import React, { Children, Component } from 'react';
import { Button, crudCreate, refreshView, resetForm, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { destroy, reset, submit } from 'redux-form';
import titleCase from 'title-case';
import { Alert } from '../components';
import { CreateDialog } from '../controllers';

const style = {
};

class CreateButton extends Component {
  state = {
    showDialog: false,
    showCanNotAddToDeletedParent: false,
  };

  handleClick = () => {
    const { parentDeleted } = this.props
    if (parentDeleted) {
      this.setState({ showCanNotAddToDeletedParent: true });
    } else {
      this.setState({ showDialog: true });
    }
  };

  handleCloseClick = () => {
    this.setState({
      showDialog: false,
    });
  };

  render() {
    const {
      showDialog,
      showCanNotAddToDeletedParent,
    } = this.state;

    const {
      icon,
      label,
      children,
      parentDeleted,
      parentResource,
      resource,
    } = this.props;

    return (
      <div
        onClick={event => event.stopPropagation()}
      >
        <Button
          label={label || `New ${titleCase(pluralize.singular(resource))}`}
          onClick={this.handleClick}
        >
          { icon || <IconContentAdd /> }
        </Button>

        {(parentResource && parentDeleted &&
          <Alert
            content={`You can not add ${titleCase(resource)} to deleted ${titleCase(parentResource.resource)}`}
            isOpen={showCanNotAddToDeletedParent}
            title={`This ${titleCase(pluralize.singular(parentResource.resource))} is deleted`}
            onClose={this.handleCloseClick}
          />
        )}

        {showDialog && (
          <CreateDialog
            {...this.props}
            close={this.handleCloseClick}
          >
            {Children.toArray(children).map(child => React.cloneElement(child, { parentResource }))}
          </CreateDialog>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
  };
}

const mapDispatchToProps = {
  reset,
  resetForm,
  refreshView,
  destroy,
  showNotification,
  crudCreate,
  submit,
};

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(
  CreateButton,
));
