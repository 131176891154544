import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Fragment } from 'react';
import compose from 'recompose/compose';

import {
  MenuItemLink
} from 'react-admin';

const styles = {
  listItem: {
    paddingLeft: '1rem',
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: '1rem',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
};

const SubMenu = ({
  handleToggle,
  onClick,
  to,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  classes,
  children,
}) => (
  <Fragment>
    { to ? (
      <MenuItemLink
        leftIcon={icon}
        primaryText={name}
        to={to}
        onClick={onClick}
      />
    ) : (
      <ListItem
        className={classes.listItem}
        button
        dense
        onClick={handleToggle}
      >
        <ListItemIcon>
          {isOpen ? <ExpandMore /> : icon}
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary={isOpen ? name : ''}
          secondary={isOpen ? '' : name}
          inset
        />
      </ListItem>
    )}
    <Collapse
      in={isOpen}
      timeout="auto"
      unmountOnExit
    >
      <List
        className={sidebarIsOpen
            ? classes.sidebarIsOpen
            : classes.sidebarIsClosed}
        component="div"
        dense
        disablePadding
      >
        {children}
      </List>
      <Divider />
    </Collapse>
  </Fragment>
);

const enhance = compose(
  withStyles(styles),
);

export default enhance(SubMenu);
