import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import titleCase from 'title-case';

const LeadStatusField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.won && 'Won' || record.lost && `Lost (${titleCase(record.lost_code)})` || 'Active'}
  </Typography>
);

LeadStatusField.propTypes = {
  record: PropTypes.object,
};

LeadStatusField.defaultProps = {
  addLabel: true,
  label: 'Status',
};

export default LeadStatusField;

