import Icon from '@material-ui/icons/Done';
import React from 'react';
import { Filter, ResourceList } from '../../controllers';
import { EnumField, LinkField, TextField } from '../../fields';
import { BooleanInput } from '../../inputs';



export default {
  Icon,
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="message"
        />
        <EnumField
          source="status"
        />
        <LinkField
          reference="users"
          source="reviewer_id"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
