import Icon from '@material-ui/icons/BookmarkBorder';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter, ResourceList } from '../../../../controllers';
import { EnumField, TextField } from '../../../../fields';
import { BooleanInput, EnumInput, SearchInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="question"
        />
        <TextInput
          source="answer_a"
        />
        <TextInput
          source="answer_b"
        />
        <TextInput
          source="answer_c"
        />
        <TextInput
          source="answer_d"
        />
        <EnumInput
          source="answer"
        />
        <TextInput
          source="position"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ mission_step_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="question"
        />
        <TextInput
          source="answer_a"
        />
        <TextInput
          source="answer_b"
        />
        <TextInput
          source="answer_c"
        />
        <TextInput
          source="answer_d"
        />
        <EnumInput
          source="answer"
        />
        <TextInput
          source="position"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="question"
        />
        <TextField
          source="answer_a"
        />
        <TextField
          source="answer_b"
        />
        <TextField
          source="answer_c"
        />
        <TextField
          source="answer_d"
        />
        <EnumField
          source="answer"
        />
        <TextField
          source="position"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
