import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#c2a9fa',
      main: '#8c6af6',
      dark: '#4604b6',
      contrastText: '#fff',
    },
    secondary: {
      light: '#a6a4bd',
      main: '#313653',
      dark: '#514e73',
      contrastText: '#fff',
    },
  },
  overrides: {
    Layout: {
      content: {
        paddingTop: '0',
      },
    },
    Datagrid: {
      rowCell: {
        color: 'white',
      },
    },
    MuiTableCell: {
      head: {
        color: 'rgba(0, 0, 0, 0.84)',
      },
      root: {
        color: 'white',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#f5f5f5',
      },
    },
  },
});
