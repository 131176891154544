import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const TrialPeriodStartEndField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.status === 'trial' || record.status === 'pending' ? `${(moment(record.trial_period_start_at).format('llll'))} to ${moment(record.trial_period_end_at).format('llll')}` : `Not in Trial`}    
  </Typography>
);

TrialPeriodStartEndField.propTypes = {
    record: PropTypes.object,
};

TrialPeriodStartEndField.defaultProps = {
    addLabel: true,
    label: 'Trial Period',
};

export default TrialPeriodStartEndField;

