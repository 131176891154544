import Icon from '@material-ui/icons/Textsms';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, TextField, TextMessageBodyField } from '../../../fields';
import { BooleanInput, EnumInput, LongTextInput, SearchInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <EnumField
          source="status"
        />
        <TextMessageBodyField />
        <DateTimeField
          source="created_at"
          sortable
        />
        <BooleanInput
          source="handled"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          defaultValue="outbound"
          source="template"
          required
        />
        <TextInput
          defaultValue="1"
          source="to_dialing_code"
          required
        />
        <TextInput
          source="to_phone_number"
        />
        <LongTextInput
          source="body"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="status"
        />
        <TextMessageBodyField />
        <DateTimeField
          source="created_at"
          sortable
        />
        <BooleanField
          source="handled"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <EnumInput
          source="status"
        />
        <EnumInput
          filterDefaultValues={{ template: 'inbound' }}
          source="template"
        />
        <BooleanInput

          source="handled"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          label="To Phone Number"
          source="to_phone_number_international_formatted"
        />
        <TextField
          label="From Phone Number"
          source="from_phone_number_international_formatted"
        />
        <LinkField
          reference="users"
          source="handled_by_id"
          allowEmpty
        />
        {/* <CreateWithinDialogButton
            label="Reply"
            icon={<ReplyIcon />}
            resource="text_messages"
            fixedValues={
              {
                template: 'outbound',
                to_dialing_code: props.record.from_dialing_code,
                to_phone_number: props.record.from_phone_number,
              }
            }
          >
            <LongTextInput source="body" />
          </CreateWithinDialogButton>
          <ReferenceManyField
            label="Organizations"
            target="text_messages"
            reference="organizations"
            sortable={false}
          >
            <SingleFieldList linkType="show">
              <TextField source="name" />
            </SingleFieldList>
          </ReferenceManyField>
          <TextMessageCard
            record={props.record}
            inbound={props.record.template === 'inbound'} */}
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
