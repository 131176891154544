import Icon from '@material-ui/icons/List';
import React from 'react';
import { Filter, ResourceList } from '../../controllers';
import { BooleanField, DateField, LinkField, NumberField, TextField } from '../../fields';
import { BooleanInput, DateInput, NumberInput, ReferenceInput, TeamMemberSelectInput } from '../../inputs';


export default {
    Icon,
    dialogs: {
    },
    routes: {
        list: props => (
          <ResourceList
            filterDefaultValues={{ 
              after: new Date().toISOString().substr(0, 10) ,
              hide_full: true,
              hide_blocked: true
            }}
            {...props}
          >
            <DateField
              label="Date"
              source="start_date"
            />
            
            <TextField
              label="Time"
              source="start_time"
            />
            <LinkField
              source="occasion_id"
            />
            <LinkField
              source="location_id"
            />
            <LinkField
              label="Guide"
              source="user_id"
            />
            <NumberField
              source="capacity"
            />
            <BooleanField
              source="blocked"
            />
          </ResourceList>
        ),
    },
    components: {
        Filter: props => (
          <Filter
            {...props}
          >
            <TeamMemberSelectInput
              label="Guide"
              source="user_id"
            />
            <ReferenceInput
              source="occasion_id"
            />
            <ReferenceInput
              source="location_id"
            />
            <DateInput
              source="after"
            />
            <DateInput
              source="before"
            />
            <NumberInput
              label="Start Hour"
              source="start_hour"
            />
            <NumberInput
              label="End Hour"
              source="end_hour"
            />
            <BooleanInput
              label="Hide Full"
              source="hide_full"
            />
            <BooleanInput
              label="Hide Blocked"
              source="hide_blocked"
            />
          </Filter>
        ),
    },
    resources: {
    },
};
