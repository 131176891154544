import Icon from '@material-ui/icons/Receipt';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { ResourceShow } from '../../../../../controllers';
import { DateField, TextField } from '../../../../../fields';
import { TextInput } from '../../../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ charge_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="amount"
        />
      </CreateButton>
    ),
  },
  routes: {
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          sortable={false}
          source="amount"
        />
        <DateField
          source="created_at"
        />
      </ResourceShow>
    ),
    
  },
  resources: {
  },
};
