import Icon from '@material-ui/icons/Note';
import React from 'react';
import titleCase from 'title-case';
import { Filter, ResourceList, SimpleShowLayout } from '../../controllers';
import { DateTimeField, EnumField, JsonField, LinkField, TextField } from '../../fields';
import { DateInput, EnumInput, ReferenceInput, TextInput } from '../../inputs';

export default {
  Icon,
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="topic"
        />
        <EnumField
          source="referencing_type"
        />
        <TextField
          source="comment"
        />
        <LinkField
          source="organization_id"
          allowEmpty
        />
        <LinkField
          reference="users"
          source="actor_id"
          allowEmpty
        />
        <DateTimeField
          source="created_at"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <TextInput
          source="referencing_id"
        />
        <ReferenceInput
          source="organization_id"
        />
        <ReferenceInput
          optionText={user => `${user.name} - ${titleCase(user.scope)} ${user.email ? `(${user.email})` : ''}`}
          reference="users"
          source="actor_id"
        />
        <ReferenceInput
          optionText={user => `${user.name} - ${titleCase(user.scope)} ${user.email ? `(${user.email})` : ''}`}
          reference="users"
          source="current_user_id"
        />
        <DateInput
          source="created_after"
        />
        <DateInput
          source="created_before"
        />
        <EnumInput
          source="topic"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <EnumField
          source="topic"
        />
        <TextField
          source="comment"
        />
        <JsonField
          source="details"
        />
        <LinkField
          source="organization_id"
          allowEmpty
        />
        <LinkField
          reference="users"
          source="actor_id"
          allowEmpty
        />
        <LinkField
          reference="users"
          source="current_user_id"
          allowEmpty
        />
        <TextField
          source="referencing_id"
        />
        <EnumField
          source="referencing_type"
        />
        <TextField
          source="oauth_application_id"
        />
        <DateTimeField
          source="created_at"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
