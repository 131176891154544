import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconCancel from '@material-ui/icons/Cancel';
import pluralize from 'pluralize';
import React, { Children, Component } from 'react';
import { Button, REDUX_FORM_NAME, SaveButton, SimpleForm, TextInput, crudCreate, refreshView, resetForm, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { destroy, isSubmitting, reset, submit } from 'redux-form';
import titleCase from 'title-case';
import { Alert } from '../components';

const style = {
  root: {
    zIndex: '1',
  },
  content: {
  },
  title: {
  },
  actions: {
    margin: '18px 20px',
  },
  actionButton: {
    alignSelf: 'flex-start',
  },
  comment: {
    marginTop: '16px',
  },
  errorSummary: {
    color: '#f44336',
    flexGrow: 1,
  },
  errorDetail: {
    color: '#f44336',
  }
};

class CreateDialog extends Component {
  state = {
    error: false,
    generalErrorNotAcknowledged: true,
    isCreating: false
  };

  resetAll() {
    const {
      resetForm,
      destroy,
    } = this.props;

    resetForm();
    destroy(REDUX_FORM_NAME);
  }

  handleSaveClick = () => {
    const { submit } = this.props;
    submit(REDUX_FORM_NAME);
  };

  handleComplete = () => {
    const { refreshView } = this.props;
    this.setState({
      isCreating: false
    });
    const {
      close,
    } = this.props;
    close();
    this.resetAll();
    refreshView()
  };

  handleGeneralErrorCloseClick = () => {
    this.setState({ generalErrorNotAcknowledged: false, isCreating: false });
  };

  handleCloseClick = () => {
    const {
      close,
    } = this.props;

    close();
    this.resetAll();
  }

  handleSubmit = (values) => {
    const {
      crudCreate,
      resource,
    } = this.props;

    this.setState({
      generalErrorNotAcknowledged: true,
      isCreating: true
    });

    crudCreate(
      resource,
      values,
      `/${resource}`,
      this.handleComplete,
    );
  };

  render() {
    const {
      generalErrorNotAcknowledged,
      isCreating,
    } = this.state;

    const {
      title,
      children,
      parentResource,
      fixedValue = {},
      defaultValue = {},
      classes,
      resource,
      basePath,
      submitErrors,
      syncErrors,
      isSubmitting,
    } = this.props;

    const resourceTitleName = resource && titleCase(pluralize.singular(resource));
    const createErrors = Object.assign({}, submitErrors, syncErrors)
    const generalError = createErrors && createErrors.general;
    const attributeErrors = createErrors && Object.keys(createErrors).filter(k => k !== 'general').map(k => `${titleCase(k.replace(/_id$/, ''))} ${createErrors[k]}`)
    return (
      <>
        {(generalError && isCreating && generalErrorNotAcknowledged &&
          <Alert
            content={generalError}
            isOpen={!!generalError}
            title={`Can't create ${titleCase(resource)}`}
            onClose={this.handleGeneralErrorCloseClick}
          />
        )}

        <Dialog
          aria-label={`Create ${resourceTitleName}`}
          className={classes.root}
          fullWidth
          open
          onClick={event => event.stopPropagation()}
          onClose={this.handleCloseClick}
        >
          <DialogTitle
            className={classes.title}
          >
            {title || `Create ${resourceTitleName}`}
          </DialogTitle>
          <Divider />
          <DialogContent
            className={classes.content}
          >
            <SimpleForm
              basePath={basePath}
              defaultValue={Object.assign({}, fixedValue, defaultValue)}
              form={REDUX_FORM_NAME}
              resource={resource}
              toolbar={null}
              onSubmit={this.handleSubmit}
            >
              {Object.keys(fixedValue).map(fixedValue => (
                <TextInput
                  key={fixedValue}
                  source={fixedValue}
                  disabled
                  required
                />
              ))}
              {Children.toArray(children).map(child => React.cloneElement(child, { parentResource }))}
            </SimpleForm>
          </DialogContent>
          <Divider />

          <DialogActions
            className={classes.actions}
          >
            {attributeErrors && isCreating && (!(generalError && generalErrorNotAcknowledged)) && (
              <div
                className={classes.errorSummary}
              >
                {(generalError &&
                  <Typography
                    className={classes.errorDetail}
                    component="p"
                  >
                    {generalError}
                  </Typography>
                )}
                {attributeErrors.map((attributeError, i) => (
                  <Typography
                    className={classes.errorDetail}
                    component="p"
                    key={`attributeError-${i}`}
                  >
                    {attributeError}
                  </Typography>
                ))}
              </div>
            )}
            <SaveButton
              className={classes.actionButton}
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              className={classes.actionButton}
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
    submitErrors: state.form && state.form['record-form'] && state.form['record-form'].submitErrors || {},
    syncErrors: state.form && state.form['record-form'] && state.form['record-form'].syncErrors || {},
  };
}

const mapDispatchToProps = {
  reset,
  resetForm,
  refreshView,
  destroy,
  showNotification,
  crudCreate,
  submit,
};

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(
  CreateDialog,
));
