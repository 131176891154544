import Icon from '@material-ui/icons/Email';
import AssignIcon from '@material-ui/icons/Input';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, RichTextField, TextField } from '../../../fields';
import { BooleanInput, EnumInput, NestedReferenceInput, RichTextInput, SearchInput, StaffTeamSelectInput, TextInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'handled'
    },
    {
      type: 'toggle',
      attribute: 'claimed'
    },
    {
      name: 'Assign',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <StaffTeamSelectInput
            source="staff_team_id"
          />
        </EditDialog>
      ),
    },
  ],
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          // override choices to only allow user-editable outbound emails
          choices={[
            { id: 'support_conversation', name: 'support@codeverse.com' },
            { id: 'guide_support_conversation', name: 'guide-support@codeverse.com' },
            { id: 'certification_support_conversation', name: 'certification-support@codeverse.com'},
            { id: 'influencer_conversation', name: 'influencers@codeverse.com'},
            { id: null, name: 'Guide Contractor Agreement Notices', disabled: true},
            { id: 'guide_contractor_agreement_signed/renewal_notice', name: 'Renewal Notice' },
            { id: 'guide_contractor_agreement_signed/renewal_notice_without_raise', name: 'Renewal Notice Without Raise' },
          ]}
          defaultValue="support_conversation"
          label="From Address"
          source="template"
        />
        <NestedReferenceInput
          label="Recipient"
          optionText={users => `${users.name} <${users.email}>`}
          source="user_id"
          required
        />

        <TextInput
          source="subject"
        />
        <RichTextInput
          source="body"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="status"
        />
        <EnumField
          source="template"
        />
        <TextField
          source="subject"
        />
        <TextField
          source="from_name"
        />
        <TextField
          source="from_email"
        />
        <DateTimeField
          source="created_at"
          sortable
        />
        <BooleanField
          source="handled"
        />
      </ResourceList>
    ),

  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <EnumInput
          filterDefaultValues={{ status: 'received' }}
          source="status"
        />
        <EnumInput
          filterDefaultValues={{ template: 'inbound' }}
          source="template"
        />
        <BooleanInput

          source="handled"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <LinkField
          label="Handled by"
          reference="users"
          source="handled_by_id"
          allowEmpty
        />
        <RichTextField
          label="Message"
          source="html_content"
        />
        <RichTextField
          label="Message"
          source="body"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
