import Icon from '@material-ui/icons/Assignment';
import moment from 'moment';
import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { CreateButton } from '../../../../buttons';
import { EditDialog, ResourceList, ResourceShow } from '../../../../controllers';
import { FileField, ImageField, ReferenceField, RichTextField, TextField } from '../../../../fields';
import { FileInput, NestedReferenceInput, ReferenceInput, TextInput } from '../../../../inputs';


export default {
    Icon,
    dialogs: {
      edit: props => (
        <EditDialog
          {...props}
        >
          <TextInput
            source="name"
          />
        </EditDialog>
      ),
      create: props => (
        <CreateButton
          fixedValue={{ contract_version_id: props.parentResource.id }}
          {...props}
        >
          <ReferenceInput
            label="Organization"
            optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
            shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
            source="organization_id"
            required
          />

          <FormDataConsumer>
            {({ formData }) => formData.organization_id && (            
              <NestedReferenceInput
                resourcePath={`/organizations/${formData.organization_id}`}
                source="user_id"
                required
              />              
            )}
          </FormDataConsumer>
          <FileInput
            source="scan"
          />
        </CreateButton>
      ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <ImageField
              source="signature"
            />
            <FileField
              source="scan"
            />
          </ResourceList>
        ),
        show: props => (
          <ResourceShow
            {...props}
          >
            <TextField
              source="name"
            />
            <ImageField
              source="signature"
            />
            <FileField
              source="scan"
              target="_blank"
              title="View"
            />
            <ReferenceField
              linkType={false}
              reference="contract_versions"
              source="version_id"
            >
              <RichTextField
                source="contents"
              />
            </ReferenceField>
          </ResourceShow>
        ),
  
    },
    components: {
    },
    resources: {
    },
};
