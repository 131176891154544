import Icon from '@material-ui/icons/FormatAlignRight';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { NestedReferenceInput } from '../../../../../inputs';


export default {
  Icon,
 
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ 
          user_id: self.localStorage.user_id 
        }}
        {...props}
      >
        <NestedReferenceInput
          optionText={version => `Created ${moment(version.created_at).format('lll')}`}
          source="version_id"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
