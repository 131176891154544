import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../controllers';
import { LinkField, TextField } from '../../../fields';
import { BooleanInput, TeamMemberSelectInput, TextInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [

  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="title"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ staff_team_id: props.parentResource.id }}
        {...props}
      >
        <TeamMemberSelectInput
          label="Team Member"
          source="user_id"
        />
        <TextInput
          source="title"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <LinkField
              source="user_id"
            />
            <TextField
              source="title"
            />
          </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          source="user_id"
        />
        <TextField
          source="title"
        />
      </ResourceShow>
    ),
  },
  components: {
     Filter: props => (
       <Filter
         {...props}
       >

         <BooleanInput
           label="Deleted"
           source="deleted"
         />
       </Filter>
    ),
  },
  resources: {
  },
};
