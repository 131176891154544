import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../../controllers';
import { JavaScriptField, TextField } from '../../../fields';
import { BooleanInput, JavaScriptInput, SearchInput, TextInput } from '../../../inputs';
import library_object_reaction_modules from './library_object_reaction_modules';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="key"
        />
        <JavaScriptInput
          source="javascript"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ library_object_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="key"
        />
        <JavaScriptInput
          source="javascript"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="key"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>

          <Tab
            label="Preview"
          >
            <JavaScriptField
              source="preview"
            />
          </Tab>

          <Tab
            label="General"
          >
            <TextField
              source="key"
            />
            <TextField
              source="javascript"
            />
          </Tab>

        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    library_object_reaction_modules,
  },
};
