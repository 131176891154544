import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, NestedResourceList, ResourceList, ResourceShow } from '../../../../controllers';
import { BooleanField, EnumField, LinkField, NumberField, TextField } from '../../../../fields';
import { BooleanInput, EnumInput } from '../../../../inputs';
import badge_comparables from '../badge_comparables';

const badge_conditions = {
  Icon,
  menuItems: [
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <EnumInput
          source="comparator"
        />
        <BooleanInput
          source="single_project"
        />
      </EditDialog>
    ),
    create: props => {
      let badgeResource = props.parentResource;
      while (badgeResource.parentResource) {
        badgeResource = badgeResource.parentResource
      }
      const badge_id = badgeResource.id
      return (
        <CreateButton
          fixedValue={{
          badge_id,
          badge_condition_id: props.parentResource.id
          }}
          {...props}
        >
          <EnumInput
            source="comparator"
          />
          <BooleanInput
            source="single_project"
          />
        </CreateButton>
    )},
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="id"
        />
        <EnumField
          source="comparator"
        />
        <BooleanField
          source="single_project"
        />
        <TextField
          source="badge_condition_id"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <EnumField
          source="comparator"
        />

        <NestedResourceList
          reference="badge_conditions"
        >
          <TextField
            source="id"
          />
          <EnumField
            source="comparator"
          />
          <BooleanField
            source="single_project"
          />
          <TextField
            source="badge_condition_id"
          />
          <NumberField
            source="count"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="badge_comparables"
        >
          <LinkField
            reference="curriculum_activities"
            source="activity_id"
          />
        </NestedResourceList>

      </ResourceShow>
    ),
  },
  components: {
  },
  resources: {
    badge_comparables
  },
};

badge_conditions.resources.badge_conditions = badge_conditions
export default badge_conditions
