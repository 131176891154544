import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const AddressField = ({ record = {} }) => (
  <div>
    <div>
      <Typography
        variant="body1"
      >
        {record.address1}
      </Typography>
    </div>
    { record.address2 &&
      <div>
        <Typography
          variant="body1"
        >
          {record.address2}
        </Typography>
      </div>}
    <div>
      <Typography
        variant="body1"
      >
        {record.city}
        {', '} 
        {record.state}
        {' '}
        {record.zip_code}
      </Typography>
    </div>
    { record.country_code != 'US' &&
      <div>
        <Typography
          variant="body1"
        >
          {record.country_code}
        </Typography>
      </div>}
  </div>
);

AddressField.propTypes = {
  record: PropTypes.object,
};

AddressField.defaultProps = {
  addLabel: true,
  label: 'Address',
};

export default AddressField;

