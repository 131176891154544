import titleCase from 'title-case';


function error(message) {
  throw message;
}

function modelAttribute(modelName, attributeName) {
  const model = window.enums[modelName] || error(`no enums found for model ${modelName}`);
  return model[attributeName] || error(`no enums found for attribute ${attributeName} on ${modelName} models`);
}

export default {
  choicesFor(modelName, attributeName) {
    return modelAttribute(modelName, attributeName).values.map((name) => {
      return { id: name, name: titleCase(name) };
    });
  },
  isRequiredFor(modelName, attributeName) {
    return !modelAttribute(modelName, attributeName).allow_null;
  },
  isOptionalFor(modelName, attributeName) {
    return modelAttribute(modelName, attributeName).allow_null;
  },
};
