import { withStyles } from '@material-ui/core/styles';
import pluralize from 'pluralize';
import React from 'react';
import { Admin, Layout, Login, Resource } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import { Menu, Theme } from './layout';
import base64UploadDecorator from './lib/base64UploadDecorator';
import reducers from './reducers';
import customRoutes from './routes';
import sagas from './sagas';

pluralize.addSingularRule(/skus$/i, 'sku');

const styles = {
  root: {
    width: '100%',
    maxWidth: 500,
  },
};

const AppLayout = props => (
  <Layout
    {...props}
    menu={Menu}
  />
);

const LoginPage = ({ classes, ...props }) => (
  <Login
    {...props}
    backgroundImage="/background.jpg"
  />
);

const App = () => (
  <Admin
    appLayout={AppLayout}
    authProvider={authProvider}
    customReducers={reducers}
    customRoutes={customRoutes}
    customSagas={sagas}
    dataProvider={base64UploadDecorator(dataProvider)}
    loginPage={LoginPage}
    theme={Theme}
    title="Houston"
  >
    <Resource
      name="assignments"
    />
    <Resource
      name="attendances"
    />
    <Resource
      name="availabilities"
    />
    <Resource
      name="availability_instances"
    />
    <Resource
      name="avatars"
    />
    <Resource
      name="badge_achievements"
    />
    <Resource
      name="badge_dependent_badges"
    />
    <Resource
      name="badge_skills"
    />
    <Resource
      name="badges"
    />
    <Resource
      name="badge_conditions"
    />
    <Resource
      name="badge_comparables"
    />
    <Resource
      name="bank_balance_adjustments"
    />
    <Resource
      name="bank_balances"
    />
    <Resource
      name="bookings"
    />
    <Resource
      name="booking_instances"
    />
    <Resource
      name="call_queue_memberships"
    />
    <Resource
      name="call_queues"
    />
    <Resource
      name="charge_refunds"
    />
    <Resource
      name="charges"
    />
    <Resource
      name="checklist_comments"
    />
    <Resource
      name="checklist_items"
    />
    <Resource
      name="checklist_submission_items"
    />
    <Resource
      name="checklist_submissions"
    />
    <Resource
      name="checklist_versions"
    />
    <Resource
      name="checklists"
    />
    <Resource
      name="checkr_accounts"
    />
    <Resource
      name="contracts"
    />
    <Resource
      name="contract_executions"
    />
    <Resource
      name="contract_versions"
    />
    <Resource
      name="coupon_occasions"
    />
    <Resource
      name="coupon_subscription_discounts"
    />
    <Resource
      name="coupons"
    />
    <Resource
      name="courses"
    />
    <Resource
      name="credits"
    />
    <Resource
      name="curriculum_activities"
    />
    <Resource
      name="curriculum_accomplishments"
    />
    <Resource
      name="curriculum_activity_skills"
    />
    <Resource
      name="curriculum_scores"
    />
    <Resource
      name="difficulties"
    />
    <Resource
      name="documents"
    />
    <Resource
      name="earnings"
    />
    <Resource
      name="economy_ledgers"
    />
    <Resource
      name="emails"
    />
    <Resource
      name="friend_requests"
    />
    <Resource
      name="followings"
    />
    <Resource
      name="guide_availabilities"
    />
    <Resource
      name="guide_availability_instances"
    />
    <Resource
      name="guide_blocks"
    />
    <Resource
      name="guide_preferences"
    />
    <Resource
      name="guide_ratings"
    />
    <Resource
      name="guide_profiles"
    />
    <Resource
      name="guide_schedulables"
    />
    <Resource
      name="guide_ranks"
    />
    <Resource
      name="guide_rank_learning_path_levels"
    />
    <Resource
      name="hardware_components"
    />
    <Resource
      name="hardware_heartbeats"
    />
    <Resource
      name="hardware_vendors"
    />
    <Resource
      name="how_heard_about"
    />
    <Resource
      name="how_heard_abouts"
    />
    <Resource
      name="incident_attachments"
    />
    <Resource
      name="incident_involvements"
    />
    <Resource
      name="interests"
    />
    <Resource
      name="lead_tasks"
    />
    <Resource
      name="learning_styles"
    />
    <Resource
      name="log_entries"
    />
    <Resource
      name="health_records"
    />
    <Resource
      name="lead_interests"
    />
    <Resource
      name="incidents"
    />
    <Resource
      name="incident_tasks"
    />
    <Resource
      name="incident_attachments"
    />
    <Resource
      name="interests"
    />
    <Resource
      name="invoice_bookings"
    />
    <Resource
      name="invoice_coupons"
    />
    <Resource
      name="invoice_product_purchases"
    />
    <Resource
      name="invoice_product_returns"
    />
    <Resource
      name="invoice_subscription_prepayments"
    />
    <Resource
      name="invoice_vouchers"
    />
    <Resource
      name="invoices"
    />
    <Resource
      name="keyring_purchases"
    />
    <Resource
      name="keyring_grants"
    />
    <Resource
      name="journeys"
    />
    <Resource
      name="launched_apps"
    />
    <Resource
      name="leads"
    />
    <Resource
      name="learning_path_levels"
    />
    <Resource
      name="learning_path_level_skill_levels"
    />
    <Resource
      name="learning_paths"
    />
    <Resource
      name="library_categories"
    />
    <Resource
      name="library_engine_configuration_options"
    />
    <Resource
      name="library_engine_configuration_templates"
    />
    <Resource
      name="library_engine_event_modules"
    />
    <Resource
      name="library_engine_event_parameters"
    />
    <Resource
      name="library_engine_events"
    />
    <Resource
      name="library_engine_method_modules"
    />
    <Resource
      name="library_engine_method_parameters"
    />
    <Resource
      name="library_engine_methods"
    />
    <Resource
      name="library_engine_modules"
    />
    <Resource
      name="library_engines"
    />
    <Resource
      name="library_engine_versions"
    />
    <Resource
      name="library_object_configuration_templates"
    />
    <Resource
      name="library_object_configuration_values"
    />
    <Resource
      name="library_object_constant_values"
    />
    <Resource
      name="library_object_constants"
    />
    <Resource
      name="library_object_event_modules"
    />
    <Resource
      name="library_object_event_parameters"
    />
    <Resource
      name="library_object_events"
    />
    <Resource
      name="library_object_included_engine_events"
    />
    <Resource
      name="library_object_included_engine_methods"
    />
    <Resource
      name="library_object_method_modules"
    />
    <Resource
      name="library_object_method_parameters"
    />
    <Resource
      name="library_object_methods"
    />
    <Resource
      name="library_object_reactions"
    />
    <Resource
      name="library_object_reaction_modules"
    />
    <Resource
      name="library_objects"
    />
    <Resource
      name="library_object_versions"
    />
    <Resource
      name="library_keyrings"
    />
    <Resource
      name="library_widgets"
    />
    <Resource
      name="locations"
    />
    <Resource
      name="log_entries"
    />
    <Resource
      name="meetings"
    />
    <Resource
      name="memberships"
    />
    <Resource
      name="merchant_accounts"
    />
    <Resource
      name="mission_achievements"
    />
    <Resource
      name="mission_objects"
    />
    <Resource
      name="mission_skills"
    />
    <Resource
      name="mission_steps"
    />
    <Resource
      name="mission_step_tips"
    />
    <Resource
      name="mission_step_insertables"
    />
    <Resource
      name="missions"
    />
    <Resource
      name="multiple_choices"
    />
    <Resource
      name="multiple_choice_answers"
    />
    <Resource
      name="notes"
    />
    <Resource
      name="note_involvements"
    />
    <Resource
      name="note_attachments"
    />
    <Resource
      name="occasions"
    />
    <Resource
      name="opportunities"
    />
    <Resource
      name="organizations"
    />
    <Resource
      name="organization_addresses"
    />
    <Resource
      name="participations"
    />
    <Resource
      name="payment_methods"
    />
    <Resource
      name="phone_calls"
    />
    <Resource
      name="phone_conversations"
    />
    <Resource
      name="phone_numbers"
    />
    <Resource
      name="phones"
    />
    <Resource
      name="product_inventories"
    />
    <Resource
      name="product_sku_trait_values"
    />
    <Resource
      name="product_skus"
    />
    <Resource
      name="product_trait_values"
    />
    <Resource
      name="product_traits"
    />
    <Resource
      name="products"
    />
    <Resource
      name="projects"
    />
    <Resource
      name="project_categories"
    />
    <Resource
      name="revisions"
    />
    <Resource
      name="slots"
    />
    <Resource
      name="slot_instances"
    />
    <Resource
      name="ranks"
    />
    <Resource
      name="rank_learning_path_levels"
    />
    <Resource
      name="steps"
    />
    <Resource
      name="subscription_add_ons"
    />
    <Resource
      name="subscription_discounts"
    />
    <Resource
      name="subscriptions"
    />
    <Resource
      name="skills"
    />
    <Resource
      name="skill_levels"
    />
    <Resource
      name="staff_teams"
    />
    <Resource
      name="staff_team_memberships"
    />
    <Resource
      name="streaming_streams"
    />
    <Resource
      name="streaming_registrations"
    />
    <Resource
      name="streaming_viewers"
    />
    <Resource
      name="streaming_chat_messages"
    />
    <Resource
      name="streaming_chat_message_approvals"
    />
    <Resource
      name="stripe_accounts"
    />
    <Resource
      name="text_messages"
    />
    <Resource
      name="trading_cards"
    />
    <Resource
      name="tutorials"
    />
    <Resource
      name="user_addresses"
    />
    <Resource
      name="user_missions"
    />
    <Resource
      name="users"
    />
    <Resource
      name="versions"
    />
    <Resource
      name="version_comments"
    />
    <Resource
      name="version_flags"
    />
    <Resource
      name="version_stars"
    />
    <Resource
      name="version_status_changes"
    />
    <Resource
      name="vouchers"
    />
  </Admin>
);

export default withStyles(styles)(App);
