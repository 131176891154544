import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../controllers';
import { BooleanField, ImageField, NumberField, TextField } from '../../fields';
import { BooleanInput, ImageInput, NumberInput, SearchInput, TextInput } from '../../inputs';
import difficulties from './difficulties';






export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <BooleanInput
          source="published"
        />
        <BooleanInput
          source="studio_only"
        />
        <NumberInput
          source="position"
        />
        <ImageInput
          source="image"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <BooleanInput
          source="published"
        />
        <BooleanInput
          source="studio_only"
        />
        <NumberInput
          source="position"
        />
        <ImageInput
          source="image"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <BooleanField
          source="published"
        />
        <BooleanField
          source="studio_only"
        />
        <NumberField
          source="position"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <BooleanField
          source="published"
        />
        <BooleanField
          source="studio_only"
        />
        <NumberField
          source="position"
        />
        <ImageField
          source="image"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    difficulties,
  },
};
