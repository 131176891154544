import Icon from '@material-ui/icons/Event';
import UndeleteIcon from '@material-ui/icons/Undo';
import React from 'react';
import notes from './notes';
import { Filter, NestedResourceList, ResourceList, SimpleShowLayout } from '../../../../controllers';
import { DateField, DateTimeField, EnumField, LinkField, RichTextField, StartAndEndTimeField, TextField } from '../../../../fields';
import { DateInput, EnumInput } from '../../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      type: 'update',
      name: 'Revert',
      icon: UndeleteIcon,
      if: attendance => attendance.status === 'checked_in' || attendance.status === 'completed',
      updateAttributes: { status: 'pending' },
      confirmation: attendance => `Are you sure you want to revert this check-in${attendance.status === 'completed' ? ' and check-out' : ''}?`,
      requireComment: true,
    }
  ],
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <DateField
          label="Date"
          options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }}
          source="date"
        />
        <LinkField
          label="Booking"
          parentReference="organizations"
          parentSource="_meta_organization_id"
          reference="bookings"
          sortable={false}
          source="booking_id"
        >
          <StartAndEndTimeField />
        </LinkField>
        <LinkField
          label="User"
          parentReference="organizations"
          parentSource="_meta_organization_id"
          reference="users"
          sortable={false}
          source="_meta_user_id"
        />
        <EnumField
          source="status"
        />
        <DateTimeField
          source="check_in_at"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <DateInput
          label="Date"
          source="date"
        />
        <EnumInput
          label="Status"
          source="status"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="notes"
        >
          <EnumField
            source="topic"
          />
          <TextField
            source="heading"
          />
          <RichTextField
            source="detail"
          />
          <DateTimeField
            source="created_at"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    notes,
  },
};
