import Icon from '@material-ui/icons/ContactPhone';
import TextMessageIcon from '@material-ui/icons/Textsms';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { CreateDialog, EditDialog, Filter, ResourceList } from '../../../controllers';
import { LinkField, PhoneNumberField } from '../../../fields';
import { BooleanInput, DialingCodeSelectInput, LongTextInput, NestedReferenceInput, PhoneNumberInput, TextInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      name: 'New Text Message',
      type: 'component',
      icon: TextMessageIcon,
      Component: props => (
        <CreateDialog
          {...props}
          fixedValue={{
            to_dialing_code: props.parentResource.record.dialing_code,
            to_phone_number: props.parentResource.record.phone_number,
          }}
          resource="text_messages"
        >
          <TextInput
            defaultValue="outbound"
            label="Template"
            source="template"
            disabled
            required
          />
          <LongTextInput
            source="body"
            fullWidth
            required
          />
        </CreateDialog>
      ),
    },
  ], 
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <DialingCodeSelectInput />
        <PhoneNumberInput
          source="phone_number"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          filterToQuery={searchText => ({ name: searchText })}
          source="user_id"
          required
        />
        <DialingCodeSelectInput />
        <PhoneNumberInput />
      </CreateButton>
    ),
  },
  routes: {
     list: props => (

       <ResourceList
         {...props}
       >
         <LinkField
           source="user_id"
         />
         <PhoneNumberField />

       </ResourceList>

      
        ),
  },
  components: {
     Filter: props => (
       <Filter
         {...props}
       >
         <BooleanInput
           label="Deleted"
           source="deleted"
         />
       </Filter>
        ),
  },
  resources: {
  },
};
