import axios from 'axios';
import merge from 'deepmerge';
import pluralize from 'pluralize';
import { stringify } from 'qs';
import { CREATE, DELETE, GET_CHART, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE } from './actions';
import defaultSettings from './default-settings';
import { NotImplementedError } from './errors';
import init from './initializer';

// Set HTTP interceptors.
init();

/**
 * Maps react-admin queries to a JSONAPI REST API
 *
 * @param {string} apiUrl the base URL for the JSONAPI
 * @param {string} userSettings Settings to configure this client.
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (apiUrl, userSettings = {}) => (type, resource, params = {}) => {
  let url = '';
  const settings = merge(defaultSettings, userSettings);

  const options = {
    headers: settings.headers,
  };

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = (params.pagination || { page: 1, perPage: 100 });
      // Create query with pagination params.
      const query = {
        per_page: perPage,
        page,
      };
      let filterParams = []
      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        if (Array.isArray(params.filter[key])) {
          params.filter[key].forEach((value) => {
            filterParams.push(`filter[${key}][]=${value}`)
          });
        }
        // except the special basePath filter
        else if (key != 'basePath') {
          filterParams.push(`filter[${key.split('-as-')[0]}]=${params.filter[key]}`);
        }
      });

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const suffix = params.sort.order === 'ASC' ? '_asc' : '_desc';
        query.sort_by = `${params.sort.field}${suffix}`;
      }

      const basePath = (params.filter || {}).basePath || '';
      url = `${apiUrl}${basePath}/${resource}?${stringify({ ...query, ...settings.query })}&${filterParams.join('&')}`;
      break;
    }

    case GET_ONE: {
      const query = {}
      // Add any settings defined metadata to the query.
      if (settings.resources[resource] && settings.resources[resource].include_meta) {
        query.include_meta = settings.resources[resource].include_meta;
      }
      url = `${apiUrl}/${resource}/${params.id}?${stringify({ ...query, ...settings.query })}`;
      break;
    }

    case GET_CHART: {
      const query = {}
      // Add any settings defined metadata to the query.
      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        // array of values to filter by
        if (Array.isArray(params.filter[key])) {
          params.filter[key].forEach((value) => {
            query[`filter[${key}][]`] = value;
          });
        }
        // single array value
        else {
          query[`filter[${key}]`] = params.filter[key];
        }
      });
      url = `${apiUrl}/${resource}/charts/${params.type}/${params.name}?${stringify({ ...query, ...settings.query })}`;
      break;
    }

    // eslint-disable-next-line no-case-declarations
    case UPDATE:
      options.method = 'PUT';
      url = `${apiUrl}/${resource}/${params.id}?${stringify({ ...settings.query })}`;

    case CREATE: {
      url = url || `${apiUrl}/${resource}?${stringify({ ...settings.query })}`;
      options.method = options.method || 'POST';

      const relationships = {};
      const attributes = {};
      const meta = { comment: params.data['_comment'] };
      Object.keys(params.data).map((key) => {
        // a weird hack here to allow owner_id-as-users to be parsed into id column "owner_id" and object type "users"
        if (key.substr(0, 6) === '_meta_') {
          // do nothing
        }
        else if (key.split('-as-')[0].substr(- 3) === '_id') {
          const keyName = key.split('-as-')[0].split(/_id$/)[0];
          const objectName = key.split('-as-')[1] || pluralize(keyName)
          relationships[`${keyName}`] = {
            data: {
              id: params.data[key],
              type: objectName,
            },
          };
        } else if (key !== 'id' && key !== 'deleted_at' && key !== '_ignore' && key !== 'deletable' && key !== 'meta' && key.substr(0, 6) !== '_meta_' && key !== '_comment') {
          attributes[key] = params.data[key];
          if (attributes[key] === '') {
            attributes[key] = null;
          }
        }
      });
      options.data = JSON.stringify({
        data: Object.assign({}, { type: resource, attributes, relationships }, params.id ? {id: params.id} : {}),
        meta,
      });
      break;
    }

    case DELETE: {
      url = `${apiUrl}/${resource}/${params.id}?${stringify({ ...settings.query })}`;
      options.method = 'DELETE';
      break;
    }

    case GET_MANY: {
      let query = params.ids.map(id => `filter[ids][]=${id}`).join('&');
      query = `${query}&filter[include_deleted]=true`;

      url = `${apiUrl}/${resource}?${query}&${stringify({ ...settings.query })}`;
      break;
    }

    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const singularResource = params.target.replace(/_id$/, '');
      const parentResourcePlural = pluralize(singularResource);
      // Create query with pagination params.
      const query = {
        per_page: perPage,
        page
      };
      url = `${apiUrl}/${parentResourcePlural}/${params.id}/${resource}?${stringify({ ...settings.query, ...query })}`;
      break;
    }

    default:
      throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
  }

  return axios({ url, ...options })
    .then((response) => {
      switch (type) {

        case GET_MANY:
        case GET_LIST:
        case GET_MANY_REFERENCE: {
          return {
            data: response.data.data.map((value) => {
              const attributes = value.attributes || {};
              const relationships = value.relationships || {};
              const meta = value.meta || {};
              Object.keys(relationships).map((key) => {
                if (relationships[key].data && !Array.isArray(relationships[key].data)) {
                  let id_attribute = `${key}_id`
                  if (key !== pluralize.singular(relationships[key].data.type)) {
                    id_attribute = `${id_attribute}-as-${relationships[key].data.type}`
                  }
                  attributes[id_attribute] = relationships[key].data.id;
                }
              });
              Object.keys(meta).map((key) => {
                attributes[`_meta_${key}`] = meta[key];
              })
              attributes.meta = meta;
              if (meta && meta.deleted_at) {
                attributes['deleted_at'] = meta.deleted_at;
              }
              if (meta && meta.deletable) {
                attributes['deletable'] = meta.deletable;
              }
              return Object.assign(
                { id: value.id },
                attributes,
              );
            }),
            total: (response.data.meta && response.data.meta.total_records || null),
          };
        }

        case CREATE:
        case UPDATE:
        case GET_ONE: {
          const id = response.data.data.id;
          const attributes = response.data.data.attributes || {};
          const relationships = response.data.data.relationships || {};
          const meta = response.data.data.meta || {};

          Object.keys(relationships).map((key) => {
            if (relationships[key].data && !Array.isArray(relationships[key].data)) {
              let id_attribute = `${key}_id`
              if (key !== pluralize.singular(relationships[key].data.type)) {
                id_attribute = `${id_attribute}-as-${relationships[key].data.type}`
              }
              attributes[id_attribute] = relationships[key].data.id;
            }
          });
          Object.keys(meta).map((key) => {
            attributes[`_meta_${key}`] = meta[key];
          })
          attributes.meta = meta;
          if (meta && meta.deleted_at) {
            attributes['deleted_at'] = meta.deleted_at;
          }
          if (meta && meta.deletable) {
            attributes['deletable'] = meta.deletable;
          }
          return {
            data: {
              id, ...attributes,

            },
          };
        }

        case DELETE: {
          return {
            data: { id: params.id },
          };
        }

        case GET_CHART: {
          return {
            values: response.data.values,
            labels: response.data.labels,
          }
        }

        default:
          throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
      }
    });
};
