import Icon from '@material-ui/icons/People';
import moment from 'moment';
import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { CreateButton } from '../../buttons';
import { Filter, ResourceList, ResourceShow } from '../../controllers';
import { DateTimeField, LinkField } from '../../fields';
import { BooleanInput, NestedReferenceInput, ReferenceInput, StreamSelectInput } from '../../inputs';



export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        {...props}
      >
        <StreamSelectInput />
        <ReferenceInput
          label="Organization"
          optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
          shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
          source="organization_id"
          required
        />

        <FormDataConsumer>
          {({ formData }) => formData.organization_id && (            
            <NestedReferenceInput
              label="Registrant"
              reference="users"
              resourcePath={`/organizations/${formData.organization_id}`}
              source="registrant_id"
              required
            />              
            )}
        </FormDataConsumer>
        
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <DateTimeField
          source="created_at"
        />
        <LinkField
          displaySource="channel"
          label="Channel"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          displaySource="title"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          reference="organizations"
          source="organization_id"
        />
        <LinkField
          reference="users"
          source="registrant_id"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <DateTimeField
          source="created_at"
          addLabel
        />
        <LinkField
          displaySource="title"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          reference="organizations"
          source="organization_id"
        />
        <LinkField
          reference="users"
          source="registrant_id"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <StreamSelectInput
          source="stream_id"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
