import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { crudGetManyReference, GET_MANY_REFERENCE } from 'react-admin';
import { connect } from 'react-redux';
import dataProvider from '../dataProvider';
import EmailCard from './EmailCard';
import NoteCard from './NoteCard';
import PhoneCallCard from './PhoneCallCard';
import TextMessageCard from './TextMessageCard';

const PER_PAGE = 100;

const style = {
  divider: {
    listStyleType: 'none',
  },
  root: {
    marginTop: '20px',
  },
  container: {
    margin: '20px 0',
  },
};

class CommunicationLog extends Component {
  static propTypes = {
    organization_id: PropTypes.string.isRequired,
    user_id: PropTypes.string,
    classes: PropTypes.object,
    crudGetManyReference: PropTypes.func,
  };

  state = {
    results: [],
  };

  componentDidMount() {
    this.fetchResults('text_messages');
    this.fetchResults('phone_calls');
    this.fetchResults('emails');
    this.fetchResults('notes');
  }

  getParams(page = 1) {
    return {
      pagination: {
        page,
        per_page: PER_PAGE,
      },
      id: (this.props.user_id || this.props.organization_id),
      target: (this.props.user_id ? 'user_id' : 'organization_id'),
    };
  }

  addResults(type, data) {
    const results = data.map((result) => {
      return {
        timestamp: result.created_at,
        inbound: ((result.template || result.leg) == 'inbound'),
        relativeTime: moment(result.created_at).fromNow(),
        type,
        id: result.id,
      };
    });
    // update the state with the new results
    this.setState({
      results: this.state.results.slice(0).concat(results),
    });
  }

  // type = text_messages, phone_calls, emails, or notes
  fetchResults(resource, page = 1) {
    const {
      crudGetManyReference,
    } = this.props;


    const reference = resource
    const target = this.props.user_id ? 'user_id' : 'organization_id'
    const id = this.props.user_id || this.props.organization_id
    const relatedTo = null
    const pagination = {
      page: page,
      perPage: PER_PAGE
    }
    const sort = {
      field: 'created_at',
      order: 'DESC'
    }
    const filter = {}
    const source = 'id'

    crudGetManyReference(
      reference,
      target,
      id,
      relatedTo,
      pagination,
      sort,
      filter,
      source,
    );
      // TODO: figure out how to detect the fetch above finished, and which of the records belong to this request
    dataProvider(GET_MANY_REFERENCE, resource, this.getParams(page))
      .then(({ data, total }) => {
        this.addResults(resource, data);
        // are there more results to get?
        if (total > ((page - 1) * PER_PAGE) + data.count) {
          this.fetchResults(resource, page + 1);
        }
      })
      .catch((error) => { throw new Error(error); });
  }

  render() {
    const { results } = this.state;
    const { classes } = this.props;

    const sortedResults = results.slice(0).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return (
      <div
        className={classes.root}
      >
        {sortedResults.map((result, i) => (
          <>
            {(i === 0 || (i > 0 && 
            result.relativeTime != sortedResults[i - 1].relativeTime)) && (
              <>
                <Typography
                  color="textSecondary"
                  display="block"
                  variant="caption"
                >
                  {result.relativeTime}
                </Typography>
                <Divider
                  className={classes.divider}
                  component="li"
                  key={`${result.id}Divider`}
                />
              </>
            )}
            <div
              className={classes.container}
              key={`${result.id}Divider`}
            >
              {(result.type === 'text_messages' &&
                <TextMessageCard
                  id={result.id}
                  inbound={result.inbound}
                  organization_id={this.props.organization_id} 
                  resource="text_messages"
                />
                ) || (result.type === 'phone_calls' &&
                  <PhoneCallCard
                    id={result.id}
                    inbound={result.inbound}
                    organization_id={this.props.organization_id}
                    resource="phone_calls"
                  />
                ) || (result.type === 'emails' &&
                  <EmailCard
                    id={result.id}
                    inbound={result.inbound}
                    organization_id={this.props.organization_id}
                    resource="emails"
                  />
                ) || (result.type === 'notes' && 
                  <NoteCard
                    id={result.id}
                    resource="notes"
                  />
                )}
            </div>
          </>
        ),
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
  };
}

const mapDispatchToProps = {
  crudGetManyReference,
};

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(
  CommunicationLog,
));
