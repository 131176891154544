import Icon from '@material-ui/icons/FormatAlignLeft';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow } from '../../../../../controllers';
import { DateTimeField, KidScriptField, TextField } from '../../../../../fields';
import { BooleanInput, KidScriptInput, SearchInput, TextInput } from '../../../../../inputs';
import revisions from './revisions';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="filename"
        />
        <KidScriptInput
          source="kidscript"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ project_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="filename"
        />
        <KidScriptInput
          source="kidscript"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="filename"
        />
        <KidScriptField
          source="kidscript"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="filename"
        />
        <KidScriptField
          source="kidscript"
        />

        <NestedResourceList
          perPage={10}
          reference="revisions"
        >
          <TextField
            source="filename"
          />
          <KidScriptField
            source="kidscript"
          />
          <DateTimeField
            source="created_at"
          />
        </NestedResourceList>

      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    revisions
  },
};
