import moment from 'moment';
import PropTypes from 'prop-types';
import { GET_LIST } from 'ra-core';
import React, { Component } from 'react';
import dataProvider from '../dataProvider';
import { AutocompleteInput, DateInput, ReferenceInput, StartAndEndTimeSelectInput } from '../inputs';

class AvailabilityScheduleInput extends Component {
  state = {
    schedule: null,
    ready: false,
    error: null,
    occasion_id: null,
    location_id: null,
    date: null
  };

  setLocationId = (a, location_id) => {
    if (this.state.location_id !== location_id) {
      this.setState({ location_id, ready: false }, this.getSchedule);
    }
  }

  handleChangeOccasionId = (a, occasion_id) => {
    if (this.state.occasion_id !== occasion_id) {
      this.setState({ occasion_id, ready: false }, this.getSchedule);
    }
  }

  handleChangeDate = (a, date) => {
    if (this.state.date !== date) {
      this.setState({ date, ready: false }, this.getSchedule);
    }
  }

  getSchedule = () => {

    if (this.state.date && this.state.occasion_id) {
      // get the location's schedule, and update the state (which will cause a redraw)
      const filter = {
        occasion_id: this.state.occasion_id,
        date: this.state.date,
      }
      if (this.state.location_id) {
        filter.location_id = this.state.location_id
      }
      dataProvider(GET_LIST, 'slot_instances', {
        filter
      })
        .then(({ data }) => {
          const timeChoices = []
          data.forEach((slotInstance) => {
            const time = `${slotInstance.start_time} - ${slotInstance.end_time}`
            const time_12_hr = `${moment(slotInstance.start_time, "HH:mm").format("h:mm A")} - ${moment(slotInstance.end_time, "HH:mm").format("h:mm A")}`
            timeChoices.push({
              id: time,
              name: `${time} (${time_12_hr})`,
            })
          })
          this.setState({
            timeChoices,
            ready: true,
          });
        })
        .catch((error) => {
          this.setState({
            error,
            ready: false,
          });
        });
    }

  }

  render() {
    return (
      <>
        <ReferenceInput
          {...this.props}
          filter={{ in_person: false }}
          filterToQuery={searchText => ({ q: searchText })}
          reference="occasions"
          source="occasion_id"
          required
          onChange={this.handleChangeOccasionId}
        >
          <AutocompleteInput
            optionText="name"
          />
        </ReferenceInput>
        <DateInput
          source="first_available_at"
          onChange={this.handleChangeDate}
        />
        {this.state.occasion_id && this.state.date && this.state.ready && (
          <StartAndEndTimeSelectInput
            choices={this.state.timeChoices}
          />
        )}
        {this.state.occasion_id && this.state.date && this.state.ready && (
          <DateInput
            source="last_available_at"
          />
        )}
      </>
    );
  }
}

AvailabilityScheduleInput.propTypes = {
  record: PropTypes.object,
};

AvailabilityScheduleInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default AvailabilityScheduleInput;

