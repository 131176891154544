import Icon from '@material-ui/icons/School';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { BooleanField, EnumField, NumberField, TextField } from '../../fields';
import { BooleanInput, ImageInput, LongTextInput, SearchInput, TextInput } from '../../inputs';
import learning_path_levels from './learning_path_levels';
import skills from './skills';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <LongTextInput
          source="description"
        />
        <ImageInput
          label="Icon"
          source="icon"
          required
        />
        <BooleanInput
          source="guide_only"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
        />
        <LongTextInput
          source="description"
        />
        <ImageInput
          label="Icon"
          source="icon"
          required
        />
        <BooleanInput
          source="guide_only"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <BooleanField
          source="guide_only"
        />
        <TextField
          source="description"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <BooleanField
          source="guide_only"
          addLabel
        />

        <NestedResourceList
          reference="skills"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="learning_path_levels"
        >
          <NumberField
            source="position"
          />
          <TextField
            source="name"
          />
          <EnumField
            source="difficulty_level"
          />
          <NumberField
            source="minutes"
          />
          <NumberField
            source="points"
          />
        </NestedResourceList>

      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="skills"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="learning_path_levels"
        >
          <TextField
            source="name"
          />
          <NumberField
            source="position"
          />
          <EnumField
            source="difficulty_level"
          />
          <NumberField
            source="minutes"
          />
        </NestedResourceList>

      </SimpleShowLayout>
    ),
  },
  resources: {
    skills,
    learning_path_levels,
  },
};
