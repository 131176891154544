import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { withTranslate } from 'ra-core';
import React from 'react';

const ExpandRowButton = ({
    classes,
    expanded,
    expandContentId,
    translate,
    ...props
}) => {
    return (
      <IconButton
        aria-controls={expandContentId}
        aria-expanded={expanded}
        aria-hidden="true"
        aria-label={translate(
                expanded ? 'ra.action.close' : 'ra.action.expand'
        )}
        className={classNames(classes.expandIcon, {
                [classes.expanded]: expanded,
            })}
        component="div"
        tabIndex={-1}
        {...props}
      >
        <ExpandMoreIcon />
      </IconButton>
    );
};

export default withTranslate(ExpandRowButton);
