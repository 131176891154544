import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const JsonField = ({ source, record = {} }) => (
  <Typography
    variant="body1"
  >
    <pre>
      {record[source] && JSON.stringify(record[source], null, 2)}
    </pre>
  </Typography>
);

JsonField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

JsonField.defaultProps = {
  addLabel: true,
};

export default JsonField;

