import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import titleCase from 'title-case';

const BookingStatusField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.status === 'completed' ? `${titleCase(record.completed_reason)}` : titleCase(record.status)}
  </Typography>
);

BookingStatusField.propTypes = {
    record: PropTypes.object,
};

BookingStatusField.defaultProps = {
    addLabel: true,
    label: 'Status',
};

export default BookingStatusField;

