import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { Filter, NestedResourceList, ResourceList, ResourceShow } from '../../../controllers';
import { LinkField, TextField } from '../../../fields';
import { BooleanInput, NestedReferenceInput } from '../../../inputs';
import library_object_configuration_values from './library_object_configuration_values';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ library_object_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          source="library_engine_configuration_template_id"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <LinkField
          source="library_engine_configuration_template_id"
        />
        <TextField
          source="name"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          source="library_engine_configuration_template_id"
        />
        <TextField
          source="name"
        />
        <NestedResourceList
          reference="library_object_configuration_values"
        >
          <LinkField
            source="library_engine_configuration_option_id"
          />
          <TextField
            source="value"
          />
          <LinkField
            source="library_object_constant_id"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    library_object_configuration_values,
  },
};
