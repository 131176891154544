import Icon from '@material-ui/icons/SpeakerNotes';
import React from 'react';
import { Filter, ResourceList, ResourceShow, SimpleShowLayout, Tab, TabbedShowLayout } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, MeetingLinkField, MeetingStartTimeField, TextField } from '../../../fields';
import { BooleanInput, EnumInput, NestedReferenceInput } from '../../../inputs';

export default {
    Icon,
    dialogs: {
    },
    routes: {
        list: props => ( 
          <ResourceList
            {...props}
            filterDefaultValues={{ 
              include_past: true,
              include_abandoned: true,
            }}
          >
            <LinkField
              source="user_id"
            />
            <LinkField
              source="occasion_id"
            />
            <MeetingStartTimeField />
            <TextField
              label="Guide"
              source="_meta_meeting_guide"
            />
            <TextField
              label="Meeting Status"
              source="meeting_status"
            />
            <EnumField
              label="Participation Status"
              source="status"
            />
            <MeetingLinkField
              source="meeting_id"
            />
          </ResourceList>
        ),
        show: props => ( 
          <ResourceShow
            {...props}
          >
            <TabbedShowLayout>
              <Tab
                label="summary"
              >
                <MeetingLinkField
                  source="meeting_id"
                />
                <DateTimeField
                  source="meeting_start_at"
                  addLabel
                />
                <DateTimeField
                  source="meeting_end_at"
                  addLabel
                />
                <TextField
                  source="meeting_status"
                />
                <EnumField
                  source="status"
                />
              </Tab>

              <Tab
                label="Kid Survey"
              >
                <EnumField
                  source="sentiment"
                />
                <TextField
                  source="comments"
                />
              </Tab>
              <Tab
                label="Parent Survey"
              >
                <EnumField
                  source="parent_sentiment"
                />
                <BooleanField
                  source="parent_keep_guide"
                  addLabel
                />
                <BooleanField
                  source="parent_make_guide_most_preferred"
                  addLabel
                />
                <BooleanField
                  source="parent_learning_concerns"
                  addLabel
                />
                <BooleanField
                  source="parent_technology_issues"
                  addLabel
                />
                <BooleanField
                  source="parent_guide_concerns"
                  addLabel
                />
                <TextField
                  source="parent_other_concerns"
                />
                <BooleanField
                  source="parent_technology_logging_in_issues"
                  addLabel
                />
                <BooleanField
                  source="parent_technology_audio_video_issues"
                  addLabel
                />
                <BooleanField
                  source="parent_technology_code_ide_issues"
                  addLabel
                />
                <TextField
                  source="parent_other_issues"
                />
                <TextField
                  source="parent_next_session_comments"
                />
                <TextField
                  source="parent_experience_comments"
                />
              </Tab>
            </TabbedShowLayout>
          </ResourceShow>
        ),
    },
    components: {
      Filter: props => (
        <Filter
          {...props}
        >
          <NestedReferenceInput
            filter={{ scope: 'child' }}
            source="user_id"
          />
          <EnumInput
            source="status"
          />
          <BooleanInput
            source="include_abandoned"
          />
          <BooleanInput
            source="include_past"
          />
        </Filter>
        ),
         Expand: props => (
           <SimpleShowLayout
             {...props}
           >
             <DateTimeField
               label="Scheduled Start Time"
               source="meeting_start_at"
               addLabel
             />
             <DateTimeField
               label="Scheduled End Time"
               source="meeting_end_at"
               addLabel
             />

             <DateTimeField
               label="Kid Joined At"
               source="first_joined_at"
               addLabel
             />
           </SimpleShowLayout>
      ),
    },
    resources: {
        
    },
};
