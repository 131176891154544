import Icon from '@material-ui/icons/Face';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, ResourceList } from '../../controllers';
import { ImageField, TextField } from '../../fields';
import { ImageInput, TextInput } from '../../inputs';

export default {
    Icon,
    dialogs: {
        edit: props => (
          <EditDialog
            {...props}
          >
            <TextInput
              source="name"
            />
            <ImageField
              label="Current Image"
              source="file"
            />
            <ImageInput
              label="New Image"
              source="file"
            />
          </EditDialog>
        ),
        create: props => (
          <CreateButton
            {...props}
          >
            <TextInput
              source="name"
            />
            <ImageInput
              label="Image"
              source="file"
            />
          </CreateButton>
        ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <TextField
              source="name"
            />
            <ImageField
              source="file"
            />
          </ResourceList>
        )
    },
    components: {
    },
    resources: {
    },
};
