import PropTypes from 'prop-types';
import React from 'react';
import { ReferenceArrayInput as ReactAdminReferenceArrayInput, required as requiredFn } from 'react-admin';

export const ReferenceArrayInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminReferenceArrayInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

ReferenceArrayInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

ReferenceArrayInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default ReferenceArrayInput;

