import Icon from '@material-ui/icons/Star';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { ResourceList } from '../../../controllers';
import { LinkField, TextField } from '../../../fields';
import { ReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ lead_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput
          source="interest_id"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="id"
        />
        <LinkField
          source="interest_id"
        />
      </ResourceList>
    ),
  },
  resources: {
  },
};
