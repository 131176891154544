import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { required as requiredFn } from 'react-admin';
import MarkdownInputField from '../components/MarkdownInputField';
const style = {
  root: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  rootWithError: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  header: {
  },
  headerWithError: {
    color: 'red',
  }
}

const MarkdownInput = ({ label, classes, isRequired, validate, meta = {}, ...props }) => {
  const { touched, error } = meta;
  const isError = !!(touched && error)
  console.log('props', props);
  return (
    <div
      className={isError ? classes.rootWithError : classes.root}
    >
      <Typography
        className={isError ? classes.headerWithError : classes.header}
        color="textSecondary"
        display="block"
        validate={isRequired ? requiredFn() : validate}
        variant="body1"
      >
        {label}
        {isError && `(${touched && error})`}
        <MarkdownInputField
          {...props}
        />
      </Typography>
    </div>
  );
}

export default withStyles(style)(MarkdownInput)
