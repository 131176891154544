import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// eslint-disable-next-line
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

window.enums = null

const startApp = async () => {
  const request = new Request(`${process.env.REACT_APP_PROTOCOL || 'https'}://${process.env.REACT_APP_API_URL}/meta/enums`, {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
  });
  const response = await fetch(request)
  window.enums = await response.json();
  ReactDOM.render(<App />, document.getElementById('root'));
}

startApp()
