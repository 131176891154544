import { withStyles } from '@material-ui/core/styles';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query, Responsive } from 'react-admin';
import titleCase from 'title-case';

const styles = () => ({
  button: {
    color: '#FFF',
    textDecoration: 'none',
  },
  rsaquo: {
    color: '#FFF',
    padding: '0 12px',
  },
  title: {
    color: '#FFF',
    display: 'inline',
  },
});

class PathBreadcrumbs extends Component {

  nameWithoutNamespace = (resource) => {
    let newName = resource.resource;
    const previousResourceName = resource.previous && resource.previous.resource;
    if (previousResourceName) {
      const parts = previousResourceName.split('_');
      for (let i = parts.length; i > 0; i--) {
        const parentName = previousResourceName.split('_').splice(0, i).join('_');
        newName = newName.replace(new RegExp(`^${parentName}_|${pluralize.singular(parentName)}_`, 'i'), '');
      }
    }
    return titleCase(newName);
  }

  render () {
    const { classes, resource, id, resourcePath } = this.props
    return (
      <Responsive
        medium={<>
          {resourcePath.map(resource => (
            <span
              key={`#${resource.basePath}`}
            >
              <a
                className={classes.button}
                href={`#${resource.basePath}`}
              >
                {this.nameWithoutNamespace(titleCase(resource.resource))}
              </a>
              { resource.id && (
                <>
                  <span
                    className={classes.rsaquo}
                    key={`#${resource.basePath}-rsaquo`}
                  >
                    &rsaquo;
                  </span>
                  <span
                    key={`#${resource.basePath}-show`}
                  >
                    <a
                      className={classes.button}
                      href={`#${resource.basePath}/${resource.id}/show`}
                    >
                      <Query
                        payload={{ id: resource.id }}
                        resource={resource.resource}
                        type="GET_ONE"
                      >
                        {({ data, loading, error }) => {
                            if (loading) { return titleCase(pluralize.singular(resource.resource)); }
                            if (error) { return 'ERROR'; }
                            return data.name || titleCase(pluralize.singular(resource.resource));
                          }}
                      </Query>
                    </a>
                    { resource.next && (
                      <span
                        className={classes.rsaquo}
                        key={`#${resource.basePath}-show-rsaquo`}
                      >
                        &rsaquo;
                      </span>
                      )}
                  </span>
                </>
                )}
            </span>
            ))}
        </>}
        small={<>
          {titleCase(resource)}
        </>}
      />
    )
  }
}

PathBreadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PathBreadcrumbs);
