import React, { Children } from 'react';
import { Filter } from 'react-admin';

export default ({ children, parentResource, basePath, ...props}) => (
  <Filter
    {...props}
  >
    {Children.toArray(children).map(child => React.cloneElement(child, { parentResource, basePath }))}
  </Filter>
)
