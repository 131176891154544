import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const EditableProjectLinkField = ({ record = {}, fullUrl = false }) => (
  <Typography
    variant="body1"
  >
    <a
      href={`https://studio.codeverse.com/projects/${record['id']}`}
      rel="noopener noreferrer"
      target="_blank"
      onClick={e => e.stopPropagation()}
    >
      {fullUrl ? `https://studio.codeverse.com/projects/${record['id']}` : 'Open' }
    </a>
  </Typography>
);

EditableProjectLinkField.propTypes = {
  record: PropTypes.object,
  fullUrl: PropTypes.bool,
};

EditableProjectLinkField.defaultProps = {
  label: 'Editable Link',
  addLabel: true
};

export default EditableProjectLinkField;

