import { withStyles } from "@material-ui/core/styles";
import jsonLogic from "json-logic-js";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { LongTextInput } from "react-admin";


let depth = 0;

function categoryFromClassName(className) {
  try {
    const obj = KidScript.Library.getClass(className);
    return (
      (obj.definition &&
        obj.definition.about &&
        obj.definition.about.category) ||
      null
    );
  } catch (error) {
    return null;
  }
}

function getAllSource(node, line = null) {
  if (typeof node.updatedSource === "string") {
    return node.updatedSource;
  }
  if (typeof node.source === "string") {
    return node.source;
  }
  console.log("in here");
  return node.children
    .map((child) => {
      if (typeof line === "undefined" || line === node.start.line) {
        return getAllSource(child, line);
      }
    })
    .join("");
}

function scalarOrVariable(node) {
  switch (node.symbol) {
    case "Variable":
      return {
        type: node.symbol,
        variableName: node.variableName,
        className: node.className,
        classCategory: categoryFromClassName(node.className),
      };
    case "Number":
      return {
        type: node.symbol,
        value: parseInt(node.content),
      };
    case "Text": {
      let i = 0;
      return {
        type: node.symbol,
        value: node.children
          .filter(
            (n) => n.symbol === "TextVariable" || n.symbol === "TextContent"
          )
          .map((n) => (n.symbol === "TextContent" ? n.content : "$" + ++i))
          .join(""),
        interpolationValues: node.children
          .filter((n) => n.symbol === "TextVariable")
          .map((n) => scalarOrVariable(n.children[1])),
      };
    }
    case "TrueFalse":
      return {
        type: node.symbol,
        value: node.children[0].content.toLowerCase() === "true",
      };
    case "List":
      return {
        type: node.symbol,
        values:
          node.children[1] &&
          node.children[1].children
            .filter((n) => n.symbol === "CommaSeparatedValue")
            .map((v) => v && v.children && scalarOrVariable(v.children[0])),
      };
    default:
      return {
        type: node.symbol,
      };
  }
}

function onSameLine(positionA, positionB) {
  return (
    positionA.absoluteLine === positionB.absoluteLine &&
    positionA.document === positionB.document &&
    positionA.line === positionB.line
  );
}

function build(node, line, addBlock, additional = {}) {
  console.log("in here", node);
  let n = {
    indentation: {
      level: node.indentation._level,
    },
    position: {
      document: node.document + 1,
      line: node.start.line,
      absoluteLine: line,
    },
  };
  if (addBlock) {
    depth = depth + 1;
    if (depth < 3) {
      let block = node.parent.children.filter(
        (n) => n.symbol === "NewBlock"
      )[0];
      if (block) {
        n.block = toJsonLogicRepresentation(block);
      }
    }
    depth = depth - 1;
  }
  return Object.assign(n, additional);
}

// builds the html, adjusted source and array representations of placeholders for the kidscript editor
function compileTree(node, results, absoluteLine = 0) {
  if (node.symbol === "Line") {
    absoluteLine++;
  }

  if (node.error || node.prompt || node.placeholder) {
    return;
  }

  // is this node has children, then recursively compile them and build the HTML string
  if (node.children.length) {
    node.children.map((child) => compileTree(child, results, absoluteLine));
  }

  if (node.parent && node.parent.symbol == "Statement") {
    results.lines.push(
      build(node, absoluteLine, false, {
        statement: node.symbol,
        kidScript: getAllSource(node, node.start.line).trim(),
      })
    );
  }

  if (node.symbol === "ImportableClass") {
    let className =
      node.children && node.children[0] && node.children[0].className;
    results.imports.push(
      build(node, absoluteLine, false, {
        className,
        classCategory: categoryFromClassName(className),
        type: "class",
      })
    );
  }

  if (node.symbol === "ImportableVariable") {
    let className =
      node.children && node.children[0] && node.children[0].className;
    results.imports.push(
      build(node, absoluteLine, false, {
        variableName: node.variableName,
        className,
        classCategory: categoryFromClassName(className),
        type: "variable",
      })
    );
  }

  if (node.symbol === "Event") {
    results.events.push(
      build(node, absoluteLine, true, {
        className: node.className,
        classCategory: categoryFromClassName(node.className),
        variableName: node.variableName,
        eventName:
          node.children && node.children[1] && node.children[1].methodName,
        parameters:
          node.children[1] &&
          node.children[1].children[2] &&
          node.children[1].children[2].children[1] &&
          node.children[1].children[2].children[1].children
            .filter((n) => n.symbol === "CommaSeperatedValue")
            .map((v) => v && v.children && scalarOrVariable(v.children[0])),
      })
    );
  }

  if (node.symbol === "ForItem") {
    results.fors.push(
      build(node, absoluteLine, true, {
        variableName: node.variableName,
      })
    );
  }

  if (node.symbol === "LoopCount") {
    results.loops.push(
      build(node, absoluteLine, true, {
        className: node.className,
        classCategory: categoryFromClassName(node.className),
        variableName: node.variableNode && node.variableNode.content,
        variable: node.variableNode ? true : false,
      })
    );
  }

  if (node.symbol === "Conditions") {
    results.ifs.push(build(node, absoluteLine, true));
  }

  if (node.symbol === "Comment") {
    let commentText =
      node.children && node.children[2] && node.children[2].content;
    results.comments.push(
      build(node, absoluteLine, false, {
        commentText,
      })
    );
  }

  if (node.symbol === "Math") {
    var operator =
      node.children[2] &&
      node.children[2].children[0] &&
      node.children[2].children[0].content;

    results.maths.push(
      build(node, absoluteLine, false, {
        leftSide: scalarOrVariable(
          node.children[0] && node.children[0].children[0]
        ),
        operator,
        rightSide: scalarOrVariable(
          node.children[4] && node.children[4].children[0]
        ),
      })
    );
  }

  if (node.symbol === "VariableAssignment") {
    let data = build(node, absoluteLine, false, {
      className: node.className,
      classCategory: categoryFromClassName(node.className),
      variableName: node.variableName,
      variableValue: scalarOrVariable(
        node.children[4] && node.children[4].children[0]
      ),
    });
    if (node.variableCreation) {
      results.variableCreations.push(data);
    } else {
      results.variableAssignments.push(data);
    }
  }

  if (node.symbol === "Invocation" && node.invocationType === "method") {
    const methodData = build(node, absoluteLine, false, {
      className: node.className,
      classCategory: categoryFromClassName(node.className),
      variableName: node.variableName,
      methodName:
        node.children && node.children[1] && node.children[1].methodName,
      parameters:
        node.children[2] &&
        node.children[2].children[1] &&
        node.children[2].children[1].children
          .filter((n) => n.symbol === "CommaSeperatedValue")
          .map((v) => v && v.children && scalarOrVariable(v.children[0])),
      chainedMethods: [],
    });
    // if there are already methods on this line, then add this new method call as a chained method
    results.methods
      .filter((n) => onSameLine(n.position, methodData.position))
      .map((n) =>
        n.chainedMethods.push({
          className: node.className,
          methodName:
            node.children && node.children[1] && node.children[1].methodName,
          parameters:
            node.children[2] &&
            node.children[2].children[1] &&
            node.children[2].children[1].children
              .filter((n) => n.symbol === "CommaSeperatedValue")
              .map((v) => v && v.children && scalarOrVariable(v.children[0])),
        })
      );
    // and add this new method to the end
    results.methods.push(methodData);
  }
}

function toJsonLogicRepresentation(tree) {
  if (tree) {
    let line = 0;
    let results = {
      imports: [],
      variableCreations: [],
      variableAssignments: [],
      methods: [],
      events: [],
      loops: [],
      maths: [],
      fors: [],
      ifs: [],
      comments: [],
      lines: [],
    };
    compileTree(tree, results, line);
    return results;
  }
}

const style = {
  container: {
    position: "relative",
  },
  error: {
    color: "#d23931",
  },
  success: {
    color: "#3ad231",
  },
  stage: {
    position: "absolute",
  },
  guard: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
};

class KidScriptValidationRulesInput extends Component {
  static propTypes = {
    kidScript: PropTypes.string,
    rules: PropTypes.string,
    source: PropTypes.string.isRequired,
  };

  static defaultProps = {
    sort: false,
    sortable: false,
    alwaysOn: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      kidScript: props.kidScript,
      rules: props.rules,
      status: null, // valid, invalid or validating
      timeoutHandler: null,
    };
  }

  componentDidMount() {
    this.setState({
      error: null,
      status: "validating",
    });
    this.testKidScript();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.kidScript !== prevState.kidScript ||
      JSON.stringify(this.state.rules) !== JSON.stringify(prevState.rules)
    ) {
      this.testKidScript();
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    if (nextProps.kidScript !== this.state.kidScript) {
      newState.kidScript = nextProps.kidScript;
    }
    if (JSON.stringify(this.state.rules) !== JSON.stringify(nextProps.rules)) {
      newState.rules = this.jsonParse(nextProps.rules);
    }
    if (Object.keys(newState).length) {
      this.setState(
        Object.assign(newState, {
          error: null,
          status: "validating",
          eventSamples: [],
        })
      );
    }
  }

  testKidScript() {
    let kidScript = this.state.kidScript;
    let rules = this.state.rules;
    try {
      depth = 0;
      let parseResult = window.KidScript.parseBlock(kidScript);
      // this will run the preprocessors (clean this up when the new version of KS is deployed)
      parseResult.toEditor();
      let tree = parseResult.getSyntaxTree();
      let jsonLogicKidScript = toJsonLogicRepresentation(tree);
      let jsonLogicStatus = jsonLogic.apply(rules, jsonLogicKidScript);
      let jsonLogicResultCount = 0;
      if (typeof jsonLogicStatus === "number") {
        jsonLogicResultCount = jsonLogicStatus;
      } else if (Array.isArray(jsonLogicStatus)) {
        jsonLogicResultCount = jsonLogicStatus.length;
      } else {
        jsonLogicResultCount = jsonLogicStatus ? 1 : 0;
      }

      this.setState({
        jsonLogicKidScript,
        error: null,
        jsonLogicStatus,
        jsonLogicResultCount,
        status: jsonLogicStatus ? "valid" : "invalid",
      });
    } catch (error) {
      this.setState({
        jsonLogicKidScript: null,
        error: error.toString(),
        status: "invalid",
      });
    }
  }

  jsonStringify(value) {
    if (typeof value === "string") {
      return value;
    }
    try {
      return JSON.stringify(value, null, 2);
    } catch (error) {
      return value;
    }
  }

  jsonParse(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  validateKidScript() {
    if (this.state.status !== "valid") {
      return "failed";
    }
  }

  render() {
    const {
      status,
      jsonLogicKidScript,
      jsonLogicResultCount,
      error,
      jsonLogicStatus,
    } = this.state;
    const { classes, kidScript, rules, ...props } = this.props;
    return (
      <>
        <div>
          <pre>
            {JSON.stringify(jsonLogicKidScript, null, 2)}
          </pre>
        </div>
        <LongTextInput
          format={(value) => value && this.jsonStringify(value)}
          parse={(value) => this.jsonParse(value)}
          {...props}
          //validate={() => this.validateKidScript()}
        />
        <div>
          <pre>
            {JSON.stringify(jsonLogicStatus, null, 2)}
          </pre>
        </div>
        <div>
          {status === "invalid" && <div
            className={classes.error}
                                   >
            {error}
          </div>}
          {status === "valid" && (
            <div
              className={classes.success}
            >
              Valid (count:
              {jsonLogicResultCount}
              )
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(style)(KidScriptValidationRulesInput);
