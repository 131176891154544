import Icon from '@material-ui/icons/LocalHospital';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter } from '../../../../controllers';
import { BooleanInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="allergies"
        />
        <TextInput
          source="learning"
        />
        <TextInput
          source="mobility"
        />
        <TextInput
          source="eyesight"
        />
        <TextInput
          source="hearing"
        />
        <TextInput
          source="medications"
        />
        <TextInput
          source="seizures"
        />
        <TextInput
          source="additional_information"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="allergies"
        />
        <TextInput
          source="learning"
        />
        <TextInput
          source="mobility"
        />
        <TextInput
          source="eyesight"
        />
        <TextInput
          source="hearing"
        />
        <TextInput
          source="medications"
        />
        <TextInput
          source="seizures"
        />
        <TextInput
          source="additional_information"
        />
      </CreateButton>
    ),
  },
  routes: {   
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
