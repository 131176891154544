import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HandledIcon from '@material-ui/icons/CheckBox';
import NotHandledIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { crudUpdate } from 'react-admin';
import { connect } from 'react-redux';
import titleCase from 'title-case';
import EmailReplyButton from './EmailReplyButton';

const style = {
  inbound: {
    width: '80%',
    marginRight: '20%',
    backgroundColor: '#e2e6ec',
  },
  outbound: {
    width: '80%',
    marginLeft: '20%',
    backgroundColor: '#e6efff',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
};

class EmailCard extends Component {
  state = {
    showMore: false,
    expanded: false,
    handled: null,
  };

  getEmailBodyContent() {
    const htmlContent = (this.props.inbound ? (this.props.record.html_content || this.props.record.text_content) : this.props.record.body) || '';
    const div = document.createElement('div');
    // the email content, without our open tracking pixel
    div.innerHTML = htmlContent.replace(/src="https?:\/\/mail\.codeverse\.com\/track/gim, 'dont-open="');
    this.removeStyleAndScriptTags(div);
    return div.innerHTML;
  }

  getEmailBody() {
    const htmlBody = this.getEmailBodyContent();
    // delete any block quotes
    if (!this.state.showMore) {
      const div = document.createElement('div');
      div.innerHTML = htmlBody;
      this.removeBlockQuotes(div);
      return div.innerHTML;
    }
    return htmlBody;
  }

  withinCurrentOrganization = !!window.location.href.match(/#\/organizations\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/);

  removeBlockQuotes(node) {
    (node.childNodes || []).forEach((child) => {
      if (child.nodeName === 'BLOCKQUOTE') {
        node.removeChild(child);
      } else {
        this.removeBlockQuotes(child);
      }
    });
  }

  removeStyleAndScriptTags(node) {
    (node.childNodes || []).forEach((child) => {
      if (child.nodeName === 'STYLE' || child.nodeName === 'SCRIPT') {
        node.removeChild(child);
      } else {
        this.removeStyleAndScriptTags(child);
      }
    });
  }

  hasBlockQuotes() {
    const htmlBody = this.getEmailBodyContent();
    return htmlBody && htmlBody.match(/<Blockquote/igm);
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleShowMoreClick = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleHandledClick = (values) => {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    crudUpdate(
      resource,
      record.id,
      Object.assign({}, record, { handled: !record.handled }),
      record,
      `/${resource}`,
      false,
    );
  };

  render() {
    const { record, inbound, classes } = this.props;

    if (!record) {
      return null
    }

    const cardClass = inbound ? classes.inbound : classes.outbound;
    const handled = this.state.handled === null ? !!record.handled_at : this.state.handled;
    const recipients = record.email_recipients.map(email_recipient => email_recipient.address).join(' ,');
    return (
      <Card
        className={cardClass}
      >
        <CardHeader
          action={inbound &&
            <>
              {this.withinCurrentOrganization && <EmailReplyButton
                emailId={record.id}
                userId={record._meta_from_user_id}
                                                 />}
              <IconButton
                onClick={this.handleHandledClick}
              >
                {handled ? <HandledIcon
                  color="primary"
                           /> : <NotHandledIcon />}
              </IconButton>
            </>}
          avatar={<Avatar>
            <EmailIcon />
          </Avatar>}
          subheader={moment(record.created_at).format('llll')}
          title={inbound ? `Email from ${record.from_email}` : `${titleCase(record.template)} Email to ${recipients} - ${titleCase(record.status)}`}
        />
        <CardContent>
          {(record.reject_reason || record.error_code || record.error_message) && (
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              ERROR: reject_reason
              {' '}
              {record.reject_reason}
              , error_code
              {' '}
              {record.error_code}
              , error_message
              {' '}
              {record.error_message}
              ,
            </Typography>
          )}
          <Typography
            variant="subheading"
            gutterBottom
          >
            Subject:
            {' '}
            {record.subject}
          </Typography>
          <Typography
            component="p"
            gutterBottom
          >
            <div
              dangerouslySetInnerHTML={{ __html: this.getEmailBody() }}
            />
            {this.hasBlockQuotes() && (
              <Button
                size="small"
                onClick={this.handleShowMoreClick}
              >
                show more
              </Button>
            )}
          </Typography>
          {record.attachments.length > 0 && (
            <Typography
              variant="subheading"
              gutterBottom
            >
              Attachments
            </Typography>
          )}
          {record.attachments.map(attachment => (
            <Typography
              key={attachment.url}
              variant="subheading"
              gutterBottom
            >
              <a
                href={attachment.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {attachment.file_name}
              </a>
              {` type: ${attachment.content_type}`}
            </Typography>
          ))}
        </CardContent>
        <CardActions
          className={classes.actions}
          disableActionSpacing
        >
          <IconButton
            aria-expanded={this.state.expanded}
            aria-label={this.state.expanded ? 'Hide more' : 'Show more'}
            className={this.state.expanded ? classes.expandOpen : classes.expand}
            onClick={this.handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            <Typography
              component="p"
            >
              {' '}
              Template:
              {record.template}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Status:
              {record.status}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              From Email:
              {record.from_email}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              From Name:
              {record.from_name}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Handled At:
              {record.handled_at}
              {' '}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}


EmailCard.propTypes = {
  resource: PropTypes.object,
  crudUpdate: PropTypes.func,
  record: PropTypes.object, 
  inbound: PropTypes.bool,
  classes: PropTypes.object,
}

function mapStateToProps(state, props) {
  return {
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
  };
}

const mapDispatchToProps = {
  crudUpdate,
}

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(EmailCard));
