import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ShareableProjectLinkField = ({ record = {}, fullUrl = false }) => (
  <Typography
    variant="body1"
  >
    {record.visibility == 'public' ? (
      <a
        href={`https://studio.codeverse.com/a/${record['short_code']}`}
        rel="noopener noreferrer"
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        {fullUrl ? `https://studio.codeverse.com/a/${record['short_code']}` : 'Open' }
      </a>
    ) : "Not Yet Published"}
  </Typography>
);

ShareableProjectLinkField.propTypes = {
  record: PropTypes.object,
};

ShareableProjectLinkField.defaultProps = {
  label: 'Shareable Link',
  addLabel: true
};

export default ShareableProjectLinkField;

