import PropTypes from 'prop-types';
import React from 'react';
import { ImageField as ReactAdminImageField, ImageInput as ReactAdminImageInput, required as requiredFn } from 'react-admin';

export const ImageInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminImageInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  >
    <ReactAdminImageField
      source="src"
      title="title"
    />
  </ReactAdminImageInput>

)

ImageInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

ImageInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default ImageInput;

