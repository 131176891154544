import PropTypes from 'prop-types';
import React from 'react';
import { LongTextInput, required as requiredFn } from 'react-admin';


const KidScriptInput = ({isRequired, validate, ...props}) => (
  <LongTextInput
    validate={isRequired ? requiredFn() : validate}
    {...props}
  />
);

KidScriptInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

KidScriptInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true,
  fullWidth: true
};

export default KidScriptInput;
