import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BooleanField as ReactAdminBooleanField } from 'react-admin';
import titleCase from 'title-case';

const style = {
  field: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display: 'inline-table',
  },
}

const WithToolTip = ({ title, children }) => (title ?
  <Tooltip
    title={title}
  >
    {children}
  </Tooltip>
  : children
);

WithToolTip.propTypes = {
   title: PropTypes.string.isRequired,
   children: PropTypes.object
};

const BooleanField = ({ classes, record = {}, source}) => {
  let title = record[source] && record[`${source}_at`] ? `${titleCase(source)} ${moment(record[`${source}_at`]).fromNow()}` : `${titleCase(source)}`
  return (
    <WithToolTip
      title={title}
    >
      <ReactAdminBooleanField
        className={classes.field}
        record={record}
        source={source}
      />
    </WithToolTip>
  )
};

BooleanField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

BooleanField.defaultProps = {
  addLabel: true,
  defaultValue: false,
};

export default withStyles(style)(BooleanField);
