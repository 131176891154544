import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextField } from '.';
import { FunctionField, ReferenceField } from '../fields';

const style = {
  link: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display:'inline-table',
  },
  linkHover: {
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
    display: 'inline-table',
  },
}

const WithToolTip = ({ title, children }) => (title ?
  <Tooltip
    title={title}
  >
    {children}
  </Tooltip>
  : children
);

class NestedLinkField extends Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = ( event ) => {
    this.setState({ isHovered: true })
  }

  handleMouseLeave = ( event ) => {
    this.setState({ isHovered: false })
  }

  render() {
    const {
      classes,
      reference,
      source,
      linkType,
      parentReference,
      parentSource,
      record,
      parentRecord,
      children,
      ...rest
    } = this.props;
    const { isHovered } = this.state;

    let href = `${reference}/${record && record.id}/${linkType || 'show'}`;

    if (parentReference) {
      href = `${parentReference}/${parentRecord[parentSource]}/${href}`;
    }
    const isDeleted = record && record.deleted_at;
    const deletedAgo = isDeleted && moment(record.deleted_at).fromNow();
    const toolTipTitle = deletedAgo && `Deleted ${deletedAgo}`;
    return (
      <WithToolTip
        title={toolTipTitle}
      >
        <a
          className={isHovered ? classes.linkHover : classes.link}
          href={`#/${href}`}
          onClick={event => event.stopPropagation()}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {(
            children && React.cloneElement(children, { record })
          ) || (typeof source === 'function' &&
            <FunctionField
              label="From"
              reference={reference}
              render={() => source(record)}
            />
          ) || (typeof source === 'string' &&
            <TextField
              record={record}
              reference={reference}
              source={source}
              style={isDeleted ? { textDecoration: 'line-through'} : {}}
              {...rest}
            />
          )}
        </a>
      </WithToolTip>
    );
  }
}

const StyledNestedLinkField = withStyles(style)(NestedLinkField);

const LinkField = ({ record, source, reference, parentSource, parentReference, displaySource, allowEmpty, filter={}, ...props }) => {
  reference = reference || source && pluralize(source.replace(/_id$/, ''))
  if (source && source[0] !== '_' && reference && source.replace(/_id$/, '') !== pluralize.singular(reference)) {
    source = `${source}-as-${reference}`
  }
  parentReference = parentReference || parentSource && pluralize(parentSource.replace(/_id$/, ''))

  return (
    <ReferenceField
      allowEmpty={!!allowEmpty}
      filter={filter}
      linkType={false}
      parentRecord={record}
      parentReference={parentReference}
      parentSource={parentSource}
      record={record}
      reference={reference}
      source={source}
      {...props}
    >
      <StyledNestedLinkField
        {...props}
        reference={reference}
        source={displaySource || 'name'}
      />
    </ReferenceField>
  )
};

LinkField.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  linkType: PropTypes.string,
  parentReference: PropTypes.string,
  parentSource: PropTypes.string,
  record: PropTypes.object,
  parentRecord: PropTypes.object,
  children: PropTypes.object,
};

LinkField.defaultProps = {
  addLabel: true,
  sortable: false,
  allowEmpty: true,
};

export default LinkField
