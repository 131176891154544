import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { ReferenceInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [
  ],
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ badge_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput
          label="Dependent Badge"
          reference="badges"
          source="dependent_badge_id-as-badges"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
