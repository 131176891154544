import Icon from '@material-ui/icons/AssignmentInd';
import RemoveFromOrgIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, ResourceList } from '../../../controllers';
import { EnumField, LinkField, TextField } from '../../../fields';
import { BooleanInput, EnumInput, InputGroup, NestedReferenceInput, ReferenceInput, TextInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      type: 'update',
      name: 'Remove From Organization',
      icon: RemoveFromOrgIcon,
      if: membership => membership.accepted,
      updateAttributes: { accepted: false },
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Membership"
        >
          <EnumInput
            source="role"
          />
          <BooleanInput
            source="accepted"
          />
        </InputGroup>
        <InputGroup
          header="Security"
          subHeader="This optional authentication mechanism allows kids to sign into a specific organization. It is most commonly used by schools."
        >
          <TextInput
            label="badge (student id or username)"
            source="badge"
          />
          <TextInput
            label="4 digit pin"
            source="pin"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="Membership"
        >
          <NestedReferenceInput
            filterToQuery={searchText => ({ name: searchText })}
            source="user_id"
          />
          <ReferenceInput
            label="Destination Organization"
            optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
            shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
            source="organization_id"
            required
          />
          <EnumInput
            source="role"
          />
          <BooleanInput
            source="accepted"
            defaultValue
          />
        </InputGroup>
        <InputGroup
          header="Security"
          subHeader="This optional authentication mechanism allows kids to sign into a specific organization. It is most commonly used by schools."
        >
          <TextInput
            label="Badge (Student ID or Username)"
            source="badge"
          />
          <TextInput
            label="4 Digit PIN"
            source="pin"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <LinkField
          source="organization_id"
        />
        <EnumField
          source="role"
        />
        <LinkField
          source="user_id"
        />
        <LinkField
          displaySource="email"
          label="Email"
          source="user_id"
        />
        <TextField
          source="badge"
        />
        <LinkField
          displaySource="scope"
          label="Scope"
          source="user_id"
        />
      </ResourceList>
    ),
  },
  components: {
  },
  resources: {
  },
};
