import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog } from '../../../controllers';
import { NumberInput, ReferenceInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [

  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <NumberInput
          source="ratio"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ mission_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput
          optionText="long_name"
          perPage={100}
          source="skill_id"
          required
        />
        <NumberInput
          source="ratio"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
