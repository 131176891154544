import RefundIcon from '@material-ui/icons/MoneyOff';
import Icon from '@material-ui/icons/Receipt';
import React from 'react';
import charge_refunds from './charge_refunds';
import { CreateButton } from '../../../../buttons';
import { CreateDialog, NestedResourceList, ResourceShow, SimpleShowLayout } from '../../../../controllers';
import { DateField, NumberField, TextField } from '../../../../fields';
import { NestedReferenceInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      name: 'Refund',
      type: 'component',
      icon: RefundIcon,
      Component: props => (
        <CreateDialog
          fixedValue={{ charge_id: props.id}}
          {...props}
          resource="charge_refunds"
        >
          <TextInput
            source="amount"
          />
        </CreateDialog>
      ),
    },
  ],
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ invoice_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          resourcePath={`/${props.parentResource.parentResource.resource}/${props.parentResource.parentResource.id}`}
          source="payment_method_id"
        />
        <TextInput
          source="amount"
        />
      </CreateButton>
    ),
  },
  routes: {
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="status"
        />
        <NumberField
          source="amount"
        />
        <NestedResourceList
          reference="charge_refunds"
        >
          <TextField
            source="amount"
          /> 
          <DateField
            source="created_at"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {

    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="charge_refunds"
        >
          <TextField
            source="amount"
          />
          <DateField
            source="created_at"
          />
        </NestedResourceList>
      </SimpleShowLayout>
      
    ),
  },
  resources: {
    charge_refunds,
  },
};
