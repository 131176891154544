import React, { Component } from 'react';
import { Field } from 'redux-form';

import BraintreeDropIn from 'braintree-web-drop-in-react';
import { Button } from 'react-admin';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const required = value => (value ? undefined : 'Required');

class BraintreeNonceInput extends Component {
  state = {
    clientToken: null,
  };

  async componentDidMount() {
    // Get a client token for authorization from your server
    const response = await fetch(`${process.env.REACT_APP_PROTOCOL || 'https'}://${process.env.REACT_APP_API_URL}/homepage/braintree_token`, {
      headers: {
        Accept: 'application/json',
      },
    });
    const clientToken = await response.json(); // If returned as JSON string

    this.setState({
      clientToken,
    });
  }

  instance;

  async sendToBraintree(nextFn) {
    // Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();
    return nextFn(nonce);
  }

  render() {
    const { input: { onChange }, meta: { error, warning } } = this.props;
    if (!this.state.clientToken) {
      return (
        <div>
          <h1>
            Loading...
          </h1>
        </div>
      );
    } else {
      return (
        <div>
          <BraintreeDropIn
            options={{ authorization: this.state.clientToken }}
            onInstance={instance => (this.instance = instance)}
          />
          <Button
            label="Check Credit Card"
            variant="raised"
            onClick={() => {
              this.sendToBraintree.bind(this)(onChange);
            }}
          >
            <CreditCardIcon />
          </Button>
          {((error && <span>
            {error}
          </span>) ||
          (warning && <span>
            {warning}
          </span>))}
        </div>
      );
    }
  }
}

const CreditCardInput = () => (
  <Field
    component={BraintreeNonceInput}
    name="payment_method_nonce"
    validate={[required]}
  />
);

export default CreditCardInput;
