import PropTypes from 'prop-types';
import React from 'react';
import { FileField as ReactAdminFileField, FileInput as ReactAdminFileInput, required as requiredFn } from 'react-admin';

export const FileInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminFileInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  >
    <ReactAdminFileField
      source="src"
      title="title"
    />
  </ReactAdminFileInput>
)

FileInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

FileInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default FileInput;

