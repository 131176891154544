import Icon from '@material-ui/icons/Face';
import InvalidateIcon from '@material-ui/icons/HighlightOff';
import LostIcon from '@material-ui/icons/ThumbDown';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../controllers';
import leadSourceCodes from '../../data/leadSourceCodes';
import { AddressField, ArrayOfStringsField, DateField, DateTimeField, EnumField, LinkField, TextField } from '../../fields';
import { BooleanInput, CountrySelectInput, DateInput, EnumInput, InputGroup, ReferenceInput, SearchInput, SelectArrayInput, SelectInput, StateSelectInput, TextInput } from '../../inputs';
import lead_interests from './lead_interests';

export default {
  Icon,
   menuItems: [
    {
      name: 'Mark Lost',
      type: 'component',
      if: lead => lead.stage != 'not_valid' && lead.lost_code == null,
      icon: LostIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <EnumInput
            source="lost_code"
          />
        </EditDialog>
      ),
    },
    {
      type: 'update',
      name: 'Mark Invalid (Test Data)',
      icon: InvalidateIcon,
      if: lead => lead.stage != 'not_valid',
      updateAttributes: {stage: 'not_valid', _comment: 'invalid lead'},
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Lead Status"
        >
          <EnumInput
            source="stage"
          />
          <EnumInput
            source="lost_code"
          />
          <DateInput
            source="revisit_at"
          />
        </InputGroup>

        <InputGroup
          header="Contact Information"
        >
          <TextInput
            source="name"
          />
          <TextInput
            source="email"
          />
          <TextInput
            source="phone"
          />
        </InputGroup>

        <InputGroup
          header="Source"
          subHeader="Optional information about where this lead came from"
        >
          <SelectArrayInput
            choices={leadSourceCodes.choices}
            source="source_codes"
          />
        </InputGroup>

        <InputGroup
          header="Location"
          subHeader="Optoinal infomation about this lead's location."
        >
          <ReferenceInput
            label="Closest Studio"
            source="location_id"
          />
          <TextInput
            source="address1"
          />
          <TextInput
            source="address2"
          />
          <TextInput
            source="city"
          />
          <StateSelectInput />
          <TextInput
            source="zip_code"
          />
          <CountrySelectInput />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="Contact Information"
        >
          <TextInput
            source="name"
          />
          <TextInput
            source="email"
          />
          <TextInput
            source="phone"
          />
        </InputGroup>

        <InputGroup
          header="Source"
          subHeader="Optional information about where this lead came from"
        >
          <SelectArrayInput
            choices={leadSourceCodes.choices}
            source="source_codes"
          />
          <TextInput
            label="Referral Code"
            source="referral_code"
          />
          <SelectInput
            choices={[          
              {id: 'Opportunities::VirtualClassesLead', name: 'Virtual Classes Lead'},
              {id: 'Opportunities::VirtualClassesProspect', name: 'Virtual Classes Prospect'},
              {id: 'Opportunities::InfluencerProgramLead', name: 'Influencer Program Lead'},
            ]}
            source="first_journey"
          />
        </InputGroup>

        <InputGroup
          header="Location"
          subHeader="Optional information about this lead's location."
        >
          <ReferenceInput
            label="Closest Studio"
            source="location_id"
          />
          <TextInput
            source="address1"
          />
          <TextInput
            source="address2"
          />
          <TextInput
            source="city"
          />
          <TextInput
            source="region"
          />
          <TextInput
            source="zip_code"
          />
          <CountrySelectInput
            source="country_code"
          />

        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        sort={{ field: 'created_at', order: 'DESC' }}
        {...props}
      >
        <TextField
          sortable={false}
          source="name"
        />
        <LinkField
          source="organization_id"
        />
        <EnumField
          sortable={false}
          source="stage"
        />
        <DateTimeField
          source="created_at"
        />
        <ArrayOfStringsField
          source="source_codes"
        />
        <EnumField
          sortable={false}
          source="lost_code"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <LinkField
          source="organization_id"
        />
        <TextField
          source="name"
        />
        <TextField
          source="email"
        />
        <TextField
          source="phone"
        />
        <AddressField />
        <TextField
          source="http_referer"
        />
        <EnumField
          source="stage"
        />
        <EnumField
          source="lost_code"
        />
        <ArrayOfStringsField
          source="source_codes"
        />
        <DateTimeField
          source="created_at"
          addLabel
        />
        <DateTimeField
          source="updated_at"
          addLabel
        />
        <TextField
          source="first_journey"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <ReferenceInput
          source="location_id"
          allowEmpty
        />
        <EnumInput
          source="stage"
        />
        <DateInput
          source="revisit_after"
        />
        <BooleanInput
          label="Show All"
          source="all_leads"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="email"
        />
        <TextField
          source="phone"
        />
        <AddressField />
        <TextField
          source="http_referer"
        />
        <EnumField
          source="stage"
        />
        <EnumField
          source="lost_code"
        />
        <TextField
          source="first_journey"
        />
        <ArrayOfStringsField
          source="source_codes"
        />
        <DateField
          source="created_at"
        />
        <DateField
          source="updated_at"
        />
      </SimpleShowLayout>
      ),
  },
  resources: {
    lead_interests,
  },
};
