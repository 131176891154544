import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import jsonLogic from "json-logic-js";
import PropTypes from "prop-types";
import { Component, default as React } from "react";
import { LongTextInput } from "react-admin";

const style = {
  container: {
    position: "relative",
  },
  error: {
    color: "#d23931",
  },
  success: {
    color: "#3ad231",
  },
  stage: {
    position: "absolute",
  },
  guard: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  eventSamples: {
    maxHeight: "400px",
    overflow: "scroll",
  },
  eventSample: {
    border: "1px solid #444",
    padding: "10px 0 0 10px",
    marginBottom: "10px",
  },
  eventSampleData: {
    margin: "0px",
    backgroundColor: "#EEE",
  },
  eventSampleResultSuccess: {
    margin: "0px",
    backgroundColor: "#ebffec",
    padding: "4px",
  },
  eventSampleResultFail: {
    backgroundColor: "#ffebeb",
    padding: "4px",
  },
};

let allEventSamples = [];

class KidScriptEventValidationRulesInput extends Component {
  static propTypes = {
    kidScript: PropTypes.string,
    rules: PropTypes.string,
    source: PropTypes.string.isRequired,
  };

  static defaultProps = {
    sort: false,
    sortable: false,
    alwaysOn: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      kidScript: props.kidScript,
      rules: props.rules,
      status: null, // valid, invalid or validating
      timeoutHandler: null,
      eventSamples: [],
    };
    allEventSamples = [];
  }

  componentWillUnmount() {
    // window.engine.stop();
    // window.interpreter.reset();
  }

  componentDidMount() {
    KidScript.library.requireCatalog().then(async () => {
      //@ts-ignore
      KidScript.library
        .requireDependencies([
          {
            name: "Game",
            version: "current",
          },
        ])
        .then(() => {
          console.log("required deps");
          window.stage = document.getElementById("stage");
          // window.physicsStage = document.getElementById("physics");

          window.interpreter = new KidScript.Interpreter(
            Object.assign(
              {},
              {
                type: "edit",
                instantiate: {
                  Game: {
                    stage: window.stage,
                    // physicsStage: physicsStageElt,
                  },
                },
              }
            )
          );

          window.interpreter.eventBus.on(
            "whenExecuter",
            "eventExecution",
            (data) => {
              // test the event
              let sanitizedData = Object.assign({}, data);
              delete sanitizedData.class;
              const result = this.testEventAgainstRules(sanitizedData);

              // for previewing the events
              // note, we do this outside of react because events are global and setState is asynchronous
              allEventSamples.push({ result, data: sanitizedData });
              this.setState({ eventSamples: allEventSamples });
            }
          );

          window.interpreter.eventBus.on(
            "classExecuter",
            "methodExecution",
            (data) => {
              // test the event
              let sanitizedData = Object.assign({}, data);
              delete sanitizedData.class;
              const result = this.testEventAgainstRules(sanitizedData);
              // for previewing the events
              // note, we do this outside of react because events are global and setState is asynchronous
              allEventSamples.push({ result, data: sanitizedData });
              this.setState({ eventSamples: allEventSamples });
            }
          );

          window.game = window.interpreter.getPreInstantiated("game");

          let documents = [this.state.kidScript, ""];
          KidScript.onDependenciesResolved(documents, KidScript.Version, () => {
            this.setState({
              kidscriptSetup: true,
              error: null,
              status: "validating",
            });
            this.executeKidScript();
          });
        });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.kidScript !== prevState.kidScript ||
      (JSON.stringify(this.state.rules) !== JSON.stringify(prevState.rules) &&
        this.state.kidscriptSetup)
    ) {
      this.executeKidScript();
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    if (nextProps.kidScript != this.state.kidScript) {
      newState.kidScript = nextProps.kidScript;
    }
    if (JSON.stringify(this.state.rules) !== JSON.stringify(nextProps.rules)) {
      newState.rules = this.jsonParse(nextProps.rules);
    }
    if (Object.keys(newState).length) {
      allEventSamples = [];
      this.setState(
        Object.assign(newState, {
          error: null,
          status: "validating",
          eventSamples: [],
        })
      );
    }
  }

  testEventAgainstRules(evt) {
    const { rules } = this.state;
    if (typeof rules !== "object") {
      return false;
    }
    try {
      const result = jsonLogic.apply(rules, evt);
      console.log("result", result);
      console.log("rules", rules);
      console.log("evt", evt);
      if (result) {
        this.setState({
          error: null,
          status: "valid",
        });
        return result;
      }
    } catch (error) {
      this.setState({
        error: error.toString(),
        status: "invalid",
      });
    }
    return false;
  }

  // validateKidScript() {
  //   if (this.state.status !== 'valid') {
  //     return 'failed'
  //   }
  // }

  executeKidScript() {
    if (!window.interpreter) return;
    if (window.interpreter.getState() !== "READY") {
      window.interpreter.stop();
    }
    if (this.state.timeoutHandler) {
      clearTimeout(this.state.timeoutHandler);
    }
    if (this.state.kidScript) {
      let documents = [this.state.kidScript, ""];
      let instructions;
      console.log("documents", documents);
      try {
        instructions = KidScript.parseDocuments(
          documents,
          KidScript.Version
        ).toInstructionTree();
      } catch (error) {
        this.setState({ status: "invalid", error: "Invalid KidScript" });
        return;
      }
      try {
        let promise = window.interpreter.execute(instructions);
        promise
          .then(
            (result) => {
              const timeoutHandler = setTimeout(() => {
                if (this.state.status === "validating") {
                  // window.engine.stop();
                  window.interpreter.pause();

                  this.setState({
                    status: "invalid",
                    error: "validation must occur within 200 msec",
                  });
                }

                this.setState({ timeoutHandler });
              }, 200);
            },
            (error) => {
              console.log(error);

              this.setState({ status: "invalid", error: "execution failed" });
            }
          )
          .catch((exception) => {
            console.log(error);

            this.setState({ status: "invalid", error: "execution failed" });
          });
      } catch (error) {
        console.log(error);

        this.setState({ status: "invalid", error: "execution failed" });
      }
    }
  }

  jsonStringify(value) {
    if (typeof value === "string") {
      return value;
    }
    try {
      return JSON.stringify(value, null, 2);
    } catch (error) {
      return value;
    }
  }

  jsonParse(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  render() {
    const { status, error, eventSamples } = this.state;
    const { classes, kidScript, rules, ...props } = this.props;
    console.log("error", error);
    return (
      <>
        <div
          className={classes.eventSamples}
        >
          {eventSamples.map((eventSample, i) => (
            <div
              className={classes.eventSample}
              key={`eventSample-${i}`}
            >
              <Typography
                variant="subheading"
              >
                Data
              </Typography>
              <pre
                className={classes.eventSampleData}
              >
                {JSON.stringify(eventSample.data, null, 2)}
              </pre>
              <Typography
                variant="subheading"
              >
                Result
              </Typography>
              <pre
                className={eventSample.result
                    ? classes.eventSampleResultSuccess
                    : classes.eventSampleResultFail}
                key={`eventResult-${i}`}
              >
                {JSON.stringify(eventSample.result, null, 2)}
              </pre>
            </div>
          ))}
        </div>
        <LongTextInput
          format={(value) => value && this.jsonStringify(value)}
          parse={(value) => this.jsonParse(value)}
          {...props}
          // validate={() => this.validateKidScript()}
        />
        <div>
          {status === "validating" && <div>
            Validating
          </div>}
          {status === "invalid" && <div
            className={classes.error}
                                   >
            {error}
          </div>}
          {status === "valid" && <div
            className={classes.success}
                                 >
            Valid
          </div>}
          <div
            id="runtimeEnvironment"
            style={{ height: "500px", width: "600px", position: "relative" }}
          >
            <div
              className={classes.guard}
            />
            <div
              className={classes.stage}
              id="stage"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(style)(KidScriptEventValidationRulesInput);
