import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
  root: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display: 'inline-table',
  }

}

class TimeZoneField extends Component {
  state = {
    showFullTimeZone: true,
    hover: false,
  };
  
  handleMouseEnter = (event) => {
    this.setState({ hover: true })
  }

  handleMouseLeave = (event) => {
    this.setState({ hover: false })
  }

  getFormatted(timeZone) {
    if (this.state.showFullTimeZone) {
      return timeZone;
    } else {
      return moment.tz.zone(timeZone).abbr(360);
    }
  }

  // the tool tip shows the inverse
  getToolTopContent(timeZone) {
    if (this.state.showFullTimeZone) {
      return moment.tz.zone(timeZone).abbr(360)
    } else {
      return timeZone;
    }
  }

  render() {
    const {
      source,
      classes,
      record,
    } = this.props;


    return (
      <Tooltip
        title={this.getToolTopContent(record[source])}
      >
        <Typography
          className={classes.root}
          variant="body1"
          onClick={(event) => {
            this.setState({
              showFullTimeZone: !this.state.showFullTimeZone,
            });
            event.stopPropagation();
          }}
        >
          {record.time_zone && this.getFormatted(record.time_zone)}
        </Typography>
      </Tooltip>
    );
  }
}

TimeZoneField.defaultProps = {
  addLabel: true,
  label: 'Time Zone'
};

TimeZoneField.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  source: PropTypes.string.isRequired,
};


export default withStyles(style)(TimeZoneField);