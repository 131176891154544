import Icon from '@material-ui/icons/Assignment';
import moment from 'moment';
import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { ResourceList } from '../../../controllers';
import { DateField, FileField, LinkField, ReferenceField, TextField } from '../../../fields';
import { FileInput, NestedReferenceInput, ReferenceInput } from '../../../inputs';


export default {
    Icon,
    dialogs: {
        create: props => (
          <CreateButton
            {...props}
            fixedValue={{ organization_id: props.parentResource.id }}
          >
            <NestedReferenceInput
              filterToQuery={searchText => ({ name: searchText })}
              source="user_id"
            />
            <ReferenceInput             
              source="contract_id"
              required
            />
            <FormDataConsumer>
              {({ formData }) => formData.contract_id && (
                <NestedReferenceInput
                  optionText={contract => moment(contract.created_at).format('ll')}
                  resourcePath={`/contracts/${formData.contract_id}/`}
                  source="contract_version_id"
                  required
                />
                )}
            </FormDataConsumer>
            <FileInput
              source="scan"
            />
          </CreateButton>
        ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <LinkField
              reference="contract_versions"
              source="version_id"
            />           
            <DateField
              label="Signed On"
              source="created_at"
            />
            <ReferenceField
              linkType={false}
              reference="users"
              source="user_id"
            >
              <TextField
                source="name"
              />
            </ReferenceField>
            <FileField
              source="scan"
              target="_blank"
              title="View"
            />
          </ResourceList>
        ),
    },
    components: {      
    },
    resources: {
    },
};
