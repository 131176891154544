import PropTypes from 'prop-types';
import React from 'react';
import { CheckboxGroupInput as ReactAdminCheckboxGroupInput, required as requiredFn } from 'react-admin';

export const CheckboxGroupInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminCheckboxGroupInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

CheckboxGroupInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

CheckboxGroupInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default CheckboxGroupInput;

