import Icon from '@material-ui/icons/AssignmentInd';
import RemoveFromOrgIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog } from '../../../controllers';
import { BooleanInput, EnumInput, InputGroup, ReferenceInput, TextInput } from '../../../inputs';

// eslint-disable-next-line

export default {
  Icon,
  menuItems: [
    {
      type: 'update',
      name: 'Remove From Organization',
      icon: RemoveFromOrgIcon,
      if: membership => membership.accepted,
      updateAttributes: { accepted: false },
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Membership"
        >
          <EnumInput
            source="role"
          />
          <BooleanInput
            source="accepted"
          />
        </InputGroup> 
        <InputGroup
          header="Security"
          subHeader="This optional authentication mechanism allows kids to sign into a specific organization. It is most commonly used by schools."
        >
          <TextInput
            label="Badge (Student ID or Username)"
            source="badge"
          />
          <TextInput
            label="4 Digit PIN"
            source="pin"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="Membership"
        >
          <ReferenceInput
            label="Destination Organization"
            optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
            shouldRenderSuggestions={(val) => {
                   return val.trim().length > 2
            }}
            source="organization_id"
            required
          />
          <EnumInput
            source="role"
          />
          <BooleanInput
            source="accepted"
            defaultValue
          />
        </InputGroup> 
        
        <InputGroup
          header="Security"
          subHeader="This optional authentication mechanism allows kids to sign into a specific organization. It is most commonly used by schools."
        >
          <TextInput
            label="Badge (Student ID or Username)"
            source="badge"
          />
          <TextInput
            label="4 Digit PIN"
            source="pin"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
