import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { linkToRecord } from 'ra-core';
import React, { Component, Fragment, isValidElement } from 'react';
import { DatagridCell } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { rowExpand } from '../actions/rowExpand';
import ExpandRowButton from './ExpandRowButton';

const sanitizeRestProps = ({
  basePath,
  children,
  classes,
  className,
  rowClick,
  id,
  isLoading,
  onToggleItem,
  push,
  record,
  resource,
  isSelected,
  style,
  styles,
  ...rest
}) => rest;

class DatagridRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      colSpan: this.computeColSpan(props),
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const colSpan = this.computeColSpan(this.props);
    if (colSpan !== prevState.colSpan) {
      this.setState({ colSpan });
    }
  };

  handleToggleExpanded = event => {
    let {
      id,
      basePath,
      rowExpand,
      expandedRows,
    } = this.props;

    const expanded = !!expandedRows[id]

    const newState = !expanded;
    this.setState({ expanded: newState });
    rowExpand(id, newState, basePath)
    event.stopPropagation();
  };

  handleToggle = event => {
    this.props.onToggleItem(this.props.id);
    event.stopPropagation();
  };

  handleClick = async event => {
    const { basePath, rowClick, id, record } = this.props;

    if (!rowClick) return;

    if (typeof rowClick === 'function') {
      const path = await rowClick(id, basePath, record);
      this.handleRedirection(path, event);
      return;
    }

    this.handleRedirection(rowClick, event);
  };

  handleRedirection = (path, event) => {
    const { basePath, id, push } = this.props;

    if (path === 'edit') {
      push(linkToRecord(basePath, id));
      return;
    }
    if (path === 'show') {
      push(linkToRecord(basePath, id, 'show'));
      return;
    }
    if (path === 'expand') {
      this.handleToggleExpanded(event);
      return;
    }
    if (!path) return;

    push(path);
  };

  computeColSpan = props => {
    const { children, hasBulkActions } = props;
    return (
      1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter(child => !!child).length // non-null children
    );
  };

  render() {
    const {
      basePath,
      children,
      classes,
      className,
      expand,
      hasBulkActions,
      isHovered,
      id,
      record,
      resource,
      isSelected,
      style,
      styles,
      expandedRows,
      ...rest
    } = this.props;
    const expanded = !!expandedRows[id]
    const { colSpan } = this.state;
    return (
      <Fragment>
        <TableRow
          className={className}
          isHovered={isHovered}
          key={id}
          style={style}
          onClick={this.handleClick}
          {...sanitizeRestProps(rest)}
        >
          {expand && (
            <TableCell
              className={classes.expandIconCell}
              padding="none"
            >
              <ExpandRowButton
                classes={classes}
                expandContentId={`${id}-expand`}
                expanded={expanded}
                onClick={this.handleToggleExpanded}
              />
            </TableCell>
          )}
          {hasBulkActions && (
            <TableCell
              padding="none"
            >
              <Checkbox
                checked={isSelected}
                className={`select-item ${classes.checkbox}`}
                color="primary"
                onClick={this.handleToggle}
              />
            </TableCell>
          )}
          {React.Children.map(children, (field, index) =>
            isValidElement(field) ? (
              <DatagridCell
                className={classNames(
                  `column-${field.props.source}`,
                  classes.rowCell
                )}
                key={`${id}-${field.props.source || index}`}
                record={record}
                {...{ field, basePath, resource }}
              />
            ) : null
          )}
        </TableRow>
        {expand && expanded && (
          <TableRow
            id={`${id}-expand`}
            key={`${id}-expand`}
          >
            <TableCell
              colSpan={colSpan}
            >
              {React.cloneElement(expand, {
                record,
                basePath,
                resource,
                id: String(id),
              })}
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  }
}

DatagridRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.node,
  hasBulkActions: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isSelected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object,
};

DatagridRow.defaultProps = {
  hasBulkActions: false,
  isHovered: true,
  record: {},
  isSelected: false,
};

function mapStateToProps(state, props) {
  return {
    expandedRows: state.expandedRows[props.basePath] || {}
  };
}

const mapDispatchToProps = {
  push,
  rowExpand,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
DatagridRow.displayName = 'DatagridRow';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatagridRow);
