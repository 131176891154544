import Icon from '@material-ui/icons/Description';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter } from '../../../../controllers';
import { BooleanInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          label="Prior Coding Experience"
          source="experience"
        />
        <TextInput
          label="Preferred Teaching Style"
          source="styles"
        />
        <TextInput
          label="Interests and Hobbies"
          source="interests"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ 
          user_id: props.parentResource.id,
          organization_id: props.parentResource.organization_id,
        }}
        {...props}
      >
        <TextInput
          label="Prior Coding Experience"
          source="experience"
        />
        <TextInput
          label="Preferred Teaching Style"
          source="styles"
        />
        <TextInput
          label="Interests and Hobbies"
          source="interests"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
