import Icon from '@material-ui/icons/Dns';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../buttons';
import { LeadOpportunityTaskCard } from '../../components';
import { EditDialog, Filter, ResourceList, SimpleShowLayout } from '../../controllers';
import { BooleanField, DateField, EnumField, LinkField, RichTextField } from '../../fields';
import { BooleanInput, DateInput, EnumInput, InputGroup, ReferenceInput, RichTextInput, SearchInput, StaffTeamSelectInput, TeamMemberSelectInput } from '../../inputs';

const CompletedRowStyle = record => ({
  opacity: record.completed_at ? '0.3' : '1',
});

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'completed'
    }
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="About This Task"
        >
          <EnumInput
            source="priority"
          />
          <DateInput
            label="Due date"
            source="due_at"
            required
          />
        </InputGroup>

        <InputGroup
          header="Change Task Details"
          fullWidth
        >
          <RichTextInput
            source="instruction"
          />
        </InputGroup>

        <InputGroup
          header="Reassign This Task"
        >
          <StaffTeamSelectInput
            source="staff_team_id"
            required
          />
          <TeamMemberSelectInput
            source="assignee_id"
          />
        </InputGroup>

        <InputGroup
          header="Has this task been completed?"
          fullWidth
        >
          <BooleanInput
            source="completed"
          />
        </InputGroup>
        

      </EditDialog>
    ),
      create: props => (
        <CreateButton
          {...props}
        >
          <InputGroup
            header="Organization"
          >
            <ReferenceInput
              label="Organization"
              optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
              shouldRenderSuggestions={(val) => {
                   return val.trim().length > 2
              }}
              source="organization_id"
              required
            />
          </InputGroup>
          
          <InputGroup
            header="About This Task"
          >
            
            <StaffTeamSelectInput
              source="staff_team_id"
              required
            />
            <TeamMemberSelectInput
              source="assignee_id"
            />
            <EnumInput
              source="priority"
            />
            <DateInput
              label="Due date"
              source="due_at"
              required
            />
          </InputGroup>

          <InputGroup
            header="Task Details"
            subHeader="Please provide very clear instructions"
            fullWidth
          >
            <RichTextInput
              source="instruction"
              required
            />
          </InputGroup>

        </CreateButton>
    ),
  },
  routes: {
    list: {
      default: {
        title: 'Today\'s Tasks',
        Controller: props => (
          <ResourceList
            RowStyle={CompletedRowStyle}
            filter={{ completed: false,
                      due_before: new Date().toISOString().substr(0, 10)
            }}
            sort={{field: 'due_at', order: 'asc' }}
            {...props}
          >
            <EnumField
              source="priority"
            />
            <DateField
              label="Due date"
              source="due_at"
            />
            <LinkField
              source="staff_team_id"
            />
            <LinkField
              label="Assigned to"
              reference="users"
              source="assignee_id"
            />
            <LinkField
              source="organization_id"
            />
            <RichTextField
              source="instruction"
            />
          </ResourceList>
        ),
        Filter: props => (
          <Filter
            {...props}
          >
            <SearchInput />
            <StaffTeamSelectInput
              source="staff_team_id"
            />
            <TeamMemberSelectInput
              label="Assigned to"
              reference="users"
              source="assignee_id"
            />
            <TeamMemberSelectInput
              label="Assigned by"
              reference="users"
              source="assignor_id"
            />
          </Filter>
        ),
      },
      incomplete: {
        title: 'Incomplete Tasks',
        Controller: props => (
          <ResourceList
            RowStyle={CompletedRowStyle}
            filter={{ completed: false }}
            filterDefaultValues={{   due_before: new Date().toISOString().substr(0, 10) }}
            {...props}
          >
            <EnumField
              source="priority"
            />
            <DateField
              label="Due date"
              source="due_at"
            />
            <LinkField
              source="staff_team_id"
            />
            <LinkField
              label="Assigned to"
              reference="users"
              source="assignee_id"
            />
            <LinkField
              source="organization_id"
            />
            <RichTextField
              source="instruction"
            />
          </ResourceList>
        ),
        Filter: props => (
          <Filter
            {...props}
          >
            <SearchInput />
            <StaffTeamSelectInput
              source="staff_team_id"
            />
            <TeamMemberSelectInput
              label="Assigned to"
              reference="users"
              source="assignee_id"
            />
            <TeamMemberSelectInput
              label="Assigned by"
              reference="users"
              source="assignor_id"
            />
            <DateInput
              source="due_before"
            />
            <DateInput
              source="due_after"
            />
          </Filter>
        ),
      },
      mine: {
        title: 'My Tasks',
        Controller: props => (
          <ResourceList
            RowStyle={CompletedRowStyle}
            filter={{
              assignee_id: self.localStorage.user_id,
              completed: false}}
            sort={{ field: "due_at", order: 'asc' }}
            {...props}
          >
            <EnumField
              source="priority"
            />
            <DateField
              label="Due date"
              source="due_at"
            />
            <LinkField
              source="staff_team_id"
            />
            <LinkField
              label="Assigned to"
              reference="users"
              source="assignee_id"
            />
            <LinkField
              source="organization_id"
            />
            <RichTextField
              source="instruction"
            />
          </ResourceList>
        ),
        Filter: props => (
          <Filter
            {...props}
          >
            <SearchInput />
            <StaffTeamSelectInput
              source="staff_team_id"
            />
            <TeamMemberSelectInput
              label="Assigned by"
              reference="users"
              source="assignor_id"
            />
            <DateInput
              source="due_before"
            />
            <DateInput
              source="due_after"
            />
          </Filter>
        ),
      },
      all: {
        title: 'All Tasks',
        Controller: props => (
          <ResourceList
            {...props}
          >
            <EnumField
              source="priority"
            />
            <DateField
              label="Due date"
              source="due_at"
            />
         
            <LinkField
              source="staff_team_id"
              allowEmpty
            />
            <LinkField
              label="Assigned to"
              reference="users"
              source="assignee_id"
              allowEmpty
            />
            <LinkField
              source="organization_id"
            />
            <RichTextField
              source="instruction"
            />
            <BooleanField
              source="completed"
            />
          </ResourceList>
        ),
        Filter: props => (
          <Filter
            {...props}
          >
            <SearchInput />
            <StaffTeamSelectInput
              source="staff_team_id"
            />
            <TeamMemberSelectInput
              label="Assigned to"
              reference="users"
              source="assignee_id"
            />
            <TeamMemberSelectInput
              label="Assigned by"
              reference="users"
              source="assignor_id"
            />
            <DateInput
              source="due_before"
            />
            <DateInput
              source="due_after"
            />
            <BooleanInput
              source="completed"
            />
            <BooleanInput
              source="deleted"
            />
          </Filter>
        ),
        Expand: props => (
          <SimpleShowLayout
            {...props}
          >
            <RichTextField
              source="conclusion"
            />
            <LeadOpportunityTaskCard />
          </SimpleShowLayout>
        ),
      }
    },
  },
  components: {
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <LeadOpportunityTaskCard />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
