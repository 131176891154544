import Icon from '@material-ui/icons/ViewWeek';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { ArrayOfStringsField, DateField, DaysOfWeekField, ImageField, LinkField, NumberField, StartAndEndTimeField, TextField } from '../../../fields';
import { ArrayOfStringsInput, BooleanInput, DateInput, ImageInput, InputGroup, NumberInput, ReferenceInput, TextInput, TimeZoneSelectInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <DateField
          source="first_available_at"
        />
        <StartAndEndTimeField />
  
        <DaysOfWeekField />
        
        <DateInput
          source="last_available_at"
        />
        <ArrayOfStringsInput
          source="excluded_dates"
        />
        <NumberInput
          source="capacity"
        />

        <InputGroup
          header="For Website"
        >
          <TextInput
            source="header"
          />
          <TextInput
            source="description"
          />
          <ImageInput
            label="Image"
            source="image"
          />
        </InputGroup>
        <InputGroup
          header="For Internal Use"
        >
          <TextInput
            source="notes"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ occasion_id: props.parentResource.id }}
        {...props}
      >  
        <InputGroup
          header="General"
        >
          <ReferenceInput
            source="location_id"
          />
          <NumberInput
            source="capacity"
          />
        </InputGroup>

        <InputGroup
          header="Start Time"
          subHeader="Be sure to use a 24 hour clock. For example, if the slot begins at 4:30 PM enter '16' for start hour and '30' for start minute."
        >
          <NumberInput
            source="start_hour"
            required
          />
          <NumberInput
            source="start_minute"
            required
          />
        </InputGroup>

        <InputGroup
          header="End Time"
          subHeader="Be sure to use a 24 hour clock. For example, if the slot ends at 5:45 PM enter '17' for start hour and '45' for start minute."
        >
          <NumberInput
            source="end_hour"
            required
          />
          <NumberInput
            source="end_minute"
            required
          />
        </InputGroup>

        <InputGroup
          header="Available Dates"
          subHeader="Last Available is optional. If no date is provided, the slot will remain available indefinitely."
        >
          <DateInput
            source="first_available_at"
            required
          />
          <DateInput
            source="last_available_at"
          />
        </InputGroup>

        <InputGroup
          header="Day of the Week"
          subHeader="Select the days on which this slot occurs. For a slot that takes place over multiple days such as a camp, choose the first day."
        >
          <BooleanInput
            source="monday"
          />
          <BooleanInput
            source="tuesday"
          />
          <BooleanInput
            source="wednesday"
          />
          <BooleanInput
            source="thursday"
          />
          <BooleanInput
            source="friday"
          />
          <BooleanInput
            source="saturday"
          />
          <BooleanInput
            source="sunday"
          />
        </InputGroup>
        
        <InputGroup
          header="Time Zone"
        >
          <TimeZoneSelectInput />
        </InputGroup>

        <InputGroup
          header="For Website"
        >
          <TextInput
            source="header"
          />
          <TextInput
            source="description"
          />
          <ImageInput
            label="Image"
            source="image"
          />
        </InputGroup>
        <InputGroup
          header="For Internal Use"
        >
          <TextInput
            source="notes"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        sort={{field: 'first_available_at', order: 'desc'}}
        {...props}
      >
        <LinkField
          source="location_id"
        />
        <StartAndEndTimeField />
        <DateField
          source="first_available_at"
        />
        <DateField
          source="last_available_at"
        />
        <DaysOfWeekField />
        <NumberField
          source="capacity"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          source="location_id"
        />
        <NumberField
          source="capacity"
        />
        <StartAndEndTimeField />
        <DateField
          source="first_available_at"
        />
        <DateField
          source="last_available_at"
        />
        <DaysOfWeekField />
        <ArrayOfStringsField
          source="excluded_dates"
        />
        <TextField
          source="header"
        />
        <TextField
          source="description"
        />
        <ImageField
          source="image"
        />
        <TextField
          source="notes"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <ReferenceInput
          source="location_id"
          allowEmpty
        />
        <DateInput
          label="Date"
          source="date"
        />
        <NumberInput
          label="Start Hour"
          source="start_hour"
        />
        <NumberInput
          label="End Hour"
          source="end_hour"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <ArrayOfStringsField
          source="excluded_dates"
        />
        <TextField
          source="header"
        />
        <TextField
          source="description"
        />
        <ImageField
          source="image"
        />
        <TextField
          source="notes"
        />

      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
