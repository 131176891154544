import Icon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { CreateButton } from '../../buttons';
import { Filter, ResourceList } from '../../controllers';
import { LinkField } from '../../fields';
import { BooleanInput, ReferenceInput, TeamMemberSelectInput } from '../../inputs';


export default {
    Icon,
    dialogs: {
        create: props => (
          <CreateButton
            {...props}
          >
            <TeamMemberSelectInput
              source="user_id"
              required
            />
            <ReferenceInput
              source="occasion_id"
              required
            />
          </CreateButton>
        ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <LinkField
              source="user_id"
            />
            <LinkField
              source="occasion_id"
            />
          </ResourceList>
        ),
    },
    components: {
        Filter: props => (
          <Filter
            {...props}
          >
            <TeamMemberSelectInput
              source="user_id"
            />
            <ReferenceInput
              source="occasion_id"
            />
            <BooleanInput
              source="deleted"
            />
          </Filter>
        ),
    },
    resources: {
    },
};
