import Icon from '@material-ui/icons/PhotoAlbum';
import React from 'react';
import { Filter, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { DateField, EditableProjectLinkField, ImageField, LinkField, TextField, VersionField } from '../../fields';
import { ReferenceInput, SearchInput } from '../../inputs';


export default {
  Icon,
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        
        <VersionField />
        <DateField
          source="created_at"
        />
        <TextField
          source="category_name"
        />
        <LinkField
          source="user_id"
        />
        <LinkField
          parentReference="users"
          parentSource="user_id"
          source="project_id"
        />
        <EditableProjectLinkField
          fullUrl
        />
        <ImageField
          source="screenshot_2x"
        />

      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <VersionField />
        <DateField
          source="created_at"
        />
        <TextField
          source="category_name"
        />
        <LinkField
          parentReference="users"
          parentSource="user_id"
          source="project_id"
        />
        <LinkField
          source="user_id"
        />
        <EditableProjectLinkField
          fullUrl
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <ReferenceInput
          reference="project_categories"
          source="category_id"
        /> 
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="description"
        />
      </SimpleShowLayout>
    )
  },
  resources: {
  },
};

