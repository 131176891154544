import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import DefaultSetIcon from '@material-ui/icons/CheckBox';
import DefaultUnsetIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NoteIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/MoreVert';
import UndeleteIcon from '@material-ui/icons/Undo';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Confirm, crudDelete, crudGetOne, crudUpdate, REDUX_FORM_NAME, resetForm } from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reset } from 'redux-form';
import titleCase from 'title-case';
import { Alert } from '../components';

const styles = (theme) => ({
  root: {
    justifyContent: 'space-between',
  },
  title: {
    margin: '12px 4px',
  },
  deletedTitle: {
    margin: '12px 4px',
    color: '#e60000'
  },
  navigation: {
    display: 'flex',
  },
  buttons: {
    logEntries: {
    }
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
});

class ActionsButton extends Component {
  state = {
    anchorEl: null,
    showEditDialog: false,
    confirmingDelete: false,
    selectedMenuItem: null,
    isUpdating: false
  };

  getMenuItems = () => {
    let {menuItems = []} = this.props;
    return menuItems.map((menuItem) => {
      let { type, attribute } = menuItem;
      switch (type) {

        // the "update" type is for custom actions, and should have all configuration provided
        case 'update':
          return menuItem

        // the "component" type should have all configuration provided
        case 'component':
          return menuItem

        // example: mark completed / uncompleted
        case 'toggle':

          return [
            {
              name: `${titleCase(attribute)}`,
              icon: DefaultSetIcon,
              if: record => !record[attribute],
              updateAttributes: { [attribute]: true },
            },
            {
              name: `Set Not ${titleCase(attribute)}`,
              icon: DefaultUnsetIcon,
              if: record => record[attribute],
              updateAttributes: { [attribute]: false },
            }
          ]

        default:
          throw new Error(`unexpected type ${type}`)

      }
    }).flat()
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    event.stopPropagation()
  };

  handleClose = (event) => {
    this.closeMenu();
    event && event.stopPropagation()
  };

  handleEditClick = (event) => {
    this.setState({ showEditDialog: true });
    this.closeMenu()
    event.stopPropagation()
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  closeEditDialog = () => {
    this.setState({
      showEditDialog: false,
      selectedMenuItem: null
    });
  };

  handleConfirmDelete = (event) => {
    this.setState({
      confirmingDelete: true,
      selectedMenuItem: null
    });
    this.closeMenu()
    event.stopPropagation()
  };

  handleCloseDeleteConfirmation = () => {
    this.setState({ confirmingDelete: false });
  };

  handleCloseConfirmation = () => {
    this.setState({ selectedMenuItem: null });
  };

  handleShowConfirmationClicked = (i, event) => {
    this.setState({ selectedMenuItem: i });
    this.closeMenu();
    event.stopPropagation()
  };

  handleUpdateComplete = () => {
    this.setState({ isUpdating: false });
  };

  handleDeleteComplete = () => {
    this.setState({ isUpdating: false });

    const {
      crudGetOne,
      resource,
      record,
    } = this.props;

    crudGetOne(
      resource,
      record.id,
      `/${resource}`
    );
  };

  handleUpdateFailedAlertClose = () => {
    this.setState({ isUpdating: false });
    this.props.reset(REDUX_FORM_NAME);
    this.props.resetForm()
  };

  handleDelete = () => {
    const {
      crudDelete,
      resource,
      record,
    } = this.props;

    this.setState({ isUpdating: true });
    crudDelete(
      resource,
      record.id,
      record,
      `/${resource}`,
      this.handleDeleteComplete,
      false,
    )
    this.handleCloseDeleteConfirmation();
    this.closeMenu();
  };

  handleUndelete = (event) => {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    this.setState({ isUpdating: true });
    crudUpdate(
      resource,
      record.id,
      Object.assign({}, record, { undelete: true }),
      record,
      `/${resource}`,
      this.handleUpdateComplete,
    );

    this.closeMenu();
    event.stopPropagation()
  };

  handleMenuItemClick(i, event) {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    const menuItems = this.getMenuItems();

    const { updateAttributes } = menuItems[i];

    this.setState({ isUpdating: true });
    crudUpdate(
      resource,
      record.id,
      Object.assign({}, record, updateAttributes),
      record,
      `/${resource}`,
      this.handleUpdateComplete,
    );

    this.closeMenu();
    this.handleCloseConfirmation();
    event && event.stopPropagation()
  }

  render() {
    const {
      classes,
      resource,
      record,
      dialogs,
      parentResource,
      updateFailed,
      updateErrors,
      basePath,
    } = this.props;

    const menuItems = this.getMenuItems();

    const {
      anchorEl,
      showEditDialog,
      selectedMenuItem,
      confirmingDelete,
      isUpdating,
    } = this.state;

    if (!record) {
      return null
    }

    if (resource === 'log_entries') {
      return null
    }

    let firstError = updateErrors ? Object.values(updateErrors)[0] : null;

    const newParentResource = {
      basePath: basePath,
      resource: resource,
      id: record.id,
      record: record,
      parentResource,
    }

    return (
      <Fragment>
        <Button
          aria-haspopup="true"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          onClick={this.handleClick}
        >
          <MenuIcon />
        </Button>
        {Boolean(anchorEl) && (
          <Menu
            anchorEl={anchorEl}
            id="simple-menu"
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >

            {(!record.deleted_at) && dialogs.edit && (
              <MenuItem
                onClick={this.handleEditClick}
              >
                <ListItemIcon
                  className={classes.icon}
                >
                  <EditIcon />
                </ListItemIcon>
                Edit
              </MenuItem>
            )}

            {(!record.deleted_at) && menuItems && menuItems.map((menuItem, i) => (
              <>
                {(typeof menuItem.if === 'undefined' || menuItem.if(record)) &&
                  <MenuItem
                    key={`menuItem-${i}`}
                    onClick={event => menuItem.confirmation || menuItem.Component ? this.handleShowConfirmationClicked(i, event) : this.handleMenuItemClick(i, event)}
                  >
                    <ListItemIcon
                      className={classes.icon}
                    >
                      <menuItem.icon />
                    </ListItemIcon>
                    {menuItem.name}
                  </MenuItem>}
              </>
            ))}

            <MenuItem
              component={Link}
              to={`/log_entries?filter=${encodeURI(JSON.stringify({ referencing_id: record.id }))}`}
              onClick={this.handleClose}
            >
              <ListItemIcon
                className={classes.icon}
              >
                <NoteIcon />
              </ListItemIcon>
              Log Entries
            </MenuItem>

            {record.deletable && record.deleted_at && (
              <MenuItem
                onClick={this.handleUndelete}
              >
                <ListItemIcon
                  className={classes.icon}
                >
                  <UndeleteIcon />
                </ListItemIcon>
                Undelete
              </MenuItem>
            )}

            {record.deletable && (!record.deleted_at) && (
              <MenuItem
                onClick={this.handleConfirmDelete}
              >
                <ListItemIcon
                  className={classes.icon}
                >
                  <DeleteIcon />
                </ListItemIcon>
                Delete
              </MenuItem>
            )}

          </Menu>
        )}

        {showEditDialog && (
          <dialogs.edit
            close={this.closeEditDialog}
            id={record.id}
            parentResource={newParentResource}
            record={record}
            resource={resource}
          />
        )}

        {confirmingDelete && (
          <Confirm
            content={`Do you want to delete this ${titleCase(pluralize.singular(resource))}`}
            isOpen={confirmingDelete}
            title="Are you sure?"
            onClose={this.handleCloseDeleteConfirmation}
            onConfirm={this.handleDelete}
          />
        )}

        {(!record.deleted_at) && menuItems && menuItems.map((menuItem, i) => (
          <>
            {(selectedMenuItem === i) && (
              menuItem.confirmation && (
                <Confirm
                  content={menuItem.confirmation(record)}
                  isOpen={selectedMenuItem === i}
                  key={`menuItem-confirmation-${i}`}
                  title="Are you sure?"
                  onClose={this.handleCloseConfirmation}
                  onConfirm={event => this.handleMenuItemClick(i)}
                />
              ) || (
                menuItem.Component && (
                  <menuItem.Component
                    close={this.closeEditDialog}
                    id={record.id}
                    parentResource={newParentResource}
                    record={record}
                    resource={resource}
                    title={menuItem.name}
                  />
                )
              )
            )}
          </>
        ))}

        {updateFailed && isUpdating && (
          <Alert
            content={firstError}
            isOpen={updateFailed}
            title={`Can't update ${titleCase(resource)}`}
            onClose={this.handleUpdateFailedAlertClose}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isLoading: state.admin.loading > 0,
    updateFailed: state.form && state.form['record-form'] && state.form['record-form'].submitFailed,
    updateErrors: state.form && state.form['record-form'] && state.form['record-form'].submitErrors,
  };
}

ActionsButton.propTypes = {
  resetForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired
};

const mapDispatchToProps = {
  reset,
  resetForm,
  crudDelete,
  crudUpdate,
  crudGetOne,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ActionsButton));
