import Icon from '@material-ui/icons/Phone';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../controllers';
import { LinkField, NumberField, TextField } from '../../fields';
import { BooleanInput, InputGroup, NumberInput, ReferenceInput, SearchInput, TeamMemberSelectInput, TextInput } from '../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <NumberInput
          source="extension"
        />
        <TextInput
          source="description"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="User Information" 
          subHeader="The username should be a full first and last name, sach as: johnsmith"
        >
          <TextInput
            source="username"
            required
          />
          <TeamMemberSelectInput
            source="user_id"
            required
          />
        </InputGroup>
        <InputGroup
          header="Optional Additional Details"
        >
          <NumberInput
            source="extension"
          />
          <ReferenceInput
            source="location_id"
          />
          <TextInput
            source="description"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="username"
        />
        <NumberField
          source="extension"
        />
        <LinkField
          source="user_id"
        />
        <LinkField
          source="location_id"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="username"
        />
        <NumberField
          source="extension"
        />
        <TextField
          source="password"
        />
        <TextField
          source="description"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
