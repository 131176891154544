import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { Children } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const styles = theme => ({
  actionButtonCell: {
    textAlign: 'right',
  },
});

const ResourceListPage = ({
  resourcePath,
  routes,
  children,
  ...props
}) => {

  // if there are multiple list pages, then create tabs for them
  const multipleListPages = typeof routes.list === 'object';
  const basePath = resourcePath[resourcePath.length - 1].basePath;
  let selectedTab = 0;
  const currentUrl = props.location.pathname;
  const listPages = multipleListPages && Object.keys(routes.list).map((c, i) => {
    const ret = {
      title: (routes.list[c].title || 'missing title'),
      url: (c === 'default' ? basePath : `${basePath}/${c}`),
    };
    if (ret.url === currentUrl) {
      selectedTab = i;
    }
    return ret;
  });


  return (
    <>
      {multipleListPages &&
        (
          <Tabs
            value={selectedTab}
          >
            { listPages.map(link => (
              <Tab
                component={Link}
                key={link.url}
                label={link.title}
                to={link.url}
              />
            ))}
          </Tabs>
        )}
      <div
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
          padding: '10px'
        }}
      >
        {Children.toArray(children).map(child => child)}
      </div>
    </>
  );
};


function mapStateToProps(state, props) {
  return {
    mostRecentCreated: state.mostRecentCreated || {}
  };
}

const mapDispatchToProps = {
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(
  ResourceListPage,
));

