import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HandledIcon from '@material-ui/icons/CheckBox';
import NotHandledIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextMessageIcon from '@material-ui/icons/Textsms';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { crudUpdate } from 'react-admin';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import titleCase from 'title-case';
import TextMessageReplyButton from './TextMessageReplyButton';

const style = {
  inbound: {
    width: '80%',
    marginRight: '20%',
    backgroundColor: '#d3e0d7',
  },
  outbound: {
    width: '80%',
    marginLeft: '20%',
    backgroundColor: '#e6ffef',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
};

class TextMessageCard extends Component {
  state = {
    expanded: false,
    handled: null,
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleHandledClick = (values) => {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    crudUpdate(
      resource,
      record.id,
      Object.assign({}, record, { handled: !record.handled }),
      record,
      `/${resource}`,
      false,
    );
  };

  render() {
    const { record, inbound, classes } = this.props;

    if (!record) {
      return null
    }

    const cardClass = inbound ? classes.inbound : classes.outbound;
    const handled = this.state.handled === null ? !!record.handled_at : this.state.handled;

    return (
      <Card
        className={cardClass}
      >
        <CardHeader
          action={inbound &&
            <>
              <TextMessageReplyButton
                from_dialing_code={record.from_dialing_code}
                from_phone_number={record.from_phone_number}
              />
              <IconButton
                onClick={this.handleHandledClick}
              >
                {handled ? <HandledIcon
                  color="primary"
                           /> : <NotHandledIcon />}
              </IconButton>
            </>}
          avatar={<Avatar>
            <TextMessageIcon />
          </Avatar>}
          subheader={moment(record.created_at).format('llll')}
          title={inbound ? `SMS from ${record.from_phone_number_formatted}` : `${titleCase(record.template)}  SMS to ${record.to_phone_number_formatted} - ${titleCase(record.status)}`}
        />
        <CardContent>
          <Typography
            component="p"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              {record.body}
            </Linkify>
          </Typography>
          {record.attachments.length > 0 && (
            <Typography
              variant="subheading"
              gutterBottom
            >
              Attachments:
            </Typography>
          )}
          {record.attachments.map((attachment, i) => (
            <Typography
              key={attachment}
              variant="subheading"
              gutterBottom
            >
              <a
                href={attachment}
                rel="noopener noreferrer"
                target="_blank"
              >
                Attachment
                {' '}
                {i + 1}
              </a>
            </Typography>
          ))}
        </CardContent>
        <CardActions
          className={classes.actions}
          disableActionSpacing
        >
          <IconButton
            aria-expanded={this.state.expanded}
            aria-label={this.state.expanded ? 'Hide more' : 'Show more'}
            className={this.state.expanded ? classes.expandOpen : classes.expand}
            onClick={this.handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            <Typography
              component="p"
            >
              {' '}
              Template:
              {record.template}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Status:
              {record.status}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              From:
              {record.from_phone_number_formatted}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              To:
              {record.to_phone_number_formatted}
              {' '}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

TextMessageCard.propTypes = {
  resource: PropTypes.object,
  crudUpdate: PropTypes.func,
  record: PropTypes.object, 
  inbound: PropTypes.bool,
  classes: PropTypes.object,
  showNotification: PropTypes.bool,
}

function mapStateToProps(state, props) {
  return {
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
  };
}

const mapDispatchToProps = {
  crudUpdate,
}

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(TextMessageCard));
