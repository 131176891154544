import PropTypes from 'prop-types';
import React from 'react';
import { SelectInput } from 'react-admin';
import timeZones from '../data/timeZones';

const TimeZoneSelectInput = props => (
  <SelectInput
    {...props}
    choices={props.choices || timeZones.choices}
  />
);

TimeZoneSelectInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  defaultValue: PropTypes.string,
  choices: PropTypes.object,
};

TimeZoneSelectInput.defaultProps = {
  label: 'Time Zone',
  source: 'time_zone',
  defaultValue: 'America/Chicago',
  sort: false,
  sortable: false,
  alwaysOn: true,
};

export default TimeZoneSelectInput;




