import Icon from '@material-ui/icons/ShoppingCart';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { ChipField, ReferenceManyField, SingleFieldList, TextField } from '../../fields';
import { BooleanInput, SearchInput, TextInput } from '../../inputs';
import product_skus from './product_skus';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <NestedResourceList
          reference="product_skus"
        >
          <TextField
            source="sku_code"
          />
          <TextField
            source="price"
          />

          <ReferenceManyField
            label="Product SKU Trait Value"
            reference="product_sku_trait_values"
            target="product_sku_id"
          >
            <SingleFieldList>
              <ChipField
                source="name"
              />
            </SingleFieldList>
          </ReferenceManyField>
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="product_skus"
        >
          <TextField
            source="sku_code"
          />
          <TextField
            source="price"
          />
          <ReferenceManyField
            label="Product SKU Trait Value"
            reference="product_sku_trait_values"
            target="product_sku_id"
          >
            <SingleFieldList>
              <ChipField
                source="name"
              />
            </SingleFieldList>
          </ReferenceManyField>
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    product_skus,
  },
};
