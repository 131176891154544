import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { EnumField, NumberField, TextField } from '../../../fields';
import { BooleanInput, EnumInput, NumberInput } from '../../../inputs';
import learning_path_level_skill_levels from './learning_path_level_skill_levels';

export default {
  Icon,
  menuItems: [
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <NumberInput
          source="minutes"
          required
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="position"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ learning_path_id: props.parentResource.id }}
        {...props}
      >
        <NumberInput
          source="minutes"
          required
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="position"
        />
      </CreateButton>
    ),
  },
  routes: {
     list: props => (
       <ResourceList
         {...props}
       >
         <NumberField
           source="position"
         />
         <TextField
           source="name"
         />
         <EnumField
           source="difficulty_level"
         />
         <NumberField
           source="minutes"
         />
         <NumberField
           source="points"
         />
       </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <NumberField
          source="position"
        />
        <TextField
          source="description"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="minutes"
        />
        <NumberField
          source="points"
        />

        <NestedResourceList
          reference="learning_path_level_skill_levels"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>

      </ResourceShow>

    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NumberField
          source="minutes"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="position"
        />

        <NestedResourceList
          reference="learning_path_level_skill_levels"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>

      </SimpleShowLayout>
    ),
  },
  resources: {
    learning_path_level_skill_levels
  },
};
