import Icon from '@material-ui/icons/Phone';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { EditDialog, Filter, ResourceList } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, NumberField, PhoneCallPartiesField, TextField } from '../../../fields';
import { BooleanInput, EnumInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <DateTimeField
          source="created_at"
        />
        <TextField
          source="caller_name"
        />
        <TextField
          source="from_phone"
        />
        <NumberField
          source="duration"
        />
        <TextField
          source="voicemail_recording_uri"
        />
        <TextField
          source="comment"
        />
      </EditDialog>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="leg"
        />
        <EnumField
          source="status"
        />
        <DateTimeField
          source="created_at"
        />
        <TextField
          source="caller_name"
        />
        <PhoneCallPartiesField />
        <NumberField
          source="duration"
        />
        <BooleanField
          source="handled"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <EnumInput
          filterDefaultValues={{ status: 'completed' }}
          source="status"
        />
        <EnumInput
          filterDefaultValues={{ leg: 'inbound' }}
          source="leg"
        />
        <BooleanInput

          source="handled"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          label="To Phone Number"
          source="to_phone_number_international_formatted"
        />
        <TextField
          label="From Phone Number"
          source="from_phone_number_international_formatted"
        />
        <EnumField
          source="template"
        />
        <LinkField
          reference="users"
          source="handled_by_id"
          allowEmpty
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
