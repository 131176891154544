import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { AutocompleteInput, ReferenceInput, required as requiredFn } from 'react-admin';

const NestedReferenceInput = ({
    parentResource,
    filter = {},
    isRequired,
    optionText = "name",
    validate,
    record = {},
    reference,
    resource,
    source,
    resourcePath,
    isEdit,
    ...props
  }) => {

  const basePath = resourcePath || (isEdit ? `/${parentResource.parentResource.resource}/${parentResource.parentResource.id}` : `/${parentResource.resource}/${parentResource.id}`);

  reference = reference || source && pluralize(source.replace(/_id$/, ''))
  if (source && reference && source.replace(/_id$/, '') !== pluralize.singular(reference)) {
    source = `${source}-as-${reference}`
  }

  return (
    <ReferenceInput
      allowEmpty={!isRequired}
      displayName="ReferenceInput"
      filterToQuery={searchText => ({ q: searchText })}
      record={record}
      reference={reference}
      resource={resource}
      source={source}
      validate={isRequired ? requiredFn() : validate}
      {...props}
      filter={Object.assign({}, filter, {basePath})}
    >
      <AutocompleteInput
        optionText={optionText}
      />
    </ReferenceInput>
  );
};

NestedReferenceInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

NestedReferenceInput.defaultProps = {
  sort: {},
  alwaysOn: true
};

export default NestedReferenceInput;
