// for the subMenus
import InboxIcon from '@material-ui/icons/Chat';
import ScheduleIcon from '@material-ui/icons/DateRange';
import StreamIcon from '@material-ui/icons/LiveTv';
import AdminIcon from '@material-ui/icons/Lock';
import CurriculumIcon from '@material-ui/icons/School';
import LibraryIcon from '@material-ui/icons/VideogameAsset';
import assignments from './assignments';
import attendances from './attendances';
import avatars from './avatars';
import badges from './badges';
import bookings from './bookings';
import booking_instances from './booking_instances';
import call_queues from './call_queues';
import call_queue_memberships from './call_queue_memberships';
import contracts from './contracts';
import coupons from './coupons';
import courses from './courses';
import curriculum_activities from './curriculum_activities';
import emails from './emails';
import guide_availabilities from './guide_availabilities';
import guide_availability_instances from './guide_availability_instances';
import guide_ranks from './guide_ranks';
import guide_ratings from './guide_ratings';
import guide_schedulables from './guide_schedulables';
import journeys from './journeys';
import launched_apps from './launched_apps';
import leads from './leads';
import lead_tasks from './lead_tasks';
import learning_paths from './learning_paths';
import library_categories from './library_categories';
import library_engines from './library_engines';
import library_keyrings from './library_keyrings';
import library_objects from './library_objects';
import library_widgets from './library_widgets';
import locations from './locations';
import log_entries from './log_entries';
import meetings from './meetings';
import missions from './missions';
import occasions from './occasions';
import opportunities from './opportunities';
import organizations from './organizations';
import phones from './phones';
import phone_calls from './phone_calls';
import products from './products';
import product_traits from './product_traits';
import project_categories from './project_categories';
import ranks from './ranks';
import staff_teams from './staff_teams';
import streaming_chat_messages from './streaming_chat_messages';
import streaming_chat_message_approvals from './streaming_chat_message_approvals';
import streaming_registrations from './streaming_registrations';
import streaming_streams from './streaming_streams';
import streaming_viewers from './streaming_viewers';
import text_messages from './text_messages';
import users from './users';

export default {
  resources: {
    organizations,
    
    // inbox subMenu
    text_messages,
    emails,
    lead_tasks,
    phone_calls,
    assignments,


    // schedule subMenu
    bookings,
    booking_instances,
    attendances,
    guide_availabilities,
    guide_availability_instances,

    // admin subMenu
    users,
    leads,
    journeys,
    opportunities,
    staff_teams,
    occasions,
    meetings,
    guide_schedulables,
    guide_ratings,
    phones,
    call_queues,
    call_queue_memberships,
    products,
    product_traits,
    coupons,
    contracts,
    log_entries,
    locations,

    // curriculum subMenu
    ranks,
    guide_ranks,
    learning_paths,
    missions,
    badges,
    curriculum_activities,
    avatars,
    launched_apps,
    project_categories,
    courses,

    // streaming subMenu
    streaming_streams,
    streaming_registrations,
    streaming_viewers,
    streaming_chat_messages,
    streaming_chat_message_approvals,

    // library
    library_widgets,
    library_engines,
    library_categories,
    library_objects,
    library_keyrings
  },
  subMenus: [
    {
      name: 'Inbox',
      Icon: InboxIcon,
      resourceNames: [
        'text_messages',
        'emails',
        'phone_calls',
        'lead_tasks',
        'assignments',
      ],
    },
    {
      name: 'Schedule',
      Icon: ScheduleIcon,
      resourceNames: [
        'bookings',
        'booking_instances',
        'attendances',
        'guide_availabilities',
        'guide_availability_instances',
      ],
    },
    {
      name: 'Admin',
      Icon: AdminIcon,
      resourceNames: [
        'users',
        'leads',
        'journeys',
        'opportunities',
        'staff_teams',
        'occasions',
        'meetings',
        'guide_schedulables',
        'guide_ratings',
        'phones',
        'call_queues',
        'call_queue_memberships',
        'products',
        'product_traits',
        'coupons',
        'contracts',
        'locations',
        'log_entries',

      ],
    },
    {
      name: 'Curriculum',
      Icon: CurriculumIcon,
      resourceNames: [
        'ranks',
        'guide_ranks',
        'learning_paths',
        'missions',
        'badges',
        'curriculum_activities',
        'launched_apps',
        'project_categories',
        'avatars',
        'courses',
      ],
    },
    {
      name: 'Streams',
      Icon: StreamIcon,
      resourceNames: [
        'streaming_streams',
        'streaming_registrations',
        'streaming_viewers',
        'streaming_chat_messages',
        'streaming_chat_message_approvals',
      ],
    },
    {
      name: 'Library',
      Icon: LibraryIcon,
      resourceNames: [
        'library_widgets',
        'library_engines',
        'library_categories',
        'library_objects',
        'library_keyrings',
      ],
    },
  ],
};
