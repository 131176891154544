import Icon from '@material-ui/icons/AccountBalance';
import ChatIcon from '@material-ui/icons/Chat';
import BillingIcon from '@material-ui/icons/CreditCard';
import BookingsIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import OpportunitiesIcon from '@material-ui/icons/Timeline';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../buttons';
import { CommunicationLog } from '../../components';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout, Tab, TabbedShowLayout } from '../../controllers';
import { AddressField, AgeField, ArrayOfStringsField, BookingStatusField, BooleanField, DateField, DateTimeField, EnumField, FileField, ImageField, LinkField, MeetingLinkField, MeetingStartTimeField, NumberField, PhoneNumberField, ReferenceField, ReferenceManyField, RichTextField, SingleFieldList, StartAndEndTimeField, SubscriptionStatusField, TextField, TimeZoneField, TrialPeriodStartEndField } from '../../fields';
import { BooleanInput, EnumInput, ImageInput, InputGroup, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import assignments from './assignments';
import bookings from './bookings';
import booking_instances from './booking_instances';
import contract_executions from './contract_executions';
import credits from './credits';
import emails from './emails';
import health_records from './health_records';
import invoices from './invoices';
import leads from './leads';
import lead_tasks from './lead_tasks';
import learning_styles from './learning_styles';
import memberships from './memberships';
import notes from './notes';
import opportunities from './opportunities';
import participations from './participations';
import payment_methods from './payment_methods';
import phone_calls from './phone_calls';
import phone_conversations from './phone_conversations';
import phone_numbers from './phone_numbers';
import streaming_registrations from './streaming_registrations';
import streaming_viewers from './streaming_viewers';
import subscriptions from './subscriptions';
import text_messages from './text_messages';
import users from './users';
import user_addresses from './user_addresses';


const OrganizationCommunicationLog = ({ record }) => (
  <CommunicationLog
    organization_id={record.id}
  />
);

const ResolvedRowStyle = record => ({
  opacity: record.resovled_at ? '0.3' : '1',
});


export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Summary"
        >
          <TextInput
            source="name"
            required
          />
          <EnumInput
            source="classification"
          />
          <EnumInput
            source="status"
          />

          <ImageInput
            label="Logo"
            source="logo"
          />
        </InputGroup>
        
        <InputGroup
          header="Referral Information"
          subHeader="If this user was referred by another Codeverse family, indicate by searching for the referring user or enter their referral code. Once set, this cannot be changed."
        >
          <ReferenceInput
            filter={{has_email: true }}
            label="Referrer Name"
            optionText={user => `${user.name} (${user.email})`}
            reference="users"
            sort="email"
            source="referred_by_id-as-users"
          />
          <TextInput
            label="Referral Code"
            source="referral_code"
          />
        </InputGroup>  
        
        <InputGroup
          header="Automatic account creation"
          subHeader="Allow kids to create their own accounts at this organization. This is most commonly used by schools."
        >
          <BooleanInput
            source="automatic_account_creation"
          />
        </InputGroup>

        <InputGroup
          header="Founding Family"
          subHeader="Is this organization part of the Founding Families program?"
        >
          <BooleanInput
            source="founding_family"
          />
        </InputGroup>

        <InputGroup
          header="Administration"
          subHeader="If this organization is a duplicate of another already in the system, select the original organization here. This will automatically delete the current organization."
        >
          <ReferenceInput
            label="Duplicate of"
            optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
            reference="organizations"
            source="duplicate_of_id-as-organizations"
          />

        </InputGroup> 
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="Summary"
        >
          <TextInput
            source="name"
            required
          />
          <EnumInput
            source="classification"
          />
          <EnumInput
            source="status"
          />
          <ImageInput
            label="Logo"
            source="logo"
          />
        </InputGroup>

        <InputGroup
          header="Referral Information"
          subHeader="If this user was referred by another Codeverse family, indicate by searching for the referring user or enter their referral code. Once set, this cannot be changed."
        >
          <ReferenceInput
            filter={{ has_email: true }}
            label="Referrer Name"
            optionText={user => `${user.name} (${user.email})`}
            reference="users"
            sort="email"
            source="referred_by_id-as-users"
          />
          <TextInput
            label="Referral Code"
            source="referral_code"
          />
        </InputGroup>

        <InputGroup
          header="Automatic account creation"
          subHeader="Allow kids to create their own accounts at this organization. This is most commonly used by schools."
        >
          <BooleanInput
            source="automatic_account_creation"
          />
        </InputGroup>

        <InputGroup
          header="Founding Family"
          subHeader="Is this organization part of the Founding Families program?"
        >
          <BooleanInput
            source="founding_family"
          />
        </InputGroup>

      </CreateButton>
    ),
  },
  routes: {
    list:  props => (
      <ResourceList
        {...props}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <TextField
          sortable={false}
          source="name"
        />
        <EnumField
          sortable={false}
          source="classification"
        />
        <EnumField
          sortable={false}
          source="status"
        />
        <DateTimeField
          source="created_at"
          sortable
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>
          <Tab
            label="General"
          >
            <EnumField
              source="classification"
            />
            <EnumField
              source="status"
            />
            <EnumField
              source="membership_type"
            />
            <TextField
              label="NRDY Account ID"
              source="nrdy_account_id"
            />
            <TrialPeriodStartEndField />
          
            <LinkField
              label="Referrer"
              reference="users"
              source="referred_by_id"
            />
            
            <NestedResourceList
              perPage={10}
              reference="users"
            >
              <EnumField
                source="scope"
              />
              <TextField
                source="name"
              />
              <TextField
                source="email"
              />

              <ReferenceManyField
                label="Phone Numbers"
                reference="phone_numbers"
                target="user_id"
              >
                <SingleFieldList>
                  <PhoneNumberField
                    source="phone_number"
                  />
                </SingleFieldList>
              </ReferenceManyField>
              <AgeField 
                showDateOfBirth 
              />
              <LinkField               
                source="rank_id"
              />
              <TextField
                label="Referral Code"
                source="referral_code"
              />
              <BooleanField
                source="verified"
              />
              <BooleanField
                source="ambassador"
              />
              <TimeZoneField
                source="time_zone"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="booking_instances"
            >
              <LinkField
                label="Child"
                parentReference="organizations"
                parentSource="organization_id"
                reference="users"
                source="user_id"
              />
              <LinkField
                source="occasion_id"
              />
              <DateField
                label="Date"
                source="start_date"
              />
              <TextField
                label="Time"
                source="start_time"
              />
              <TimeZoneField
                source="time_zone"
              />
              <TextField
                source="guide"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="learning_styles"
            >
              <LinkField
                parentReference="organizations"
                parentSource="organization_id"
                source="user_id"
              />
              <TextField
                label="Prior Coding Experience"
                source="experience"
              />
              <TextField
                label="Preferred Teaching Style"
                source="styles"
              />
              <TextField
                label="Interests and Hobbies"
                source="interests"
              />
            </NestedResourceList>
            
            <NestedResourceList
              perPage={10}
              reference="notes"
            >
              <EnumField
                source="topic"
              />
              <TextField
                source="heading"
              />
              <RichTextField
                source="detail"
              />
              <TextField
                label="Author"
                source="_meta_author_name"
              />
              <DateTimeField
                source="created_at"
              />
              <BooleanField
                source="pinned"
              />
            </NestedResourceList>
           
          </Tab>
          
          <Tab
            label="Opportunities"
          >
         
            <NestedResourceList
              perPage={10}
              reference="opportunities"
            >
              <EnumField
                source="journey"
              />
              <EnumField
                source="stage"
              />
              <DateTimeField
                source="created_at"
              />
              <DateTimeField
                source="updated_at"
              />
              <LinkField
                label="Last Updated By"
                reference="users"
                source="stage_changer_id"
              />
              <BooleanField
                source="completed"
              />
              <BooleanField
                source="interrupted"
              />
            </NestedResourceList>
          
            <NestedResourceList
              RowStyle={ResolvedRowStyle}
              perPage={10}
              reference="assignments"
            >
              <EnumField
                source="priority"
              />
              <EnumField
                source="topic"
              />
              <DateTimeField
                label="Due"
                source="due_at"
              />
              <LinkField
                source="staff_team_id"
              />
              <LinkField
                label="Assigned to"
                reference="users"
                source="owner_id"
              />
              <TextField
                source="task"
              />
              <BooleanField
                source="resolved"
              />
              <BooleanField
                source="canceled"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="lead_tasks"
            >
              <EnumField
                source="priority"
              />
              <DateField
                label="Due date"
                source="due_at"
              />
              <LinkField
                source="staff_team_id"
              />
              <LinkField
                label="Assigned to"
                reference="users"
                source="assignee_id"
              />
            
              <RichTextField
                source="instruction"
              />
              <BooleanField
                source="completed"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="leads"
            >
              <DateTimeField
                source="created_at"
              />
              <TextField
                source="name"
              />
              <TextField
                source="phone"
              />
              <TextField
                source="email"
              />
              <EnumField
                source="stage"
              />
              <ArrayOfStringsField
                source="source_codes"
              />              

              <EnumField
                source="lost_code"
              />
              
            </NestedResourceList>
          </Tab>
          
          <Tab
            label="Bookings"
          >
            <NestedResourceList
              filter={{  scope: 'child' }}
              reference="users"
            >
              <EnumField
                source="scope"
              />
              <TextField
                source="email"
              />
              <TextField
                source="name"
              />
              <AgeField />
              <LinkField
                source="rank_id"
              />
            
            </NestedResourceList>
            
            <NestedResourceList
              perPage={10}
              reference="bookings"
            >
              <LinkField
                source="user_id"
              />
              <LinkField
                source="location_id"
              />
              <LinkField
                source="occasion_id"
              />
              <DateField
                source="first_visit_at"
              />
              <DateField
                source="last_visit_at"
              />
              <EnumField
                source="weekday_name"
              />
              <StartAndEndTimeField />
              <BookingStatusField />
            </NestedResourceList>
         
            <NestedResourceList
              perPage={10}
              reference="participations"
            >
              <LinkField
                source="user_id"
              />
              <LinkField
                source="occasion_id"
              />
              <MeetingStartTimeField />

              <EnumField
                label="Participation Status"
                source="status"
              />
              <MeetingLinkField
                source="meeting_id"
              />
            </NestedResourceList>
        
            <NestedResourceList
              perPage={10}
              reference="streaming_registrations"
            >
              <LinkField
                displaySource="title"
                reference="streaming_streams"
                source="stream_id"
              />
              <LinkField
                reference="users"
                source="registrant_id"
              />
            </NestedResourceList>
          
            <NestedResourceList
              perPage={10}
              reference="streaming_viewers"
            >
              <LinkField
                displaySource="title"
                reference="streaming_streams"
                source="stream_id"
              />
              <LinkField
                reference="users"
                source="user_id"
              />
              <EnumField
                source="status"
              />
              <NumberField
                source="seconds_watched"
              />
              <DateTimeField
                source="last_ping_at"
              />
              <DateTimeField
                source="last_left_at"
              />
            </NestedResourceList>
          </Tab>
          
          <Tab
            label="Communication"
          >
            <NestedResourceList
              filter={{scope: 'account'}}
              reference="users"
            >
              <TextField
                source="name"
              />
              <TextField
                source="email"
              />
              <TimeZoneField
                source="time_zone"
              />
              <BooleanField
                label="Don't Text"
                source="do_not_text_message"
              />
              <BooleanField
                label="Don't Call"
                source="do_not_phone_call"
              />
              <BooleanField
                label="Don't Email"
                source="do_not_email"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="phone_numbers"
            >
              <LinkField
                source="user_id"
              />
              <LinkField
                displaySource="email"
                source="user_id"
              />
              <TextField
                label="Phone Number"
                source="phone_number_international_formatted"
              />
            </NestedResourceList>

            <OrganizationCommunicationLog />
          </Tab>
          <Tab
            label="Billing"
          >
            <TextField
              source="unused_credits"
            />

            <NestedResourceList
              
              perPage={10}
              reference="credits"
            >
              <EnumField
                source="source"
              />
              <DateField
                source="created_at"
              />
              <BooleanField
                source="unused"
              />
              <DateField
                source="used_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={5}
              reference="payment_methods"
            >
              <TextField
                source="name"
              />
              <TextField
                source="default"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="subscriptions"
            >
              <TextField
                label="Subscription"
                source="name"
              />
              <SubscriptionStatusField />
              <DateField          
                source="start_at"
              />
              <DateField          
                source="cancel_at"
              />
              <DateField
                label="Next Bill Date"
                source="braintree_next_billing_date"
              />
              <NumberField
                label="Next Charge"
                source="braintree_next_billing_period_amount"
              />
              <EnumField
                source="cadence"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="invoices"
            >
              <TextField
                source="description"
              />
              <DateField
                source="created_at"
              />
              <DateField
                source="paid_at"
              />
              <DateField
                source="due_at"
              />
              <TextField
                source="amount"
              />
              <TextField
                source="balance"
              />
              <EnumField
                source="status"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Contracts"
          >
            <NestedResourceList
              reference="contract_executions"
            >
              <TextField
                source="contract"
              />
              <DateTimeField
                source="created_at"
              />

              <ReferenceField
                linkType={false}
                reference="users"
                source="user_id"
              >
                <TextField
                  source="name"
                />
              </ReferenceField>
              <FileField
                source="scan"
                target="_blank"
                title="View"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Settings"
          >
            <TextField
              source="id"
            />
            <DateTimeField
              source="created_at"
              addLabel
            />

            <BooleanField
              source="automatic_account_creation"
              addLabel
            />
              
            <BooleanField
              source="founding_family"
              addLabel
            />

            <ImageField
              source="logo"
            />
            
            <NestedResourceList
              reference="organization_addresses"
            >
              <TextField
                source="name"
              />
              <AddressField />
            </NestedResourceList>

            <ReferenceManyField
              label="Address"
              reference="organization_addresses"
              target="organization_id"
            >
              <SingleFieldList>
                <AddressField />
              </SingleFieldList>
            </ReferenceManyField>

          </Tab>

        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <EnumInput
          source="classification"
        />
        <EnumInput
          source="status"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        
        <TrialPeriodStartEndField />
        <EnumField
          source="membership_type"
        />
        <TextField
          label="NRDY Account ID"
          source="nrdy_account_id"
        />
        
        <NestedResourceList
          perPage={10}
          reference="opportunities"
        >
          <EnumField
            source="journey"
          />
          <EnumField
            source="stage"
          />
          <DateTimeField
            source="created_at"
          />
          <DateTimeField
            source="updated_at"
          />
          <LinkField
            label="Last Updated By"
            reference="users"
            source="stage_changer_id"
          />
          <BooleanField
            source="completed"
          />
          <BooleanField
            source="interrupted"
          />
        </NestedResourceList>
          
      </SimpleShowLayout>
    ),
  },
  resources: {
    bookings,
    booking_instances,
    participations,
    streaming_registrations,
    streaming_viewers,
    notes,
    leads,
    opportunities,
    lead_tasks,
    assignments,
    phone_numbers,
    contract_executions,
    // Billing subMenu
    credits,
    subscriptions,
    payment_methods,
    invoices,
    // People subMenu
    users,
    memberships,
    health_records,
    learning_styles,
    user_addresses,
    // Communication subMenu
    emails,
    text_messages,
    phone_calls,
    phone_conversations,
  },
  subMenus: [
    {
      name: 'Opportunities',
      Icon: OpportunitiesIcon,
      resourceNames: [
        'opportunities',
        'assignments',
        'lead_tasks',
        'leads',
      ],
    },
    {
      name: 'Bookings',
      Icon: BookingsIcon,
      resourceNames: [
        'bookings',
        'booking_instances',
        'participations',
        'streaming_registrations',
        'streaming_viewers'
      ],
    },
    {
      name: 'Billing',
      Icon: BillingIcon,
      resourceNames: [
        'subscriptions',
        'invoices',
        'payment_methods',
        'credits',
      ],
    },
    {
      name: 'People',
      Icon: PeopleIcon,
      resourceNames: [
        'users',
        'memberships',
        'health_records',
        'learning_styles',
        'user_addresses',
        'phone_numbers',
        'contract_executions'
      ],
    },
    {
      name: 'Communications',
      Icon: ChatIcon,
      resourceNames: [
        'emails',
        'text_messages',
        'phone_calls',
        'phone_conversations',
      ],
    },
  ],
};
