import Icon from '@material-ui/icons/LocalHospital';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../controllers';
import { DateField, LinkField, TextField } from '../../../fields';
import { BooleanInput, LongTextInput, NestedReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <LongTextInput
          source="allergies"
        />
        <LongTextInput
          source="learning"
        />
        <LongTextInput
          source="mobility"
        />
        <LongTextInput
          source="eyesight"
        />
        <LongTextInput
          source="hearing"
        />
        <LongTextInput
          source="medications"
        />
        <LongTextInput
          source="seizures"
        />
        <LongTextInput
          source="additional_information"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          source="user_id"
        />
        <LongTextInput
          source="allergies"
        />
        <LongTextInput
          source="learning"
        />
        <LongTextInput
          source="mobility"
        />
        <LongTextInput
          source="eyesight"
        />
        <LongTextInput
          source="hearing"
        />
        <LongTextInput
          source="medications"
        />
        <LongTextInput
          source="seizures"
        />
        <LongTextInput
          source="additional_information"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <LinkField
          source="user_id"
          allowEmpty
        />
        <DateField
          sortable={false}
          source="updated_at"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          source="user_id"
        />
        <TextField
          source="allergies"
        />
        <TextField
          source="learning"
        />
        <TextField
          source="mobility"
        />
        <TextField
          source="eyesight"
        />
        <TextField
          source="hearing"
        />
        <TextField
          source="medications"
        />
        <TextField
          source="seizures"
        />
        <TextField
          source="additional_information"
        />
      </ResourceShow>
    ),

  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="allergies"
        />
        <TextField
          source="learning"
        />
        <TextField
          source="mobility"
        />
        <TextField
          source="eyesight"
        />
        <TextField
          source="hearing"
        />
        <TextField
          source="medications"
        />
        <TextField
          source="seizures"
        />
        <TextField
          source="additional_information"
        />
      </SimpleShowLayout>
    ),

  },
  resources: {

  },
};
