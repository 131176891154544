import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CompletedIcon from '@material-ui/icons/CheckBox';
import NotCompletedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { showNotification, UPDATE } from 'react-admin';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import dataProvider from '../dataProvider';

const style = {
  root: {
  },
  divider: {
    marginTop: '12px',
  }
};

class IncidentTaskCard extends Component {
  state = {
    expanded: false,
    completed: null,
  };

  handleCompletedClick = (event) => {
    const { record, showNotification } = this.props;
    const currentlyCompleted = this.state.completed === null ? !!record.completed_at : this.state.completed;
    event.stopPropagation();
    dataProvider(UPDATE, 'incident_tasks', { id: record.id, data: { completed: !currentlyCompleted } })
      .then(({ data }) => {
        if (data.completed_at) {
          this.setState({ completed: true });
          showNotification('Task marked as completed');
        } else {
          this.setState({ completed: false });
          showNotification('Task is no longer marked as completed');
        }
      })
      .catch(() => {
        showNotification('Could not save update', 'warning');
      });
  }
  render() {
    const { record, inbound, classes } = this.props;
    const completed = this.state.completed === null ? !!record.completed_at : this.state.completed;

    if (!record) {
      return null
    }
    return (
      <Card
        className={classes.root}
      >
        <CardHeader
          action={(
            <IconButton
              onClick={this.handleCompletedClick}
            >
              {completed ? (
                <CompletedIcon
                  color="primary"
                />
              ) : <NotCompletedIcon />}
            </IconButton>
          )}
          avatar={<Avatar>
            <CompletedIcon />
          </Avatar>}
          subheader={`Created ${moment(record.created_at).fromNow()}`}
          title={`Priority: ${record.priority}`}
        />
        <CardContent>
          <Typography
            variant="subheading"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              {record.due_at &&
                <p>
                  Due:
                  {' '}
                  {moment(record.due_at).format('llll')}
                  {' '}
                  (
                  {moment(record.due_at).fromNow()}
                  )
                </p>}
              {record.heading}
            </Linkify>
          </Typography>

          <Divider
            className={classes.divider}
          />
          <Typography
            component="p"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: record.instruction }}
              />
            </Linkify>
          </Typography>

          {record.completed && (
            <>
              <Typography
                component="p"
                gutterBottom
              >
                <Linkify
                  properties={{ target: '_blank' }}
                >
                  Completed
                  {' '}
                  {moment(record.completed_at).format('llll')}
                  <span
                    dangerouslySetInnerHTML={{ __html: record.conclusion }}
                  />
                </Linkify>
              </Typography>
            </>
          )}

          <Typography
            component="p"
            gutterBottom
          >
            Author:
            {' '}
            {record._meta_author_name}
          </Typography>

          <Typography
            component="p"
            gutterBottom
          >
            Assigned by:
            {' '}
            {record._meta_assignor_name}
          </Typography>

          <Typography
            component="p"
            gutterBottom
          >
            Assigned to:
            {' '}
            {record._meta_assignee_name}
          </Typography>

        </CardContent>
      </Card>
    );
  }
}

IncidentTaskCard.propTypes = {
  record: PropTypes.object, 
  inbound: PropTypes.bool,
  classes: PropTypes.object,
  showNotification: PropTypes.bool
}

const mapDispatchToProps = {
  showNotification
};

export default withStyles(style)(connect(null, mapDispatchToProps)(
  IncidentTaskCard,
));

