import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const MeetingLinkField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    <a
      href={`http://houston.codeverse.com/#/meetings/${record['meeting_id']}/show`}
      rel="noopener noreferrer"
      target="_blank"
      onClick={e => e.stopPropagation()}
    >
      View Meeting
    </a>
  </Typography>
);

MeetingLinkField.propTypes = {
  record: PropTypes.object,
};

MeetingLinkField.defaultProps = {
  addLabel: true,
  label: 'Meeting'
};

export default MeetingLinkField;
