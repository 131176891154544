import Icon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { ReferenceInput } from '../../../inputs';

export default {
    Icon,
    dialogs: {
        create: props => (
          <CreateButton
            {...props}
            fixedValue={{
                    user_id: props.parentResource.id
            }}
          >
            <ReferenceInput
              source="occasion_id"
              required
            />
          </CreateButton>
        ),
    },
    routes: {
    },
    components: {
    },
    resources: {
    },
};