import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const TextMessageBodyField = ({ isShowingNumbers, record = {} }) => (
  <Typography
    variant="body1"
  >
    { isShowingNumbers &&
      <>
        {record.from_phone_number_formatted}
        {' '}
        to
        {record.to_phone_number_formatted}
        <br />
      </>}
    {record.body}
  </Typography>
);

TextMessageBodyField.propTypes = {
  record: PropTypes.object,
  isShowingNumbers: PropTypes.bool,
};

TextMessageBodyField.defaultProps = {
  addLabel: true,
  isShowingNumbers: false,
  label: 'Message',
};

export default TextMessageBodyField;

