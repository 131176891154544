import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { addField } from 'ra-core';
import React, { Component } from 'react';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import * as Showdown from 'showdown';

const styles = {};

class MarkdownInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    source: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      tab: 'write',
    };
  }

  componentDidMount() {
    const {
      input: { value }
    } = this.props;

    this.setState({ value });

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  handleValueChange(value) {
    this.setState({ value });
    this.props.input.onChange(value);
  }

  handleTabChange(tab) {
    this.setState({ tab });
  }

  render() {
    return (
      <FormControl 
        className='ra-input-mde' 
        fullWidth={true}
      >
        <ReactMde
          generateMarkdownPreview={markdown => {
            if (this.converter) {
              return Promise.resolve(this.converter.makeHtml(markdown))
            }
            return Promise.resolve();
          }}
          selectedTab={this.state.tab}
          value={this.state.value}
          onChange={value => this.handleValueChange(value)}
          onTabChange={tab => this.handleTabChange(tab)}
        />
      </FormControl>
    );
  }
}

const MarkDownInputWithField = addField(withStyles(styles)(MarkdownInput));

MarkDownInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true,
};
export default MarkDownInputWithField;