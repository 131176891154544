import Icon from '@material-ui/icons/Dns';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { LeadOpportunityTaskCard } from '../../../components';
import { EditDialog, Filter, FormDataConsumer, ResourceList, SimpleShowLayout } from '../../../controllers';
import { BooleanField, DateField, EnumField, LinkField, RichTextField } from '../../../fields';
import { BooleanInput, DateInput, EnumInput, InputGroup, LongTextInput, RichTextInput, SearchInput, StaffTeamSelectInput, TeamMemberSelectInput } from '../../../inputs';


export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'completed'
    }
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="About This Task"
        >
          <EnumInput
            source="priority"
          />
          <DateInput
            label="Due date"
            source="due_at"
            required
          />
        </InputGroup>

        <InputGroup
          header="Change Task Details"
          fullWidth
        >
          <RichTextInput
            source="instruction"
          />
        </InputGroup>

        <InputGroup
          header="Reassign This Task"
        >
          <StaffTeamSelectInput
            source="staff_team_id"
            required
          />
          <TeamMemberSelectInput
            source="assignee_id"
          />
        </InputGroup>

        <FormDataConsumer>
          {({ formData }) => (
            <InputGroup
              header="Has this task been completed?"
              fullWidth
            >
              <BooleanInput
                source="completed"
              />
              {formData.completed &&
                <LongTextInput
                  label="Any notes related to the completion of this task"
                  source="conclusion"
                />}
            </InputGroup>
          )}
        </FormDataConsumer>

      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="About This Task"
        >
          <StaffTeamSelectInput
            source="staff_team_id"
            required
          />
          <TeamMemberSelectInput
            source="assignee_id"
          />
          <EnumInput
            source="priority"
          />
          <DateInput
            label="Due date"
            source="due_at"
            required
          />
        </InputGroup>

        <InputGroup
          header="Task Details"
          subHeader="Please provide very clear instructions"
          fullWidth
        >
          <RichTextInput
            source="instruction"
            required
          />
        </InputGroup>

      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="priority"
        />
        <DateField
          label="Due date"
          source="due_at"
        />
        <LinkField
          source="staff_team_id"
        />
        <LinkField
          label="Assigned to"
          reference="users"
          source="assignee_id"
        />
        <LinkField
          label="Organization"
          reference="organizations"
          source="_meta_organization_id"
        />
        <RichTextField
          source="instruction"
        />
        <BooleanField
          source="completed"
        />
        <BooleanField
          source="deleted"
        />

      </ResourceList>
    ),
  },
  components: {
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <LeadOpportunityTaskCard />
      </SimpleShowLayout>
    ),
     Filter: props => (
       <Filter
         {...props}
       >
         <SearchInput />

         <DateInput
           source="due_before"
         />
         <DateInput
           source="due_after"
         />
         <BooleanInput
           source="completed"
         />
         <BooleanInput
           source="deleted"
         />
       </Filter>
        ),
  },
  resources: {
  },
};
