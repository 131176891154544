import Icon from '@material-ui/icons/Directions';
import React from 'react';
import { ResourceList, ResourceShow } from '../../controllers';
import { TextField } from '../../fields';






export default {
  Icon,
  dialogs: {
 
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="diagram"
        />
      </ResourceList>
    ),

    show: (props) => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        
        <TextField
          source="diagram"
        />

      </ResourceShow>
    )  
    },
  components: {

  },
  resources: {
  },
};
