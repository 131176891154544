import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { required as requiredFn } from 'react-admin';
import { titleCase } from 'title-case';
import { ReferenceInput } from '.';


export const StreamSelectInput = ({ label, source, isRequired, validate, ...props }) => (
  <ReferenceInput
    label={"Stream" || label}
    optionText={stream => `${titleCase(stream.channel)} - ${moment(stream.start_at).format('lll')} - ${stream.title}`}
    reference="streaming_streams"
    sort="start_at"
    source={"streaming_stream_id" || source}
    validate={isRequired ? requiredFn() : validate}
    {...props}
  />
)

StreamSelectInput.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    validate: PropTypes.func,
};

StreamSelectInput.defaultProps = {
    sort: false,
    sortable: false,
    alwaysOn: true,
};

export default StreamSelectInput;
