import PropTypes from 'prop-types';
import React from 'react';
import { DateTimeInput as ReactAdminDateTimeInput, required as requiredFn } from 'react-admin';

export const DateTimeInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminDateTimeInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

DateTimeInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

DateTimeInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default DateTimeInput;

