import axios from 'axios';
import { HttpError } from 'react-admin';

// Handle HTTP errors.
export default () => {
  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      const current_user_id = localStorage.getItem('user_id');
      const access_token = localStorage.getItem('access_token');

      const newConfig = config || {};
      newConfig.params = newConfig.params || {};

      if (access_token) {
        newConfig.params.access_token = access_token;
      }

      if (current_user_id) {
        newConfig.params.current_user_id = current_user_id;
      }

      // if the paths starts with an organization, then we use it to set the current_organization_id
      const matches = window.location.href.match(/#\/organizations\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/);
      if (matches) {
        newConfig.params.current_organization_id = matches[1];
      }

      return newConfig;
    },
    err => Promise.reject(err),
  );

  // Response interceptor
  axios.interceptors.response.use(
    response => response,
    (error) => {
      const { status, data } = error.response;

      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError(data, status),
        );
      }

      return Promise.reject(error);
    },
  );
};
