import PropTypes from 'prop-types';
import React from 'react';
import { required as requiredFn, SelectInput } from 'react-admin';

const ChartResolutionInput = ({ isRequired, validate, ...props }) => (
  <SelectInput
    {...props}
    choices={[
      { id: 'day', name: 'Day'},
      { id: 'month', name: 'Month'},
      { id: 'week', name: 'Week'},
      { id: 'year', name: 'Year'},
    ]}
    validate={isRequired ? requiredFn() : validate}
  />
);

ChartResolutionInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  defaultValue: PropTypes.string,
  isRequired: PropTypes.bool,
};

ChartResolutionInput.defaultProps = {
  label: 'Resolution',
  source: 'resolution',
  defaultValue: 'day',
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default ChartResolutionInput;
