import PropTypes from 'prop-types';
import React from 'react';
import { required as requiredFn } from 'react-admin';
import { ReferenceInput } from '.';

export const TeamMemberSelectInput = ({ label, source, isRequired, validate, ...props}) => (
  <ReferenceInput
    filter={{ scope: 'administrator' }}
    label={label || "Owner"}
    optionText={users => `${users.name} (${users.email})`}
    reference="users"
    sort="email"
    source={source}
    validate={isRequired ? requiredFn() : validate}
  />
)

TeamMemberSelectInput.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

TeamMemberSelectInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true,
};

export default TeamMemberSelectInput;
