import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HandledIcon from '@material-ui/icons/CheckBox';
import NotHandledIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneIcon from '@material-ui/icons/Phone';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { crudUpdate } from 'react-admin';
import { connect } from 'react-redux';
import titleCase from 'title-case';
import CreatePhoneCallNoteButton from './CreatePhoneCallNoteButton';

const style = {
  inbound: {
    width: '80%',
    marginRight: '20%',
    backgroundColor: '#ece8de',
  },
  outbound: {
    width: '80%',
    marginLeft: '20%',
    backgroundColor: '#fff7e6',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
};

class PhoneCallCard extends Component {
  state = {
    expanded: false,
    handled: null,
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleHandledClick = (values) => {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    crudUpdate(
      resource,
      record.id,
      Object.assign({}, record, { handled: !record.handled }),
      record,
      `/${resource}`,
      false,
    );
  };

  render() {
    const { record, inbound, classes, organization_id} = this.props;

    if (!record) {
      return null
    }

    const cardClass = inbound ? classes.inbound : classes.outbound;
    const handled = this.state.handled === null ? !!record.handled_at : this.state.handled;
    const minutes = Math.floor(record.duration / 60);
    const seconds = record.duration - (minutes * 60);

    return (
      <Card
        className={cardClass}
      >
        <CardHeader
          action={<>
            {organization_id && <CreatePhoneCallNoteButton
              organization_id={organization_id}
              record={record}
                                /> }
            {inbound && 
              <>
                <IconButton
                  onClick={this.handleHandledClick}
                >
                  {handled ? ( <HandledIcon
                    color="primary"
                               />) : ( 
                                 <NotHandledIcon /> 
                  )}
                </IconButton>
              </>}
          </>}
          avatar={<Avatar>
            <PhoneIcon />
          </Avatar>}
          subheader={moment(record.created_at).format('llll')}
          title={inbound ? `Phone Call from ${record.from_phone_number_formatted}` : `${titleCase(record.template)}  Phone Call to ${record.to_phone_number_formatted}`}
        />
        <CardContent>
          {record.conversations.length > 0 && (
            <Typography
              variant="subheading"
              gutterBottom
            >
              Conversations:
            </Typography>
          )}
          {record.conversations.map(conversation => (
            <Typography
              key={conversation.recording_uri}
              variant="subheading"
              gutterBottom
            >
              {/* <a
                href={conversation.recording_uri}
                rel="noopener noreferrer"
                target="_blank"
              >
                Listen to call
              </a> */}
            </Typography>
          ))}
          {record.voicemail_recording_uri && (
            <>
              <Typography
                key={record.voicemail_recording_uri}
                variant="subheading"
                gutterBottom
              >
                Voicemail
              </Typography>
              <Typography
                variant="body1"
              >
                {record.voicemail_transcription}
              </Typography>
              <Typography
                variant="body1"
              >
                <a
                  href={record.voicemail_recording_uri}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Listen to
                  {' '}
                  {record.voicemail_recording_duration}
                  {' '}
                  second voicemail
                </a>
              </Typography>
            </>
          )}
          <Typography
            variant="subheading"
            gutterBottom
          >
            Total call duration was
            {' '}
            {minutes}
            {' '}
            minutes and
            {' '}
            {seconds}
            {' '}
            seconds
          </Typography>
        </CardContent>
        <CardActions
          className={classes.actions}
          disableActionSpacing
        >
          <IconButton
            aria-expanded={this.state.expanded}
            aria-label={this.state.expanded ? 'Hide more' : 'Show more'}
            className={this.state.expanded ? classes.expandOpen : classes.expand}
            onClick={this.handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            <Typography
              component="p"
            >
              {' '}
              Comment:
              { record.comment }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              To Phone Number Formatted:
              { record.to_phone_number_formatted }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              From Phone Number Formatted:
              { record.from_phone_number_formatted }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Caller Name:
              { record.caller_name }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Duration:
              { record.duration }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Leg:
              { record.leg }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Voicemail Recording Uri:
              { record.voicemail_recording_uri }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Status:
              { record.status }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Answered By:
              { record.answered_by }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Termination Status:
              { record.termination_status }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Handled:
              { record.handled }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Queued At:
              {record.queued_at}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Answered At:
              {record.answered_at}
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Handled At:
              { record.handled_at }
              {' '}
            </Typography>
            <Typography
              component="p"
            >
              {' '}
              Created At:
              { record.created_at }
              {' '}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}
PhoneCallCard.propTypes = {
  resource: PropTypes.object,
  crudUpdate: PropTypes.func,
  record: PropTypes.object, 
  inbound: PropTypes.bool, 
  classes: PropTypes.object, 
  organization_id: PropTypes.string,
}

function mapStateToProps(state, props) {
  return {
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
  };
}

const mapDispatchToProps = {
  crudUpdate,
}

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(PhoneCallCard));
