import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import { Button } from 'react-admin';

const style = {
  root: {
    zIndex: '1',
  },
};

class EditButton extends Component {
  state = {
    showDialog: false,
  };

  handleClick = (event) => {
    this.setState({ showDialog: true });
    event.stopPropagation()
  };

  closeEditDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const { showDialog } = this.state;
    const {
      icon,
      label,
      record,
      resource,
      dialogs,
      parentResource,
    } = this.props;

    return (
      <>
        <Button
          label={label || 'ra.action.edit'}
          onClick={this.handleClick}
        >
          { icon || <EditIcon /> }
        </Button>
        {showDialog && record &&
          <dialogs.edit
            close={this.closeEditDialog}
            id={record.id}
            parentResource={parentResource}
            resource={resource}
          />}
      </>
    );
  }
}

export default withStyles(style)(EditButton);
