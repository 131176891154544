import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const LeadStageField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record && `${record.name} (${record.position + 1})`}
  </Typography>
);

LeadStageField.propTypes = {
  record: PropTypes.object,
};

LeadStageField.defaultProps = {
  addLabel: true,
  label: 'Stage',
};

export default LeadStageField;

