import Icon from '@material-ui/icons/Assignment';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { EditDialog, NestedResourceList, ResourceList, ResourceShow } from '../../../controllers';
import { DateField, FileField, ReferenceField, RichTextField, TextField } from '../../../fields';
import { RichTextInput } from '../../../inputs';
import contract_executions from './contract_executions';

export default {
    Icon,
    dialogs: {
      edit: props => (
        <EditDialog
          {...props}
        >
          <RichTextInput
            source="contents"
          />
        </EditDialog>
      ),
      create: props => (
        <CreateButton
          fixedValue={{ contract_id: props.parentResource.id }}
          {...props}
        >
          <RichTextInput
            source="contents"
          />
        </CreateButton>
      ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <DateField
              source="created_at"
            />
            <RichTextField
              source="contents"
            />
          </ResourceList>
        ),
        show: props => (
          <ResourceShow
            {...props}
          >
            <DateField
              source="created_at"
            />
            <RichTextField
              source="contents"
            />
            <NestedResourceList
              reference="contract_executions"
            >
              <DateField
                source="created_at"
              />
              <ReferenceField
                linkType={false}
                reference="users"
                source="user_id"
              >
                <TextField
                  source="name"
                />
              </ReferenceField>
          
              <FileField
                source="scan"
                target="_blank"
                title="View"
              />
            </NestedResourceList>
          </ResourceShow>
        ),    
    },
    components: {
        Expand: props => (
          <SimpleShowLayout
            {...props}
          >
            <NestedResourceList
              reference="contract_executions"
            >
              <DateField
                source="created_at"
              />
              <RichTextField
                source="contents"
              />
            </NestedResourceList>
          </SimpleShowLayout>
        ),
    },
    resources: {
      contract_executions,
    },
};
