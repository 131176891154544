import Icon from '@material-ui/icons/ContactMail';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog } from '../../../../controllers';
import { CountrySelectInput, StateSelectInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <TextInput
          source="address1"
          required
        />
        <TextInput
          source="address2"
        />
        <TextInput
          source="city"
          required
        />
        <StateSelectInput />
        <TextInput
          source="zip_code"
          required
        />
        <CountrySelectInput />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
        defaultValue={{
          name: props.parentResource.name
        }}
        fixedValue={{
          user_id: props.parentResource.id
        }}
      >
        <TextInput
          source="name"
          required
        />
        <TextInput
          source="address1"
          required
        />
        <TextInput
          source="address2"
        />
        <TextInput
          source="city"
          required
        />
        <StateSelectInput />
        <TextInput
          source="zip_code"
          required
        />
        <CountrySelectInput />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
