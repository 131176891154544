import Icon from '@material-ui/icons/Memory';
import ConfigurationIcon from '@material-ui/icons/SettingsApplications';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../controllers';
import { CurrentVersionField, ExtendsEngineVersionField, JavaScriptField, LinkField, NextVersionField, TextField } from '../../fields';
import { BooleanInput, NestedReferenceInput, RadioButtonGroupInput, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import library_engine_configuration_templates from './library_engine_configuration_templates';
import library_engine_events from './library_engine_events';
import library_engine_methods from './library_engine_methods';
import library_engine_modules from './library_engine_modules';
import library_objects from './library_objects';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextField
          source="name"
        />
        <CurrentVersionField />
        <TextInput
          source="changelog"
        />
        <RadioButtonGroupInput
          choices={[
            { id: 'patch', name: 'Patch' },
            { id: 'minor', name: 'Minor' },
            { id: 'major', name: 'Major' },
          ]}
          source="significance"
        />
        <BooleanInput
          source="published"
        />
        <NestedReferenceInput
          filter={{published: true}}
          reference="library_engine_versions"
          resourcePath={`/library_engines/${props.record['library_extends_engine_id-as-library_engines']}`}
          source="library_extends_engine_version_id"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
        />
        <ReferenceInput
          reference="library_engines"
          source="library_extends_engine_id"
          allowEmpty
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <CurrentVersionField />
        <NextVersionField />
        <LinkField
          label="Extends Engine"
          reference="library_engines"
          source="library_extends_engine_id"
        />
        <ExtendsEngineVersionField />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>

          <Tab
            label="General"
          >
            <TextField
              source="name"
            />
            <TextField
              source="changelog"
            />
            <CurrentVersionField />
            <NextVersionField />
          </Tab>

          <Tab
            label="Type Definition"
          >
            <JavaScriptField
              source="type_definition"
            />

          </Tab>

          <Tab
            label="Methods"
          >
            <NestedResourceList
              reference="library_engine_methods"
            >
              <TextField
                source="name"
              />
              <TextField
                source="description"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Events"
          >
            <NestedResourceList
              reference="library_engine_events"
            >
              <TextField
                source="name"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Modules"
          >
            <NestedResourceList
              reference="library_engine_modules"
            >
              <TextField
                source="name"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Configuration"
          >
            <NestedResourceList
              reference="library_engine_configuration_templates"
            >
              <TextField
                source="name"
              />
            </NestedResourceList>
          </Tab>

        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  subMenus: [
    {
      name: 'Configuration',
      Icon: ConfigurationIcon,
      resourceNames: [
        'library_engine_configuration_templates',
      ],
    },
  ],
  resources: {
    library_engine_configuration_templates,
    library_engine_methods,
    library_engine_events,
    library_engine_modules,
    library_objects,
  },
};
