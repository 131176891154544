import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../../controllers';
import { BooleanField, EnumField, JavaScriptField, LinkField, NumberField, TextField } from '../../../fields';
import { BooleanInput, JavaScriptInput, ReferenceInput, SearchInput, TextInput } from '../../../inputs';
import library_object_event_modules from './library_object_event_modules';
import library_object_event_parameters from './library_object_event_parameters';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextField
          source="name"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <BooleanInput
          source="super_power"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="key"
        />
        <JavaScriptInput
          source="javascript"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ library_object_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="name"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <BooleanInput
          source="super_power"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="key"
        />
        <JavaScriptInput
          source="javascript"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          source="library_keyring_id"
          allowEmpty
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>

          <Tab
            label="Preview"
          >
            <JavaScriptField
              source="preview"
            />
          </Tab>

          <Tab
            label="General"
          >
            <TextField
              source="name"
            />
            <TextField
              source="description"
            />
            <BooleanField
              source="super_power"
            />
            <TextField
              source="key"
            />
            <JavaScriptField
              source="javascript"
            />
            <LinkField
              source="library_keyring_id"
              allowEmpty
            />
          </Tab>

          <Tab
            label="Parameters"
          >
            <NestedResourceList
              reference="library_object_event_parameters"
            >
              <NumberField
                source="position"
              />
              <TextField
                source="name"
              />
              <EnumField
                source="class_name"
                versionedAttribute
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Modules"
          >
            <NestedResourceList
              reference="library_object_event_modules"
            >
              <TextField
                source="name"
              />
            </NestedResourceList>
          </Tab>

        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    library_object_event_modules,
    library_object_event_parameters,
  },
};
