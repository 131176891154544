import ReplyIcon from '@material-ui/icons/Reply';
import PropTypes from 'prop-types';
import React from 'react';
import { CreateButton } from '../buttons';
import { LongTextInput } from '../inputs';

const TextMessageReplyButton = ({  from_dialing_code, from_phone_number }) => (
  <CreateButton
    fixedValue={{
        template: 'outbound',
        to_dialing_code: from_dialing_code,
        to_phone_number: from_phone_number,
    }}
    icon={<ReplyIcon />}
    label="Reply"
    resource="text_messages"
  >
    <LongTextInput
      label="Message"
      source="body"
      required
    />
  </CreateButton>
);

TextMessageReplyButton.propTypes = {
  from_dialing_code: PropTypes.string, 
  from_phone_number: PropTypes.string, 
}

export default TextMessageReplyButton;
