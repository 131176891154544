import Icon from '@material-ui/icons/Timelapse';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../../controllers';
import { BooleanField, DateField, DateTimeField, EnumField, LinkField, NumberField, SubscriptionStatusField, TextField } from '../../../fields';
import { BooleanInput, DateInput, EnumInput, InputGroup, NestedReferenceInput, ReferenceInput } from '../../../inputs';
import subscription_add_ons from './subscription_add_ons';
import subscription_discounts from './subscription_discounts';
// import invoice_subscription_prepayments from './invoice_subscription_prepayments';
// import notes from './notes';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Payment Method"
        >
          <NestedReferenceInput
            source="payment_method_id"
          />
        </InputGroup>

        {props.record.status === 'new' && (<InputGroup
          fullWidth={false}
          header="First Bill Date"
                                           >
          <DateInput
            label="Date"     
            source="start_at"     
            required
          />
          <BooleanInput
            label="Begins with trial period?"
            source="trial_period"
          />
        </InputGroup>)}

        <InputGroup
          header="Cancellation Details"
        >
          <DateInput
            source="cancel_at"
          />
          <EnumInput
            source="cancellation_code"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="Payment Method"
        >
          <NestedReferenceInput
            source="payment_method_id"
          />
        </InputGroup>

        <InputGroup
          header="First Bill Date"
        >
          <DateInput
            label="Date"     
            source="start_at"     
            required
          />     
          <BooleanInput
            label="Begins with trial period?"
            source="trial_period"
          />
        </InputGroup>
        
        <InputGroup
          header="Billing Cadence"
        >
          <EnumInput
            defaultValue='monthly'          
            source="cadence"
          />
        </InputGroup>

        <InputGroup
          header="Merchant Account"
        >
          <ReferenceInput
          // set default merchant acccount to 'Codeverse Explorer LLC'
            defaultValue='9887d05b-3cd1-4a6d-a261-0100f90faed5' 
            source="merchant_account_id"
            disabled
            required
          />
        </InputGroup>

      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          label="Subscription"
          source="name"
        />
        <SubscriptionStatusField />
        <DateField          
          source="start_at"
        />
        <DateField          
          source="cancel_at"
        />
        <DateField
          label="Next Bill Date"
          source="braintree_next_billing_date"
        />
        <NumberField
          label="Next Charge"
          source="braintree_next_billing_period_amount"
        />
        <EnumField
          source="cadence"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>
          <Tab
            label="Summary"
          >
            <TextField
              source="id"
            />
            <DateField
              source="start_at"
            />
            <DateField
              source="cancel_at"
            />
            <SubscriptionStatusField />

            <NumberField
              label="Balance"
              source="braintree_balance"
            />
            <DateField
              label="Next Billing Date"
              source="braintree_next_billing_date"
            />
            <NumberField
              label="Next Billing Amount"
              source="braintree_next_billing_period_amount"
            />
            <EnumField
              source="cadence"
            />
            <BooleanField
              source="trial_period"
              addLabel
            />
            
            <NestedResourceList
              reference="subscription_discounts"
            >
              <EnumField
                source="reason"
              />
              <TextField
                source="description"
              />
              <NumberField
                source="amount"
              />
              <NumberField
                label="Addt'l Member Amount"
                source="additional_member_amount"
              />
              <NumberField
                label="Billing Cycles"
                source="number_of_billing_cycles"
              />
              <NumberField
                source="quantity"
              />
              <EnumField
                source="status"
              />
              <DateField
                source="activate_at"
              />
              <DateTimeField
                source="added_at"
              />
              <LinkField
                source="coupon_id"
              />
              
            </NestedResourceList>

            <NestedResourceList
              reference="subscription_add_ons"
            >
              <EnumField
                source="product"
              />
              <NumberField
                source="member_count"
              />
              <NumberField
                label="First Member Amount"
                source="membership"
              />
              <NumberField
                label="Addt'l Member Discount"
                source="membership_additional_member_recurring_discount"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="invoice_subscription_prepayments"
            >
              <TextField
                source="description"
              />
              <NumberField
                source="amount"
              />
              <LinkField
                displaySource="created_at"
                label="Invoice Date"
                parentReference="organizations"
                parentSource="organization_id"
                source="invoice_id"
              />
              <LinkField
                displaySource="status"
                label="Invoice Status"
                parentReference="organizations"
                parentSource="organization_id"
                source="invoice_id"
              />
            </NestedResourceList> 
          </Tab>

          <Tab
            label="Braintree Details"
          >           
            <DateField
              source="braintree_billing_period_start_date"
            />
            <DateField
              source="braintree_billing_period_end_date"
            />
            <NumberField
              source="braintree_current_billing_cycle"
            />
            <NumberField
              source="braintree_billing_day_of_month"
            />
            <DateField
              source="braintree_next_billing_date"
            />
            <NumberField
              source="braintree_balance"
            />
            <NumberField
              source="braintree_next_billing_period_amount"
            />
            <NumberField
              source="braintree_days_past_due"
            />
            <NumberField
              source="braintree_failure_count"
            />
          </Tab>
        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <EnumInput
          source="status"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="id"
        />
        <DateField
          source="start_at"
        />
        <DateField
          source="cancel_at"
        />
        <NumberField
          label="Account Balance"
          source="braintree_balance"
        />
        <NumberField
          label="Next Bill Date"
          source="braintree_next_billing_date"
        />
        <NumberField
          label="Next Bill Amount"
          source="braintree_next_billing_period_amount"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
    subscription_discounts,
    subscription_add_ons,
    // invoice_subscription_prepayments,
  },
};
