import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const VersionField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    v
    {record.major_version}
    .
    {record.minor_version}
    {typeof record.patch_version === 'number' &&
      <>
        .
        {record.patch_version}
      </>}
  </Typography>
);

VersionField.propTypes = {
  record: PropTypes.object,
};

VersionField.defaultProps = {
  addLabel: true,
  label: 'Version',
};

export default VersionField;

