import Icon from '@material-ui/icons/Email';
import AssignIcon from '@material-ui/icons/Input';
import React from 'react';
import { ChipField, SingleFieldList } from 'react-admin';
import EmailCard from '../../components/EmailCard';
import { EditDialog, Filter, ResourceList, SimpleShowLayout } from '../../controllers';
import { BooleanField, DateTimeField, EnumField, FunctionField, LinkField, ReferenceManyField, TextField } from '../../fields';
import { BooleanInput, EnumInput, SearchInput, StaffTeamSelectInput } from '../../inputs';

const HandledRowStyle = record => ({
  opacity: record.handled_at ? '0.3' : '1',
});

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'handled'
    },
    {
      type: 'toggle',
      attribute: 'claimed'
    },
    {
      name: 'Assign',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <StaffTeamSelectInput
            source="staff_team_id"
          />
        </EditDialog>
      ),
    },
    
  ],
  dialogs: {
  },
  routes: {
    list: {
      default: {
        title: 'Unhandled Emails',
        Controller: props => (
          <ResourceList
            RowStyle={HandledRowStyle}
            filter={{ 
              template: 'inbound', 
              handled: false,
              claimed: false,
            }}
            {...props}
          >
            <FunctionField
              label="From"
              render={record => `${record.from_name} <${record.from_email}>`}
            />
            <ReferenceManyField
              label="Org Status"
              reference="organizations"
              sortable={false}
              target="emails"
            >
              <SingleFieldList
                linkType="show"
              >
                <ChipField
                  source="status"
                />
              </SingleFieldList>
            </ReferenceManyField>
            <TextField
              source="subject"
            />
            <DateTimeField
              label="Received"
              source="created_at"
              sortable
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id" 
            />
          </ResourceList>
        ),
      },
      claimed: {
        title: 'Claimed Emails',
        Controller: props => (
          <ResourceList
            filter={{
              template: 'inbound',
              handled: false,
              claimed: true,
            }}
            {...props}
          >
            <EnumField
              source="status"
            />
            <EnumField
              source="template"
            />
            <TextField
              source="subject"
            />
            <FunctionField
              label="From"
              render={record => `${record.from_name} <${record.from_email}>`}
            />
            <DateTimeField
              label="Received"
              source="created_at"
              sortable
            />
            <BooleanField
              source="handled"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
      },
      all: {
        title: 'All Emails',
        Controller: props => (
          <ResourceList
            {...props}
          >
            <EnumField
              source="status"
            />
            <EnumField
              source="template"
            />
            <TextField
              source="subject"
            />
            <FunctionField
              label="From"
              render={record => `${record.from_name} <${record.from_email}>`}
            />
            <DateTimeField
              label="Received"
              source="created_at"
              sortable
            />
            <BooleanField
              source="handled"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
        Filter: (props) => {
          return (
            <Filter
              {...props}
            >
              <StaffTeamSelectInput
                label="Assigned to"
                source="staff_team_id"
              />
              <SearchInput
                label="Search"
                source="q"
              />
              <EnumInput
                filterDefaultValue="received"
                source="status"
              />
              <EnumInput
                source="template"
              />
              <BooleanInput
                source="handled"
              />
              <BooleanInput
                source="claimed"
              />
            </Filter>
          );
        },
      },
    },
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <StaffTeamSelectInput
          label="Assigned to"
          source="staff_team_id"
        />
        <SearchInput
          label="Search"
          source="q"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <ReferenceManyField
          label="Organizations"
          reference="organizations"
          sortable={false}
          target="emails"
        >
          <SingleFieldList
            linkType="show"
          >
            <ChipField
              source="name"
            />
          </SingleFieldList>
        </ReferenceManyField>
        <EmailCard
          id={props.id}
          inbound={props.record.template === 'inbound'}
          record={props.record}
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
