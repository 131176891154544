import Icon from '@material-ui/icons/Speaker';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { ReferenceInput } from '../../../../inputs';
import product_inventories from './product_inventories';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ product_sku_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput          
          optionText="value"
          perPage={50}
          source="product_trait_value_id"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
    product_inventories,
  },
};
