import Icon from '@material-ui/icons/ChatBubble';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../controllers';
import { DateTimeField, EnumField, LinkField, TextField } from '../../../fields';
import { BooleanInput, DateTimeInput, EnumInput, InputGroup, JsonInput, TeamMemberSelectInput, TextInput } from '../../../inputs';
import streaming_chat_message_approvals from './streaming_chat_message_approvals';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ 
          streaming_stream_id: props.parentResource.id
        }}
        {...props}
      >
        <InputGroup
          header="Message"
        >        
          <TextInput
            source="message"
            required
          />
          <TextInput
            source="send_at"
          />
          <TeamMemberSelectInput
            label="Send As"
            source="user_id"
            required
          />
        </InputGroup>
        <InputGroup
          header="Other Events"
        >
          <JsonInput
            source="metadata"
          />
        </InputGroup>
      </CreateButton>
    ),
    edit: props => (
      <EditDialog
        {...props}
      >
        <DateTimeInput
          source="send_at"
        />
        <EnumInput
          source="status"
        />
      </EditDialog>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        sort={{ field: "created_at", order: 'asc' }}
        {...props}
      >
        <LinkField
          displaySource="title"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          reference="users"
          source="user_id"
        />
        <EnumField
          source="status"
        />
        <TextField
          source="message"
        />
        <DateTimeField
          source="send_at"
        />
        <DateTimeField
          source="created_at"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          displaySource="title"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          reference="users"
          source="user_id"
        />
        <EnumField
          source="status"
        />
        <TextField
          source="message"
        />
        <DateTimeField
          source="send_at"
        />
      </ResourceShow>
    )
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
        <EnumInput
          source="status"
        />

      </Filter>
    ),
  },
  resources: {
    streaming_chat_message_approvals
  },
};
