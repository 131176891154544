export default {
  choices: [
    { id: 'facebook', name: 'Facebook' },
    { id: 'instagram', name: 'Instagram' },
    { id: 'snapchat', name: 'Snapchat' },
    { id: 'twitter', name: 'Twitter' },
    { id: 'google', name: 'Google' },
    { id: 'bing', name: 'Bing' },
    { id: 'yahoo', name: 'Yahoo' },
    { id: 'baidu', name: 'Baidu' },
    { id: 'referral_program', name: 'Referral Program' },
    { id: 'event', name: 'Event' },
    { id: 'partner', name: 'Partner' },
    { id: 'magazine_or_newspaper', name: 'Magazine or Newspaper' },
    { id: 'online_video', name: 'Online Video' },
    { id: 'tv', name: 'TV' },
    { id: 'radio', name: 'Radio' },
    { id: 'podcast', name: 'Podcast' },
    { id: 'billboard', name: 'Billboard' },
    { id: 'word_of_mouth', name: 'Word of Mouth' },
    { id: 'drop_in', name: 'Drop In' },
    { id: 'other', name: 'Other' },
    { id: 'studio_event', name: 'Studio Event' },
    { id: 'external_event', name: 'External Event' },
    { id: 'field_trip', name: 'Field Trip' },
    { id: 'birthday_party', name: 'Birthday Party' },
    { id: 'school_partnership', name: 'School Partnership' },
  ],
};
