import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { EnumField, NumberField, TextField } from '../../../fields';
import { BooleanInput, SearchInput, TextInput } from '../../../inputs';
import skill_levels from './skill_levels';

export default {
  Icon,
  menuItems: [

  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ learning_path_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
      </CreateButton>
    ),
  },
  routes: {
     list: props => (
       <ResourceList
         {...props}
       >
         <TextField
           source="name"
         />
         <TextField
           source="description"
         />
       </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <NestedResourceList
          reference="skill_levels"
        >
          <NumberField
            source="position"
          />
          <EnumField
            source="difficulty_level"
          />
          <NumberField
            source="minutes"
          />
          <NumberField
            source="points"
          />
        </NestedResourceList>

      </ResourceShow>
    )
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="skill_levels"
        >
          <NumberField
            source="minutes"
          />
          <EnumField
            source="difficulty_level"
          />
          <NumberField
            source="position"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    skill_levels
  },
};
