import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PinnedIcon from '@material-ui/icons/Info';
import NotPinnedIcon from '@material-ui/icons/InfoOutline';
import NoteIcon from '@material-ui/icons/Note';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { crudUpdate } from 'react-admin';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const style = {
  root: {
    width: '80%',
    marginLeft: '20%',
    backgroundColor: '#e3cffa',
  },
  divider: {
    marginTop: '12px',
  }
};

class NoteCard extends Component {
  state = {
    expanded: false,
    pinned: null,
  };

  handlePinnedClick = (values) => {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    crudUpdate(
      resource,
      record.id,
      Object.assign({}, record, { pinned: !record.pinned }),
      record,
      `/${resource}`,
      false,
    );
  };

  render() {
    const { record, classes } = this.props;
    
    if (!record) {
      return null
    }

    const pinned = this.state.pinned === null ? !!record.pinned_at : this.state.pinned;
    
    return (
      <Card
        className={classes.root}
      >
        <CardHeader
          action={<IconButton
            onClick={this.handlePinnedClick}
                  >
            {pinned ? <PinnedIcon
              color="primary"
                      /> : <NotPinnedIcon />}
          </IconButton>}
          avatar={<Avatar>
            <NoteIcon />
          </Avatar>}
          subheader={moment(record.created_at).format('llll')}
          title={record._meta_author_name}
        />
        <CardContent>
          <Typography
            variant="subheading"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              {record.heading}
            </Linkify>
          </Typography>
          {record.detail &&
            <>
              <Divider
                className={classes.divider}
              />
              <Typography
                component="p"
                gutterBottom
              >
                <Linkify
                  properties={{ target: '_blank' }}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: record.detail }}
                  />
                </Linkify>
              </Typography>
            </>}

          {record._meta_involvements.length > 0 && (
            <>
              <Divider
                className={classes.divider}
              />
              <Typography
                variant="subheading"
                gutterBottom
              >
                This note involves:
              </Typography>
              {record._meta_involvements.map((involvement, i) => (
                <Typography
                  key={involvement.id}
                  variant="subheading"
                  gutterBottom
                >
                  <Link
                    to={`/users/${involvement.id}`}
                  >
                    {involvement.name}
                  </Link>
                </Typography>
              ))}
            </>
          )}

          {record._meta_attachments.length > 0 && (
            <>
              <Divider
                className={classes.divider}
              />
              <Typography
                variant="subheading"
                gutterBottom
              >
                Attachments:
              </Typography>
              {record._meta_attachments.map((attachment, i) => (
                <Typography
                  key={attachment.id}
                  variant="subheading"
                  gutterBottom
                >
                  <a
                    href={attachment.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Attachment
                    {' '}
                    {i + 1}
                  </a>
                  {attachment.description}
                </Typography>
              ))}
            </>
          )}

        </CardContent>
      </Card>
    );
  }
}

NoteCard.propTypes = {
  resource: PropTypes.object,
  crudUpdate: PropTypes.func,
  record: PropTypes.object,
  classes: PropTypes.object,
}

function mapStateToProps(state, props) {
  return {
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
  };
}

const mapDispatchToProps = {
  crudUpdate,
}

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(NoteCard));
