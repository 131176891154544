import Icon from '@material-ui/icons/List';
import React from 'react';
import { Filter, ResourceList } from '../../../controllers';
import { DateField, LinkField, TextField } from '../../../fields';
import { DateInput, NestedReferenceInput, NumberInput, ReferenceInput } from '../../../inputs';


export default {
  Icon,
  dialogs: {},
  routes: {
    list: props => ( 
      <ResourceList
        filterDefaultValues={{
          after: new Date().toISOString().substr(0, 10)
        }}
        {...props}
      >
        <DateField
          label="Date"
          source="start_date"
        />
        <TextField
          label="Time"
          source="start_time"
        />
        <LinkField
          source="occasion_id"
        />
        <LinkField
          source="location_id"
        />
        <LinkField
          label="Child"
          parentReference="organizations"
          parentSource="organization_id"
          reference="users"
          source="user_id"
        />
        <TextField
          source="guide"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => ( <Filter
      {...props}
                       >
      <NestedReferenceInput
        source="user_id"
      />
      <ReferenceInput
        source="occasion_id"
      />
      <ReferenceInput
        source="location_id"
      />
      <DateInput
        source="after"
      />
      <DateInput
        source="before"
      />
      <NumberInput
        label="Start Hour"
        source="start_hour"
      />
      <NumberInput
        label="End Hour"
        source="end_hour"
      />
    </Filter>
    ),
  },
  resources: {
    
  },
};
