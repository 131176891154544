import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const DaysOfWeekField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.monday ? 'M' : '-'}
    &nbsp;
    {record.tuesday ? 'T' : '-'}
    &nbsp;
    {record.wednesday ? 'W' : '-'}
    &nbsp;
    {record.thursday ? 'T' : '-'}
    &nbsp;
    {record.friday ? 'F' : '-'}
    &nbsp;
    {record.saturday ? 'S' : '-'}
    &nbsp;
    {record.sunday ? 'S' : '-'}
  </Typography>
);

DaysOfWeekField.propTypes = {
  record: PropTypes.object,
};

DaysOfWeekField.defaultProps = {
  addLabel: true,
  label: 'Weekdays',
};

export default DaysOfWeekField;

