import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

export default (type, params) => {

  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${process.env.REACT_APP_PROTOCOL || 'https'}://${process.env.REACT_APP_API_URL}/sessions`, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'sessions',
          attributes: {
            email: username,
            password,
          },
        },
      }),
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status > 201) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ included }) => {
        if (included[0].type !== 'users' || included[1].type !== 'access_tokens') {
          throw new Error('Invalid response from login API');
        }

        const user_id = included[0].id;
        const user_name = included[0].attributes.name;
        const access_token = included[1].attributes.access_token;

        // store the included user and access token in the local session
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('user_name', user_name);
        localStorage.setItem('access_token', access_token);
      });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('access_token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    if (params.status === 401) {
      window.location.href = '/#/login';
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');
      localStorage.removeItem('access_token');
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
  }

  return Promise.reject('Unknown method');

};
