import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconCancel from '@material-ui/icons/Cancel';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import { Button, REDUX_FORM_NAME, SaveButton, SimpleForm, crudGetOne, crudUpdate, resetForm } from 'react-admin';
import { connect } from 'react-redux';
import { destroy, isSubmitting, reset, submit } from 'redux-form';
import titleCase from 'title-case';
import { Alert } from '../components';
import { CommentInput } from '../inputs';

const style = {
  root: {
    zIndex: '1',
  },
  content: {
  },
  title: {
  },
  actions: {
    margin: '18px 20px',
  },
  actionButton: {
    alignSelf: 'flex-start',
  },
  comment: {
    marginTop: '16px',
  },
  errorSummary: {
    color: '#f44336',
    flexGrow: 1,
  },
  errorDetail: {
    color: '#f44336',
  }
};

class EditDialog extends Component {
  state = {
    error: false,
    generalErrorNotAcknowledged: true,
    isEditing: false
  };

  componentDidMount() {
    this.updateData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.id !== nextProps.id ||
      nextProps.version !== this.props.version
    ) {
      this.props.reset(REDUX_FORM_NAME);
      this.updateData(nextProps.resource, nextProps.id);
    }
  }

  resetAll() {
    const {
      resetForm,
      destroy,
    } = this.props;

    resetForm();
    destroy(REDUX_FORM_NAME);
  }

  updateData(resource = this.props.resource, id = this.props.id) {
    const {
      crudGetOne,
      basePath,
    } = this.props;
    crudGetOne(resource, id, basePath);
  }

  handleSaveClick = () => {
    this.setState({
      generalErrorNotAcknowledged: true,
      isEditing: true
    });
    const { submit } = this.props;
    submit(REDUX_FORM_NAME);
  };

  handleSubmit = (values) => {
    const {
      crudUpdate,
      resource,
      record,
    } = this.props;

    this.setState({
      generalErrorNotAcknowledged: true,
      isEditing: true
    });
    crudUpdate(
      resource,
      record.id,
      values,
      record,
      `/${resource}`,
      this.handleComplete,
    );
  };

  handleComplete = () => {
    this.handleCloseClick();
  }

  handleGeneralErrorCloseClick = () => {
    this.setState({ generalErrorNotAcknowledged: false });
  };

  handleCloseClick = () => {
    const {
      close,
    } = this.props;

    close();
    this.resetAll();
  }

  render() {
    const {
      isEditing,
      generalErrorNotAcknowledged,
    } = this.state;

    const {
      record,
      children,
      hideComment,
      isSubmitting,
      resource,
      classes,
      parentResource,
      submitErrors,
      syncErrors,
      title,
    } = this.props;

    const resourceTitleName = resource && titleCase(pluralize.singular(resource));
    const updateErrors = Object.assign({}, submitErrors, syncErrors)
    const generalError = updateErrors && updateErrors.general;
    const attributeErrors = updateErrors && Object.keys(updateErrors).filter(k => k !== 'general').map(k => `${titleCase(k.replace(/_id$/, ''))} ${updateErrors[k]}`)
    return (
      <Dialog
        aria-label={`Edit ${resourceTitleName}`}
        className={classes.root}
        fullWidth
        open
        onClick={event => event.stopPropagation()}
        onClose={this.handleCloseClick}
      >
        {(generalError && isEditing && generalErrorNotAcknowledged &&
          <Alert
            content={generalError}
            isOpen={!!generalError}
            title={`Can't update ${titleCase(resource)}`}
            onClose={this.handleGeneralErrorCloseClick}
          />
        )}

        <DialogTitle
          className={classes.title}
        >
          {title || `Edit ${resourceTitleName}`}
        </DialogTitle>
        <Divider />
        <DialogContent
          className={classes.content}
        >
          <SimpleForm
            // We override the redux-form name to avoid collision with the react-admin main form
            form={REDUX_FORM_NAME}
            record={record}
            resource={resource}
            // We override the redux-form onSubmit prop to handle the submission ourselves
            toolbar={null}
            // We want no toolbar at all as we have our modal actions
            onSubmit={this.handleSubmit}
          >
            {Children.toArray(children).map(child => React.cloneElement(child, { parentResource, isEdit: true }))}
            {hideComment || <CommentInput
              label={`Why are you editing this ${resourceTitleName}?`}
              required
                            />}
          </SimpleForm>
        </DialogContent>
        <Divider />

        <DialogActions
          className={classes.actions}
        >
          {attributeErrors && isEditing && (!(generalError && generalErrorNotAcknowledged)) && (
            <div
              className={classes.errorSummary}
            >
              {(generalError &&
                <Typography
                  className={classes.errorDetail}
                  component="p"
                >
                  {generalError}
                </Typography>
              )}
              {attributeErrors.map((attributeError, i) => (
                <Typography
                  className={classes.errorDetail}
                  component="p"
                  key={`attributeError-${i}`}
                >
                  {attributeError}
                </Typography>
              ))}
            </div>
          )}
          <SaveButton
            saving={isSubmitting}
            onClick={this.handleSaveClick}
          />
          <Button
            label="ra.action.cancel"
            onClick={this.handleCloseClick}
          >
            <IconCancel />
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

EditDialog.propTypes = {
  resource: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  crudUpdate: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  record: PropTypes.object,
  version: PropTypes.number,
};

function mapStateToProps(state, props) {
  return {
    isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
    submitErrors: state.form && state.form['record-form'] && state.form['record-form'].submitErrors || {},
    syncErrors: state.form && state.form['record-form'] && state.form['record-form'].syncErrors || {},
  };
}

const mapDispatchToProps = {
  reset,
  resetForm,
  destroy,
  crudUpdate,
  crudGetOne,
  submit,
};

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(
  EditDialog,
));
