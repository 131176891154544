import PropTypes from 'prop-types';
import React from 'react';
import { SelectArrayInput as ReactAdminSelectArrayInput, required as requiredFn } from 'react-admin';

export const SelectArrayInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminSelectArrayInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

SelectArrayInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

SelectArrayInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default SelectArrayInput;

