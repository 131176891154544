import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Children } from 'react';

const style = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '0 0 16px 0',
  },
  content: {
    marginTop: '16px',
    minWidth: '220px',
  },
  contentFullWidth: {
    marginTop: '16px',
    width: '100%',
  },
  fields: {
    width: '280px',
  },
  fieldsFullWidth: {
    width: '100%',
  },
  divider: {
    margin: '12px 0',
  },
};

const sideBySideInputStyle = {
  width: '280px',
}

const fullWidthInputStyle = {
  width: '100%',
}

const InputGroup = ({ classes, header, subHeader, children, fullWidth, ...props }) => {
  const inputStyle = fullWidth ? fullWidthInputStyle : sideBySideInputStyle;
  return (
    <>
      <div
        className={classes.container}
      >
        <div
          className={fullWidth ? classes.contentFullWidth : classes.content}
        >
          <Typography
            style={{lineHeight: 'normal'}}
            variant="subheading"
          >
            {header}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {subHeader}
          </Typography>
        </div>
        <div
          className={fullWidth ? classes.fieldsFullWidth : classes.fields}
        >
          {Children.toArray(children).map(child => React.cloneElement(child, { style: inputStyle, ...props }))}
        </div>
      </div>
      <Divider
        className={classes.divider}
      />
    </>
  )
}


InputGroup.propTypes = {
  classes: PropTypes.object,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  children: PropTypes.object,
  fullWidth: PropTypes.bool,
}

export default withStyles(style)(
  InputGroup,
);
