import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { required, SelectInput } from 'react-admin';
import enums from '../data/enums.js';


const EnumInput = (props) => {
  const resourceName = props.versionedAttribute ? `${pluralize.singular(props.resource)}_versions` : props.resource;
  const choices = enums.choicesFor(resourceName, props.source);
  const isRequired = enums.isRequiredFor(resourceName, props.source);
  const isOptional = enums.isOptionalFor(resourceName, props.source);
  return (
    <SelectInput
      {...props}
      choices={props.choices || choices}
      resettable={isOptional}
      validate={isRequired && required()}
    />
  );
};

EnumInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

EnumInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default EnumInput;
