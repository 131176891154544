import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog } from '../../../controllers';

export default {
  Icon,
  menuItems: [
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ mission_id: props.parentResource.id }}
        {...props}
      >
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
