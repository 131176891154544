import PropTypes from 'prop-types';
import React from 'react';
import { LongTextInput, required as requiredFn } from 'react-admin';


const JavaScriptInput = ({isRequired, validate, ...props}) => (
  <LongTextInput
    validate={isRequired ? requiredFn() : validate}
    {...props}
  />
);

JavaScriptInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  
};

JavaScriptInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default JavaScriptInput;
