import PropTypes from 'prop-types';
import React from 'react';
import { LongTextInput, required as requiredFn } from 'react-admin';

const CommentInput = ({label, isRequired, validate, ...props}) => (
  <LongTextInput
    label={label || "Reason for change"}
    source="_comment"
    style={{ width: '100%' }}
    validate={isRequired ? requiredFn() : validate }
    {...props}
  />
);

CommentInput.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
}

export default CommentInput;
