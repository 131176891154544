import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import * as Showdown from 'showdown';

class MarkdownField extends Component {
  static propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      tab: 'preview',
    };
  }

  componentDidMount() {
    const { record, source } = this.props;
    const value = record[source];

    this.setState({ value });

    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  handleValueChange(value) {
    this.setState({ value });
    this.props.input.onChange(value);
  }

  handleTabChange(tab) {
    this.setState({ tab });
  }


  render() {
    return (
      <Typography
        color="textSecondary"
        display="block"
        variant="body1"
      >
        <ReactMde
          generateMarkdownPreview={markdown => {
            if (this.converter) {
              return Promise.resolve(this.converter.makeHtml(markdown))
            }
            return Promise.resolve();
          }}
          selectedTab={this.state.tab}
          value={this.state.value}
          onChange={value => this.handleValueChange(value)}
          onTabChange={tab => this.handleTabChange(tab)}
        />

      </Typography>
    );
  }
}

MarkdownField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.string,
}

MarkdownField.defaultProps = {
  addLabel: true,
};

export default MarkdownField;
