import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const EndTimeField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.end_hour}
    :
    {record.end_minute < 10 ? `0${record.end_minute}` : record.end_minute}
  </Typography>
);

EndTimeField.propTypes = {
  record: PropTypes.object,
};

EndTimeField.defaultProps = {
  addLabel: true,
  label: 'End',
};

export default EndTimeField;

