import PropTypes from 'prop-types';
import React from 'react';
import { LongTextInput, required as requiredFn } from 'react-admin';


function JsonParse(value) {
  try {
    return JSON.parse(value);
  }
  catch (error) {
    return value
  }
}
function JsonStringify(value) {
  try {
    if (typeof value === 'string') {
      return value
    }
    return JSON.stringify(value, null, 2)
  }
  catch (error) {
    return value
  }
}

const JsonInput = ({isRequired, validate, ...props}) => (
  <LongTextInput
    format={value => value && JsonStringify(value)}
    parse={value => JsonParse(value)}
    validate={isRequired ? requiredFn() : validate}
    {...props}
  />
);

JsonInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

JsonInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default JsonInput;
