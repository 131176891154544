import PropTypes from 'prop-types';
import React from 'react';
import { AutocompleteInput, FormDataConsumer, required as requiredFn, TextInput } from 'react-admin';


const StateSelectInput = ({ isRequired, validate, ...props}) => (
  <FormDataConsumer
    {...props}
  >
    {({ formData, ...rest }) => (formData.country_code === 'US' ? (
      <AutocompleteInput
        choices={americanStates.choices}
        source="state"
        validate={isRequired ? requiredFn() : validate}
        {...rest}
      />
    ) : (
      <TextInput
        source="state"
        {...rest}
      />
    ))}
  </FormDataConsumer>
);

StateSelectInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

StateSelectInput.defaultProps = {
  label: 'State',
  sort: false,
  sortable: false,
  alwaysOn: true,
  isRequired: true
};

export default StateSelectInput;

