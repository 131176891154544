import PropTypes from 'prop-types';
import React from 'react';
import { SelectInput } from 'react-admin';
import dialingCodes from '../data/dialingCodes';

const CurrencySelectInput = props => (
  <SelectInput
    {...props}
    choices={dialingCodes.choices}
  />
);

CurrencySelectInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  defaultValue: PropTypes.string,
};

CurrencySelectInput.defaultProps = {
  label: 'Dialing Code',
  source: 'dialing_code',
  defaultValue: '1',
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default CurrencySelectInput;
