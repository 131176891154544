import IgnoredIcon from '@material-ui/icons/Clear';
import AcceptedIcon from '@material-ui/icons/Done';
import ReconsiderIcon from '@material-ui/icons/Redo';
import Icon from '@material-ui/icons/SupervisorAccount';
import RevokedIcon from '@material-ui/icons/Undo';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { NestedReferenceInput, TextInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      type: 'update',
      name: 'Ignore',
      icon: IgnoredIcon,
      if: friend_request => !friend_request.ignored && !friend_request.accepted && !friend_request.revoked,
      updateAttributes: {ignored: true},
    },
    {
      type: 'update',
      name: 'Accept',
      icon: AcceptedIcon,
      if: friend_request => !friend_request.ignored && !friend_request.accepted && !friend_request.revoked,
      updateAttributes: {accepted: false},
    },
    {
      type: 'update',
      name: 'Revoke',
      icon: RevokedIcon,
      if: friend_request => !friend_request.ignored && friend_request.accepted && !friend_request.revoked,
      updateAttributes: {revoked: true},
    },
    {
      type: 'update',
      name: 'Reconsider',
      icon: ReconsiderIcon,
      if: friend_request => friend_request.ignored && !friend_request.accepted && !friend_request.revoked,
      updateAttributes: {ignored: false},
    },
  ],
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          filterToQuery={searchText => ({ name: searchText })}
          source="user_id"
        />
        <TextInput
          source="username"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
