import GeneralIcon from '@material-ui/icons/Apps';
import Icon from '@material-ui/icons/FormatAlignLeft';
import FeaturedIcon from '@material-ui/icons/Highlight';
import PrevFeaturedIcon from '@material-ui/icons/Undo';
import PublicIcon from '@material-ui/icons/Visibility';
import PrivateIcon from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow } from '../../../../../controllers';
import { DateTimeField, EnumField, ImageField, LinkField, TextField, UrlField } from '../../../../../fields';
import { BooleanInput, EnumInput, ImageInput, InputGroup, LongTextInput, ReferenceInput, SearchInput, TextInput } from '../../../../../inputs';
import PreviewGame from './../PreviewGame';

export default {
  Icon,
  menuItems: [
    {
      type: 'update',
      name: 'Make Public',
      icon: PublicIcon,
      if: version => version.status === 'is_private', 
      updateAttributes: { status: 'is_public' },
    },
    {
      type: 'update',
      name: 'Make Private',
      icon: PrivateIcon,
      if: version => version.status !== 'is_private', 
      updateAttributes: { status: 'is_private' },
    },
    {
      type: 'update',
      name: 'Set General',
      icon: GeneralIcon,
      if: project_category => project_category !== 'Community General', 
      updateAttributes: { project_category_id: '5601caee-d546-4255-9558-f36e70d0ed1a' },
    },
    {
      type: 'update',
      name: 'Set Featured',
      icon: FeaturedIcon,
      if: project_category => project_category !== 'Community Highlight', 
      updateAttributes: { project_category_id: '20a2fbf4-5c99-4011-95ab-7db519e2c4c9' },
    },
    {
      type: 'update',
      name: 'Set Previously Featured',
      icon: PrevFeaturedIcon,
      if: project_category => project_category !== 'Previously Featured', 
      updateAttributes: { project_category_id: '117915f9-6bee-4c12-95db-f902ca7ad8a1' },
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <LongTextInput
          source="description"
        />
        <ImageInput
          source="screenshot"
        />
        <ReferenceInput
          reference="project_categories"
          source="project_category_id"
        />

        <InputGroup
          header="App Store"
        >
          <EnumInput
            source="status"
          />
          <LongTextInput
            source="status_comments"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ project_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          source="significance"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <LinkField
          label="Project Category"
          reference="project_categories"
          source="project_category_id"
        />
        <ImageField
          source="screenshot_2x"
        />  
        <UrlField
          source="url"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          label="Project Category"
          reference="project_categories"
          source="project_category_id"
        />
        <TextField
          source="description"
        />
        <ImageField
          source="screenshot_2x"
        />
        <UrlField
          source="url"
        />        
        <PreviewGame 
          id={props.id}
        />

        <NestedResourceList
          reference="version_status_changes"
        >
          <EnumField
            source="status"
          />
          <TextField
            source="comments"
          />
          <DateTimeField
            source="changed_at"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  }
};
