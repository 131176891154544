import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { Title } from 'react-admin';
import { Link } from 'react-router-dom';


const styles = {
  container: {
    padding: '16px',
  },
};

export default withStyles(styles)(
  ({
    classes,
    children,
    routes,
    resourcePath,
    ...props
  }) => {
    const { defaultTitle } = props;
    const currentUrl = props.location.pathname;
    // if there are multiple list pages, then create tabs for them
    const multipleListPages = typeof routes.list === 'object';
    let selectedTab = 0;
    const basePath = resourcePath[resourcePath.length - 1].basePath;
    const listPages = multipleListPages && Object.keys(routes.list).map((c, i) => {
      const ret = {
        title: (routes.list[c].title || 'missing title'),
        url: (c === 'default' ? basePath : `${basePath}/${c}`),
      };
      if (ret.url === currentUrl) {
        selectedTab = i;
      }
      return ret;
    });
    return (
      <>
        { multipleListPages &&
      (
        <Tabs
          value={selectedTab}
        >
          {listPages.map(link => (
            <Tab
              component={Link}
              key={link.url}
              label={link.title}
              to={link.url}
            />
          ),
          )}
          ;
        </Tabs>
      )}
        <Title
          defaultTitle={defaultTitle}
        />
        <Paper
          className={classes.container}
        >
          { children }
        </Paper>
      </>
    )
  }
)
