import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/FilterList';
import Icon from '@material-ui/icons/PieChart';
import Chart from "chart.js";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { getFormValues, reduxForm } from 'redux-form';
import dataProvider from '../dataProvider';
import { DateInput } from '../inputs';
import { GET_CHART } from '../lib/ra-jsonapi-client/actions';

const style = {
  root: {
    marginBottom: '12px',
    width: '100%',
  },
  icon: {
    transform: 'rotate(-180deg)',
  },
}

class PieChart extends Component {
  chartRef = React.createRef();

  state = {
    loaded: false,
    expanded: false,
    values: [],
    labels: [],
    error: null,
    formFilter: {},
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    const nextValues = nextProps.formFilter;
    const formFilter = this.props.formFilter;
    // if at least one of the form formFilter changed
    if (Object.keys(nextValues).some((key) => nextValues[key] !== formFilter[key])) {
      this.getData(nextProps.formFilter);
    }
  }

  getData(nextFilter={}) {
    const {
      source,
      name,
      type,
      filter,
    } = this.props;

    const combinedFilter = Object.assign({}, filter, nextFilter)

    dataProvider(GET_CHART, source, { filter: combinedFilter, type, name})
      .then(({ values, labels }) => {
        this.setState({
          values,
          labels,
          loaded: true,
        });
        this.buildChart();
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  buildChart() {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { values, labels } = this.state;
    const { title } = this.props;

    new Chart(myChartRef, {
      type: "pie",
      data: {
        //Bring in data
        labels: labels,
        datasets: [
          {
            label: title,
            data: values,
            backgroundColor: ['#8c6af6', '#d66af6', '#f66ac2', '#f66a6a', '#6a93f6', '#6ac7f6', '#91f66a', '#f6f46a', '#f6ba6a']
          }
        ]
      },
      options: {
        //Customize chart options
      }
    });
  }

  render() {
    const { loaded, expanded } = this.state;
    const { classes, description, title } = this.props;

    return (
      <Card
        className={classes.root}
      >
        <CardHeader
          action={(
            <IconButton
              aria-expanded={this.state.expanded}
              aria-label={this.state.expanded ? 'Hide more' : 'Show more'}
              className={this.state.expanded ? classes.expandOpen : classes.expand}
              onClick={this.handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
          avatar={(
            <Avatar>
              <Icon
                className={classes.icon}
              />
            </Avatar>
          )}
          subheader={description}
          title={title}
        />
        <CardContent>
          {loaded && (
            <canvas
              id="myChart"
              ref={this.chartRef}
            />
          )}
        </CardContent>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            <DateInput
              source="from"
            />
            <DateInput
              source="to"
            />
          </CardContent>
        </Collapse>
      </Card>
    )
  }
}

PieChart.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string,
  filter: PropTypes.object,
  formFilter: PropTypes.object,
  name: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
};

PieChart.defaultProps = {
  filter: {},
  formFilter: {},
  type: 'contribution' // or 'series'
}

const enhance = compose(
  withStyles(style),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
  connect((state, props) => ({
    formFilter: getFormValues(props.form)(state)
  }))
);

const EnhancedPieChart = enhance(PieChart);

export default (props) => (
  <EnhancedPieChart
    {...props}
    form={`${props.name}-${props.source}-${props.type||'contribution'}-${props.title}`}
  />
)
