import PropTypes from 'prop-types';
import React from 'react';
import { SearchInput as ReactAdminSearchInput, required as requiredFn } from 'react-admin';

export const SearchInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminSearchInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

SearchInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

SearchInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true,
  label: "Search",
  source: "q",
};

export default SearchInput;

