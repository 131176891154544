import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const StartTimeField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.start_hour}
    :
    {record.start_minute < 10 ? `0${record.start_minute}` : record.start_minute}
  </Typography>
);

StartTimeField.propTypes = {
  record: PropTypes.object,
};

StartTimeField.defaultProps = {
  addLabel: true,
  label: 'Start',
};

export default StartTimeField;

