import Icon from '@material-ui/icons/CreditCard';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { Filter, ResourceShow } from '../../../controllers';
import { BooleanField, DateTimeField, TextField } from '../../../fields';
import { BooleanInput, CreditCardInput, SearchInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <CreditCardInput />
      </CreateButton>
    ),
  },
  routes: {
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="brand"
        />
        <TextField
          source="last_four"
        />
        <BooleanField
          source="default"
        />
        <DateTimeField
          source="created_at"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
