import React from 'react';
import { Tab } from 'react-admin';

export default ({ children, resources, parentResource, ...props }) => (
  <Tab
    {...props}
  >
    {React.Children.toArray(children).map(child => React.cloneElement(child, {
      parentResource,
      resources,
    }))}
  </Tab>
)

