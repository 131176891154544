import PropTypes from 'prop-types';
import React from 'react';

const PreviewGame = ({ record = {}, id }) => {

  return (
    <div>
      <iframe
        height="800"
        src={`https://studio.codeverse.com/home/play/${id || record.id}`}
        width="480"
      />
    </div>
)}

PreviewGame.propTypes = {
  record: PropTypes.object,
  id: PropTypes.string,
}

export default PreviewGame;
