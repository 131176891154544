import EmailIcon from '@material-ui/icons/Email';
import BookingIcon from '@material-ui/icons/Event';
import GradeIcon from '@material-ui/icons/Grade';
import Icon from '@material-ui/icons/Person';
import moment from 'moment';
import React from 'react';
import { SingleFieldList } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { CreateDialog, EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout, Tab, TabbedShowLayout } from '../../../controllers';
import { AddressField, AgeField, BooleanField, CurrentVersionField, DateField, DateTimeField, EditableProjectLinkField, EnumField, ImageField, LinkField, MeetingLinkField, NumberField, ReferenceField, ReferenceManyField, RichTextField, SkillProgressField, StartAndEndTimeField, TextField, TimeZoneField } from '../../../fields';
import { BookingScheduleInput, BooleanInput, DateInput, EnumInput, ImageInput, InputGroup, NestedReferenceInput, NumberInput, ReferenceInput, RichTextInput, SearchInput, TextInput, TimeZoneSelectInput } from '../../../inputs';
import phone_numbers from '../../phone_numbers';
import checkr_accounts from '../../users/checkr_accounts';
import curriculum_accomplishments from '../../users/curriculum_accomplishments';
import followings from '../../users/followings';
import friend_requests from '../../users/friend_requests';
import guide_availabilities from '../../users/guide_availabilities';
import guide_blocks from '../../users/guide_blocks';
import guide_preferences from '../../users/guide_preferences';
import guide_profiles from '../../users/guide_profiles';
import guide_schedulables from '../../users/guide_schedulables';
import memberships from '../../users/memberships';
import mission_achievements from '../../users/mission_achievements';
import participations from '../../users/participations';
import payment_methods from '../../users/payment_methods';
import health_records from './health_records';
import how_heard_about from './how_heard_about';
import learning_styles from './learning_styles';
import projects from './projects';
import user_addresses from './user_addresses';

export default {
  Icon,
  menuItems: [
    {
      name: 'New Email',
      type: 'component',
      if :user => user.email && user.scope != 'child',
      icon: EmailIcon,
      Component: props => (
        <CreateDialog
          {...props}
          fixedValue={{
            user_id: props.parentResource.id,
            organization_id: props.parentResource.parentResource.id,
          }}
          resource="emails"
        >
          <EnumInput
          // override choices to only allow user-editable outbound emails
            choices={[
                { id: 'support_conversation', name: 'support@codeverse.com'}, 
                { id: 'guide_support_conversation', name: 'guide-support@codeverse.com'}, 
                { id: 'certification_support_conversation', name: 'certification-support@codeverse.com'},
                { id: 'influencer_conversation', name: 'influencers@codeverse.com'},
            ]}
            defaultValue="support_conversation"
            label="From Address"
            source="template"
          />
          <TextInput
            source="subject"
            required
          />
          <RichTextInput
            source="body"
            required
          />
        </CreateDialog>
      ),
    },
    {
      name: 'New Booking',
      type: 'component',
      if: user => user.scope != 'account',
      icon: BookingIcon,
      Component: props => (
        <CreateDialog
          {...props}
          fixedValue={{
            user_id: props.parentResource.id,
            organization_id: props.parentResource.parentResource.id,
          }}
          resource="bookings"
        >
          <BookingScheduleInput />
        </CreateDialog>
      ),
    },
    {
      name: 'New Accomplishment',
      type: 'component',
      if: user => user.scope === 'child',
      icon: GradeIcon,
      Component: props => (
        <CreateDialog
          {...props}
          fixedValue={{
            user_id: props.parentResource.id,
            organization_id: props.parentResource.parentResource.id,
          }}
          resource="curriculum_accomplishments"
        >
          <ReferenceInput
            filter={{ processor: 'manual' }}
            source="curriculum_activity_id"
            required
          />
          <NumberInput
            source="count"
            required
          />
        </CreateDialog>
      ),
    },
    {
      name: 'New Mission Achievement',
      type: 'component',
      if: user => user.scope === 'child',
      icon: GradeIcon,
      Component: props => (
        <CreateDialog
          {...props}
          fixedValue={{
            user_id: props.parentResource.id,
            organization_id: props.parentResource.parentResource.id,
          }}
          resource="mission_achievements"
        >
          <ReferenceInput
            source="mission_id"
            required
          />
          <NestedReferenceInput
            source="project_id"
            required
          />
          <BooleanInput
            source="completed"
            defaultValue
          />
          <BooleanInput
            source="submitted"
            defaultValue
          />
        </CreateDialog>
      ),
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Contact Information"
          subHeader="Name and email information"
        >
          <EnumInput
            source="scope"
          />
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="email"
          />
          {props.record.scope == 'child' && (
            <TextInput
              source="password"
            />
          )}
          <EnumInput
            source="gender"
          />
          <NumberInput
            source="grade"
          />
          <DateInput
            source="date_of_birth"
          />
          <EnumInput
            source="difficulty_level"
          />
        </InputGroup>

        <InputGroup
          header="Influencer Program"
          subHeader="Members of our Influencer program assist us in promoting our brand and product accross social media and other platforms"
        >
          <BooleanInput
            source="verified"
          />
        </InputGroup>

        <InputGroup
          header="Ambassador Program"
          subHeader="Members of our ambassador program are typically partners, rather than parents. They use different referral codes than regular customers, and are paid in cash for sending us new customers"
        >
          <BooleanInput
            source="ambassador"
          />
        </InputGroup>

        <InputGroup
          header="User Preferences"
        >
          <TimeZoneSelectInput 
            choices={[
              {id: 'America/New_York', name: 'Eastern'},
              {id: 'America/Chicago', name: 'Central'},
              {id: 'America/Denver', name: 'Mountain'},
              {id: 'America/Los_Angeles', name: 'Pacific'},
              
              {id: null, name: 'Other Time Zones', disabled: true},
              {id: 'Africa/Lagos', name: 'Africa/Lagos'},
              {id: 'Africa/Johannesburg', name: 'Africa/Johannesburg'},
              {id: 'America/Bogota', name: 'America/Bogota'},
              {id: 'America/Juneau', name: 'America/Juneau'},
              {id: 'America/Phoenix', name: 'America/Phoenix'},
              {id: 'America/Guyana', name: 'America/Guyana'},
              {id: 'America/Halifax', name: 'America/Halifax'},
              {id: 'Asia/Baghdad', name: 'Asia/Baghdad'},
              {id: 'Asia/Colombo', name: 'Asia/Colombo'},
              {id: 'Asia/Tbilisi', name: 'Asia/Tbilisi'},
              {id: 'Asia/Hong_Kong', name: 'Asia/Hong_Kong'},
              {id: 'Asia/Jerusalem', name: 'Asia/Jerusalem'},
              {id: 'Asia/Kolkata', name: 'Asia/Kolkata'},
              {id: 'Asia/Kuwait', name: 'Asia/Kuwait'},
              {id: 'Asia/Seoul', name: 'Asia/Seoul'},
              {id: 'Asia/Shanghai', name: 'Asia/Shanghai'},
              {id: 'Asia/Singapore', name: 'Asia/Singapore'},
              {id: 'Asia/Taipei', name: 'Asia/Taipei'},
              {id: 'Asia/Tokyo', name: 'Asia/Tokyo'},
              {id: 'Australia/Perth', name: 'Australia/Perth'},
              {id: 'Australia/Sydney', name: 'Australia/Sydney'},
              {id: 'Europe/Copenhagen', name: 'Europe/Copenhagen'},
              {id: 'Europe/Dublin', name: 'Europe/Dublin'},
              {id: 'Europe/Helsinki', name: 'Europe/Helsinki'},
              {id: 'Europe/Istanbul', name: 'Europe/Istanbul'},
              {id: 'Europe/London', name: 'Europe/London'},
              {id: 'Europe/Madrid', name: 'Europe/Madrid'},
              {id: 'Europe/Oslo', name: 'Europe/Oslo'},
              {id: 'Europe/Paris', name: 'Europe/Paris'},
              {id: 'Europe/Stockholm', name: 'Europe/Stockholm'},
              {id: 'Europe/Zurich', name: 'Europe/Zurich'},
              {id: 'Pacific/Auckland', name: 'Pacific/Auckland'},
              {id: 'Pacific/Honolulu', name: 'Pacific/Honolulu'},
            ]}
          />
          <BooleanInput
            source="do_not_phone_call"
          />
          <BooleanInput
            source="do_not_email"
          />
          <BooleanInput
            source="do_not_text_message"
          />
        </InputGroup>

        <InputGroup
          header="Avatar and Photo"
          subHeader="Choose an avatar or upload a photo for this user."
        >
          <ReferenceInput
            reference="avatars"
            source="avatar_id"
          />
          <ImageInput
            label="photo"
            source="photo"
          />
        </InputGroup>

        <InputGroup
          header="Marketing"
        >
          <EnumInput
            source="t_shirt_size"
          />
          <ReferenceInput
            label="How did this user hear about us?"
            source="how_heard_about_id"
          />
        </InputGroup>

        <InputGroup
          header="Administration"
          subHeader="If this user is a duplicate of another already in the system, select the original user here. This will automatically delete the current user."
        >
          <ReferenceInput
            filer={{ has_email: "true" }}
            optionText={users => `${users.name} (${users.email}) - Created ${moment(users.created_at).format('lll')}`}
            reference="users"
            source="duplicate_of_id"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="Contact Information"
          subHeader="Name and email information"
        >
          <EnumInput
            source="scope"
          />
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="email"
          />
          <TextInput
            source="password"
          />
          <EnumInput
            source="gender"
          />
          <NumberInput
            source="grade"
          />
          <DateInput
            source="date_of_birth"
          />
          <EnumInput
            defaultValue="beginner"
            source="difficulty_level"
          />
        </InputGroup>

        <InputGroup
          header="Influencer Program"
          subHeader="Members of our Influencer program assist us in promoting our brand and product accross social media and other platforms"
        >
          <BooleanInput
            source="verified"
          />
        </InputGroup>

        <InputGroup
          header="Ambassador Program"
          subHeader="Members of our ambassador program are typically partners, rather than parents. They use different referral codes than regular customers, and are paid in cash for sending us new customers"
        >
          <BooleanInput
            source="ambassador"
          />
        </InputGroup>

        <InputGroup
          header="User Preferences"
        >
          <TimeZoneSelectInput 
            choices={[
              {id: 'America/New_York', name: 'Eastern'},
              {id: 'America/Chicago', name: 'Central'},
              {id: 'America/Denver', name: 'Mountain'},
              {id: 'America/Los_Angeles', name: 'Pacific'},
              
              {id: null, name: 'Other Time Zones', disabled: true},
              {id: 'Africa/Lagos', name: 'Africa/Lagos'},
              {id: 'Africa/Johannesburg', name: 'Africa/Johannesburg'},
              {id: 'America/Bogota', name: 'America/Bogota'},
              {id: 'America/Juneau', name: 'America/Juneau'},
              {id: 'America/Phoenix', name: 'America/Phoenix'},
              {id: 'America/Guyana', name: 'America/Guyana'},
              {id: 'America/Halifax', name: 'America/Halifax'},
              {id: 'Asia/Baghdad', name: 'Asia/Baghdad'},
              {id: 'Asia/Colombo', name: 'Asia/Colombo'},
              {id: 'Asia/Tbilisi', name: 'Asia/Tbilisi'},
              {id: 'Asia/Hong_Kong', name: 'Asia/Hong_Kong'},
              {id: 'Asia/Jerusalem', name: 'Asia/Jerusalem'},
              {id: 'Asia/Kolkata', name: 'Asia/Kolkata'},
              {id: 'Asia/Kuwait', name: 'Asia/Kuwait'},
              {id: 'Asia/Seoul', name: 'Asia/Seoul'},
              {id: 'Asia/Shanghai', name: 'Asia/Shanghai'},
              {id: 'Asia/Singapore', name: 'Asia/Singapore'},
              {id: 'Asia/Taipei', name: 'Asia/Taipei'},
              {id: 'Asia/Tokyo', name: 'Asia/Tokyo'},
              {id: 'Australia/Perth', name: 'Australia/Perth'},
              {id: 'Australia/Sydney', name: 'Australia/Sydney'},
              {id: 'Europe/Copenhagen', name: 'Europe/Copenhagen'},
              {id: 'Europe/Dublin', name: 'Europe/Dublin'},
              {id: 'Europe/Helsinki', name: 'Europe/Helsinki'},
              {id: 'Europe/Istanbul', name: 'Europe/Istanbul'},
              {id: 'Europe/London', name: 'Europe/London'},
              {id: 'Europe/Madrid', name: 'Europe/Madrid'},
              {id: 'Europe/Oslo', name: 'Europe/Oslo'},
              {id: 'Europe/Paris', name: 'Europe/Paris'},
              {id: 'Europe/Stockholm', name: 'Europe/Stockholm'},
              {id: 'Europe/Zurich', name: 'Europe/Zurich'},
              {id: 'Pacific/Auckland', name: 'Pacific/Auckland'},
              {id: 'Pacific/Honolulu', name: 'Pacific/Honolulu'},
            ]}
          />
        </InputGroup>

        <InputGroup
          header="Communication Preferences"
          subHeader="Indiciate if a customer has requested not to be contacted."
        >
          <BooleanInput
            source="do_not_phone_call"
          />
          <BooleanInput
            source="do_not_email"
          />
          <BooleanInput
            source="do_not_text_message"
          />
        </InputGroup>

        <InputGroup
          header="Avatar or Photo"
          subHeader="Choose an avatar or upload a photo for this user."
        >
          <ReferenceInput
            reference="avatars"
            source="avatar_id"
          />
          <ImageInput
            label="photo"
            source="photo"
          />
        </InputGroup>

        <InputGroup
          header="Marketing"
        >
          <EnumInput
            source="t_shirt_size"
          />
          <ReferenceInput
            label="How did this user hear about us?"
            source="how_heard_about_id"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          sortable={false}
          source="scope"
        />
        <TextField
          sortable={false}
          source="name"
        />
        <TextField
          source="email"
        />
        <AgeField 
          sortable={false}
          showDateOfBirth
        />
        <TextField
          sortable={false}
          source="referral_code"
        />
        <BooleanField
          sortable={false}
          source="verified"
          addLabel
        />
        <BooleanField
          sortable={false}
          source="ambassador"
          addLabel
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>
          <Tab
            label="summary"
          >
            <DateField
              source="created_at"
            />
            <TextField
              source="id"
            />
            <TextField
              label="NRDY Student ID"
              source="nrdy_student_id"
            />
            <EnumField
              source="scope"
            />
            <TextField
              source="name"
            />
            <TextField
              source="email"
            />
            <TextField
              source="gender"
            />
            <AgeField />
            <DateField
              source="date_of_birth"
            />
            <TextField
              source="referral_code"
            />
            <BooleanField
              source="verified"
              addLabel
            />
            <BooleanField
              source="ambassador"
              addLabel
            />
            <EnumField
              source="t_shirt_size"
            />
            <TimeZoneField
              source="time_zone"
              addLabel
            />
            <BooleanField
              source="do_not_email"
              addLabel
            />
            <BooleanField
              source="do_not_phone_call"
              addLabel
            />
            <BooleanField
              source="do_not_text_message"
              addLabel
            />
            <ReferenceField
              reference="avatars"
              source="avatar_id"
              allowEmpty
            >
              <ImageField
                source="file"
              />
            </ReferenceField>

            <ImageField
              source="photo"
            />
            
            <NestedResourceList
              reference="phone_numbers"
            >
              <TextField
                label="Phone Number"
                source="phone_number_international_formatted"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="user_addresses"
            >
              <TextField
                source="name"
              />
              <AddressField />
            </NestedResourceList>

            <NestedResourceList
              reference="memberships"
            >
              <LinkField
                source="organization_id"
              />
              <TextField
                source="badge"
              />
              <EnumField
                source="role"
              />
              <BooleanField
                source="accepted"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="guide_preferences"
            >
              <NumberField
                source="position"
              />
              <LinkField
                reference="users"
                source="guide_id"
              />
              <NumberField
                source="completed_meetings_count"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="guide_blocks"
            >
              <LinkField
                reference="users"
                source="guide_id"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="learning_styles"
            >
              <LinkField
                source="user_id"
              />
              <TextField
                label="Prior Coding Experience"
                source="experience"
              />
              <TextField
                label="Preferred Teaching Style"
                source="styles"
              />
              <TextField
                label="Interests and Hobbies"
                source="interests"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="notes"
            >
              <EnumField
                source="topic"
              />
              <TextField
                source="heading"
              />
              <RichTextField
                source="detail"
              />
              <TextField
                label="Author"
                source="_meta_author_name"
              />
              <DateTimeField
                source="created_at"
              />
              <BooleanField
                source="pinned"
              />
            </NestedResourceList>
          </Tab>
            
          <Tab
            label="Payment Methods"
          >
            <NestedResourceList
              reference="payment_methods"
            >
              <TextField
                source="brand"
              />
              <TextField
                source="last_four"
              />
              <BooleanField
                source="default"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Curriculum"
          >
            <LinkField
              source="rank_id"
            />

            <NestedResourceList
              perPage={10}
              reference="badge_achievements"
            >
              <LinkField
                source="badge_id"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="mission_achievements"
            >
              <LinkField
                source="mission_id"
              />
              <LinkField
                parentReference="users"
                parentSource="user_id"
                source="project_id"
              />
              <BooleanField
                source="completed"
              />
              <BooleanField
                source="submitted"
              />
              <BooleanField
                source="failed"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="curriculum_accomplishments"
            >
              <LinkField
                source="project_id"
              />
              <LinkField
                displaySource="difficulty_level"
                label="Difficulty Level"
                source="curriculum_activity_id"
              />
              <LinkField
                source="curriculum_activity_id"
              />
              <NumberField
                source="count"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="curriculum_scores"
            >
              <LinkField
                source="skill_id"
              />
              <NumberField
                source="points"
              />
              <SkillProgressField />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Projects"
          >
            <NestedResourceList
              perPage={10}
              reference="projects"
            >
              <TextField
                source="name"
              />
              <DateTimeField
                source="created_at"
              />
              <ImageField
                source="screenshot_2x"
              />
              <TextField
                source="current_kidscript_version"
              />
              <CurrentVersionField />
              <EnumField
                source="visibility"
              />
              <BooleanField
                source="archived"
              />
              <EditableProjectLinkField />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Social"
          >
            <BooleanField
              source="private_profile"
              addLabel
            />
            <TextField
              source="follower_count"
            />
            <TextField
              source="following_count"
            />
            
            <NestedResourceList
              reference="followings"
            >
              <LinkField
                reference="users"
                source="following_id"
              />
            </NestedResourceList>
          </Tab>
          
          <Tab
            label="Meetings"
          >
            <NestedResourceList
              filter={{
                include_past: true
              }}
              reference="participations"
            >
              <MeetingLinkField
                source="meeting_id"
              />
              <DateTimeField
                source="meeting_start_at"
              />
              <DateTimeField
                source="meeting_end_at"
              />
              <EnumField
                source="meeting_status"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Guides"
          >
            <LinkField
              source="guide_rank_id"
            />

            <NestedResourceList
              reference="guide_profiles"
            >
              <TextField
                source="bio"
              />
            </NestedResourceList>
                
            <NestedResourceList
              perPage={10}
              reference="guide_availabilities"
            >
              <LinkField
                source="occasion_id"
              />
              <DateField
                source="first_available_at"
              />
              <DateField
                source="last_available_at"
              />
              <EnumField
                source="weekday_name"
              />
              <StartAndEndTimeField />
              <BooleanField
                source="accept_codeverse_customers"
              />
              <BooleanField
                source="disabled"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="guide_schedulables"
            >
              <LinkField
                source="occasion_id"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="stripe_accounts"
            >
              <TextField
                source="stripe_user_id"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="checkr_accounts"
            >
              <TextField
                source="checkr_candidate_id"
              />
              <TextField
                source="status"
              />
            </NestedResourceList>

          </Tab>
        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="name"
        />
        <EnumInput
          source="scope"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <DateField
          source="created_at"
        />
        <TimeZoneField
          source="time_zone"
          addLabel
        />
        <ReferenceManyField
          label="Badge"
          reference="memberships"
          target="user_id"
        >
          <SingleFieldList>
            <TextField
              source="badge"
            />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          label="Pin"
          reference="memberships"
          target="user_id"
        >
          <SingleFieldList>
            <TextField
              source="pin"
            />
          </SingleFieldList>
        </ReferenceManyField>

        <NestedResourceList
          perPage={5}
          reference="projects"
        >
          <TextField
            source="name"
          />
          <ImageField
            source="screenshot"
          />
          <DateTimeField
            source="created_at"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    phone_numbers,
    health_records,
    learning_styles,
    user_addresses,
    payment_methods,
    how_heard_about,
    projects,
    memberships,
    participations,
    curriculum_accomplishments,
    mission_achievements,
    guide_preferences,
    friend_requests,
    followings,
    guide_blocks,
    guide_profiles,
    guide_availabilities,
    guide_schedulables,
    checkr_accounts
  },
};
