import Icon from '@material-ui/icons/ChatBubble';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../controllers';
import { DateTimeField, EnumField, LinkField, TextField } from '../../fields';
import { BooleanInput, DateTimeInput, EnumInput, JsonInput, ReferenceInput, TeamMemberSelectInput, TextInput } from '../../inputs';
import streaming_chat_message_approvals from './streaming_chat_message_approvals';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        {...props}
      >
        <ReferenceInput
          optionText={stream => `${stream.title} (${stream.channel})`}
          source="streaming_stream_id"
        />
        <TextInput
          source="message"
          required
        />
        <JsonInput
          source="metadata"
        />
        <DateTimeInput
          source="send_at"
        />
        <TeamMemberSelectInput
          source="user_id"
          required
        />
      </CreateButton>
    ),
    edit: props => (
      <EditDialog
        {...props}
      >
        <DateTimeInput
          source="send_at"
        />
        <EnumInput
          source="status"
        />
      </EditDialog>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <LinkField
          displaySource="title"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          reference="users"
          source="user_id"
        />
        <EnumField
          source="status"
        />
        <TextField
          source="message"
        />
        <DateTimeField
          source="send_at"
        />
        <DateTimeField
          source="created_at"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          displaySource="title"
          reference="streaming_streams"
          source="stream_id"
        />
        <LinkField
          reference="users"
          source="user_id"
        />
        <EnumField
          source="status"
        />
        <TextField
          source="message"
        />
        <DateTimeField
          source="send_at"
        />
      </ResourceShow>
    )
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
        <EnumInput
          source="status"
        />
      </Filter>
    ),
  },
  resources: {
    streaming_chat_message_approvals
  },
};
