import Icon from '@material-ui/icons/SpeakerNotes';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { EditDialog } from '../../../../../controllers';
import { EnumInput, RichTextInput, TextInput } from '../../../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ attendance_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          source="topic"
        />
        <TextInput
          source="heading"
          required
        />
        <RichTextInput
          source="detail"
          required
        />
      </CreateButton>
    ),
    edit: props => (
      <EditDialog
        {...props}
      >
        <EnumInput
          source="topic"
        />
        <TextInput
          source="heading"
          required
        />
        <RichTextInput
          source="detail"
          required
        />
      </EditDialog>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
