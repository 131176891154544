import Icon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { CommentInput, TeamMemberSelectInput } from '../../../inputs';

export default {
    Icon,
    dialogs: {
        create: props => (
          <CreateButton
            {...props}
            fixedValue={{
                    user_id: props.parentResource.id
            }}
          >
            <TeamMemberSelectInput
              label="Team Member"
              source="guide_id"
            />
            <CommentInput
              label="Why are you adding this block?"
              required
            />
          </CreateButton>
        ),
    },
    routes: {
    },
    components: {
    },
    resources: {
    },
};
