import Icon from '@material-ui/icons/Receipt';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter, ResourceShow } from '../../../../controllers';
import { LinkField, NumberField } from '../../../../fields';
import { BooleanInput, NumberInput, ReferenceInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <LinkField
          source="product_sku_trait_value_id"
        />
        <NumberInput
          source="quantity"
          required
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ invoice_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput
          label="Product"
          perPage={100}
          source="product_sku_trait_value_id"
          required
        />
        <NumberInput
          source="quantity"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
    show: props => (
      <ResourceShow
        {...props}
      >
        <LinkField
          source="product_sku_trait_value_id"
        />
        <NumberField
          label="Unit Price"
          source="unit_price"
        />
        <NumberField
          source="quantity"
          required
        />
        <NumberField
          label="Total Amount"
          source="amount"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
