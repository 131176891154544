import Icon from '@material-ui/icons/MoneyOff';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog } from '../../../../controllers';
import { EnumInput, NumberInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <NumberInput
          source="member_count"
          required
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ subscription_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          // only show current options (e.g., Nova, Supernova*), others have been deprecated and will cause errors
          choices={[
              {id: 'nova', name: 'Nova'},
              {id: 'supernova_starter', name: 'Supernova Starter'},
              {id: 'supernova_lite', name: 'Supernova Lite'},
              {id: 'supernova', name: 'Supernova'},
              {id: 'supernova_pro', name: 'Supernova Pro'},    
          ]}
          source="product"
        />
        <NumberInput
          source="member_count"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
