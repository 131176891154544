import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const NextVersionField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    { `v${record.major_version}.${record.minor_version} (${record.significance})` }
  </Typography>
);

NextVersionField.propTypes = {
  record: PropTypes.object,
};

NextVersionField.defaultProps = {
  addLabel: true,
  label: 'Next Version',
};

export default NextVersionField;

