import PropTypes from 'prop-types';
import React from 'react';
import { Fields } from 'redux-form';
import { SelectInput } from '.';

const StartAndEndTimeSelectInput = ({ start_hour, start_minute, end_hour, end_minute, ...props }) => (
  <SelectInput
    {...props}
    label="Time"
    source="_ignore"
    required
    resettable
    onChange={(a, value) => {
      const matches = value.match(/^(\d+):(\d+) - (\d+):(\d+)$/);
      start_hour.input.onChange(matches[1]);
      start_minute.input.onChange(matches[2]);
      end_hour.input.onChange(matches[3]);
      end_minute.input.onChange(matches[4]);
    }}
  />
);

StartAndEndTimeSelectInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  start_hour: PropTypes.number,
  start_minute: PropTypes.number,
  end_hour: PropTypes.number,
  end_minute: PropTypes.number,
};

const StartAndEndTimeSelectInputField = props => (
  <Fields
    {...props}
    component={StartAndEndTimeSelectInput}
    names={['start_hour', 'start_minute', 'end_hour', 'end_minute']}
    required
  />
);


StartAndEndTimeSelectInputField.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};


export default StartAndEndTimeSelectInputField;
