import Icon from '@material-ui/icons/Face';
import InvalidateIcon from '@material-ui/icons/HighlightOff';
import LostIcon from '@material-ui/icons/ThumbDown';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { EditDialog, ResourceList, ResourceShow } from '../../../controllers';
import leadSourceCodes from '../../../data/leadSourceCodes';
import { AddressField, ArrayOfStringsField, DateField, DateTimeField, EnumField, TextField } from '../../../fields';
import { CountrySelectInput, DateInput, EnumInput, InputGroup, ReferenceInput, SelectArrayInput, StateSelectInput, TextInput } from '../../../inputs';

export default {
  Icon,
   menuItems: [
    {
      name: 'Mark Lost',
      type: 'component',
      if: lead => lead.stage != 'not_valid' && lead.lost_code == null,
      icon: LostIcon,
      Component: props => (
        <EditDialog
          {...props}
          hideComment
        >
          <EnumInput
            source="lost_code"
          />
          <TextInput
            defaultValue="Lost"
            source="_comment"
          />
        </EditDialog>
      ),
    },
    {
      type: 'update',
      name: 'Mark Invalid (Test Data)',
      icon: InvalidateIcon,
      if: lead => lead.stage != 'not_valid',
      updateAttributes: {stage: 'not_valid', _comment: 'invalid lead'},
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Lead Status"
        >
          <EnumInput
            source="stage"
          />
          <EnumInput
            source="lost_code"
          />
          <DateInput
            source="revisit_at"
          />
        </InputGroup>

        <InputGroup
          header="Contact Information"
        >
          <TextInput
            source="name"
          />
          <TextInput
            source="email"
          />
          <TextInput
            source="phone"
          />
        </InputGroup>

        <InputGroup
          header="Source"
          subHeader="Optional information about where this lead came from"
        >
          <SelectArrayInput
            choices={leadSourceCodes.choices}
            source="source_codes"
          />
        </InputGroup>

        <InputGroup
          header="Location"
          subHeader="Optoinal infomation about this lead's location."
        >
          <ReferenceInput
            label="Closest Studio"
            source="location_id"
          />
          <TextInput
            source="address1"
          />
          <TextInput
            source="address2"
          />
          <TextInput
            source="city"
          />
          <StateSelectInput />
          <TextInput
            source="zip_code"
          />
          <CountrySelectInput />
        </InputGroup>
      </EditDialog>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          sortable={false}
          source="name"
        />
        <EnumField
          sortable={false}
          source="stage"
        />
        <DateTimeField
          source="created_at"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <TextField
          source="name"
        />
        <TextField
          source="email"
        />
        <TextField
          source="phone"
        />
        <AddressField />
        <TextField
          source="http_referer"
        />
        <EnumField
          source="stage"
        />
        <EnumField
          source="lost_code"
        />
        <ArrayOfStringsField
          source="source_codes"
        />
        <DateTimeField
          source="created_at"
          addLabel
        />
        <DateTimeField
          source="updated_at"
          addLabel
        />
        <TextField
          source="first_journey"
        />      
      </ResourceShow>
    ),
  },
  components: {
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="email"
        />
        <TextField
          source="phone"
        />
        <AddressField />
        <TextField
          source="http_referer"
        />
        <EnumField
          source="stage"
        />
        <EnumField
          source="lost_code"
        />
        <ArrayOfStringsField
          source="source_codes"
        />
        <DateField
          source="created_at"
        />
        <DateField
          source="updated_at"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
