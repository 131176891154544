import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../../controllers';
import { LinkField, TextField } from '../../../../fields';
import { BooleanInput, NestedReferenceInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="value"
        />
        <NestedReferenceInput
          resourcePath={`/library_objects/${props.parentResource.parentResource.parentResource.id}`}
          source="library_object_constant_id"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ library_object_configuration_template_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          source="library_engine_configuration_option_id"
        />
        <TextInput
          source="value"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="value"
        />
        <LinkField
          source="library_object_constant_id"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="value"
        />
        <LinkField
          source="library_object_constant_id"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
