import Icon from '@material-ui/icons/Loyalty';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { ResourceList } from '../../../controllers';
import { TextField } from '../../../fields';
import { ReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ coupon_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput
          source="occasion_id"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          sortable={false}
          source="name"
        />
      </ResourceList>
    ),
  },
  components: {
  },
  resources: {
  },
};
