import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter } from '../../../../controllers';
import { EnumField, NumberField } from '../../../../fields';
import { EnumInput, NumberInput } from '../../../../inputs';

export default {
  Icon,
  menuItems: [
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <NumberInput
          source="position"
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="minutes"
          required
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ skill_id: props.parentResource.id }}
        {...props}
      >
        <NumberInput
          source="position"
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="minutes"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <NumberField
          source="minutes"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="position"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NumberField
          source="minutes"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="position"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
