import Icon from '@material-ui/icons/Grade';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { NumberInput, ReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => ( <CreateButton
      fixedValue={{    user_id: props.parentResource.id    }}
      {...props}
                       >
      <ReferenceInput
        filter={{processor: 'manual' }}
        source="curriculum_activity_id"
        required
      />
      <NumberInput
        source="count"
        required
      />
    </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
