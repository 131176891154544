import React from 'react';
import { BooleanInput, DateInput, EnumInput, InputGroup, NumberInput, ReferenceInput, TextInput, TimeZoneSelectInput } from '.';

export const UserInformationInput = () => (
  <>
    <InputGroup
      header="Contact Information"
      subHeader="Name and email information"
    >
      <TextInput
        source="name"
        required
      />
      <TextInput
        source="email"
      />
      <EnumInput
        source="gender"
      />
      <NumberInput
        source="grade"
      />
      <DateInput
        source="date_of_birth"
      />

      <EnumInput
        source="scope"
      />
    </InputGroup>

    <InputGroup
      header="Ambassador Program"
      subHeader="Members of our ambassador program are typically partners, rather than parents. They use different referral codes than regular customers, and are paid in cash for sending us new customers"
    >
      <BooleanInput
        source="ambassador"
      />
    </InputGroup>

    <InputGroup
      header="User Preferences"
      subHeader="Indiciate if a customer has requested not to be contacted."
    >
      <TimeZoneSelectInput />
      <EnumInput
        source="t_shirt_size"
      />
      <EnumInput
        source="difficulty_level"
      />
    </InputGroup>

    <InputGroup
      header="Communication Preferences"
      subHeader="Indiciate if a customer has requested not to be contacted."
    >
      <BooleanInput
        source="do_not_phone_call"
      />
      <BooleanInput
        source="do_not_email"
      />
      <BooleanInput
        source="do_not_text_message"
      />
    </InputGroup>

    <InputGroup
      header="Marketing"
    >
      <ReferenceInput
        label="How did this user hear about us?"
        source="how_heard_about_id"
      />
    </InputGroup>
  </>
)


UserInformationInput.defaultProps = {
    sort: false,
    sortable: false,
    alwaysOn: true
};

export default UserInformationInput;

