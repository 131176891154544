import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, NestedResourceList, ResourceList, ResourceShow } from '../../../controllers';
import { BooleanField, EnumField, LinkField, NumberField, TextField } from '../../../fields';
import { BooleanInput, EnumInput } from '../../../inputs';
import badge_comparables from './badge_comparables';
import badge_conditions from './badge_conditions';

export default {
  Icon,
  menuItems: [],
  dialogs: {
    edit: (props) => (
      <EditDialog
        {...props}
      >
        <EnumInput
          source="comparator"
        />
        <BooleanInput
          source="single_project"
        />
      </EditDialog>
    ),
    create: (props) => (
      <CreateButton
        fixedValue={{ badge_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          source="comparator"
        />
        <BooleanInput
          source="single_project"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: (props) => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="comparator"
        />
        <BooleanField
          source="single_project"
        />
      </ResourceList>
    ),
    show: (props) => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <EnumField
          source="comparator"
        />
        <BooleanField
          source="single_project"
          addLabel
        />
        <NestedResourceList
          reference="badge_comparables"
        >
          <LinkField
            reference="curriculum_activities"
            source="activity_id"
          />
          <NumberField
            source="count"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="badge_conditions"
        >
          <EnumField
            source="comparator"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {

  },
  resources: {
    badge_conditions,
    badge_comparables,
  },
};

