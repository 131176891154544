import Icon from '@material-ui/icons/SettingsApplications';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow } from '../../../controllers';
import { BooleanField, TextField } from '../../../fields';
import { BooleanInput, TextInput } from '../../../inputs';
import library_engine_configuration_options from './library_engine_configuration_options';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextField
          source="name"
        />
        <BooleanInput
          source="is_unique"
        />
        <BooleanInput
          source="is_required"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ library_engine_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="name"
        />
        <BooleanInput
          source="is_unique"
        />
        <BooleanInput
          source="is_required"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <BooleanField
          source="is_unique"
        />
        <BooleanField
          source="is_required"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <BooleanField
          source="is_required"
        />
        <BooleanField
          source="is_unique"
        />
        <NestedResourceList
          reference="library_engine_configuration_options"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    library_engine_configuration_options,
  },
};
