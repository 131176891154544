export default (previousState = null, { type, payload, meta }) => {
  if (type === 'RA/CRUD_CREATE_SUCCESS') {
    return {
      id: payload.data.id,
      resource: meta.resource,
      timestamp: Date.now(),
    }
  }
  return previousState;
}
