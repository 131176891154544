import AgreeIcon from '@material-ui/icons/Check';
import DisagreeIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/icons/FormatAlignRight';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { EditDialog } from '../../../../../controllers';
import { BooleanInput, NestedReferenceInput } from '../../../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      type: 'update',
      name: 'Agree',
      icon: AgreeIcon,
      if: version_flag => !version_flag.agreed && !version_flag.disagreed,
      updateAttributes: {agreed: true},
    },
    {
      type: 'update',
      name: 'Disagree',
      icon: DisagreeIcon,
      if: version_flag => !version_flag.agreed && !version_flag.disagreed,
      updateAttributes: {disagreed: true},
    },
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <BooleanInput 
          source="agreed"
        />
        <BooleanInput 
          source="disagreed"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ 
          user_id: self.localStorage.user_id 
        }}
        {...props}
      >
        <NestedReferenceInput
          optionText={version => `Created ${moment(version.created_at).format('lll')}`}
          source="version_id"
        />
        <BooleanInput 
          source="agreed"
        />
        <BooleanInput 
          source="disagreed"
        />
        
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
