import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { Children } from 'react';
import { List } from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionsButton } from '../buttons';
import Title from '../layout/Title';
import Datagrid from '../overrides/Datagrid';
import { PaginationIfAvailable } from './PaginationIfAvailable';

const styles = theme => ({
  actionButtonCell: {
    textAlign: 'right',
  },
});

const ResourceList = ({
  actionName,
  children,
  classes,
  components,
  dialogs,
  menuItems,
  resourcePath,
  parentResource,
  resourceIds,
  RowStyle,
  resources,
  routes,
  // note, we use onRowClick instead of rowClick so it one table doesnt propogate this value down to nested components
  onRowClick,
  resource,
  record = {},
  mostRecentCreated,
  ...props
}) => {

  // default actionName
  actionName = actionName || 'default';
  // if there are multiple list pages, then create tabs for them
  const multipleListPages = typeof routes.list === 'object';
  const basePath = resourcePath[resourcePath.length - 1].basePath;
  let selectedTab = 0;
  const currentUrl = props.location.pathname;
  const listPages = multipleListPages && Object.keys(routes.list).map((c, i) => {
    const ret = {
      title: (routes.list[c].title || 'missing title'),
      url: (c === 'default' ? basePath : `${basePath}/${c}`),
    };
    if (ret.url === currentUrl) {
      selectedTab = i;
    }
    return ret;
  });

  // the additional page components
  let { Actions, Aside, Exporter, BulkActionButtons, Filter, Expand } = components;
  // if available, then override the component selections with their index page specific version
  if (multipleListPages) {
    Actions = routes.list[actionName].Actions || Actions;
    Aside = routes.list[actionName].Aside || Aside;
    Exporter = routes.list[actionName].Exporter || Exporter;
    BulkActionButtons = routes.list[actionName].BulkActionButtons || BulkActionButtons;
    Filter = routes.list[actionName].Filter || Filter;
    Expand = routes.list[actionName].Expand || Expand;
  }

  const DefaultRowStyle = (record) => {
    if (record.deleted_at) {
      return {
        backgroundColor: '#FFE6E5',
        textDecoration: 'line-through',
      }
    }
    else if (mostRecentCreated && record.id == mostRecentCreated.id && (Date.now() - mostRecentCreated.timestamp) / 1000 < 5) {
      return {
        backgroundColor: '#e5e3ff',
        textDecoration: 'inherit',
      }
    }
    return {
      backgroundColor: 'white',
      textDecoration: 'inherit',
    }
  }

  return (
    <>
      {multipleListPages &&
        (
          <Tabs
            value={selectedTab}
          >
            { listPages.map(link => (
              <Tab
                component={Link}
                key={link.url}
                label={link.title}
                to={link.url}
              />
            ),
            )}
            ;
          </Tabs>
        )}
      <List
        {...props}
        actions={Actions ? <Actions /> : null}
        aside={Aside ? <Aside /> : false}
        bulkActionButtons={BulkActionButtons ? <BulkActionButtons /> : false}
        exporter={Exporter ? <Exporter /> : false}
        filter={Object.assign({}, (props.filter || {}), {
          basePath: parentResource && parentResource.id ? `/${parentResource.resource}/${parentResource.id}` : '',
        })}
        filterDefaultValues={props.filterDefaultValues || {}}
        filters={Filter ? (
          <Filter
            parentResource={parentResource}
            resources={resources}
          />
        ): null}
        pagination={<PaginationIfAvailable />}
        perPage={50}
        record={record}
        resource={resource}
        sort={props.sort || {}}
        title={(
          <Title
            {...props}
            record={record}
            resource={resource}
            resourcePath={resourcePath}
          />
        )}
        hasShow
      >
        <Datagrid
          expand={Expand ? (
            <AddParentResource>
              <Expand
                resources={resources}
              />
            </AddParentResource>
          ) : null}
          rowClick={onRowClick || routes.show && 'show' || Expand && 'expand'}
          rowStyle={RowStyle || DefaultRowStyle}
        >
          {Children.toArray(children).map(child => React.cloneElement(child, { parentResource }))}
          <ActionsButton
            cellClassName={classes.actionButtonCell}
            dialogs={dialogs}
            menuItems={menuItems}
            parentResource={parentResource}
            textAlign="right"
          />
        </Datagrid>
      </List>
    </>
  );
};

const AddParentResource = ({ children, parentResource, ...props }) => {
  const newParentResource = {
    basePath: props.basePath,
    resource: props.resource,
    id: props.record.id,
    record: props.record,
    parentResource,
  }
  return React.cloneElement(Children.only(children), { parentResource: newParentResource, ...props })
}

function mapStateToProps(state, props) {
  return {
    mostRecentCreated: state.mostRecentCreated || {}
  };
}

const mapDispatchToProps = {
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(
  ResourceList,
));

