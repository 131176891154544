import AssignIcon from '@material-ui/icons/Input';
import Icon from '@material-ui/icons/Textsms';
import React from 'react';
import { ChipField, SingleFieldList } from 'react-admin';
import TextMessageCard from '../../components/TextMessageCard';
import { EditDialog, Filter, ResourceList, SimpleShowLayout } from '../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, ReferenceManyField, TextMessageBodyField } from '../../fields';
import { BooleanInput, EnumInput, SearchInput, StaffTeamSelectInput } from '../../inputs';

const HandledRowStyle = record => ({
  opacity: record.handled_at ? '0.3' : '1',
});

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'handled'
    },
    {
      type: 'toggle',
      attribute: 'claimed'
    },
    {
      name: 'Assign',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
          hideComment
        >
          <StaffTeamSelectInput
            source="staff_team_id"
          />
        </EditDialog>
      ),
    },
  ],
  dialogs: {
  },
  routes: {
    list: {
      default: {
        title: 'Unhandled Text Messages',
        Controller: props => (
          <ResourceList
            RowStyle={HandledRowStyle}
            filter={{ 
              template: 'inbound', 
              handled: false,
              claimed: false,
            }}
            {...props}
          >
            <TextMessageBodyField />
            <ReferenceManyField
              label="Org Status"
              reference="organizations"
              sortable={false}
              target="text_messages"
            >
              <SingleFieldList
                linkType="show"
              >
                <ChipField
                  source="status"
                />
              </SingleFieldList>
            </ReferenceManyField>
            <DateTimeField
              label="Received"
              source="created_at"
              sortable
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
      },
      claimed: {
        title: 'Claimed Messages',
        Controller: props => (
          <ResourceList
            filter={{ 
              template: 'inbound',
              handled: false,
              claimed: true,
            }}
            {...props}
          >
            <EnumField
              source="status"
            />
            <EnumField
              source="template"
            />
            <TextMessageBodyField />
            <DateTimeField
              source="created_at"
              sortable
            />
            <BooleanField
              source="handled"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
      },
      all: {
        title: 'All Text Messages',
        Controller: props => (
          <ResourceList
            {...props}
          >
            <EnumField
              source="status"
            />
            <EnumField
              source="template"
            />
            <TextMessageBodyField />
            <DateTimeField
              source="created_at"
              sortable
            />
            <BooleanField
              source="handled"
            />
            <LinkField
              reference="users"
              source="claimed_by_id"
            />
            <LinkField
              label="Assigned to"
              source="staff_team_id"
            />
          </ResourceList>
        ),
        Filter: (props) => {
          return (
            <Filter
              {...props}
            >
              <StaffTeamSelectInput
                label="Assigned to"
                source="staff_team_id"
              />
              <SearchInput
                label="Search"
                source="q"
              />
              <EnumInput
                source="status"
              />
              <EnumInput
                source="template"
              />
              <BooleanInput
                source="handled"
              />
              <BooleanInput
                source="claimed"
              />
            </Filter>
          );
        },
      },
    },
  },
  components: {
    Filter: (props) => {
      return (
        <Filter
          {...props}
        >
          <StaffTeamSelectInput
            label="Assigned to"
            source="staff_team_id"
          />
          <SearchInput
            label="Search"
            source="q"
          />
          <EnumInput
            source="status"
          />
        </Filter>
      );
    },
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <ReferenceManyField
          label="Organizations"
          reference="organizations"
          sortable={false}
          target="text_messages"
        >
          <SingleFieldList
            linkType="show"
          >
            <ChipField
              source="name"
            />
          </SingleFieldList>
        </ReferenceManyField>
        <TextMessageCard
          id={props.id}
          inbound={props.record.template === 'inbound'}
          record={props.record}
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
  },
};
