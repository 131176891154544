import Icon from '@material-ui/icons/SpeakerNotes';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { NoteCard } from '../../../components';
import { EditDialog, Filter, NestedResourceList, ResourceList, SimpleShowLayout } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, FileField, ImageField, RichTextField, TextField } from '../../../fields';
import { BooleanInput, EnumInput, InputGroup, NestedReferenceInput, RichTextInput, SearchInput, TextInput } from '../../../inputs';
import note_attachments from './note_attachments';


export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'pinned'
    }
  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="What is this note about?"
        >
          <TextInput
            source="heading"
            required
          />
          <EnumInput
            choices={[
              {id: 'comments', name: 'Comments'},
              {id: 'progress', name: 'Progress'},
              {id: 'goals', name: 'Goals'},
              {id: 'feedback', name: 'Feedback'},
              {id: 'concerns', name: 'Concerns'},
              {id: 'sales', name: 'Sales'},
            ]}
            source="topic"
          />
        </InputGroup>
        <InputGroup
          fullWidth
        >
          <RichTextInput
            label=""
            source="detail"
            required
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="What is this note about?"
        >
          <EnumInput
            // temporarily override enum list to prevent accidental use of parent_update note type
            choices={[
              {id: 'comments', name: 'Comments'},
              {id: 'progress', name: 'Progress'},
              {id: 'goals', name: 'Goals'},
              {id: 'feedback', name: 'Feedback'},
              {id: 'concerns', name: 'Concerns'},
              {id: 'sales', name: 'Sales'},
            ]}
            source="topic"
          />  
          <TextInput
            source="heading"
            required
          />
          <NestedReferenceInput
            source="user_id"
          />
        </InputGroup>
        <InputGroup
          fullWidth
        >
          <RichTextInput
            label=""
            source="detail"
            required
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="topic"
        />
        <TextField
          source="heading"
        />
        <RichTextField
          source="detail"
        />
        <TextField
          label="Author"
          source="_meta_author_name"
        />
        <DateTimeField
          source="created_at"
        />
        <DateTimeField
          source="pinned_at"
        />
        <BooleanField
          source="pinned"
        />
      </ResourceList>
    ),
  },
  components: {
     Filter: props => (
       <Filter
         {...props}
       >
         <SearchInput
           label="Search"
           source="q"
         />
         <EnumInput
           source="topic"
         />
         <BooleanInput
           source="pinned"
         />
         <BooleanInput
           source="deleted"
         />
       </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NoteCard 
          id={props.id}
        />

        <NestedResourceList
          reference="note_attachments"
        >
          <TextField
            source="description"
          />
          <FileField
            label="Attachment"
            source="file"
            target="_blank"
            title="View"
          />
          <ImageField
            label="Preview"
            source="file"
          />
    
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    note_attachments,
  },
};
