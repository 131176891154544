import Icon from '@material-ui/icons/Phone';
import React from 'react';
import { Filter, NestedResourceList, ResourceList, ResourceShow } from '../../../controllers';
import { DateTimeField, LinkField, NumberField, TextField, UrlField } from '../../../fields';
import { BooleanInput, SearchInput } from '../../../inputs';





export default {
  Icon,
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <DateTimeField
          source="created_at"
        />
        <NumberField
          source="duration"
        />
        <TextField
          source="caller_name"
        />
        <TextField
          source="termination_status"
        />
        <UrlField
          source="recording_uri"
        />
        <LinkField
          source="phone_call_id"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <DateTimeField
          source="created_at"
        />
        <NumberField
          source="duration"
        />
        <TextField
          source="status"
        />
        <TextField
          source="caller_name"
        />
        <TextField
          source="termination_status"
        />
        <UrlField
          source="recording_uri"
        />
        <LinkField
          source="phone_call_id"
        />

        <NestedResourceList
          basePath={props.parentResource.basePath}
          reference="notes"
        >
          <DateTimeField
            source="created_at"
          />
          <TextField
            source="topic"
          />
          <TextField
            source="heading"
          />
          <TextField
            source="detail"
          />
        </NestedResourceList>

      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
