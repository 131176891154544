import pluralize from 'pluralize';
import React, { Children } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import resources from '../resources/';


function error(message) {
  throw message;
}

// note : we're breaking out staticContext just to strip it off the props object
const Resource = ({ children, staticContext, ...props }) => {

  // follow the list of parentResources to build an array representing
  // the path to this resource
  const resourcePath = [];
  let r = props;
  while (r) {
    resourcePath.unshift({
      resource: r.resource,
      basePath: r.basePath,
      id: r.id,
    });
    r = r.parentResource;
  }

  // add convenient pointers to each member of the resource path array
  for (let i = 0; i < resourcePath.length; i++) {
    resourcePath[i].next = resourcePath[i + 1];
    resourcePath[i].previous = resourcePath[i - 1];
  }

  // fetch the resource at the location represented by this route/path
  let resource = resources;
  resourcePath.forEach((resourcePathElement) => {
    resource = resource.resources ? resource.resources[resourcePathElement.resource] : error(`There is no resource called ${resource.resource} at ${resourcePath.map(resrc => resrc.resource).join('.')}`);
  });

  // build a convenience object which contains the key and id for each parent resource
  const resourceIds = {};
  // we only want the nearest parent with an id
  const nearestParentResourceWithId = resourcePath.filter(rP => rP.id).reverse()[0];
  if (nearestParentResourceWithId) {
    resourceIds[`${pluralize.singular(nearestParentResourceWithId.resource)}_id`] = nearestParentResourceWithId.id;
  }

  const routes = resource.routes || {};
  const dialogs = resource.dialogs || {};
  const menuItems = resource.menuItems || [];
  const finalProps = Object.assign({}, props, {
    resources: (resource.resources || {}),
    components: (resource.components || {}),
    dialogs,
    menuItems,
    routes,
    hasCreate: ('create' in dialogs),
    hasEdit: ('edit' in dialogs),
    hasShow: ('show' in routes),
    hasList: ('list' in routes),
    resourcePath,
    resourceIds,
  });

  // return breadcrumbs and the page controller
  return Children.map(children, (child) => {
    return React.cloneElement(child, finalProps);
  });
};

/*
  Recursively parse nested routes

  Top level resources have routes such as:
    Occasion Model
      list:   /occasions
      create: /occasions/create
      edit:   /occasions/occasion_id
      show:   /occasions/occasion_id/show

  A child resource may be nested within a route, such as:
    Slots for a specific Occasion Model
      list:   /occasions/occasion_id/slots
      create: /occasions/occasion_id/slots/create
      edit:   /occasions/occasion_id/slots/occasion_id
      show:   /occasions/occasion_id/slots/occasion_id/show

*/
const uuidSegment = ':id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})';
const pageSegment = ':page([a-z]+[_a-z]*)';
const resourceSegment = ':resource([a-z]+[_a-z]*)';

const Routes = ({ children, ...props }) => {
  const { parentResource } = props;
  const matchUrl = props.match ? props.match.url : '';
  const basePath = parentResource ? `${parentResource.basePath}/${parentResource.id}` : '';
  return (
    <Switch>

      {
        /*
          react admin has the create path as /resource/create but we
          use dialog boxes instead, redirect onto the list page
        */
      }
      <Route
        path={`${matchUrl}/${resourceSegment}/create`}
        render={renderProps =>
          (<Redirect
            to={`${basePath}/${renderProps.match.params.resource}`}
          />)}
        exact
      />

      <Route
        path={`${matchUrl}/${resourceSegment}/${uuidSegment}/show`}
        render={renderProps =>
          (<Resource
            {...renderProps}
            action="show"
            basePath={`${basePath}/${renderProps.match.params.resource}`}
            id={renderProps.match.params.id}
            parentResource={parentResource}
            resource={renderProps.match.params.resource}
          >
            {children}
          </Resource>)}
      />

      {
        /*
          react admin has the edit path as /resource/id but we
          use dialog boxes instead, redirect onto the show page
        */
      }
      <Route
        path={`${matchUrl}/${resourceSegment}/${uuidSegment}`}
        render={renderProps =>
          (<Redirect
            to={`${basePath}/${renderProps.match.params.resource}/${renderProps.match.params.id}/show`}
          />)}
        exact
      />

      <Route
        path={`${matchUrl}/${resourceSegment}`}
        render={renderProps =>
          (<Resource
            {...renderProps}
            action="list"
            basePath={`${basePath}/${renderProps.match.params.resource}`}
            parentResource={parentResource}
            resource={renderProps.match.params.resource}
          >
            {children}
          </Resource>)}
        exact
      />

      {
        /*
          this route matches a nested resource, and calls this same
          component recursively so we can handle X nested resources
        */
      }
      <Route
        path={`${matchUrl}/${resourceSegment}/${uuidSegment}`}
        render={renderProps =>
          (<Routes
            {...renderProps}
            parentResource={Object.assign({}, {
              id: renderProps.match.params.id,
              resource: renderProps.match.params.resource,
              basePath: `${basePath}/${renderProps.match.params.resource}`,
            }, parentResource ? { parentResource } : {})}
          >
            {children}
          </Routes>)}
      />

      {
        /*
          alternative (multiple) index pages
        */
      }
      <Route
        path={`${matchUrl}/${resourceSegment}/${pageSegment}`}
        render={renderProps =>
          (<Resource
            {...renderProps}
            action="list"
            actionName={renderProps.match.params.page}
            basePath={`${basePath}/${renderProps.match.params.resource}`}
            parentResource={parentResource}
            resource={renderProps.match.params.resource}
          >
            {children}
          </Resource>)}
        exact
      />
    </Switch>
  );
};

export default Routes;

