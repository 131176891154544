import React from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import ResourceRouter from 'controllers/ResourceRouter';
import ContentHeader from 'layout/ContentHeader';

const Routes = (props) => {
  const { routes, resourcePath, action, actionName } = props;
  let Controller = routes[action];
  // if there is more than one possible page for this action, then the action will be an
  // object with one key per page
  if (typeof Controller === 'object') {
    if (typeof Controller[actionName || 'default'] !== 'object') {
      throw new Error(`no controller configuration object found for ${action}.${actionName || 'default'} at ${resourcePath.join('.')}`);
    }
    Controller = Controller[actionName || 'default'].Controller;
    if (typeof Controller !== 'function') {
      throw new Error(`no valid controller found for ${action}.${actionName || 'default'} at ${resourcePath.join('.')}`);
    }
  }
  // ensure a controller exists for this action
  if (typeof Controller !== 'function') {
    throw new Error(`no controller found for ${action} at ${resourcePath.map(r => r.resource).join('.')}`);
  }
  return (
    <>
      <ContentHeader
        {...props}
      />
      <Controller
        {...props}
      />
    </>
  );
};

// react admin expects an array of routes
export default [
  <Switch>
    <Route
      path="/"
      render={() =>
        (<Redirect
          to="/organizations"
        />)}
      exact
    />
    <ResourceRouter>
      <Routes />
    </ResourceRouter>
  </Switch>,
];

