// in src/bitcoinSaga.js
import { put, select, takeEvery } from 'redux-saga/effects';

export const currentFilteredResource = (state) => state.currentFilteredResource;

export default function* clearFiltersOnRouteChange() {
  yield takeEvery('@@router/LOCATION_CHANGE', function* () {
    let currentFilteredResourceName = yield select(currentFilteredResource)
    yield put({
      type: 'RA/CRUD_CHANGE_LIST_PARAMS',
      payload: {
        perPage: 50,
        page: 1,
        filter: {},
      },
      meta: {
        resource: currentFilteredResourceName,
        clear: true
      }
    })
  })
}
