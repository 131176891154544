import Icon from '@material-ui/icons/Event';
import React from 'react';
import { Filter, ResourceList } from '../../../controllers';
import { BookingStatusField, DateTimeField, LinkField, StartAndEndTimeField } from '../../../fields';
import { DateInput, NumberInput, ReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        filterDefaultValues={{ date: new Date().toISOString().substr(0, 10), status: 'active' }}
        rowClick={(booking_id, basePath, record) => {
          return `/organizations/${record.organization_id}/bookings/${booking_id}/show`;
        }}
        {...props}
      >
        <LinkField
          parentSource="organization_id"
          source="user_id"
        />
        <LinkField
          displaySource="email"
          label="Email"
          parentSource="organization_id"
          source="user_id"
        />
        <LinkField
          source="occasion_id"
        />
        <DateTimeField
          source="first_visit_at"
        />
        <DateTimeField
          source="last_visit_at"
        />
        <StartAndEndTimeField />
        <BookingStatusField />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <DateInput
          label="Date"
          source="date"
        />
        <ReferenceInput
          source="occasion_id"
          allowEmpty
        />
        <BookingStatusField />
        <NumberInput
          label="Start Hour"
          source="start_hour"
        />
        <NumberInput
          label="End Hour"
          source="end_hour"
        />
      </Filter>
    )
  },
  resources: {
  },
};
