import Icon from '@material-ui/icons/GroupWork';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { Filter, ResourceList } from '../../../controllers';
import { BooleanField, DateField, EnumField } from '../../../fields';
import { BooleanInput, CommentInput, EnumInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          // limit options because 'Subscription' and 'Overage' credits should be created automatically
          choices={[
            {id: 'adjustment', name: 'Adjustment'},
            {id: 'promotion', name: 'Promotion'},
            {id: 'loyalty', name: 'Loyalty'},
          ]}
          source="source"
        />
        <CommentInput
          label="Why are you adding this credit?"
          required
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="source"
        />
        <DateField
          source="created_at"
        />
        <BooleanField
          source="unused"
        />
        <DateField
          source="used_at"
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <EnumInput
          source="source"
        />
        <BooleanInput
          label="Only Show Used"
          source="used"
        />
        <BooleanInput
          label="Only Show Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
