import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { Filter, ResourceList, ResourceShow } from '../../../../controllers';
import { TextField } from '../../../../fields';
import { BooleanInput, NestedReferenceInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ library_object_reaction_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          resourcePath={`/library_objects/${props.parentResource.parentResource.id}`}
          source="library_engine_module_id"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
