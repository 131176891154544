import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ExtendsEngineVersionField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.extends_engine_major_version === null && (
      ''
    )}
    {typeof record.extends_engine_major_version === 'number' && (
      <>
        v
        {record.extends_engine_major_version}
        .
        {record.extends_engine_minor_version}
      </>
    )}
  </Typography>
);

ExtendsEngineVersionField.propTypes = {
  record: PropTypes.object,
};

ExtendsEngineVersionField.defaultProps = {
  addLabel: true,
  label: 'Extends Engine Version',
};

export default ExtendsEngineVersionField;

