import Icon from '@material-ui/icons/FormatAlignLeft';

export default {
  Icon,
  dialogs: {
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
