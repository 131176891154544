import Icon from '@material-ui/icons/ContactPhone';
import TextMessageIcon from '@material-ui/icons/Textsms';
import React from 'react';
import { CreateButton } from '../../buttons';
import { CreateDialog, EditDialog } from '../../controllers';
import { DialingCodeSelectInput, LongTextInput, PhoneNumberInput, TextInput } from '../../inputs';

export default {
  Icon,
  menuItems: [
    {
      name: 'New Text Message',
      type: 'component',
      icon: TextMessageIcon,
      Component: props => (
        <CreateDialog
          {...props}
          fixedValue={{
            to_dialing_code: props.parentResource.record.dialing_code,
            to_phone_number: props.parentResource.record.phone_number,
          }}
          resource="text_messages"
        >
          <TextInput
            defaultValue="outbound"
            label="Template"
            source="template"
            disabled
            required
          />
          <LongTextInput
            source="body"
            fullWidth
            required
          />
        </CreateDialog>
      ),
    },
  ], dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <DialingCodeSelectInput />
        <PhoneNumberInput
          source="phone_number"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <DialingCodeSelectInput />
        <PhoneNumberInput />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
