import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const PhoneCallPartiesField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.from_phone_number_formatted && `from: ${record.from_phone_number_formatted}` || `to: ${record.to_phone_number_formatted}`}
  </Typography>
);

PhoneCallPartiesField.propTypes = {
  record: PropTypes.object,
};

PhoneCallPartiesField.defaultProps = {
  addLabel: true,
  label: 'Participants',
};

export default PhoneCallPartiesField;

