import Icon from '@material-ui/icons/Label';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter } from '../../../controllers';
import { BooleanInput, SearchInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="value"
        />
        <TextInput
          source="sku_code"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ product_trait_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="value"
        />
        <TextInput
          source="sku_code"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
