import Icon from '@material-ui/icons/Label';
import React from 'react';
import product_trait_values from './product_trait_values';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow } from '../../controllers';
import { TextField } from '../../fields';
import { BooleanInput, SearchInput, TextInput } from '../../inputs';







export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="sku_code"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="sku_code"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="sku_code"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="sku_code"
        />


        <NestedResourceList
          reference="product_trait_values"
        >
          <TextField
            source="value"
          />
          <TextField
            source="sku_code"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    product_trait_values,
  },
};
