// errorSagas.js, to be imported as a custom saga
import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE } from 'react-admin';
import { stopSubmit } from 'redux-form';
import { put, takeEvery } from 'redux-saga/effects';

const extractErrors = (errors) => {
  const errorObject = {};
  if (errors) {
    errors.forEach((error) => {
      const words = [];
      if (error.status === '400') {
        if (error.detail.substring(0, 19) === 'data[relationships]') {
          error.detail.replace(/\[(.+?)\]/g, ($0, $1) => { words.push($1); });
          errorObject[`${words[1]}_id`] = error.detail.split(']')[2].capitalize();
        } else if (error.detail.match(']')) {
          error.detail.replace(/\[(.+?)\]/g, ($0, $1) => { words.push($1); });
          errorObject[`${words[1]}`] = error.detail.split(']')[2].capitalize();
        } else {
          errorObject.general = error.detail;
        }
      } else if (error.status === '422') {
        const attribute = error.source.pointer.split('/').slice(-1).pop();
        errorObject[`${attribute}`] = error.detail;
      }
    });
  }
  return errorObject;
};

export function* crudCreateFailure(action) {
  const violations = extractErrors(action.error.errors);
  yield put(stopSubmit('record-form', violations));

}

export default function* errorSagas() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudCreateFailure);
  yield takeEvery(CRUD_UPDATE_FAILURE, crudCreateFailure);
}