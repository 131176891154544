import Icon from '@material-ui/icons/Person';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../controllers';
import { AddressField, AgeField, BooleanField, CurrentVersionField, DateField, DateTimeField, EditableProjectLinkField, EnumField, ImageField, LinkField, MeetingLinkField, NumberField, ReferenceField, RichTextField, ShareableProjectLinkField, SkillProgressField, StartAndEndTimeField, TextField, TimeZoneField } from '../../fields';
import { BooleanInput, DateInput, EnumInput, ImageInput, InputGroup, NumberInput, ReferenceInput, SearchInput, TextInput, TimeZoneSelectInput } from '../../inputs';
import projects from '../organizations/users/projects';
import phone_numbers from '../phone_numbers';
import bank_balance_adjustments from './bank_balance_adjustments';
import checkr_accounts from './checkr_accounts';
import curriculum_accomplishments from './curriculum_accomplishments';
import followings from './followings';
import friend_requests from './friend_requests';
import guide_availabilities from './guide_availabilities';
import guide_blocks from './guide_blocks';
import guide_preferences from './guide_preferences';
import guide_profiles from './guide_profiles';
import guide_ratings from './guide_ratings';
import guide_schedulables from './guide_schedulables';
import keyring_purchases from './keyring_purchases';
import memberships from './memberships';
import mission_achievements from './mission_achievements';
import notes from './notes';
import participations from './participations';
import payment_methods from './payment_methods';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Contact Information"
          subHeader="Name and email information"
        >
          <EnumInput
            source="scope"
          />
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="email"
          />
          {props.record.scope == 'child' && (
            <TextInput
              source="password"
            />
          )}
          <EnumInput
            source="gender"
          />
          <NumberInput
            source="grade"
          />
          <DateInput
            source="date_of_birth"
          />
          <EnumInput
            source="difficulty_level"
          />
        </InputGroup>

        <InputGroup
          header="Influenncer Program"
          subHeader="Members of our Influencer program "
        >
          <BooleanInput
            source="verified"
          />
        </InputGroup>

        <InputGroup
          header="Ambassador Program"
          subHeader="Members of our ambassador program are typically partners, rather than parents. They use different referral codes than regular customers, and are paid in cash for sending us new customers"
        >
          <BooleanInput
            source="ambassador"
          />
        </InputGroup>

        <InputGroup
          header="User Preferences"

        >
          <TimeZoneSelectInput
            choices={[
              {id: 'America/New_York', name: 'Eastern'},
              {id: 'America/Chicago', name: 'Central'},
              {id: 'America/Denver', name: 'Mountain'},
              {id: 'America/Los_Angeles', name: 'Pacific'},

              {id: null, name: 'Other Time Zones', disabled: true},
              {id: 'Africa/Lagos', name: 'Africa/Lagos'},
              {id: 'Africa/Johannesburg', name: 'Africa/Johannesburg'},
              {id: 'America/Bogota', name: 'America/Bogota'},
              {id: 'America/Juneau', name: 'America/Juneau'},
              {id: 'America/Phoenix', name: 'America/Phoenix'},
              {id: 'America/Guyana', name: 'America/Guyana'},
              {id: 'America/Halifax', name: 'America/Halifax'},
              {id: 'Asia/Baghdad', name: 'Asia/Baghdad'},
              {id: 'Asia/Colombo', name: 'Asia/Colombo'},
              {id: 'Asia/Tbilisi', name: 'Asia/Tbilisi'},
              {id: 'Asia/Hong_Kong', name: 'Asia/Hong_Kong'},
              {id: 'Asia/Jerusalem', name: 'Asia/Jerusalem'},
              {id: 'Asia/Kolkata', name: 'Asia/Kolkata'},
              {id: 'Asia/Kuwait', name: 'Asia/Kuwait'},
              {id: 'Asia/Seoul', name: 'Asia/Seoul'},
              {id: 'Asia/Shanghai', name: 'Asia/Shanghai'},
              {id: 'Asia/Singapore', name: 'Asia/Singapore'},
              {id: 'Asia/Taipei', name: 'Asia/Taipei'},
              {id: 'Asia/Tokyo', name: 'Asia/Tokyo'},
              {id: 'Australia/Perth', name: 'Australia/Perth'},
              {id: 'Australia/Sydney', name: 'Australia/Sydney'},
              {id: 'Europe/Copenhagen', name: 'Europe/Copenhagen'},
              {id: 'Europe/Dublin', name: 'Europe/Dublin'},
              {id: 'Europe/Helsinki', name: 'Europe/Helsinki'},
              {id: 'Europe/Istanbul', name: 'Europe/Istanbul'},
              {id: 'Europe/London', name: 'Europe/London'},
              {id: 'Europe/Madrid', name: 'Europe/Madrid'},
              {id: 'Europe/Oslo', name: 'Europe/Oslo'},
              {id: 'Europe/Paris', name: 'Europe/Paris'},
              {id: 'Europe/Stockholm', name: 'Europe/Stockholm'},
              {id: 'Europe/Zurich', name: 'Europe/Zurich'},
              {id: 'Pacific/Auckland', name: 'Pacific/Auckland'},
              {id: 'Pacific/Honolulu', name: 'Pacific/Honolulu'},
            ]}
          />
          <BooleanInput
            source="do_not_phone_call"
            addLabel
          />
          <BooleanInput
            source="do_not_email"
            addLabel
          />
          <BooleanInput
            source="do_not_text_message"
            addLabel
          />
        </InputGroup>

        <InputGroup
          header="Avatar or Photo"
          subHeader="Choose an avatar or upload a photo for this user."
        >
          <ReferenceInput
            reference="avatars"
            source="avatar_id"
          />
          <ImageInput
            label="photo"
            source="photo"
          />
        </InputGroup>

        <InputGroup
          header="Marketing"
        >
          <EnumInput
            source="t_shirt_size"
          />
          <ReferenceInput
            label="How did this user hear about us?"
            source="how_heard_about_id"
          />
        </InputGroup>

        <InputGroup
          header="Administration"
          subHeader="If this user is a duplicate of another already in the system, select the original user here. This will automatically delete the current user."
        >
          <ReferenceInput
            filer={{has_email: "true"}}
            optionText={users => `${users.name} (${users.email}) - Created ${moment(users.created_at).format('lll')}`}
            reference="users"
            source="duplicate_of_id"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="Contact Information"
          subHeader="Name and email information"
        >
          <ReferenceInput
            label="Target Organization"
            optionText={organizations => `${organizations.name} (${organizations.id}) - Created ${moment(organizations.created_at).format('lll')}`}
            shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
            source="organization_id"
            required
          />
          <EnumInput
            source="scope"
          />
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="email"
          />
          <EnumInput
            source="gender"
          />
          <NumberInput
            source="grade"
          />
          <DateInput
            source="date_of_birth"
          />
          <EnumInput
            defaultValue="beginner"
            source="difficulty_level"
          />
        </InputGroup>

        <InputGroup
          header="Influenncer Program"
          subHeader="Members of our Influencer program "
        >
          <BooleanInput
            source="verified"
          />
        </InputGroup>

        <InputGroup
          header="Ambassador Program"
          subHeader="Members of our ambassador program are typically partners, rather than parents. They use different referral codes than regular customers, and are paid in cash for sending us new customers"
        >
          <BooleanInput
            source="ambassador"
          />
        </InputGroup>

        <InputGroup
          header="User Preferences"
        >
          <TimeZoneSelectInput
            choices={[
              {id: 'America/New_York', name: 'Eastern'},
              {id: 'America/Chicago', name: 'Central'},
              {id: 'America/Denver', name: 'Mountain'},
              {id: 'America/Los_Angeles', name: 'Pacific'},

              {id: null, name: 'Other Time Zones', disabled: true},
              {id: 'Africa/Lagos', name: 'Africa/Lagos'},
              {id: 'Africa/Johannesburg', name: 'Africa/Johannesburg'},
              {id: 'America/Bogota', name: 'America/Bogota'},
              {id: 'America/Juneau', name: 'America/Juneau'},
              {id: 'America/Phoenix', name: 'America/Phoenix'},
              {id: 'America/Guyana', name: 'America/Guyana'},
              {id: 'America/Halifax', name: 'America/Halifax'},
              {id: 'Asia/Baghdad', name: 'Asia/Baghdad'},
              {id: 'Asia/Colombo', name: 'Asia/Colombo'},
              {id: 'Asia/Tbilisi', name: 'Asia/Tbilisi'},
              {id: 'Asia/Hong_Kong', name: 'Asia/Hong_Kong'},
              {id: 'Asia/Jerusalem', name: 'Asia/Jerusalem'},
              {id: 'Asia/Kolkata', name: 'Asia/Kolkata'},
              {id: 'Asia/Kuwait', name: 'Asia/Kuwait'},
              {id: 'Asia/Seoul', name: 'Asia/Seoul'},
              {id: 'Asia/Shanghai', name: 'Asia/Shanghai'},
              {id: 'Asia/Singapore', name: 'Asia/Singapore'},
              {id: 'Asia/Taipei', name: 'Asia/Taipei'},
              {id: 'Asia/Tokyo', name: 'Asia/Tokyo'},
              {id: 'Australia/Perth', name: 'Australia/Perth'},
              {id: 'Australia/Sydney', name: 'Australia/Sydney'},
              {id: 'Europe/Copenhagen', name: 'Europe/Copenhagen'},
              {id: 'Europe/Dublin', name: 'Europe/Dublin'},
              {id: 'Europe/Helsinki', name: 'Europe/Helsinki'},
              {id: 'Europe/Istanbul', name: 'Europe/Istanbul'},
              {id: 'Europe/London', name: 'Europe/London'},
              {id: 'Europe/Madrid', name: 'Europe/Madrid'},
              {id: 'Europe/Oslo', name: 'Europe/Oslo'},
              {id: 'Europe/Paris', name: 'Europe/Paris'},
              {id: 'Europe/Stockholm', name: 'Europe/Stockholm'},
              {id: 'Europe/Zurich', name: 'Europe/Zurich'},
              {id: 'Pacific/Auckland', name: 'Pacific/Auckland'},
              {id: 'Pacific/Honolulu', name: 'Pacific/Honolulu'},
            ]}
          />
          <BooleanInput
            source="do_not_phone_call"
          />
          <BooleanInput
            source="do_not_email"
          />
          <BooleanInput
            source="do_not_text_message"
          />
        </InputGroup>

        <InputGroup
          header="Avatar or Photo"
          subHeader="Choose an avatar or upload a photo for this user."
        >
          <ReferenceInput
            reference="avatars"
            source="avatar_id"
          />
          <ImageInput
            label="photo"
            source="photo"
          />
        </InputGroup>

        <InputGroup
          header="Marketing"
        >
          <EnumInput
            source="t_shirt_size"
          />
          <ReferenceInput
            label="How did this user hear about us?"
            source="how_heard_about_id"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="scope"
        />
        <TextField
          source="name"
        />
        <TextField
          source="email"
        />
        <AgeField
          showDateOfBirth
        />
        <TextField
          source="referral_code"
        />
        <BooleanField
          source="verified"
        />
        <BooleanField
          source="ambassador"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>
          <Tab
            label="summary"
          >
            <DateField
              source="created_at"
            />
            <TextField
              source="id"
            />
            <TextField
              label="NRDY Student ID"
              source="nrdy_student_id"
            />
            <EnumField
              source="scope"
            />
            <TextField
              source="name"
            />
            <TextField
              source="email"
            />
            <TextField
              source="gender"
            />
            <AgeField />
            <DateField
              source="date_of_birth"
            />
            <TextField
              source="referral_code"
            />
            <BooleanField
              source="verified"
              addLabel
            />
            <BooleanField
              source="ambassador"
              addLabel
            />
            <EnumField
              source="t_shirt_size"
            />
            <TimeZoneField
              source="time_zone"
            />
            <BooleanField
              source="do_not_email"
              addLabel
            />
            <BooleanField
              source="do_not_phone_call"
              addLabel
            />
            <BooleanField
              source="do_not_text_message"
              addLabel
            />
            <ReferenceField
              reference="avatars"
              source="avatar_id"
              allowEmpty
            >
              <ImageField
                source="file"
              />
            </ReferenceField>

            <ImageField
              source="photo"
            />

            <NestedResourceList
              reference="phone_numbers"
            >
              <TextField
                label="Phone Number"
                source="phone_number_international_formatted"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="user_addresses"
            >
              <TextField
                source="name"
              />
              <AddressField />
            </NestedResourceList>

            <NestedResourceList
              reference="memberships"
            >
              <LinkField
                source="organization_id"
              />
              <TextField
                source="badge"
              />
              <EnumField
                source="role"
              />
              <BooleanField
                source="accepted"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="guide_preferences"
            >
              <NumberField
                source="position"
              />
              <LinkField
                reference="users"
                source="guide_id"
              />
              <NumberField
                source="completed_meetings_count"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="guide_blocks"
            >
              <LinkField
                reference="users"
                source="guide_id"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="learning_styles"
            >
              <LinkField
                source="user_id"
              />
              <TextField
                label="Prior Coding Experience"
                source="experience"
              />
              <TextField
                label="Preferred Teaching Style"
                source="styles"
              />
              <TextField
                label="Interests and Hobbies"
                source="interests"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="notes"
            >
              <EnumField
                source="topic"
              />
              <TextField
                source="heading"
              />
              <RichTextField
                source="detail"
              />
              <TextField
                label="Author"
                source="_meta_author_name"
              />
              <DateTimeField
                source="created_at"
              />
              <BooleanField
                source="pinned"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Payment Methods"
          >
            <NestedResourceList
              reference="payment_methods"
            >
              <TextField
                source="brand"
              />
              <TextField
                source="last_four"
              />
              <BooleanField
                source="default"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Curriculum"
          >
            <LinkField
              source="rank_id"
            />

            <NestedResourceList
              perPage={10}
              reference="badge_achievements"
            >
              <LinkField
                source="badge_id"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="mission_achievements"
            >
              <LinkField
                source="mission_id"
              />
              <LinkField
                parentReference="users"
                parentSource="user_id"
                source="project_id"
              />
              <BooleanField
                source="completed"
              />
              <BooleanField
                source="submitted"
              />
              <BooleanField
                source="failed"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="curriculum_accomplishments"
            >
              <LinkField
                source="project_id"
              />
              <LinkField
                displaySource="difficulty_level"
                label="Difficulty Level"
                source="curriculum_activity_id"
              />
              <LinkField
                source="curriculum_activity_id"
              />
              <NumberField
                source="count"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="curriculum_scores"
            >
              <LinkField
                source="skill_id"
              />
              <NumberField
                source="points"
              />
              <SkillProgressField />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Economy"
          >
            <NestedResourceList
              reference="bank_balances"
            >
              <NumberField
                source="coins"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="bank_balance_adjustments"
            >
              <LinkField
                reference="users"
                source="adjusted_by_id"
              />
              <NumberField
                source="coins"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="keyring_purchases"
            >
              <LinkField
                reference="library_keyrings"
                source="library_keyring_id"
              />
              <NumberField
                source="coins"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="keyring_grants"
            >
              <LinkField
                reference="library_keyrings"
                source="library_keyring_id"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="economy_ledgers"
            >
              <TextField
                source="coins"
              />
              <TextField
                source="mission_achievement_id"
              />
              <TextField
                source="badge_achievement_id"
              />
              <TextField
                source="keyring_purchase_id"
              />
              <DateTimeField
                source="created_at"
              />
            </NestedResourceList>

          </Tab>

          <Tab
            label="Projects"
          >
            <NestedResourceList
              perPage={10}
              reference="projects"
            >
              <TextField
                source="name"
              />
              <DateTimeField
                source="created_at"
              />
              <ImageField
                source="screenshot_2x"
              />
              <TextField
                source="current_kidscript_version"
              />
              <CurrentVersionField />
              <EnumField
                source="visibility"
              />
              <BooleanField
                source="archived"
              />
              <EditableProjectLinkField
                fullUrl
              />
              <ShareableProjectLinkField
                fullUrl
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Social"
          >
            <BooleanField
              source="private_profile"
              addLabel
            />
            <TextField
              source="follower_count"
            />
            <TextField
              source="following_count"
            />

            <NestedResourceList
              reference="followings"
            >
              <LinkField
                reference="users"
                source="user_id"
              />
              <LinkField
                reference="users"
                source="following_id"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Meetings"
          >
            <NestedResourceList
              reference="participations"
            >
              <MeetingLinkField
                source="meeting_id"
              />
              <DateTimeField
                source="meeting_start_at"
              />
              <DateTimeField
                source="meeting_end_at"
              />
              <EnumField
                source="meeting_status"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Guides"
          >
            <LinkField
              source="guide_rank_id"
            />

            <NestedResourceList
              reference="guide_profiles"
            >
              <TextField
                source="bio"
              />
            </NestedResourceList>

            <NestedResourceList
              perPage={10}
              reference="guide_availabilities"
            >
              <LinkField
                source="occasion_id"
              />
              <DateField
                source="first_available_at"
              />
              <DateField
                source="last_available_at"
              />
              <EnumField
                source="weekday_name"
              />
              <StartAndEndTimeField />
              <BooleanField
                source="accept_codeverse_customers"
              />
              <BooleanField
                source="disabled"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="guide_schedulables"
            >
              <LinkField
                source="occasion_id"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="stripe_accounts"
            >
              <TextField
                source="stripe_user_id"
              />
            </NestedResourceList>

            <NestedResourceList
              reference="checkr_accounts"
            >
              <TextField
                source="checkr_candidate_id"
              />
              <TextField
                source="status"
              />
            </NestedResourceList>

          </Tab>
        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          source="q"
        />
        <EnumInput
          source="scope"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    memberships,
    notes,
    payment_methods,
    phone_numbers,
    curriculum_accomplishments,
    mission_achievements,
    projects,
    friend_requests,
    followings,
    guide_profiles,
    guide_ratings,
    guide_blocks,
    guide_preferences,
    guide_availabilities,
    guide_schedulables,
    checkr_accounts,
    participations,
    bank_balance_adjustments,
    keyring_purchases
  },
};
