import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { GET_LIST } from 'ra-core';
import React, { Component } from 'react';
import dataProvider from '../dataProvider';

const styles = {
  container: {

  },
  bookings: {
    marginBottom: '18px'
  },
  availabilities: {
    marginBottom: '18px'
  },
  capacity: {
    marginBottom: '18px'
  },
  list: {
    margin: '0',
    padding: '0',
  },
  listItem: {
    listStyleType: 'none',
  }
}

class SlotInstanceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingInstances: [],
      bookingInstancesLoading: true,
      availabilityInstances: [],
      availabilityInstancesLoading: true,
    };
  }

  componentDidMount = () => {
    this.getBookingInstances();
    this.getAvailabilityInstances();
  };


  getBookingInstances = () => {
    dataProvider(GET_LIST, 'booking_instances', {
      filter: {
        slot_instance_id: this.props.slotInstanceId,
      }
    })
      .then(({ data }) => {
        this.setState({
          bookingInstances: data,
          bookingInstancesLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          bookingInstancesLoading: false,
        });
      });
  }

  getAvailabilityInstances = () => {
    dataProvider(GET_LIST, 'guide_availability_instances', {
      filter: {
        slot_instance_id: this.props.slotInstanceId,
      }
    })
      .then(({ data }) => {
        this.setState({
          availabilityInstances: data,
          availabilityInstancesLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          availabilityInstancesLoading: false,
        });
      });
  }

  getAvailabilityUserFromBooking = (booking) => {
    const availabilityInstance = booking.availability_instance_id ? this.state.availabilityInstances.find(ai => ai.id == booking.availability_instance_id) : null
    return availabilityInstance && availabilityInstance.user
  }

  render() {
    const { bookingInstances, availabilityInstances } = this.state
    const { classes, slotCapacity, guideAvailability, guideAcceptingCodeverseCustomersAvailability, totalAttendees, availableCapacity, slotCount } = this.props
    return (
      <div
        className={classes.container}
      >
        <div
          className={classes.bookings}
        >
          <Typography
            variant="subheading"
            gutterBottom
          >
            Bookings
          </Typography>
          {bookingInstances.map(booking => (
            <div
              key={booking.id}
            >
              <a
                href={`/#/organizations/${booking.organization_id}/users/${booking.user_id}`}
              >
                {booking.user}
              </a>
              {' matched with: '}
              {this.getAvailabilityUserFromBooking(booking) || 'none'}
            </div>
          ))}
        </div>
        <div
          className={classes.availabilities}
        >
          <Typography
            variant="subheading"
            gutterBottom
          >
            Available Guides
          </Typography>
          {availabilityInstances.map(availability => !availability.blocked && availability.capacity > 0 && (
            <div
              key={availability.id}
            >
              <a
                href={`/#/users/${availability.user_id}`}
              >
                {availability.user}
              </a>
            </div>
          ))}
        </div>
        <div
          className={classes.capacity}
        >
          <Typography
            variant="subheading"
            gutterBottom
          >
            Capacity
          </Typography>
          <ul
            className={classes.list}
          >
            <li
              className={classes.listItem}
            >
              Slot Capacity:
              {' '} 
              {slotCapacity}
            </li>
            <li
              className={classes.listItem}
            >
              Guide Availability:
              {' '} 
              {guideAvailability}
              {' ('}
              {guideAcceptingCodeverseCustomersAvailability}
              {' Accepting Codeverse customers)'}
            </li>
            <li
              className={classes.listItem}
            >
              Total Attendees:
              {' '} 
              {totalAttendees}
            </li>
            <li
              className={classes.listItem}
            >
              Available Capacity:
              {' '} 
              {availableCapacity}
            </li> 
          </ul>
        </div>
      </div>
    );

  }
}

SlotInstanceDetails.propTypes = {
  classes: PropTypes.object,
  slotInstanceId: PropTypes.string,
  slotCapacity: PropTypes.number,
  guideAvailability: PropTypes.number,
  guideAcceptingCodeverseCustomersAvailability: PropTypes.number,
  totalAttendees: PropTypes.number,
  availableCapacity: PropTypes.number,
  slotCount: PropTypes.number,
}

export default withStyles(styles)(SlotInstanceDetails);
