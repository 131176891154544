import Icon from '@material-ui/icons/Grade';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, ResourceList } from '../../controllers';
import { LinkField, NumberField } from '../../fields';
import { BooleanInput, NumberInput, SearchInput, TeamMemberSelectInput } from '../../inputs';


export default {
    Icon,
    dialogs: {
        edit: props => (
          <EditDialog
            {...props}
          >
            <NumberInput
              source="stars"
            />
          </EditDialog>
        ),
        create: props => (
          <CreateButton
            {...props}
          >
            <TeamMemberSelectInput
              source="user_id"
              required
            />
            <NumberInput
              source="stars"
            />
          </CreateButton>
        ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <LinkField
              reference="users"
              source="user_id"
            />
            <NumberField
              source="stars"
            />
          </ResourceList>
        )
    },
    components: {
        Filter: props => (
          <Filter
            {...props}
          >
            <SearchInput
              label="Search"
              source="q"
            />
            <BooleanInput
              label="Deleted"
              source="deleted"
            />
          </Filter>
        ),
    },
    resources: {
    },
};
