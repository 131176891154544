import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../controllers';
import { CurrentVersionField, LinkField, TextField } from '../../../fields';
import { SearchInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="id"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ library_category_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="id"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          source="library_category_id"
          allowEmpty
        />
        <CurrentVersionField />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
