import Icon from '@material-ui/icons/Book';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, ResourceList, ResourceShow } from '../../../../controllers';
import { NumberField, TextField } from '../../../../fields';
import { NumberInput, TextInput } from '../../../../inputs';
import steps from './steps';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <NumberInput
          source="position"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ difficulty_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <NumberInput
          source="position"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <NumberField
          source="position"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <NumberField
          source="position"
        />
      </ResourceShow>
    ),
  },
  components: {
  },
  resources: {
    steps,
  },
};
