import Icon from '@material-ui/icons/Receipt';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { DateField, DateTimeField, EnumField, LinkField, NumberField, TextField } from '../../../fields';
import { BooleanInput, DateInput, InputGroup, NestedReferenceInput, ReferenceInput } from '../../../inputs';
import charges from './charges';
import invoice_bookings from './invoice_bookings';
import invoice_coupons from './invoice_coupons';
import invoice_product_purchases from './invoice_product_purchases';
import invoice_subscription_prepayments from './invoice_subscription_prepayments';

// import invoice_product_returns from './invoice_product_returns';
// import invoice_vouchers from './invoice_vouchers';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextField
          source="description"
        />
         
        <InputGroup
          header="Optional"
          subHeader="Provide a payment method and a due date so that this invoice will automatically be charged the remaining balance on that date."
        >
          <NestedReferenceInput
            source="payment_method_id"
          />
          <DateInput
            source="due_at"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ organization_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="Required"
          subHeader="Select a merchant account (bank account) for this invoice."
        >
          <ReferenceInput
            // set default merchant account to  'Codeverse Explorer LLC'
            defaultValue="9887d05b-3cd1-4a6d-a261-0100f90faed5"
            source="merchant_account_id"
            required
          />
        </InputGroup>
        
        <InputGroup
          header="Optional"
          subHeader="Provide a payment method and a due date so that this invoice will automatically be charged the remaining balance on that date."
        >
          <NestedReferenceInput
            source="payment_method_id"
          />
          <DateInput
            source="due_at"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="description"
        />
        <DateField
          source="created_at"
        />
        <DateField
          source="paid_at"
        />
        <TextField
          source="due_at"
        />
        <TextField
          source="amount"
        />
        <TextField
          source="balance"
        />
        <EnumField
          source="status"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >

        <TextField
          source="id"
        />
        <TextField
          source="description"
        />
        <DateField
          source="created_at"
        />
        <DateField
          source="paid_at"
        />
        <TextField
          source="due_at"
        />
        <LinkField
          source="payment_method_id"
          allowEmpty
        />
        <TextField
          source="amount"
        />
        <TextField
          source="balance"
        />
        <TextField
          source="paid_amount"
        />
        <TextField
          source="refunded_amount"
        />
        <EnumField
          source="status"
        />

        <NestedResourceList
          reference="invoice_bookings"
        >
          <LinkField
            source="booking_id"
          />
          <EnumField
            source="allocation"
          />
          <NumberField
            source="amount"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="invoice_coupons"
        >
          <TextField
            source="name"
          />
          <NumberField
            source="final_amount"
          />
        </NestedResourceList>
     
        <NestedResourceList
          reference="invoice_subscription_prepayments"
        >
          <TextField
            source="description"
          />
          <NumberField
            source="amount"
          />
          <LinkField
            displaySource="start_at"
            label="Subscription Start Date"
            reference="subscriptions"
            source="subscription_id"
          />
          <LinkField
            displaySource="status"
            label="Subscription Status"
            reference="subscriptions"
            source="subscription_id"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="invoice_product_purchases"
        >
          <LinkField
            source="product_sku_trait_value_id"
          />
          <NumberField
            label="Unit Price"
            source="unit_price"
          />
          <NumberField
            source="quantity"
          />
          <NumberField
            label="Total Amount"
            source="amount"
          />
        </NestedResourceList>
        <NestedResourceList
          reference="charges"
        >
          <TextField
            source="status"
          />
          <NumberField
            source="amount"
          />
          <DateTimeField
            source="created_at"
          />
        </NestedResourceList>

      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="due_at"
        />
        <LinkField
          source="payment_method_id"
          allowEmpty
        />
        <TextField
          source="balance"
        />
        <TextField
          source="amount"
        />
        <TextField
          source="paid_amount"
        />
        <TextField
          source="refunded_amount"
        />
        
      </SimpleShowLayout>
    ),
  },
  resources: {
    invoice_bookings,
    invoice_product_purchases,
    invoice_coupons,
    invoice_subscription_prepayments,
    // disable for now
    // invoice_product_returns,
    // invoice_vouchers,
    charges,
  },
};
