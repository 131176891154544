import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const SkillProgressField = ({ record = {} }) => {
  const level = record.level
  if (!level) {
    return (
      <Typography
        variant="body1"
      >
        No level reached
      </Typography>
    )
  }
  const levelPosition = level.position || 0
  const nextLevelTotalPoints = level.next_level_total_points
  return (
    <Typography
      variant="body1"
    >
      Level
      {' '}
      {levelPosition}
      {' '}
      (
      {nextLevelTotalPoints - record.points}
      {' '}
      until level
      {' '}
      {levelPosition + 1}
      )
    </Typography>
  );
}

SkillProgressField.propTypes = {
  record: PropTypes.object,
};

SkillProgressField.defaultProps = {
  addLabel: true,
  label: 'SkillProgress',
};

export default SkillProgressField;

