import Icon from '@material-ui/icons/Label';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow } from '../../../controllers';
import { ChipField, ReferenceManyField, SingleFieldList, TextField } from '../../../fields';
import { BooleanInput, TextInput } from '../../../inputs';
import product_sku_trait_values from './product_sku_trait_values';






export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="price"
        />
        <TextInput
          source="sku_code"
        />
      </EditDialog>
    ),
    create: (props) => (
      <CreateButton
        {...props}
        fixedValue={{ product_id: props.parentResource.id }}
      >
        <TextInput
          source="price"
        />
        <TextInput
          source="sku_code"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="price"
        />
        <TextField
          source="sku_code"
        />

        <ReferenceManyField
          label="Product SKU Trait Value"
          reference="product_sku_trait_values"
          target="product_sku_id"
        >
          <SingleFieldList>
            <ChipField
              source="name"
            />
          </SingleFieldList>
        </ReferenceManyField>
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="price"
        />
        <TextField
          source="sku_code"
        />

        <NestedResourceList
          reference="product_sku_trait_values"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
    product_sku_trait_values,
  },
};
