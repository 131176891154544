import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionCheck from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import compose from 'recompose/compose';

const styles = theme =>
  createStyles({
    contentText: {
      minWidth: 400,
    },
    confirmPrimary: {
      color: theme.palette.primary.main,
    },
    confirmWarning: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    iconPaddingStyle: {
      paddingRight: '0.5em',
    },
  });

class Alert extends Component {

  handleDone = (e) => {
    e.stopPropagation();
    this.props.onClose();
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      isOpen,
      title,
      content,
      confirmColor,
      classes,
    } = this.props;

    return (
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={isOpen}
        onClick={this.handleClick}
      >
        <DialogTitle
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.contentText}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classnames('ra-confirm', {
              [classes.confirmWarning]:
                confirmColor === 'warning',
              [classes.confirmPrimary]:
                confirmColor === 'primary',
            })}
            autoFocus
            onClick={this.handleDone}
          >
            <ActionCheck
              className={classes.iconPaddingStyle}
            />
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  confirmColor: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

Alert.defaultProps = {
  cancel: 'ra.action.cancel',
  classes: {},
  confirm: 'ra.action.confirm',
  confirmColor: 'primary',
  isOpen: false,
};

export default compose(
  withStyles(styles)
)(Alert);
