import Icon from '@material-ui/icons/DateRange';
import React from 'react';
import { ColorField, ColorInput } from 'react-admin-color-input';
import { CreateButton } from '../../buttons';
import { AvailabilitiesCalendar, SlotCalendar } from '../../components';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceListPage, ResourceShow, SimpleShowLayout, Tab, TabbedShowLayout } from '../../controllers';
import { BooleanField, DateField, DaysOfWeekField, EnumField, LinkField, NumberField, StartAndEndTimeField, TextField } from '../../fields';
import { BooleanInput, EnumInput, InputGroup, NullableBooleanInput, NumberInput, SearchInput, TextInput } from '../../inputs';
import slots from './slots';

export default {
  Icon,
  dialogs: {
    edit: (props) => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="total_amount"
        />
        <TextInput
          source="deposit_amount"
        />
        <BooleanInput
          source="subscription_required"
        />

        <EnumInput
          source="room_type"
        />
        <BooleanInput
          source="dynamic_capacity"
        />
        <NumberInput
          source="students_per_guide"
        />
        <NumberInput
          source="advance_bookable_days"
        />
        <NumberInput
          source="buffer_minutes_before"
        />
        <NumberInput
          source="buffer_minutes_after"
        />
        <ColorInput
          source="color"
        />
        <TextInput
          source="highlight_1"
        />
        <TextInput
          source="highlight_1_icon_name"
        />
        <TextInput
          source="highlight_2"
        />
        <TextInput
          source="highlight_2_icon_name"
        />
        <TextInput
          source="highlight_3"
        />
        <TextInput
          source="highlight_3_icon_name"
        />
      </EditDialog>
    ),
    create: (props) => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="General"
        >
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="description"
            required
          />
          <ColorInput
            source="color"
            required
          />
        </InputGroup>

        <InputGroup
          header="Cost"
        >
          <TextInput
            source="total_amount"
            required
          />
          <TextInput
            source="deposit_amount"
            required
          />
          <BooleanInput
            source="subscription_required"
          />
        </InputGroup>

        <InputGroup
          header="Schedule Settings"
        >
          <NumberInput
            source="number_of_days"
          />
          <BooleanInput
            source="recurring"
          />
          <NullableBooleanInput
            source="whole_studio"
          />
          <BooleanInput
            source="in_person"
          />
        </InputGroup>

        <InputGroup
          header="Virtual Settings"
        >
          <BooleanInput
            source="dynamic_capacity"
          />
          <NumberInput
            source="students_per_guide"
          />
          <NumberInput
            source="advance_bookable_days"
          />
          <NumberInput
            source="buffer_minutes_before"
          />
          <NumberInput
            source="buffer_minutes_after"
          />
          <EnumInput
            source="room_type"
          />
        </InputGroup>
        <InputGroup
          header="For Website"
        >
          <TextInput
            source="highlight_1"
          />
          <TextInput
            source="highlight_1_icon_name"
          />
          <TextInput
            source="highlight_2"
          />
          <TextInput
            source="highlight_2_icon_name"
          />
          <TextInput
            source="highlight_3"
          />
          <TextInput
            source="highlight_3_icon_name"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: {
      default: {
        title: "List View",
        Controller: (props) => (
          <ResourceList
            {...props}
          >
            <TextField
              source="name"
            />
            <TextField
              source="total_amount"
            />
            <TextField
              source="deposit_amount"
            />
            <BooleanField
              source="subscription_required"
            />
            <NumberField
              source="number_of_days"
            />
            <BooleanField
              source="recurring"
            />
            <BooleanField
              source="whole_studio"
            />
            <BooleanField
              source="in_person"
            />
            <EnumField
              source="room_type"
            />
            <BooleanField
              source="dynamic_capacity"
            />
            <NumberField
              source="students_per_guide"
            />
            <ColorField
              source="color"
            />
          </ResourceList>
        ),
      },
      slots: {
        title: "Slot Calendar",
        Controller: (props) => (
          <ResourceListPage
            {...props}
          >
            <SlotCalendar />
          </ResourceListPage>
        ),
      },
      availabilities: {
        title: "Guide Availabilities",
        Controller: (props) => (
          <ResourceListPage
            {...props}
          >
            <AvailabilitiesCalendar />
          </ResourceListPage>
        ),
      },
    },
    show: (props) => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>
          <Tab
            label="summary"
          >
            <TextField
              source="id"
            />
            <TextField
              source="name"
            />
            <TextField
              source="description"
            />
            <TextField
              source="total_amount"
            />
            <TextField
              source="deposit_amount"
            />
            <BooleanField
              source="subscription_required"
              addLabel
            />
            <NumberField
              source="number_of_days"
            />
            <BooleanField
              source="recurring"
              addLabel
            />
            <BooleanField
              source="whole_studio"
              addLabel
            />
            <BooleanField
              source="in_person"
              addLabel
            />
            <EnumField
              source="room_type"
            />
            <BooleanField
              source="dynamic_capacity"
            />
            <NumberField
              source="students_per_guide"
            />
            <NumberField
              source="advance_bookable_days"
            />
            <NumberField
              source="buffer_minutes_before"
            />
            <NumberField
              source="buffer_minutes_after"
            />
            <ColorField
              source="color"
            />
            <TextField
              source="highlight_1"
            />
            <TextField
              source="highlight_1_icon_name"
            />
            <TextField
              source="highlight_2"
            />
            <TextField
              source="highlight_2_icon_name"
            />
            <TextField
              source="highlight_3"
            />
            <TextField
              source="highlight_3_icon_name"
            />
          </Tab>

          <Tab
            label="slots"
          >
            <NestedResourceList
              reference="slots"
            >
              <LinkField
                source="location_id"
              />
              <LinkField
                source="occasion_id"
              />
              <StartAndEndTimeField />
              <DateField
                source="first_available_at"
              />
              <DateField
                source="last_available_at"
              />
              <NumberField
                source="capacity"
              />
              <DaysOfWeekField />
            </NestedResourceList>
          </Tab>
        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: (props) => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <NullableBooleanInput
          label="In Person"
          source="in_person"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: (props) => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="id"
        />
        <NumberField
          source="advance_bookable_days"
        />
        <NumberField
          source="buffer_minutes_before"
        />
        <NumberField
          source="buffer_minutes_after"
        />
        <TextField
          source="description"
        />
        <TextField
          source="highlight_1"
        />
        <TextField
          source="highlight_1_icon_name"
        />
        <TextField
          source="highlight_2"
        />
        <TextField
          source="highlight_2_icon_name"
        />
        <TextField
          source="highlight_3"
        />
        <TextField
          source="highlight_3_icon_name"
        />
        <NestedResourceList
          reference="slots"
        >
          <LinkField
            source="location_id"
          />
          <TextField
            source="description"
          />
          <StartAndEndTimeField />
          <DateField
            options={{
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              timeZone: "UTC",
            }}
            source="first_available_at"
          />
          <DateField
            options={{
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              timeZone: "UTC",
            }}
            source="last_available_at"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    slots,
  },
};
