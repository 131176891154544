import Typography from '@material-ui/core/Typography';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { LongTextInput } from 'react-admin';


function parse(value) {
  return value && value.split(/\n|\r/);
}

function format(value) {
  return value && value.join('\n');
}

const ArrayOfStringsInput = props => (
  <div>
    <LongTextInput
      format={format}
      parse={parse}
      {...props}
    />
    <Typography
      variant="body1"
    >
      One
      {' '}
      {pluralize.singular(props.source.replace('_', ' '))}
      {' '}
      per line.
    </Typography>
  </div>
);

ArrayOfStringsInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};

ArrayOfStringsInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default ArrayOfStringsInput;
