import PropTypes from 'prop-types';
import React from 'react';
import { DateField as ReactAdminDateField } from 'react-admin';

const DateField = ({ record = {}, source, options}) => (

  <ReactAdminDateField
    options={options || { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'}} 
    record={record}
    source={source}
    style={{ whiteSpace: 'nowrap' }}
  />
);

DateField.propTypes = {
  record: PropTypes.object,
  options: PropTypes.object,
  source: PropTypes.string,
};

DateField.defaultProps = {
  addLabel: true,
};

export default DateField;

