import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { AutocompleteInput, ReferenceInput as ReactAdminReferenceInput, required as requiredFn } from 'react-admin';
import titleCase from 'title-case';

const ReferenceInput = ({
  isRequired,
  optionText = "name",
  validate,
  record = {},
  reference,
  source,
  label,
  ...props
}) => {
  reference = reference || source && pluralize(source.replace(/_id$/, ''))
  if (source && reference && source.replace(/_id$/, '') !== pluralize.singular(reference)) {
    source = `${source}-as-${reference}`
  }

  label = label || source && titleCase(source.split('_id')[0])

  return (
    <ReactAdminReferenceInput
      allowEmpty={!isRequired}
      filterToQuery={searchText => ({ q: searchText })}
      label={label}
      record={record}
      reference={reference}
      source={source}
      validate={isRequired ? requiredFn() : validate}
      {...props}
    >
      <AutocompleteInput
        optionText={optionText}
      />
    </ReactAdminReferenceInput>
  )
};

ReferenceInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  reference: PropTypes.string,
  optionText: PropTypes.string,
  label: PropTypes.string,
};

ReferenceInput.defaultProps = {
  sort:{},
  alwaysOn: true,
};

export default ReferenceInput;

