import Icon from '@material-ui/icons/Assignment';
import moment from 'moment';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { DateField, LinkField, RichTextField, TextField } from '../../fields';
import { BooleanInput, NestedReferenceInput, TextInput } from '../../inputs';
import contract_versions from './contract_versions';

export default {
    Icon,
    dialogs: {
      edit: props => (
        <EditDialog
          {...props}
        >
          <TextInput
            source="name"
          />
          <NestedReferenceInput
            label="Current Version"
            optionText={contracts => `Created ${moment(contracts.created_at).format('lll')}`}
            reference="contract_versions"
            resourcePath={`/contracts/${props.parentResource.id}/`}
            source="current_version_id-as-contract_versions"
            required
          />          
        </EditDialog>
      ),
      create: props => (
        <CreateButton
          {...props}
        >
          <TextInput
            source="name"
          />
        </CreateButton>
      ),
    },
    routes: {
        list: props => (
          <ResourceList
            {...props}
          >
            <TextField
              source="name"
            />
            <LinkField
              displaySource="created_at"
              reference="contract_versions"
              source="current_version_id"
            />
          </ResourceList>
        ),
        show: props => (
          <ResourceShow
            {...props}
          >
            <TextField
              source="id"
            />
            <TextField
              source="name"
            />
            <LinkField
              displaySource="created_at"
              reference="contract_versions"
              source="current_version_id"
            />
            
            <NestedResourceList
              reference="contract_versions"
            >
              <DateField
                source="created_at"
              />
              <RichTextField
                source="contents"
              />
            </NestedResourceList>
          </ResourceShow>
        ),
    },
    components: {
        Filter: props => (
          <Filter
            {...props}
          >
            <BooleanInput
              label="Deleted"
              source="deleted"
            />
          </Filter>
        ),
      Expand: props => (
        <SimpleShowLayout
          {...props}
        >
          <NestedResourceList
            reference="contract_versions"
          >
            <DateField
              source="created_at"
            />
            <TextField
              source="id"
            />
          </NestedResourceList>
        </SimpleShowLayout>
      ),
    },
    resources: {
      contract_versions,
    },
};
