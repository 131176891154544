import Icon from '@material-ui/icons/Speaker';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { ReferenceInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        {...props}
      >
        <ReferenceInput
          source="how_heard_about_id"
        />
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
