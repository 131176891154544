import jsonapiClient from './lib/ra-jsonapi-client';

const url = `${process.env.REACT_APP_PROTOCOL || 'https'}://${process.env.REACT_APP_API_URL}`;

export default jsonapiClient(url, {
  resources: {
    locations: {
      include_meta: {
        schedule: true,
      },
    },
    occasions: {
      include_meta: {
        schedule: true,
      },
    },
  },
});
