import Icon from '@material-ui/icons/Assignment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NotCompletedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DelegateIcon from '@material-ui/icons/Input';
import ClaimIcon from '@material-ui/icons/PanTool';
import React from 'react';
import { SelectInput } from 'react-admin';
import { AssignmentCard } from '../../../components';
import { EditDialog, Filter, FormDataConsumer, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { DateTimeField, EnumField, LinkField, TextField } from '../../../fields';
import { BooleanInput, DateInput, DateTimeInput, EnumInput, TeamMemberSelectInput, TextInput } from '../../../inputs';


const ResolvedRowStyle = record => ({
  opacity: record.resovled_at ? '0.3' : '1',
});


export default {
  Icon,
  menuItems: [
      {
      name: 'Resolve',
      type: 'component',
      icon: CheckBoxIcon,
      if: assignment => !assignment.resolved && !assignment.canceled,
      Component: props => (
        <EditDialog
          {...props}
          hideComment
        >
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <SelectInput
                  choices={Object.values(formData.meta.resolutions_meta).map(meta => { return { id: meta.event, name: (meta.description || meta.event) } })}
                  source="resolution"
                  fullWidth
                  resettable
                />
                {(formData.resolution == 'callback_later' || formData.resolution == 'not_finished'|| formData.resolution == 'check_in_later' ) && 
                  <SelectInput
                    choices={Object.values(formData.meta.delayed_events_meta).map(meta => { return { id: meta.event, name: (meta.description || meta.event) } })}
                    source="delayed_event"
                    fullWidth
                    resettable
                  />}
                {formData.delayed_event &&
                  <DateTimeInput
                    source="delayed_event_at"
                  />}
                <TextInput
                  source="notes"
                />
              </>
            )}
          </FormDataConsumer>
        </EditDialog>
      ),
    },
    {
      type: 'update',
      name: 'Claim',
      icon: ClaimIcon,
      if: assignment => !assignment.resolved && !assignment.canceled && !assignment['owner_id-as-users'],
      updateAttributes: { claim: true },
    },
    {
      type: 'update',
      name: 'Release',
      icon: NotCompletedIcon,
      if: assignment => !assignment.resolved && !assignment.canceled && assignment['owner_id-as-users'] == self.localStorage.user_id,
      updateAttributes: { release: true },
    },
    {
      name: 'Delegate',
      type: 'component',
      icon: DelegateIcon,
      if: assignment => !assignment.resolved && !assignment.canceled,
      Component: props => (
        <EditDialog
          {...props}
          hideComment
        >
          <TeamMemberSelectInput
            source="owner_id"
          />
          <TextInput
            source="assignor_notes"
          />
        </EditDialog>
      ),
    },
  ],
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        RowStyle={ResolvedRowStyle}
        sort={{ field: "due_at", order: 'asc' }}
        {...props}
      >
        <EnumField
          source="priority"
        />
        <EnumField
          source="topic"
        />
        <DateTimeField
          label="Due"
          source="due_at"
        />
        <LinkField
          source="staff_team_id"
        />
        <LinkField
          label="Assigned to"
          reference="users"
          source="owner_id"
        />
        <TextField
          source="task"
        />
        <DateTimeField
          label="Canceled"
          source="canceled_at"
        />
        <DateTimeField
          label="Resolved"
          source="resolved_at"
        />
        <DateTimeField
          label="Created"
          source="created_at"
        />
        <DateTimeField
          label="Last Updated"
          source="updated_at"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <AssignmentCard />
      </ResourceShow>
    ),
  },
  components: {
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <AssignmentCard />
      </SimpleShowLayout>
    ),
    Filter: props => (
      <Filter
        {...props}
      >

        <EnumInput
          source="topic"
        />
        
        <DateInput
          source="due_before"
        />
        <DateInput
          source="due_after"
        />
        <BooleanInput
          source="resolved"
        />
        <BooleanInput
          source="canceled"
        />
      </Filter>
        ),
  },
  resources: {
  },
};
