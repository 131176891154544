import React, { Component } from 'react';
import { Field } from 'redux-form';

class SettableInput extends Component {

  componentDidMount() {
    const { newValue, input: { onChange }, meta: { error, warning } } = this.props;
    onChange(newValue)
  }

  render() {
    return null
  }
}

const SetValueInput = ({ source, value}) => (
  <Field
    component={SettableInput}
    name={source}
    // required because we sometimes need to set the value "false"
    newValue={value}
    normalize={v => !!v}
  />
);

export default SetValueInput
