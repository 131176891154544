import PropTypes from 'prop-types';
import React from 'react';
import { DateInput as ReactAdminDateInput, required as requiredFn } from 'react-admin';

const todaysDateFormatted = function() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  return yyyy + '-' + mm + '-' + dd;
}

export const DateInput = ({ isRequired, validate, hasDefaultValueToday, ...props }) => (
  <ReactAdminDateInput
    {...props}
    defaultValue={hasDefaultValueToday ? todaysDateFormatted() : null}
    validate={isRequired ? requiredFn() : validate}
  />
)

DateInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  hasDefaultValueToday: PropTypes.bool,
};

DateInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true,
  hasDefaultValueToday: false
};

export default DateInput;

