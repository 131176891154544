import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import resources from '../resources';

import ResourceRouter from '../controllers/ResourceRouter';
import MenuItems from './MenuItems';

import pjson from '../../package.json';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    marginLeft: theme.spacing.unit * 10,
    marginRight: theme.spacing.unit * 10,
    marginTop: theme.spacing.unit * 2,
    fontWeight: '500',
    width: '100%',
  },
});

class Menu extends Component {
  state = {};

  static propTypes = {
    onMenuClick: PropTypes.func,
  };

  render() {

    const {
      classes,
      onMenuClick,
      open,
      resourcePath,
    } = this.props;

    return (
      <>
        <MenuItems
          basePath=""
          className={classes.menu}
          currentResourcePath={resourcePath[0]}
          open={open}
          resources={resources.resources}
          subMenus={resources.subMenus}
          onMenuClick={onMenuClick}
        />
        <div
          className={classes.root}
        >
          <Chip
            className={classes.chip}
            label={`v${pjson.version}`}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    {},
  ),
);

const EnhancedMenu = enhance(Menu);

export default props => (
  <ResourceRouter
    {...props}
  >
    <EnhancedMenu />
  </ResourceRouter>
);

