import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import Title from '../layout/Title';

const ResourceShow = ({
  children,
  classes,
  components: { Actions, Aside },
  resourcePath,
  parentResource,
  resourceIds,
  resources,
  routes,
  ...props
}) => {
  const newParentResource = {
    basePath: `${props.basePath}/${props.id}`,
    resource: props.resource,
    id: props.id,
    parentResource: parentResource,
  }

  return (
    <Show
      {...props}
      actions={Actions ? <Actions /> : null}
      aside={Aside ? <Aside /> : false}
      title={(
        <Title
          {...props}
          resourcePath={resourcePath}
        />
      )}
    >
      <SimpleShowLayout>
        {React.Children.toArray(children).map(child => React.cloneElement(child, {
          parentResource: newParentResource,
          resources,
        }))}
      </SimpleShowLayout>
    </Show>
  )
};

export default ResourceShow;
