import Icon from '@material-ui/icons/MoneyOff';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter, FormDataConsumer, ResourceList, ResourceShow } from '../../../../controllers';
import { DateField, DateTimeField, EnumField, NumberField, TextField } from '../../../../fields';
import { BooleanInput, DateInput, EnumInput, InputGroup, NumberInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Discount Reason"
        >
          <EnumField
            source="reason"
            addLabel
          />
          <TextInput
            source="description"
          />
        </InputGroup>
        
        {props.record.status === 'new' && (
          <FormDataConsumer>
            {({ formData }) => (
              <>

                <InputGroup
                  header="Discount Details"
                >
                  <DateInput
                    source="activate_at"
                    required
                  />
                  <TextInput
                    source="amount"
                    required
                  />
          
                  {formData.reason && formData.reason.includes('incentive') && (
                    <>
                      <TextInput
                        label="Additional Member Amount"
                        source="additional_member_amount"
                        required
                      />
                      <NumberInput
                        label="Billing Cycles"
                        source="number_of_billing_cycles"
                      />
                    </>
                  )}
                  {formData.reason && formData.reason.includes('credit') && (                
                    <NumberInput
                      source="quantity"
                      required
                    />
                  )}
                </InputGroup>
              </>)}
          </FormDataConsumer>
        )}
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ subscription_id: props.parentResource.id }}
        {...props}
      >         
        <InputGroup
          header="Discount Reason"
        >
          <EnumInput
            // only show valid options (e.g., Nova, Supernova*), others have been deprecated and will cause errors
            choices={[
            {id: null, name: 'Nova Discounts', disabled: true},
            {id: 'nova_sign_up_incentive', name: 'Nova Sign Up Incentive'},
            {id: 'nova_loyalty_incentive', name: 'Nova Loyalty Incentive'},
            {id: 'nova_retention_incentive', name: 'Nova Retention Incentive'},
            {id: 'nova_referral_credit', name: 'Nova Referral Credit'},

            {id: null, name: 'Supernova Starter Discounts', disabled: true},
            {id: 'supernova_starter_sign_up_incentive', name: 'Supernova Starter Sign Up Incentive'},
            {id: 'supernova_starter_loyalty_incentive', name: 'Supernova Starter Loyalty Incentive'},
            {id: 'supernova_starter_retention_incentive', name: 'Supernova Starter Retention Incentive'},
            {id: 'supernova_starter_referral_credit', name: 'Supernova Starter Referral Credit'},
           
            {id: null, name: 'Supernova Lite Discounts', disabled: true},
            {id: 'supernova_lite_sign_up_incentive', name: 'Supernova Lite Sign Up Incentive'},
            {id: 'supernova_lite_loyalty_incentive', name: 'Supernova Lite Loyalty Incentive'},
            {id: 'supernova_lite_retention_incentive', name: 'Supernova Lite Retention Incentive'},
            {id: 'supernova_lite_referral_credit', name: 'Supernova Lite Referral Credit'},
           
            {id: null, name: 'Supernova Discounts', disabled: true},
            {id: 'supernova_sign_up_incentive', name: 'Supernova Sign Up Incentive'},
            {id: 'supernova_loyalty_incentive', name: 'Supernova Loyalty Incentive'},
            {id: 'supernova_retention_incentive', name: 'Supernova Retention Incentive'},
            {id: 'supernova_referral_credit', name: 'Supernova Referral Credit'},
           
            {id: null, name: 'Supernova Pro Discounts', disabled: true},
            {id: 'supernova_pro_sign_up_incentive', name: 'Supernova Pro Sign Up Incentive'},
            {id: 'supernova_pro_loyalty_incentive', name: 'Supernova Pro Loyalty Incentive'},
            {id: 'supernova_pro_retention_incentive', name: 'Supernova Pro Retention Incentive'},
            {id: 'supernova_pro_referral_credit', name: 'Supernova Pro Referral Credit'},
              
            ]}
            source="reason"
          />
          <TextInput
            source="description"
            required
          />
        </InputGroup>

        <FormDataConsumer>
          {({ formData }) => (
            <>

              <InputGroup
                header="Discount Details"
              >
                <DateInput
                  source="activate_at"
                  required
                />
                <TextInput
                  source="amount"
                  required
                />
          
                {formData.reason && formData.reason.includes('incentive') && (
                  <>
                    <TextInput
                      label="Additional Member Amount"
                      source="additional_member_amount"
                      required
                    />
                    <NumberInput
                      label="Billing Cycles"
                      source="number_of_billing_cycles"
                    />
                  </>
                  )}
                {formData.reason && formData.reason.includes('credit') && (                
                  <NumberInput
                    source="quantity"
                    required
                  />
                  )}
              </InputGroup>
            </>)}
        </FormDataConsumer>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="reason"
        />
        <TextField
          source="description"
        />
        <NumberField
          source="amount"
        />
        <NumberField
          label="Addt'l Member Amount"
          source="additional_member_amount"
        />
        <NumberField
          label="Billing Cycles"
          source="number_of_billing_cycles"
        />
        <NumberField
          source="quantity"
        />
        <EnumField
          source="status"
        />
        <DateField
          source="activate_at"
        />
        <DateTimeField
          source="added_at"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <EnumField
          source="reason"
        />
        <TextField
          source="description"
        />
        <NumberField
          source="amount"
        />
        <NumberField
          label="Addt'l Member Amount"
          source="additional_member_amount"
        />
        <NumberField
          source="number_of_billing_cycles"
        />
        <NumberField
          source="quantity"
        />
        <TextField
          source="activate_at"
        />
        <TextField
          source="status"
        />
        <TextField
          source="added_at"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
