import Icon from '@material-ui/icons/BookmarkBorder';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { BooleanField, EnumField, ImageField, JsonField, KidScriptField, MarkdownField, NumberField, TextField, UrlField } from '../../../fields';
import { BooleanInput, EnumInput, ImageInput, JsonInput, KidScriptInput, MarkdownInput, NumberInput, SearchInput, TextInput } from '../../../inputs';
import mission_step_insertables from './mission_step_insertables';
import mission_step_tips from './mission_step_tips';
import multiple_choices from './multiple_choices';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <EnumInput
          source="boilerplate"
        />
        <EnumInput
          source="display"
        />
        <TextInput
          source="header"
        />
        <MarkdownInput
          source="content"
        />
        <MarkdownInput
          source="body"
        />
        <MarkdownInput
          source="detail"
        />
        <TextInput
          source="side_bar_header"
        />
        <BooleanInput
          source="optional"
        />
        <BooleanInput
          source="skippable"
        />
        <BooleanInput
          source="fullscreen"
        />
        <KidScriptInput
          source="kidscript"
        />
        <EnumInput
          source="button_type"
        />
        <NumberInput
          source="document"
        />
        <KidScriptInput
          source="target_kidscript_after"
        />
        <KidScriptInput
          source="target_kidscript_before"
        />
        <TextInput
          source="success_header"
        />
        <TextInput
          source="success_message"
        />
        <ImageInput
          label="Image"
          source="image"
        />
        <TextInput
          source="video_uri"
        />
        <TextInput
          source="audio_uri"
        />
        <NumberInput
          source="position"
        />
        <KidScriptInput
          source="kidscript_head"
        />
        <EnumInput
          source="kidscript_head_strategy"
        />
        <KidScriptInput
          source="kidscript_body"
        />
        <EnumInput
          source="kidscript_body_strategy"
        />
        <JsonInput
          source="validation_rules"
        />
        <JsonInput
          source="cursor"
        />
        <JsonInput
          source="highlight"
        />
        <JsonInput
          source="editable_symbols"
        />
        <JsonInput
          source="library"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ mission_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          source="boilerplate"
        />
        <EnumInput
          source="display"
        />
        <TextInput
          source="header"
        />
        <TextInput
          source="content"
        />
        <TextInput
          source="body"
        />
        <MarkdownInput
          source="detail"
        />
        <TextInput
          source="side_bar_header"
        />
        <BooleanInput
          source="optional"
        />
        <BooleanInput
          source="skippable"
        />
        <BooleanInput
          source="fullscreen"
        />
        <KidScriptInput
          source="kidscript"
        />
        <EnumInput
          source="button_type"
        />
        <NumberInput
          source="document"
        />
        <KidScriptInput
          source="target_kidscript_after"
        />
        <KidScriptInput
          source="target_kidscript_before"
        />
        <TextInput
          source="success_header"
        />
        <TextInput
          source="success_message"
        />
        <ImageInput
          label="Image"
          source="image"
        />
        <TextInput
          source="video_uri"
        />
        <TextInput
          source="audio_uri"
        />
        <NumberInput
          source="position"
        />
        <KidScriptInput
          source="kidscript_head"
        />
        <EnumInput
          source="kidscript_head_strategy"
        />
        <KidScriptInput
          source="kidscript_body"
        />
        <EnumInput
          source="kidscript_body_strategy"
        />
        <JsonInput
          source="validation_rules"
        />
        <JsonInput
          source="cursor"
        />
        <JsonInput
          source="highlight"
        />
        <JsonInput
          source="editable_symbols"
        />
        <JsonInput
          source="library"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="boilerplate"
        />
        <EnumField
          source="display"
        />
        <TextField
          source="header"
        />
        <EnumField
          source="button_type"
        />
        <TextField
          source="position"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <EnumField
          source="boilerplate"
        />
        <EnumField
          source="display"
        />
        <TextField
          source="header"
        />
        <MarkdownField
          source="content"
          disabled
        />
        <MarkdownField
          source="body"
          disabled
        />
        <MarkdownField
          source="detail"
          disabled
        />
        <TextField
          source="side_bar_header"
        />
        <BooleanField
          source="optional"
          addLabel
        />
        <BooleanField
          source="skippable"
          addLabel
        />
        <BooleanField
          source="fullscreen"
          addLabel
        />
        <KidScriptField
          source="kidscript"
        />
        <EnumField
          source="button_type"
        />
        <NumberField
          source="document"
        />
        <KidScriptField
          source="target_kidscript_after"
        />
        <KidScriptField
          source="target_kidscript_before"
        />
        <TextField
          source="success_header"
        />
        <TextField
          source="success_message"
        />
        <ImageField
          label="Image"
          source="image"
        />
        <TextField
          source="video_uri"
        />
        <TextField
          source="audio_uri"
        />
        <NumberField
          source="position"
        />
        <KidScriptField
          source="kidscript_head"
        />
        <EnumField
          source="kidscript_head_strategy"
        />
        <KidScriptField
          source="kidscript_body"
        />
        <EnumField
          source="kidscript_body_strategy"
        />
        <JsonField
          source="validation_rules"
        />
        <JsonField
          source="cursor"
        />
        <JsonField
          source="highlight"
        />
        <JsonField
          source="editable_symbols"
        />
        <JsonField
          source="library"
        />

        <NestedResourceList
          reference="mission_step_tips"
        >
          <TextField
            source="icon"
          />
          <TextField
            source="content"
          />
          <TextField
            source="line"
          />
          <TextField
            source="position"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="multiple_choices"
        >
          <TextField
            source="question"
          />
          <TextField
            source="answer_a"
          />
          <TextField
            source="answer_b"
          />
          <TextField
            source="answer_c"
          />
          <TextField
            source="answer_d"
          />
          <EnumField
            source="answer"
          />
          <TextField
            source="position"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="mission_step_insertables"
        >
          <TextField
            source="object"
          />
          <KidScriptField
            source="kidscript"
          />
          <TextField
            source="position"
          />
          <EnumField
            source="display"
          />
        </NestedResourceList>
      </ResourceShow>
    ),

  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <MarkdownField
          source="content"
        />
        <MarkdownField
          source="body"
        />
        <MarkdownField
          source="detail"
        />
        <UrlField
          source="video_uri"
        />
        <UrlField
          source="audio_uri"
        />
        <NestedResourceList
          reference="mission_step_tips"
        >
          <TextField
            source="icon"
          />
          <TextField
            source="content"
          />
          <TextField
            source="line"
          />
          <TextField
            source="position"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="multiple_choices"
        >
          <TextField
            source="question"
          />
          <TextField
            source="answer_a"
          />
          <TextField
            source="answer_b"
          />
          <TextField
            source="answer_c"
          />
          <TextField
            source="answer_d"
          />
          <EnumField
            source="answer"
          />
          <TextField
            source="position"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="mission_step_insertables"
        >
          <TextField
            source="object"
          />
          <KidScriptField
            source="kidscript"
          />
          <TextField
            source="position"
          />
          <EnumField
            source="display"
          />
        </NestedResourceList>

      </SimpleShowLayout>
    ),
  },
  resources: {
    mission_step_tips,
    mission_step_insertables,
    multiple_choices
  },
};
