import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { ResourceList } from '../../../controllers';
import { EnumField } from '../../../fields';
import tutorials from './tutorials';





const ListTutorials = (id, basePath) => {
  return `${basePath}/${id}/tutorials`;
};

export default {
  Icon,
  dialogs: {
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="level"
        />
      </ResourceList>
    ),
  },
  components: {
  },
  resources: {
    tutorials,
  },
};
