import Icon from '@material-ui/icons/Settings';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../../controllers';
import { TextField } from '../../../../fields';
import { ArrayOfStringsInput, BooleanInput, EnumInput, NumberInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextField
          source="name"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="default_value"
        />
        <BooleanInput
          source="is_required"
        />
        <BooleanInput
          source="is_array"
        />
        <TextInput
          source="validation_regex"
        />
        <ArrayOfStringsInput
          source="attachment_mime_types"
        />
        <NumberInput
          source="attachment_max_kilobytes"
        />
        <TextInput
          source="number_format"
        />
        <NumberInput
          source="validate_greater_than"
        />
        <NumberInput
          source="validate_greater_than_or_equal"
        />
        <NumberInput
          source="validate_less_than"
        />
        <NumberInput
          source="validate_less_than_or_equal"
        />
        <EnumInput
          source="expected_type"
          versionedAttribute
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ library_engine_configuration_template_id: props.parentResource.id }}
        {...props}
      >
        <TextInput
          source="name"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="default_value"
        />
        <BooleanInput
          source="is_required"
        />
        <BooleanInput
          source="is_array"
        />
        <TextInput
          source="validation_regex"
        />
        <ArrayOfStringsInput
          source="attachment_mime_types"
        />
        <NumberInput
          source="attachment_max_kilobytes"
        />
        <TextInput
          source="number_format"
        />
        <NumberInput
          source="validate_greater_than"
        />
        <NumberInput
          source="validate_greater_than_or_equal"
        />
        <NumberInput
          source="validate_less_than"
        />
        <NumberInput
          source="validate_less_than_or_equal"
        />
        <EnumInput
          source="expected_type"
          versionedAttribute
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
