import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const StreamLinkField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    <a
      href={`https://studio.codeverse.com/stream/${record['id']}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      View the Stream
    </a>
  </Typography>
);

StreamLinkField.propTypes = {
  record: PropTypes.object,
};

StreamLinkField.defaultProps = {
  addLabel: true,
  label: 'Stream Link'
};

export default StreamLinkField;
