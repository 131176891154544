import DefaultIcon from '@material-ui/icons/WebAsset';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MenuItemLinks from './MenuItemLinks';
import SubMenu from './SubMenu';

class MenuItems extends Component {
  state = {};

  static propTypes = {
    onMenuClick: PropTypes.func,
  };

  handleToggle = (menu) => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  resourceWithName = (resourceName) => {
    if (!this.props.resources[resourceName]) {
      // eslint-disable-next-line no-throw-literal
      throw `resource ${resourceName} not in list`;
    }
    return Object.assign({}, this.props.resources[resourceName], {
      name: resourceName,
    });
  };

  resourceNotInSubMenu = (resourceName) => {
    if (this.props.subMenus) {
      for (let i = 0; i < this.props.subMenus.length; i++) {
        const resourceNames = this.props.subMenus[i].resourceNames;
        if (resourceNames.indexOf(resourceName) !== -1) {
          return false;
        }
      }
    }
    return true;
  };

  resourceWithListRoute = (resourceName) => {
    return !!(this.resourceWithName(resourceName).routes || {}).list;
  };

  resourcesNotInSubMenus = () => {
    return (Object.keys(this.props.resources) || [])
      .sort()
      .filter(this.resourceNotInSubMenu).filter(this.resourceWithListRoute).map(this.resourceWithName);
  };

  subMenusWithResources = () => {
    return (this.props.subMenus || []).map(subMenu => Object.assign({}, subMenu, {
      resources: subMenu.resourceNames.map(this.resourceWithName),
    }));
  };

  subMenuFromName = (subMenuName) => {
    return (this.props.subMenus || []).filter(subMenu => subMenu.name === subMenuName)[0];
  }

  subMenuHasResource = (subMenuName, resourceName) => {
    const subMenu = this.subMenuFromName(subMenuName);
    return (subMenu ? subMenu.resourceNames : []).indexOf(resourceName) !== -1;
  };

  isOpen = (menu) => {
    // default to open if the current resource is in this sub menu
    if (typeof this.state[menu] === 'undefined' && this.props.currentResourcePath) {
      return this.subMenuHasResource(menu, this.props.currentResourcePath.resource);
    } else {
      return this.state[menu];
    }
  };

  render() {
    const {
      open,
      currentResourcePath,
      basePath,
      onMenuClick,
      parentName,
    } = this.props;

    return (
      <>
        <MenuItemLinks
          basePath={basePath}
          currentResourcePath={currentResourcePath}
          open={open}
          parentName={parentName}
          resources={this.resourcesNotInSubMenus()}
          onMenuClick={onMenuClick}
        />
        { this.subMenusWithResources().map(subMenu => (
          <SubMenu
            handleToggle={() => this.handleToggle(subMenu.name)}
            icon={subMenu.Icon ? <subMenu.Icon /> : <DefaultIcon />}
            isOpen={this.isOpen(subMenu.name)}
            key={subMenu.name}
            name={subMenu.name}
            sidebarIsOpen={open}
          >
            <MenuItemLinks
              basePath={basePath}
              currentResourcePath={currentResourcePath}
              open={open}
              parentName={parentName ? `${pluralize.singular(parentName)} ${subMenu.name}` : subMenu.name}
              resources={subMenu.resources}
              onMenuClick={onMenuClick}
            />
          </SubMenu>
        ))}
      </>
    );
  }
}

export default MenuItems;
