import PropTypes from 'prop-types';
import React from 'react';
import { SelectInput } from 'react-admin';
import currencyCodes from '../data/currencies';

const CurrencySelectInput = props => (
  <SelectInput
    {...props}
    choices={currencyCodes.choices}
  />
);

CurrencySelectInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  defaultValue: PropTypes.string,
};

CurrencySelectInput.defaultProps = {
  label: 'Currency',
  source: 'currency_code',
  defaultValue: 'USD',
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default CurrencySelectInput;
