import Icon from '@material-ui/icons/BugReport';
import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { CreateButton } from '../../../buttons';
import { NestedReferenceInput, ReferenceInput } from '../../../inputs';

export default {
  Icon,
  menuItems: [

  ],
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ rank_id: props.parentResource.id }}
        {...props}
      >
        <ReferenceInput             
          source="learning_path_id"
          required
        />
        <FormDataConsumer>
          {({ formData }) => formData.learning_path_id && (
            <NestedReferenceInput
              optionText="position"
              resourcePath={`/learning_paths/${formData.learning_path_id}/`}
              source="learning_path_level_id"
              required
            />
                )}
        </FormDataConsumer>
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
