import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const PhoneNumberField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.dialing_code === '1' ? record.phone_number_formatted : record.phone_number_international_formatted}
  </Typography>
);

PhoneNumberField.propTypes = {
  record: PropTypes.object,
};

PhoneNumberField.defaultProps = {
  addLabel: true,
  label: 'Phone Number',
};

export default PhoneNumberField;

