import NoteIcon from '@material-ui/icons/Note';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { CreateButton } from '../buttons';
import { EnumInput, InputGroup, NestedReferenceInput, RichTextInput, TextInput } from '../inputs';


const CreatePhoneCallNoteButton = ({  record, organization_id }) => (
  <CreateButton
    fixedValue={{
        organization_id: organization_id,
    }}
    icon={<NoteIcon />}
    label="Create Note"
    resource="notes"
  >
    <InputGroup
      header="What is this note about?"
    >
      <EnumInput
        // temporarily override enum list to prevent accidental use of parent_update note type
        choices={[
          {id: 'comments', name: 'Comments'},
          {id: 'progress', name: 'Progress'},
          {id: 'goals', name: 'Goals'},
          {id: 'feedback', name: 'Feedback'},
          {id: 'concerns', name: 'Concerns'},
          {id: 'sales', name: 'Sales'},
        ]}
        source="topic"
      />  
      <TextInput
        defaultValue={`Phone Call on ${moment(record.created_at).format('lll')}`}
        source="heading"
        required
      />
      <NestedReferenceInput
        resourcePath={`/organizations/${organization_id}`}
        source="user_id"
      />
    </InputGroup>
    
    <InputGroup
      fullWidth
    >
      <RichTextInput
        label=""
        source="detail"
        required
      />
    </InputGroup>
  </CreateButton>
);
CreatePhoneCallNoteButton.propTypes = {
  record: PropTypes.object.isRequired,
  organization_id: PropTypes.string.isRequired,
}

export default CreatePhoneCallNoteButton;
