import React from 'react';
import { TabbedShowLayout } from 'react-admin';

export default ({ children, resources, parentResource, ...props}) => (
  <TabbedShowLayout
    {...props}
  >
    {React.Children.toArray(children).map(child => React.cloneElement(child, {
      parentResource,
      resources,
    }))}
  </TabbedShowLayout>
)

