import PropTypes from 'prop-types';
import React from 'react';
import { NullableBooleanInput as ReactAdminNullableBooleanInput, required as requiredFn } from 'react-admin';

export const NullableBooleanInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminNullableBooleanInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

NullableBooleanInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

NullableBooleanInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default NullableBooleanInput;

