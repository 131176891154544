import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DefaultIcon from '@material-ui/icons/WebAsset';

import pluralize from 'pluralize';
import titleCase from 'title-case';

import {
  MenuItemLink
} from 'react-admin';

import MenuItems from './MenuItems';
import SubMenu from './SubMenu';

class MenuItemLinks extends Component {
  static propTypes = {
    onMenuClick: PropTypes.func,
  };

  nameWithoutNamespace = (name) => {
    let newName = name;
    if (this.props.parentName) {
      const parts = this.props.parentName.split(' ');
      for (let i = parts.length; i > 0; i--) {
        const parentName = this.props.parentName.split(' ').splice(0, i).join(' ');
        newName = newName.replace(new RegExp(`^${parentName}\\s|${parentName}\\s`, 'i'), '').replace(new RegExp(`^${parentName}\\s|${pluralize.singular(parentName)}\\s`, 'i'), '');
      }
    }
    return newName;
  }

  render() {
    const {
      resources,
      currentResourcePath,
      basePath,
      onMenuClick,
      open,
    } = this.props;

    return (
      <>
        { resources.map(resource => (
          <div
            key={resource.name}
          >
            { currentResourcePath &&
              currentResourcePath.resource === resource.name &&
              currentResourcePath.id ? (
                <SubMenu
                  icon={resource.Icon ? <resource.Icon /> : <DefaultIcon />}
                  name={this.nameWithoutNamespace(titleCase(resource.name))}
                  sidebarIsOpen={open}
                  to={`${basePath}/${resource.name}`}
                  isOpen
                  onClick={onMenuClick}
                >
                  { resource.routes && resource.routes.show && (
                    <MenuItemLink
                      leftIcon={resource.Icon ? <resource.Icon /> : <DefaultIcon />}
                      primaryText="Summary"
                      to={`${currentResourcePath.basePath}/${currentResourcePath.id}/show`}
                      onClick={onMenuClick}
                    />
                  )}
                  <MenuItems
                    basePath={`${currentResourcePath.basePath}/${currentResourcePath.id}`}
                    currentResourcePath={currentResourcePath.next}
                    open={open}
                    parentName={titleCase(resource.name)}
                    resources={resource.resources}
                    subMenus={resource.subMenus}
                    onMenuClick={onMenuClick}
                  />
                </SubMenu>
              ) : (
                <MenuItemLink
                  leftIcon={resource.Icon ? <resource.Icon /> : <DefaultIcon />}
                  primaryText={this.nameWithoutNamespace(titleCase(resource.name))}
                  to={`${basePath}/${resource.name}`}
                  onClick={onMenuClick}
                />
              )}
          </div>
        ))}
      </>
    );
  }
}

export default MenuItemLinks;
