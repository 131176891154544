import Icon from '@material-ui/icons/Star';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../controllers';
import { BooleanField, EnumField, ImageField, LinkField, NumberField, TextField } from '../../fields';
import { BooleanInput, EnumInput, ImageInput, LongTextInput, NumberInput, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import badge_conditions from './badge_conditions';
import badge_dependent_badges from './badge_dependent_badges';
import badge_skills from './badge_skills';

export default {
  Icon,
  menuItems: [
    {
      type: "toggle",
      attribute: "published",
    },
  ],
  dialogs: {
    edit: (props) => (
      <EditDialog
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <LongTextInput
          source="description"
          required
        />
        <NumberInput
          source="minutes"
          required
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="coins"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <ImageInput
          label="Image"
          source="image"
          required
        />
        <ReferenceInput
          label="Previous Badge"
          reference="badges"
          source="previous_badge_id"
        />
        <BooleanInput
          source="published"
        />
        <BooleanInput
          source="secret"
        />
        <BooleanInput
          source="studio_only"
        />
        <BooleanInput
          source="guide_only"
        />
      </EditDialog>
    ),
    create: (props) => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
          required
        />
        <LongTextInput
          source="description"
          required
        />
        <NumberInput
          source="minutes"
          required
        />
        <EnumInput
          source="difficulty_level"
          required
        />
        <NumberInput
          source="coins"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <ImageInput
          label="Image"
          source="image"
          required
        />
        <ReferenceInput
          label="Previous Badge"
          reference="badges"
          source="previous_badge_id-as-badges"
        />
        <BooleanInput
          source="published"
        />
        <BooleanInput
          source="secret"
        />
        <BooleanInput
          source="studio_only"
        />
        <BooleanInput
          source="guide_only"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: (props) => (
      <ResourceList
        filter={{ include_unpublished: true }}
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          reference="badges"
          source="first_badge_id"
        />
        <NumberField
          source="position"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="minutes"
        />
        <NumberField
          source="coins"
        />
        <LinkField
          source="library_keyring_id"
        />
        <NumberField
          source="points"
        />
        <ImageField
          label="Image"
          source="image"
          required
        />
        <BooleanField
          source="published"
        />
        <BooleanField
          source="secret"
        />
        <BooleanField
          source="studio_only"
        />
        <BooleanField
          source="guide_only"
        />
      </ResourceList>
    ),
    show: (props) => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <EnumField
          source="difficulty_level"
        />
        <NumberField
          source="minutes"
        />
        <NumberField
          source="points"
        />
        <NumberField
          source="coins"
        />
        <LinkField
          source="library_keyring_id"
        />
        <ImageField
          label="Image"
          source="image"
          required
        />
        <BooleanField
          source="published"
          addLabel
        />
        <BooleanField
          source="secret"
          addLabel
        />
        <BooleanField
          source="studio_only"
          addLabel
        />
        <BooleanField
          source="guide_only"
          addLabel
        />

        <NestedResourceList
          reference="badge_skills"
        >
          <LinkField
            source="skill_id"
          />
          <NumberField
            source="ratio"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="badge_conditions"
        >
          <EnumField
            source="comparator"
          />
          <BooleanField
            source="single_project"
          />
          <LinkField
            source="badge_condition_id"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="badge_dependent_badges"
        >
          <LinkField
            label="Dependent Badge"
            reference="badges"
            source="dependent_badge_id"
          />
        </NestedResourceList>
      </ResourceShow>
    ),
  },
  components: {
    Filter: (props) => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          source="first_badge_only"
        />
        <ReferenceInput
          filter={{ first_badge_only: true }}
          reference="badges"
          source="first_badge_id"
          allowEmpty
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: (props) => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="badge_skills"
        >
          <LinkField
            source="skill_id"
          />
          <NumberField
            source="ratio"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="badge_conditions"
        >
          <TextField
            source="id"
          />
          <EnumField
            source="comparator"
          />
          <BooleanField
            source="single_project"
          />
        </NestedResourceList>

        <NestedResourceList
          reference="badge_dependent_badges"
        >
          <LinkField
            label="Dependent Badge"
            reference="badges"
            source="dependent_badge_id"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    badge_skills,
    badge_dependent_badges,
    badge_conditions,
  },
};

