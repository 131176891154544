const convertFileToBase64 = (key, rawFile) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(rawFile);

  reader.onload = () => resolve([key, reader.result]);
  reader.onerror = reject;
});

// note this currently only works with the first file, it wont work if a form contains two
const addUploadCapabilities = requestHandler => (type, resource, params) => {
  if (type === 'UPDATE' || type === 'CREATE') {
    let promises = []
    const keys = Object.keys(params.data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (params.data[key] && params.data[key].rawFile instanceof File) {
        promises.push(convertFileToBase64(key, params.data[key].rawFile))
      }
    }
    if (promises.length) {
      return Promise.all(promises).then(transformedNewPictures => {
        let imageFields = {}
        for (let i = 0; i < transformedNewPictures.length; i++) {
          imageFields[transformedNewPictures[i][0]] = transformedNewPictures[i][1];
        }
        return requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            ...imageFields,
          },
        })
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
