import Icon from '@material-ui/icons/Gamepad';
import AssignIcon from '@material-ui/icons/Input';
import ConfigurationIcon from '@material-ui/icons/SettingsApplications';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../controllers';
import { ArrayOfStringsField, CurrentVersionField, LinkField, NextVersionField, TextField } from '../../fields';
import { ArrayOfStringsInput, BooleanInput, BulkEngineEventLink, BulkEngineMethodLink, EnumInput, JsonInput, NestedReferenceInput, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import GeneratorExample from './GeneratorExample';
import library_object_configuration_templates from './library_object_configuration_templates';
import library_object_constants from './library_object_constants';
import library_object_events from './library_object_events';
import library_object_included_engine_events from './library_object_included_engine_events';
import library_object_included_engine_methods from './library_object_included_engine_methods';
import library_object_methods from './library_object_methods';
import library_object_reactions from './library_object_reactions';
import library_object_versions from './library_object_versions';
export default {
  Icon,
  menuItems: [
    {
      name: 'Apply Generation Config',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <JsonInput
            source="generator_code"
          />
          <GeneratorExample />
        </EditDialog>
      ),
    },
    {
      name: 'Bulk Engine Method Link',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <BulkEngineMethodLink />
        </EditDialog>
      ),
    },
    {
      name: 'Bulk Engine Event Link',
      type: 'component',
      icon: AssignIcon,
      Component: props => (
        <EditDialog
          {...props}
        >
          <BulkEngineEventLink />
        </EditDialog>
      ),
    },

  ],
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <TextField
          source="name"
        />
        <NestedReferenceInput
          optionText="name"
          resourcePath={`/library_engines/${props.record.library_engine_id}`}
          source="library_engine_version_id"
        />
        <ReferenceInput
          source="library_category_id"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <ArrayOfStringsInput
          source="synonyms"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="icon"
        />
        <TextInput
          source="changelog"
        />
        <EnumInput
          source="significance"
          versionedAttribute
        />
        <BooleanInput
          source="published"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <TextInput
          source="name"
        />
        <ReferenceInput
          source="library_category_id"
        />
        <ReferenceInput
          source="library_engine_id"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
        <ArrayOfStringsInput
          source="synonyms"
        />
        <TextInput
          source="description"
        />
        <TextInput
          source="icon"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          source="library_category_id"
          allowEmpty
        />
        <LinkField
          source="library_keyring_id"
          allowEmpty
        />
        <CurrentVersionField />
        <LinkField
          source="library_engine_id"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>

          <Tab
            label="General"
          >
            <TextField
              source="name"
            />
            <LinkField
              source="library_keyring_id"
            />
            <LinkField
              label="Category"
              reference="library_categories"
              source="library_category_id"
              allowEmpty
            >
              <TextField
                source="name"
              />
            </LinkField>
            <CurrentVersionField />
            <NextVersionField />
            <LinkField
              label="Engine"
              linkType="show"
              reference="library_engines"
              source="library_engine_id"
              allowEmpty
            >
              <TextField
                source="name"
              />
            </LinkField>
            <ArrayOfStringsField
              source="synonyms"
            />
            <TextField
              source="description"
            />
            <TextField
              source="icon"
            />

          </Tab>

          <Tab
            label="Methods"
          >
            <NestedResourceList
              reference="library_object_methods"
            >
              <TextField
                source="name"
              />
              <LinkField
                source="library_keyring_id"
                allowEmpty
              />
            </NestedResourceList>
            <NestedResourceList
              reference="library_object_included_engine_methods"
            >
              <TextField
                source="name"
              />
              <LinkField
                source="library_keyring_id"
                allowEmpty
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Events"
          >
            <NestedResourceList
              reference="library_object_events"
            >
              <TextField
                source="name"
              />
              <LinkField
                source="library_keyring_id"
                allowEmpty
              />
            </NestedResourceList>
            <NestedResourceList
              reference="library_object_included_engine_events"
            >
              <TextField
                source="name"
              />
              <LinkField
                source="library_keyring_id"
                allowEmpty
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Reactions"
          >
            <NestedResourceList
              reference="library_object_reactions"
            >
              <TextField
                source="key"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Constants"
          >
            <NestedResourceList
              reference="library_object_constants"
            >
              <TextField
                source="name"
              />
            </NestedResourceList>
          </Tab>

          <Tab
            label="Configuration"
          >
            <NestedResourceList
              reference="library_object_configuration_templates"
            >
              <LinkField
                displaySource="name"
                label="Template"
                reference="library_engine_configuration_templates"
                source="library_engine_configuration_template_id"
              />
              <LinkField
                displaySource="is_required"
                label="Required"
                reference="library_engine_configuration_templates"
                source="library_engine_configuration_template_id"
              />
              <LinkField
                displaySource="is_unique"
                label="Unique"
                reference="library_engine_configuration_templates"
                source="library_engine_configuration_template_id"
              />
              <TextField
                source="name"
              />
            </NestedResourceList>
          </Tab>

        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  subMenus: [
    {
      name: 'Configuration',
      Icon: ConfigurationIcon,
      resourceNames: [
        'library_object_configuration_templates',
      ],
    },
  ],
  resources: {
    library_object_versions,
    library_object_configuration_templates,
    library_object_constants,
    library_object_methods,
    library_object_events,
    library_object_included_engine_methods,
    library_object_included_engine_events,
    library_object_reactions,
  },
};
