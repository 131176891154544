
import InProgressIcon from '@material-ui/icons/Autorenew';
import ValidateIcon from '@material-ui/icons/CheckCircle';
import SubmitIcon from '@material-ui/icons/Send';
import ResetIcon from '@material-ui/icons/Undo';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { BooleanInput, NestedReferenceInput, ReferenceInput } from '../../../inputs';

export default {
   menuItems: [
    {
      type: 'update',
      name: 'Validate',
      icon: ValidateIcon,
      if: mission_achievements => mission_achievements.submitted && !mission_achievements.completed,
      updateAttributes: {completed: true},
    },
    {
      type: 'update',
      name: 'Set "In Progress"',
      icon: InProgressIcon,
      if: mission_achievements => mission_achievements.failed || mission_achievements.completed,
      updateAttributes: {completed: false, failed: false},
    },
    {
      type: 'update',
      name: 'Reset',
      icon: ResetIcon,
      if: mission_achievements => !mission_achievements.failed,
      updateAttributes: {failed: true},
    },
    {
      type: 'update',
      name: 'Submit',
      icon: SubmitIcon,
      if: mission_achievements => !mission_achievements.completed,
      updateAttributes: {submitted: true},
    }
  ],
  dialogs: {
    create: props => ( <CreateButton
      fixedValue={{ user_id: props.parentResource.id }}
      {...props}
                       >
      <ReferenceInput
        filter={{  processor: 'manual',  published: true  }}
        source="mission_id"
        required
      />
      <NestedReferenceInput
        resourcePath={`/users/${props.parentResource.id}`}
        source="project_id"
        required
      />
      <BooleanInput
        defaultValue={false}
        source="completed"
      />
    </CreateButton>
    ),
  },
  routes: {},
  components: {},
  resources: {},
};
