import Icon from '@material-ui/icons/SpeakerNotes';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter } from '../../../controllers';
import { BooleanInput, EnumInput, InputGroup, RichTextInput, SearchInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="What is this note about?"
        >
          <EnumInput
            source="topic"
          />
          <TextInput
            source="heading"
            required
          />
        </InputGroup> 
        {' '}
        <InputGroup
          fullWidth
        >
          <RichTextInput
            label=""
            source="detail"
            required
          />
        </InputGroup>
        {' '}
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
        <InputGroup
          header="What is this note about?"
        >
          <EnumInput
            // temporarily override enum list to prevent accidental use of parent_update note type
            choices={[
              {id: 'comments', name: 'Comments'},
              {id: 'progress', name: 'Progress'},
              {id: 'goals', name: 'Goals'},
              {id: 'feedback', name: 'Feedback'},
              {id: 'concerns', name: 'Concerns'},
              {id: 'sales', name: 'Sales'},
            ]}
            source="topic"
          />  
          <TextInput
            source="heading"
            required
          />
        </InputGroup>
        <InputGroup
          fullWidth
        >
          <RichTextInput
            label=""
            source="detail"
            required
          />
        </InputGroup>

      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
