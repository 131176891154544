import RemoveIcon from '@material-ui/icons/Clear';
import Icon from '@material-ui/icons/Event';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../buttons';
import { AvailabilitiesCalendar } from '../../components';
import { EditDialog, Filter, ResourceList, ResourceListPage, ResourceShow } from '../../controllers';
import { BooleanField, DateField, EnumField, LinkField, StartAndEndTimeField, TextField } from '../../fields';
import { ArrayOfStringsInput, AvailabilityScheduleInput, BooleanInput, DateInput, NumberInput, ReferenceInput, TeamMemberSelectInput, TimeZoneSelectInput } from '../../inputs';


export default {
    Icon,
    menuItems: [
      {
        type: 'update',
        name: 'Remove After Today',
        icon: RemoveIcon,
        if: availability => availability.last_available_at == null || availability.last_available_at > new Date().toISOString().substr(0, 10),
        updateAttributes: {
          last_available_at: new Date().toISOString().substr(0, 10), 
          _comment: 'set last available at date'},
      },
    ],
    dialogs: {
      edit: props => (
        <EditDialog
          {...props}
        >
          <DateInput
            source="last_available_at"
          />
          <ArrayOfStringsInput
            source="excluded_dates"
          />
          <BooleanInput
            source="accept_codeverse_customers"
          />
          <BooleanInput
            source="disabled"
          />
        </EditDialog>
      ),
      create: props => (
        <CreateButton
          {...props}
        >
          <TeamMemberSelectInput
            label="Guide"
            source='user_id'
            required
          />
          <AvailabilityScheduleInput />
          <ArrayOfStringsInput
            source="excluded_dates"
          />
          <BooleanInput
            source="accept_codeverse_customers"
            defaultValue
          />
          <BooleanInput
            source="disabled"
          />
          <TimeZoneSelectInput />
        </CreateButton>
      ),
    },
    routes: {
      list:{
        default: {
          title: 'Calendar View',
          Controller: props => (
            <ResourceListPage
              {...props}
            >
              <AvailabilitiesCalendar />
            </ResourceListPage>
          ),
        },
        list: {
          title: 'List View',
          Controller: props => (
            <ResourceList
              sort={{field: 'first_available_at', order: 'desc' }}
              {...props}
            >
              <LinkField
                label="Guide"
                source="user_id"
              />
              <LinkField
                source="occasion_id"
              />
              <DateField
                source="first_available_at"
              />
              <DateField
                source="last_available_at"
              />
              <EnumField
                source="weekday_name"
              />
              <StartAndEndTimeField />
              <BooleanField
                source="accept_codeverse_customers"
              />
              <BooleanField
                source="disabled"
              />
            </ResourceList>
          )
        },
       
      }, 
      show: props => (
        <ResourceShow
          {...props}
        >
          <LinkField
            source="user_id"
          />
          <LinkField
            source="occasion_id"
          />
          <TextField
            source="first_available_at"
          />
          <TextField
            source="last_available_at"
          />
          <StartAndEndTimeField />
          <TextField
            source="excluded_dates"
          />
          <BooleanField
            source="accept_codeverse_customers"
            addLabel
          />
          <BooleanField
            source="disabled"
            addLabel
          />
        </ResourceShow>
      ),
    },
    components: {
      Filter: props => (
        <Filter
          {...props}
        >
          <TeamMemberSelectInput 
            label="Guide"
            source='user_id'
          />
          <ReferenceInput
            filter={{in_person: false}}
            source="occasion_id"
          />
          <DateInput
            label="Date"
            source="date"
          />
          <NumberInput
            label="Start Hour"
            source="start_hour"
          />
          <NumberInput
            label="End Hour"
            source="end_hour"
          />
          <BooleanInput
            label="Deleted"
            source="deleted"
          />
        </Filter>
      ),
      Expand: props => (
        <SimpleShowLayout
          {...props}
        >
          <TextField
            source="excluded_dates"
          />
        </SimpleShowLayout>
      ),
    },
    resources: {
    },
};
