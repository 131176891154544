import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const JavaScriptField = ({ source, record = {} }) => (
  <Typography
    variant="body1"
  >
    <pre>
      {record[source]}
    </pre>
  </Typography>
);

JavaScriptField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

JavaScriptField.defaultProps = {
  addLabel: true,
};

export default JavaScriptField;

