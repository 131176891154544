import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../controllers';
import { LinkField, TextField } from '../../../fields';
import { BooleanInput, NestedReferenceInput, ReferenceInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ library_object_id: props.parentResource.id }}
        {...props}
      >
        <NestedReferenceInput
          source="library_engine_method_id"
        />
        <ReferenceInput
          source="library_keyring_id"
        />
      </CreateButton>
    ),
    edit: props => (
      <EditDialog
        {...props}
      >
        <ReferenceInput
          source="library_keyring_id"
        />
      </EditDialog>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          source="library_keyring_id"
          allowEmpty
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="name"
        />
        <LinkField
          source="library_keyring_id"
          allowEmpty
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
