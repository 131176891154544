import Icon from '@material-ui/icons/Grade';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { NumberInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    create: props => (<CreateButton
      fixedValue={{ user_id: props.parentResource.id }}
      {...props}
                      >
      <TextInput
        source="description"
        required
      />
      <NumberInput
        source="coins"
        required
      />
    </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
