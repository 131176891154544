import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import titleCase from 'title-case';

const SubscriptionStatusField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.status === 'canceled' ? `Canceled (${titleCase(record.cancellation_code)})` : titleCase(record.status)}
  </Typography>
);

SubscriptionStatusField.propTypes = {
    record: PropTypes.object,
};

SubscriptionStatusField.defaultProps = {
    addLabel: true,
    label: 'Status',
};

export default SubscriptionStatusField;

