import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/icons/ChevronLeft';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { crudDelete, crudGetOne, crudUpdate } from 'react-admin';
import { connect } from 'react-redux';
import titleCase from 'title-case';
import { ActionsButton } from '../buttons';

const styles = (theme) => ({
  root: {
    justifyContent: 'space-between',
  },
  title: {
    margin: '12px 4px',
  },
  deletedTitle: {
    margin: '12px 4px',
    color: '#e60000'
  },
  navigation: {
    display: 'flex',
  },
  buttons: {
    logEntries: {
    }
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
});

class ContentHeader extends Component {
  state = {
    anchorEl: null,
    showEditDialog: false,
    confirmingDelete: false,
  };

  componentDidMount() {
    this.updateData(this.props.resource, this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.id !== nextProps.id ||
      this.props.resource !== nextProps.resource
    ) {
      this.updateData(nextProps.resource, nextProps.id);
    }
  }

  updateData(resource, id) {
    const { crudGetOne, basePath } = this.props;
    if (id) {
      crudGetOne(resource, id, basePath);
    }
  }

  render() {
    const {
      classes,
      resource,
      action,
      label,
      id,
      record,
      basePath,
      hasCreate,
      match,
      parentResource,
      dialogs,
      menuItems,
    } = this.props;

    return (
      <Toolbar
        classes={{ root: classes.root }}
        disableGutters
      >
        {(action == 'show' || action == 'edit') && record && (
          <>
            <div
              className={classes.navigation}
              href={`#${basePath}`}
            >
              <IconButton
                aria-label="Back"
                className={classes.button}
                href={`#${basePath}`}
              >
                <Icon />
              </IconButton>
              <Typography
                className={record.deleted_at ? classes.deletedTitle : classes.title}
                variant="title"
              >
                {record.name ? `${record.name} (${record.deleted_at ? 'Deleted ' : ''}${titleCase(pluralize.singular(resource))})` : titleCase(pluralize.singular(resource))}
              </Typography>
            </div>
            <div
              href={`#${basePath}-buttons`}
            >
              {action === 'show' &&
                <ActionsButton
                  dialogs={dialogs}
                  id={id}
                  menuItems={menuItems}
                  parentResource={parentResource}
                  record={record}
                  resource={resource}
                />}
            </div>
          </>
        )
      ||
        <div
          className={classes.navigation}
        >
          {(basePath != match.url &&
            <IconButton
              aria-label="Back"
              className={classes.button}
              href={`#${basePath}`}
            >
              <Icon />
            </IconButton>
          ) || (parentResource &&
            <IconButton
              aria-label="Back"
              className={classes.button}
              href={`#${parentResource.basePath}`}
            >
              <Icon />
            </IconButton>
          )}
          <Typography
            className={classes.title}
            variant="title"
          >
            {action === 'create' &&
              `New ${titleCase(pluralize.singular(resource))}`
            || action === 'edit' &&
              `Edit ${titleCase(pluralize.singular(resource))}`
            ||
              titleCase(resource)}
          </Typography>
        </div>}
        {action === 'list' && hasCreate &&
          <dialogs.create
            basePath={basePath}
            parentResource={parentResource}
            resource={resource}
          />}
      </Toolbar>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    id: props.id,
    record: (props.id && state.admin.resources[props.resource])
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
  };
}

ContentHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  crudDelete,
  crudUpdate,
  crudGetOne,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContentHeader));
