import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const exampleRequiredGeneratorCode = JSON.stringify({
  "physics": {
    "airResistance": 1,
    "bounce": 4,
    "enabled": true,
    "friction": 82,
    "mass": 100,
  },
  "display": {
    "width": 200,
    "height": 200,
    "fixedAspectRatio": false
  }
}, null, 2)

const exampleCompleteGeneratorCode = JSON.stringify({
  "physics": {
    "airResistance": 1,
    "anchorOffsetX": 0,
    "anchorOffsetY": 0,
    "arcade": false,
    "bodyType": "rectangle",
    "bounce": 4,
    "enabled": true,
    "fixed": true,
    "friction": 82,
    "frictionStatic": 0.5,
    "mass": 0,
    "slop": 0.05
  },
  "display": {
    "width": 200,
    "height": 200,
    "x": 50,
    "y": 50,
    "angle": 0,
    "fixedAspectRatio": false,
    "opacity": 100,
    "frames": [
      "Default",
      "Walk1"
    ]
  },
  "layers": [{
    "name": "helmet",
    "color": "#0044FF",
    "opacity": 100,
    "visible": true,
    "shapes": [
      "Rounded Corners",
      "Circle"
    ]
  }]
}, null, 2)

const style = {
  exampleJsonShown: {
    borderRadius: '10px',
    background: 'white',
    border: '1px solid #444',
    padding: '10px 14px',
  },
  exampleJsonHidden: {
    display:'none'
  }
};


class GeneratorExample extends Component {
  state = {
    shown: false,
  };

  render() {
    const { classes } = this.props;
    const { shown } = this.state;

    return (
      <>
        {shown && (
          <>
            <p
              onClick={() => { this.setState({shown: false})}}
            >
              Hide Example
            </p>
          </>
        ) || (
          <p
            onClick={() => { this.setState({ shown: true }) }}
          >
            Show Example
          </p>
        )}
        <pre
          className={shown && classes.exampleJsonShown || classes.exampleJsonHidden}
        >
          {"Required Configuration:\n"}
          {exampleRequiredGeneratorCode}
          {"\n\nAll Possible Configuration:\n"}
          {exampleCompleteGeneratorCode}
        </pre>
      </>
    )
  }
}


export default withStyles(style)(
  GeneratorExample
);

