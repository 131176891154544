import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import titleCase from 'title-case';
import ResourceRouter from '../controllers/ResourceRouter';


const styles = theme => ({
  root: {
    marginTop: '20px',
    marginBottom: '20px',
    background: '#fafafa',
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    borderBottom: '1px solid #888',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  rowCell: {
    verticalAlign: 'top',
    padding: '12px',
  },
  editButtonCell: {
    textAlign: 'right',
  },
});

const NestedResourceListToolbar = ({ classes, CreateButton, reference, ...props }) => {
  const basePath = `${props.basePath}/${props.record.id}/${reference}`;
  return (
    <Toolbar
      className={classes.toolbar}
    >
      <div
        className={classes.title}
      >
        <Typography
          variant="title"
        >
          {titleCase(reference)}
        </Typography>
      </div>
      <div
        className={classes.spacer}
      />
      <div
        className={classes.actions}
      >
        {CreateButton}
      </div>
    </Toolbar>
  );
};

const NestedResourceListToolbarWithRouterProps = props => (
  <ResourceRouter>
    <NestedResourceListToolbar
      {...props}
    />
  </ResourceRouter>
);

export default withStyles(styles)(NestedResourceListToolbarWithRouterProps);
