import Icon from '@material-ui/icons/BookmarkBorder';
import React from 'react';
import { CreateButton } from '../../../../../buttons';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../../../controllers';
import { DateField, EnumField, JsonField, KidScriptField, NumberField, TextField } from '../../../../../fields';
import { BooleanInput, EnumInput, JsonInput, KidScriptInput, NumberInput, SearchInput, TextInput } from '../../../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <EnumInput
          source="boilerplate"
        />
        <TextInput
          source="header"
        />
        <TextInput
          source="content"
        />
        <TextInput
          source="hint"
        />
        <TextInput
          source="button_type"
        />
        <TextInput
          source="success_header"
        />
        <TextInput
          source="success_message"
        />
        <NumberInput
          source="position"
        />
        <KidScriptInput
          source="kidscript_head"
        />
        <TextInput
          source="kidscript_head_strategy"
        />
        <KidScriptInput
          source="kidscript_body"
        />
        <TextInput
          source="kidscript_body_strategy"
        />
        <JsonInput
          source="editor_options"
        />
        <JsonInput
          source="cursor"
        />
        <JsonInput
          source="highlight"
        />
        <JsonInput
          source="editable_symbols"
        />
        <JsonInput
          source="validation_rules"
        />
        <JsonInput
          source="meta"
        />
        <TextInput
          source="library"
        />
        <TextInput
          source="test_instructions"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        fixedValue={{ tutorial_id: props.parentResource.id }}
        {...props}
      >
        <EnumInput
          source="boilerplate"
        />
        <TextInput
          source="header"
        />
        <TextInput
          source="content"
        />
        <TextInput
          source="hint"
        />
        <TextInput
          source="button_type"
        />
        <TextInput
          source="success_header"
        />
        <TextInput
          source="success_message"
        />
        <NumberInput
          source="position"
        />
        <KidScriptInput
          source="kidscript_head"
        />
        <TextInput
          source="kidscript_head_strategy"
        />
        <KidScriptInput
          source="kidscript_body"
        />
        <TextInput
          source="kidscript_body_strategy"
        />
        <JsonInput
          source="editor_options"
        />
        <JsonInput
          source="cursor"
        />
        <JsonInput
          source="highlight"
        />
        <JsonInput
          source="editable_symbols"
        />
        <JsonInput
          source="validation_rules"
        />
        <JsonInput
          source="meta"
        />
        <TextInput
          source="library"
        />
        <TextInput
          source="test_instructions"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="boilerplate"
        />
        <TextField
          source="header"
        />
        <TextField
          source="button_type"
        />
        <TextField
          source="position"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <EnumField
          source="boilerplate"
        />
        <TextField
          source="header"
        />
        <TextField
          source="content"
        />
        <TextField
          source="hint"
        />
        <TextField
          source="button_type"
        />
        <TextField
          source="success_header"
        />
        <TextField
          source="success_message"
        />
        <NumberField
          source="position"
        />
        <KidScriptField
          source="kidscript_head"
        />
        <TextField
          source="kidscript_head_strategy"
        />
        <KidScriptField
          source="kidscript_body"
        />
        <TextField
          source="kidscript_body_strategy"
        />
        <JsonField
          source="editor_options"
        />
        <JsonField
          source="cursor"
        />
        <JsonField
          source="highlight"
        />
        <JsonField
          source="editable_symbols"
        />
        <JsonField
          source="validation_rules"
        />
        <JsonField
          source="meta"
        />
        <TextField
          source="library"
        />
        <TextField
          source="test_instructions"
        />
        <DateField
          source="created_at"
        />
      </ResourceShow>
    ),

  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
