import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { GET_LIST } from 'ra-core';
import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Alert } from '.';
import dataProvider from '../dataProvider';
import SlotInstanceDetails from './SlotInstanceDetails';

const localizer = momentLocalizer(moment);

const styles = {
  container: {
    padding: '20px 0'
  },
  occasionSelector: {
    border: '1px solid',
    listStyleType: 'none',
    display: 'block',
    height: '20px',
    margin: '2px 8px 2px 2px',
    padding: '4px',
    fontFamily: 'monospace',
  },
  occasionSelectorCheckbox: {
    marginRight: '8px',
  },
  occasionSelectorContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0',
    margin: '0',
  }
}

class SlotCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slotInstances: [],
      loading: true,
      error: null,
      moreDetails: null,
      occasions: {},
      selectedOccasions: [],
      calendarView: 'week'
    };
  }

  occasionName(id) {
    return this.state.occasions && this.state.occasions[id].name;
  }

  occasionColor(id) {
    return this.state.occasions && this.state.occasions[id].color;
  }

  componentDidMount = () => {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    this.start = new Date(curr.setDate(first));
    this.end = new Date(curr.setDate(last));

    this.getOccasions(() => {
      this.getSlotInstances();
    })
  };


  getOccasions = (onComplete) => {
    dataProvider(GET_LIST, 'occasions')
      .then(({ data }) => {
        const occasions = {}
        const selectedOccasions = []
        data.forEach(o => {
          if(!o.in_person){
            occasions[o.id] = {
              color: o.color,
              name: o.name,
              id: o.id
            }
          }
        })
        this.setState({
          occasions,
          selectedOccasions
        }, onComplete);
      })
      .catch((error) => {
        this.setState({
          error,
          loading: false,
        });
      });
  }


  getSlotInstances = () => {
    this.setState({
      loading: true,
    });
    const sm = this.start.getMonth() + 1
    const sd = this.start.getDate()
    const em = this.end.getMonth() + 1
    const ed = this.end.getDate()
    const after = `${this.start.getUTCFullYear()}-${sm < 10 ? `0${sm}` : sm}-${sd < 10 ? `0${sd}` : sd}`
    const before = `${this.end.getUTCFullYear()}-${em < 10 ? `0${em}` : em}-${ed < 10 ? `0${ed}` : ed}`

    if (this.state.selectedOccasions.length == 0) {
      this.setState({
        slotInstances: [],
        loading: false,
      });
      return
    }

    dataProvider(GET_LIST, 'slot_instances', {
      filter: {
        occasion_ids: this.state.selectedOccasions,
        after,
        before,
      }
    })
      .then(({ data }) => {
        this.setState({
          slotInstances: data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          loading: false,
        });
      });
  }

  handleRangeChange = (range) => {
    this.start = range.start || range[0]
    this.end = range.end || range[range.length - 1]
    this.getSlotInstances()
  }

  tooltipAccessor(props) {
    return 'Click to view';
  }

  eventPropGetter(props) {
    return { style: { backgroundColor: props.color } };
  }

  eventClicked = (moreDetails) => {
    this.setState({ moreDetails })
  }

  eventsList = () => {
    const events = [];
    const slotInstances = this.state.slotInstances
    slotInstances.forEach((slotInstance) => {
      let title = this.occasionName(slotInstance.occasion_id)
      title = '(' + slotInstance._meta_total_attendees + '/' + (slotInstance._meta_total_attendees + slotInstance._meta_available_capacity) + ') ' + title 
      events.push({
        start: new Date(`${slotInstance.start_date} ${slotInstance.start_time}`),
        end: new Date(`${slotInstance.end_date} ${slotInstance.end_time}`),
        color: this.occasionColor(slotInstance.occasion_id),
        record: slotInstance,
        title
      });
    });

    return events;
  };

  handleCloseSlotInstance = () => {
    this.setState({ moreDetails: null})
  }

  handleToggleOccasion = ({ target }) => {
    if (target.checked) {
      this.setState({
        selectedOccasions: this.state.selectedOccasions.concat(target.id)
      }, this.getSlotInstances)
    } else {
      this.setState({
        selectedOccasions: this.state.selectedOccasions.concat().filter(id => id != target.id)
      }, this.getSlotInstances)
    }
  }

  occasionSelected = (id) => {
    return this.state.selectedOccasions.indexOf(id) !== -1
  }

  handleView = (calendarView) => {
    this.setState({ calendarView });
  }

  render() {
    const { moreDetails, occasions, calendarView, loading } = this.state
    const { classes } = this.props
    return (
      <div
        className={classes.container}
        style={loading ? {opacity: 0.4} : {}}
      >
        <ul
          className={classes.occasionSelectorContainer}
        >
          {Object.values(occasions).map(occasion => (
            <li
              className={classes.occasionSelector}
              key={occasion.id}
              style={{borderColor: occasion.color}}
            >
              <input
                checked={this.occasionSelected(occasion.id)}
                className={classes.occasionSelectorCheckbox}
                id={occasion.id}
                type="checkbox"
                onClick={this.handleToggleOccasion}
              />
              {occasion.name}
            </li>
          ))}
        </ul>
        <Calendar
          endAccessor="end"
          eventPropGetter={this.eventPropGetter}
          events={this.eventsList()}
          localizer={localizer}
          startAccessor="start"
          tooltipAccessor={this.tooltipAccessor}
          view={calendarView}
          onRangeChange={this.handleRangeChange}
          onSelectEvent={this.eventClicked.bind(this)}
          onView={this.handleView}
        />
        { moreDetails && (
          <Alert
            content={<SlotInstanceDetails
              availableCapacity={moreDetails.record._meta_available_capacity}
              guideAcceptingCodeverseCustomersAvailability={moreDetails.record._meta_guide_accepting_codeverse_customers_availability}
              guideAvailability={moreDetails.record._meta_guide_availability}
              slotCapacity={moreDetails.record._meta_slot_capacity}
              slotCount={moreDetails.record._meta_slot_count}
              slotInstanceId={moreDetails.record.id}
              totalAttendees={moreDetails.record._meta_total_attendees}
                     />}
            title={`${moreDetails.start}`}
            isOpen
            onClose={this.handleCloseSlotInstance}
          />
        )}
      </div>
    );
  }
}

SlotCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SlotCalendar);
