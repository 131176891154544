import Icon from '@material-ui/icons/Event';
import React from 'react';
import { CreateButton } from '../../../buttons';

export default {
  Icon,
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{ user_id: props.parentResource.id }}
        {...props}
      >
      </CreateButton>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
