import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Labeled } from 'react-admin';
import NumberFormat from 'react-number-format';
import { Field } from 'redux-form';

function MyNumberFormat({ record, source, ...props }) {
  const { input, input: { onChange, value }, meta, ...other } = props;
  const { touched, error } = meta;
  return (
    <NumberFormat
      {...other}
      error={!!(touched && error)}
      format="(###) ###-####"
      getInputRef={() => input}
      helperText={touched && error}
      value={value}
      onValueChange={({ value }) => onChange(value)}
    />
  );
}

MyNumberFormat.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
}

const PhoneNumberInput = (props) => {
  const source = props.source || 'phone_number';
  return (
    <Labeled
      label="Phone Number"
    >
      <span>
        <Field
          component={MyNumberFormat}
          customInput={TextField}
          name="phone_number"
          value={props.record && props.record[source]}
        />
      </span>
    </Labeled>
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default PhoneNumberInput;
