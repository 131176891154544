import Icon from '@material-ui/icons/Loyalty';
import React from 'react';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, SimpleShowLayout } from '../../controllers';
import { BooleanField, EnumField, LinkField, NumberField, TextField } from '../../fields';
import { BooleanInput, InputGroup, NumberInput, ReferenceInput, SearchInput, TextInput } from '../../inputs';
import coupon_occasions from './coupon_occasions';
import coupon_subscription_discounts from './coupon_subscription_discounts';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Coupon Summary"
          subHeader="Coupons must be created with a name in all caps followed by a number which represents the value. For example, COUPON-50."
        >
          <TextInput
            source="name"
          />
          <TextInput
            source="description"
          />
          <NumberInput
            source="quantity"
          />
        </InputGroup>

        <InputGroup
          header="Combinable"
          subHeader="Can this coupon be combined with others?"
        >
          <BooleanInput
            source="combinable"
          />
        </InputGroup>
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="Coupon Summary"
          subHeader="Coupons must be created with a name in all caps followed by a number which represents the value. For example, COUPON-50."
        >
          <TextInput
            source="name"
          />
          <TextInput
            source="description"
          />
          <NumberInput
            source="quantity"
          />
        </InputGroup>

        <InputGroup
          header="Combinable"
          subHeader="Can this coupon be combined with others?"
        >
          <BooleanInput
            source="combinable"
          />
        </InputGroup>

        <InputGroup
          header="Associated Ambassador"
          subHeader="Select an ambassador to be associated with this coupon. Members of our ambassador program are typically partners, rather than parents."
        >
          <ReferenceInput
            label="Ambassador"
            optionText={users => `${users.name} (${users.email})`}
            reference="users"
            source="ambassador_id"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <TextField
          source="description"
        />
        <BooleanField
          source="combinable"
        />
        <NumberField
          source="quantity_in_use"
        />
        <NumberField
          source="remaining_quantity"
        />
        <LinkField
          reference="users"
          source="ambassador_id"
          allowEmpty
        />
      </ResourceList>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="coupon_occasions"
        >
          <TextField
            source="name"
          />
        </NestedResourceList>
        <NestedResourceList
          reference="coupon_subscription_discounts"
        >
          <TextField
            source="amount"
          />
          <TextField
            source="additional_member_amount"
          />
          <NumberField
            source="number_of_billing_cycles"
          />
          <EnumField
            source="target"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
  },
  resources: {
    coupon_occasions,
    coupon_subscription_discounts,
  },
};
