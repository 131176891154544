import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const AgeField = ({  record = {}, showDateOfBirth}) => ( 
  <Typography
    variant="body1"
  >
    {record.date_of_birth && moment().diff(record.date_of_birth, 'years')}  
    {record.date_of_birth && showDateOfBirth ? ' (' + record.date_of_birth + ')' : null} 
  </Typography>
);

AgeField.propTypes = {
  record: PropTypes.object,
  showDateOfBirth: PropTypes.bool
};

AgeField.defaultProps = {
  addLabel: true,
  label: 'Age',
  showDateOfBirth: false,
};

export default AgeField;
 