import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import titleCase from 'title-case';

const EnumField = ({ source, record = {} }) => (
  <Typography
    style={{whiteSpace: 'nowrap'}}
    variant="body1"
  >
    {titleCase(record[source])}
  </Typography>
);

EnumField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

EnumField.defaultProps = {
  addLabel: true,
  sortable: false
};

export default EnumField;

