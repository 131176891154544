import Icon from '@material-ui/icons/Tv';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../buttons';
import { EditDialog, Filter, FormDataConsumer, NestedResourceList, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../controllers';
import { DateTimeField, EnumField, LinkField, NumberField, StreamLinkField, TextField } from '../../fields';
import { BooleanInput, DateInput, DateTimeInput, EnumInput, InputGroup, ReferenceInput, TeamMemberSelectInput, TextInput } from '../../inputs';
import streaming_chat_messages from './streaming_chat_messages';


export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="General"
        >
          <TextInput
            source="title"
            required
          />
          <TextInput
            source="description"
            required
          />
        </InputGroup>

        <InputGroup
          header="Stream Details"
        >
          <EnumInput
            source="channel"
          />
          <EnumInput
            source="audience"
          />
          <EnumInput
            source="source"
            fullWidth
            resettable
          />
          <FormDataConsumer>
            {({ formData }) => (
              (formData.source == 'prerecorded') &&
                <TextInput
                  source="source_file"
                />
            )}
          </FormDataConsumer>
          <TeamMemberSelectInput
            label="Presenter"
            source="presenter_id-as-users"
          />
          <DateTimeInput
            source="start_at"
          />
          <DateTimeInput
            source="end_at"
          />
          <EnumInput
            source="status"
            fullWidth
          />
        </InputGroup>


      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="General"
        >
          <TextInput
            source="title"
            required
          />
          <TextInput
            source="description"
            required
          />
        </InputGroup>

        <InputGroup
          header="Stream Details"
        >
          <ReferenceInput
            optionText={stream => `${stream.title} (${stream.channel}) ${stream.start_at}`}
            reference="streaming_streams"
            source="replay_chat_stream_id"
          />
          <EnumInput
            source="channel"
          />
          <EnumInput
            source="audience"
          />
          <EnumInput
            source="source"
            fullWidth
            resettable
          />
          <FormDataConsumer>
            {({ formData }) => (
              (formData.source == 'prerecorded') &&
                <TextInput
                  source="source_file"
                />
            )}
          </FormDataConsumer>
          <DateTimeInput
            source="start_at"
          />
          <DateTimeInput
            source="end_at"
          />
          <TeamMemberSelectInput
            label="Presenter"
            source="presenter_id-as-users"
          />
        </InputGroup>
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="title"
        />
        <EnumField
          source="channel"
        />
        <EnumField
          source="audience"
        />
        <EnumField
          source="source"
        />
        <DateTimeField
          source="start_at"
        />
        <DateTimeField
          source="end_at"
        />
        <LinkField
          reference="users"
          source="presenter_id"
        />
        <EnumField
          source="status"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >


        <TabbedShowLayout>
          <Tab
            label="General"
          >
            <TextField
              source="id"
            />
            <StreamLinkField />
            <TextField
              source="title"
            />
            <TextField
              source="description"
            />
            <DateTimeField
              source="start_at"
              addLabel
            />
            <DateTimeField
              source="end_at"
              addLabel
            />
            <LinkField
              reference="users"
              source="presenter_id"
            />
            <EnumField
              source="channel"
            />
            <EnumField
              source="audience"
            />
            <EnumField
              source="source"
            />
            <EnumField
              source="status"
            />
            <NumberField
              source="viewers_count"
            />
          </Tab>
        
          <Tab
            label="Chat Messages"
          >
            <NestedResourceList
              perPage={50}
              reference="streaming_chat_messages"
            >
              <LinkField
                displaySource="title"
                reference="streaming_streams"
                source="stream_id"
              />
              <LinkField
                reference="users"
                source="user_id"
              />
              <EnumField
                source="status"
              />
              <TextField
                source="message"
              />
              <DateTimeField
                source="send_at"
              />
            </NestedResourceList>
          </Tab>
        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <EnumInput
          source="channel"
        />
        <EnumInput
          source="audience"
        />
        <EnumInput
          source="source"
        />
        <DateInput
          source="before"
        />
        <DateInput
          source="after"
        />
        <TeamMemberSelectInput
          label="Presenter"
          source="presenter_id"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <TextField
          source="id"
        />
        <StreamLinkField />
        <TextField
          source="description"
        />
        <EnumField
          source="channel"
        />
        <EnumField
          source="audience"
        />
        <EnumField
          source="source"
        />
        <NumberField
          source="viewers_count"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
    streaming_chat_messages
  },
};
