import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const RichTextField = ({ source, record }) => {
  let contents = record[source]
  // add style to all but the last

  if (!contents) {
    return null
  }

  const lastOccurrence = contents.toLowerCase().lastIndexOf('<p');
  // process last first, as these changes will alter the length
  if (lastOccurrence >= 0) {
    contents = contents.slice(0, lastOccurrence + 2) + ' style="margin-bottom:0"' + contents.slice(lastOccurrence + 2);
  }

  const firstOccurrence = contents.toLowerCase().indexOf('<p');
  if (firstOccurrence >= 0) {
    contents = contents.slice(0, firstOccurrence + 2) + ' style="margin-top:0"' + contents.slice(firstOccurrence + 2);
  }

  // in case it was the same element
  contents = contents.replace('style="margin-top:0" style="margin-bottom:0"', 'style="margin-bottom:0; margin-top:0"')

  return (
    <Typography
      variant="body1"
    >
      <span
        dangerouslySetInnerHTML={{ __html: contents }}
      />
    </Typography>
  );
}

RichTextField.defaultProps = {
  addLabel: true,
};

RichTextField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default RichTextField
