import Icon from '@material-ui/icons/SpeakerNotes';
import React from 'react';
import { CreateButton } from '../../../../buttons';
import { EditDialog } from '../../../../controllers';
import { EnumInput, InputGroup, RichTextInput, TextInput } from '../../../../inputs';

export default {
  Icon,
  menuItems: [
    {
      type: 'toggle',
      attribute: 'pinned'
    }
  ],
  dialogs: {
    create: props => (
      <CreateButton
        fixedValue={{
          attendance_id: props.parentResource.id,
          organization_id: props.parentResource.record._meta_organization_id
        }}
        {...props}
      >
        <InputGroup
          header="What is this note about?"
        >
          <EnumInput
            source="topic"
          />
          <TextInput
            source="heading"
            required
          />
        </InputGroup>
        <InputGroup
          fullWidth
        >
          <RichTextInput
            label=""
            source="detail"
            required
          />
        </InputGroup>
      </CreateButton>
    ),
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="What is this note about?"
        >
          <EnumInput
            source="topic"
          />
          <TextInput
            source="heading"
            required
          />
        </InputGroup>
        <InputGroup
          fullWidth
        >
          <RichTextInput
            label=""
            source="detail"
            required
          />
        </InputGroup>
      </EditDialog>
    ),
  },
  routes: {
  },
  components: {
  },
  resources: {
  },
};
