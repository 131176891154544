import Icon from '@material-ui/icons/Bookmark';
import React from 'react';
import { EditDialog, Filter, ResourceList, ResourceShow } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, TextField, VersionField } from '../../../fields';
import { BooleanInput, EnumInput, LongTextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <BooleanInput
          source="as_instance"
        />
        <BooleanInput
          source="importable"
        />
        <EnumInput
          source="significance"
        />
        <LongTextInput
          source="icon"
        />
        <BooleanInput
          source="published"
        />
        <LongTextInput
          source="changelog"
        />
      </EditDialog>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <VersionField />
        <EnumField
          source="significance"
        />
        <DateTimeField
          source="published_at"
        />
        <LinkField
          reference="users"
          source="publisher_id"
          allowEmpty
        />
        <BooleanField
          source="published"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TextField
          source="id"
        />
        <TextField
          source="icon"
        />
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
