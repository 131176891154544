import Icon from '@material-ui/icons/Phone';
import React from 'react';
import { Filter, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../../controllers';
import { BooleanField, DateTimeField, EnumField, LinkField, MeetingLinkField, TextField } from '../../../fields';
import { BooleanInput } from '../../../inputs';

export default {
  Icon,
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <LinkField
          reference="users"
          source="user_id"
        />
        <DateTimeField
          source="first_joined_at"
        />
        <DateTimeField
          source="last_left_at"
        />
      </ResourceList>
    ),
     show: props => ( 
       <ResourceShow
         {...props}
       >
         <TabbedShowLayout>
           <Tab
             label="summary"
           >
             <MeetingLinkField
               source="meeting_id"
             />
             
             <DateTimeField
               source="meeting_start_at"
               addLabel
             />
             <DateTimeField
               source="meeting_end_at"
               addLabel
             />
             <EnumField
               source="meeting_status"
             />
             <EnumField
               source="status"
             />
             <EnumField
               source="scope"
             />
           </Tab>

           <Tab
             label="Kid Survey"
           >

             <EnumField
               source="sentiment"
             />
             <TextField
               source="comments"
             />
           </Tab>
           <Tab
             label="Parent Survey"
           >
             <EnumField
               source="parent_sentiment"
             />
             <BooleanField
               source="parent_keep_guide"
               addLabel
             />
             <BooleanField
               source="parent_make_guide_most_preferred"
               addLabel
             />
             <BooleanField
               source="parent_learning_concerns"
               addLabel
             />
             <BooleanField
               source="parent_technology_issues"
               addLabel
             />
             <BooleanField
               source="parent_guide_concerns"
               addLabel
             />
             <TextField
               source="parent_other_concerns"
             />
             <BooleanField
               source="parent_technology_logging_in_issues"
               addLabel
             />
             <BooleanField
               source="parent_technology_audio_video_issues"
               addLabel
             />
             <BooleanField
               source="parent_technology_code_ide_issues"
               addLabel
             />

             
             <TextField
               source="parent_other_issues"
             />
             <TextField
               source="parent_next_session_comments"
             />
             <TextField
               source="parent_experience_comments"
             />
           </Tab>
         </TabbedShowLayout>
         
       </ResourceShow>
     ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};

