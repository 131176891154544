import Icon from '@material-ui/icons/DevicesOther';
import React from 'react';
import { CreateButton } from '../../../buttons';
import { EditDialog, Filter, NestedResourceList, ResourceList, ResourceShow, SimpleShowLayout } from '../../../controllers';
import { DateTimeField, EnumField, TextField } from '../../../fields';
import { BooleanInput, EnumInput, ReferenceInput, SearchInput, TextInput } from '../../../inputs';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <EnumInput
          source="driver"
        />
        <EnumInput
          source="status"
        />
        <TextInput
          source="hostname"
        />
        <TextInput
          source="asset_tag"
        />
        <TextInput
          source="serial_number"
        />
        <TextInput
          source="mac_address"
        />
        <TextInput
          source="ip_address"
        />
        <TextInput
          source="subnet"
        />
        <TextInput
          source="port"
        />
        <TextInput
          source="dmx_channel"
        />
        <TextInput
          source="major_channel"
        />
        <TextInput
          source="minor_channel"
        />
        <TextInput
          source="push_token"
        />
        <TextInput
          source="x"
        />
        <TextInput
          source="y"
        />
        <TextInput
          source="z"
        />
      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
        fixedValue={{ location_id: props.parentResource.id }}
      >
        <ReferenceInput
          source="hardware_vendor_id"
          required
        />
        <EnumInput
          source="driver"
        />
        <TextInput
          source="status"
        />
        <TextInput
          source="hostname"
        />
        <TextInput
          source="asset_tag"
        />
        <TextInput
          source="serial_number"
        />
        <TextInput
          source="mac_address"
        />
        <TextInput
          source="ip_address"
        />
        <TextInput
          source="subnet"
        />
        <TextInput
          source="port"
        />
        <TextInput
          source="dmx_channel"
        />
        <TextInput
          source="major_channel"
        />
        <TextInput
          source="minor_channel"
        />
        <TextInput
          source="push_token"
        />
        <TextInput
          source="x"
        />
        <TextInput
          source="y"
        />
        <TextInput
          source="z"
        />
      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <EnumField
          source="last_heartbeat_topic"
        />
        <DateTimeField
          label="Last Seen"
          source="last_seen_at"
        />
        <EnumField
          source="driver"
        />
        <EnumField
          source="status"
        />
        <TextField
          source="hostname"
        />
        <TextField
          sortable={false}
          source="mac_address"
        />
        <TextField
          sortable={false}
          source="ip_address"
        />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <EnumField
          source="last_heartbeat_topic"
        />
        <TextField
          source="last_seen_at"
        />
        <TextField
          source="id"
        />
        <EnumField
          source="driver"
        />
        <TextField
          source="status"
        />
        <TextField
          source="hostname"
        />
        <TextField
          source="asset_tag"
        />
        <TextField
          source="serial_number"
        />
        <TextField
          source="mac_address"
        />
        <TextField
          source="ip_address"
        />
        <TextField
          source="subnet"
        />
        <TextField
          source="port"
        />
        <TextField
          source="dmx_channel"
        />
        <TextField
          source="major_channel"
        />
        <TextField
          source="minor_channel"
        />
        <TextField
          source="push_token"
        />
        <TextField
          source="x"
        />
        <TextField
          source="y"
        />
        <TextField
          source="z"
        />
      </ResourceShow>
    ),
  },
  components: {
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <NestedResourceList
          reference="hardware_heartbeats"
        >
          <DateTimeField
            source="created_at"
          />
          <EnumField
            source="direction"
          />
          <EnumField
            source="topic"
          />
          <TextField
            source="comment"
          />
          <TextField
            source="millisecond_duration"
          />
        </NestedResourceList>
      </SimpleShowLayout>
    ),
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <EnumInput
          source="last_heartbeat_topic"
        />
        <EnumInput
          source="driver"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
  },
  resources: {
  },
};
