import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const StartAndEndTimeField = ({ record = {} }) => (
  <Typography
    variant="body1"
  >
    {record.start_hour}
    :
    {record.start_minute < 10 ? `0${record.start_minute}` : record.start_minute}
    &nbsp;-&nbsp;
    {record.end_hour}
    :
    {record.end_minute < 10 ? `0${record.end_minute}` : record.end_minute}
    &nbsp;CT
  </Typography>
);

StartAndEndTimeField.propTypes = {
  record: PropTypes.object,
};

StartAndEndTimeField.defaultProps = {
  addLabel: true,
  label: 'Time',
};

export default StartAndEndTimeField;

