import Icon from '@material-ui/icons/Business';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../buttons';
import { BarChart, LineChart } from '../../charts';
import { EditDialog, Filter, ResourceList, ResourceShow, Tab, TabbedShowLayout } from '../../controllers';
import { AddressField, LinkField, NumberField, PhoneNumberField, TextField, TimeZoneField } from '../../fields';
import { BooleanInput, CountrySelectInput, DialingCodeSelectInput, InputGroup, NumberInput, PhoneNumberInput, ReferenceInput, SearchInput, StateSelectInput, TextInput, TimeZoneSelectInput } from '../../inputs';
import attendances from './attendances';
import bookings from './bookings';
import hardware_components from './hardware_components';
import slots from './slots';
import Calendar from './StudioCalendar';

// import phones from './phones';

export default {
  Icon,
  dialogs: {
    edit: props => (
      <EditDialog
        {...props}
      >
        <InputGroup
          header="Address"
        >
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="address1"
            required
          />
          <TextInput
            source="address2"
          />
          <TextInput
            source="city"
            required
          />
          <StateSelectInput
            required
          />
          <TextInput
            source="zip_code"
            required
          />
          <CountrySelectInput
            required
          />
          <TimeZoneSelectInput
            required
          />
        </InputGroup>

        <InputGroup
          header="Phone"
        >
          <DialingCodeSelectInput
            required
          />
          <PhoneNumberInput
            source="phone_number"
            required
          />
        </InputGroup>

        <InputGroup
          header="Networking"
        >
          <TextInput
            source="hostname"
            required
          />
          <TextInput
            label="IP Address"
            source="ip_address"
          />
          <TextInput
            source="subnet"
          />
        </InputGroup>

        <InputGroup
          header="Admin"
        >
          <NumberInput
            source="capacity"
            required
          />
        </InputGroup>

      </EditDialog>
    ),
    create: props => (
      <CreateButton
        {...props}
      >
        <InputGroup
          header="Address"
        >
          <TextInput
            source="name"
            required
          />
          <TextInput
            source="address1"
            required
          />
          <TextInput
            source="address2"
          />
          <TextInput
            source="city"
            required
          />
          <StateSelectInput
            required
          />
          <TextInput
            source="zip_code"
            required
          />
          <CountrySelectInput
            required
          />
          <TimeZoneSelectInput
            required
          />
        </InputGroup>

        <InputGroup
          header="Phone"
        >
          <DialingCodeSelectInput
            required
          />
          <PhoneNumberInput
            source="phone_number"
            required
          />
        </InputGroup>

        <InputGroup
          header="Networking"
        >
          <TextInput
            source="hostname"
            required
          />
          <TextInput
            label="IP Address"
            source="ip_address"
          />
          <TextInput
            source="subnet"
          />
        </InputGroup>

        <InputGroup
          header="Admin"
        >
          <ReferenceInput
            source="merchant_account_id"
            required
          />
          <NumberInput
            source="capacity"
            required
          />
        </InputGroup>

      </CreateButton>
    ),
  },
  routes: {
    list: props => (
      <ResourceList
        {...props}
      >
        <TextField
          source="name"
        />
        <AddressField />
        <PhoneNumberField />
      </ResourceList>
    ),
    show: props => (
      <ResourceShow
        {...props}
      >
        <TabbedShowLayout>
          <Tab
            label="summary"
          >
            <TextField
              source="name"
            />
            <AddressField />
            <PhoneNumberField />
            <TimeZoneField
              source="time_zone"
              addlabel
            />
            <NumberField
              source="capacity"
            />
            <TextField
              source="hostname"
            />
            <TextField
              label="IP Address"
              source="ip_address"
            />
            <TextField
              source="subnet"
            />
          </Tab>
          <Tab
            label="Calendar"
          >
            <Calendar />
          </Tab>
          <Tab
            label="summary"
          >
            <LineChart
              filter={{ location_id: props.id }}
              name="created"
              source="bookings"
              title='New Bookings'
            />
            <LineChart
              filter={{ location_id: props.id }}
              name="status"
              source="attendances"
              title='Attendance Status'
            />
            <BarChart
              filter={{ location_id: props.id }}
              name="status"
              source="bookings"
              title='Booking Status'
            />
            <BarChart
              filter={{ location_id: props.id }}
              name="occasion"
              source="bookings"
              title='Booking Types'
            />
          </Tab>
        </TabbedShowLayout>
      </ResourceShow>
    ),
  },
  components: {
    Filter: props => (
      <Filter
        {...props}
      >
        <SearchInput
          label="Search"
          source="q"
        />
        <BooleanInput
          label="Deleted"
          source="deleted"
        />
      </Filter>
    ),
    Expand: props => (
      <SimpleShowLayout
        {...props}
      >
        <PhoneNumberField />
        <TimeZoneField
          source="time_zone"
        />
        <NumberField
          source="capacity"
        />
        <TextField
          source="hostname"
        />
        <TextField
          label="IP Address"
          source="ip_address"
        />
        <TextField
          source="subnet"
        />
        <LinkField
          source="merchant_account_id"
        />
      </SimpleShowLayout>
    ),
  },
  resources: {
    attendances,
    bookings,
    slots,
    hardware_components,
  },
};
