import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ResolvedIcon from '@material-ui/icons/CheckBox';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { showNotification } from 'react-admin';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';

const style = {
  root: {
  },
  divider: {
    margin: '12px 0',
  }
};

class AssignmentCard extends Component {
  state = {
    expanded: false,
    resolved: null,
  };

  render() {
    const { record, inbound, classes } = this.props;
    const resolved = this.state.resolved === null ? !!record.resolved_at : this.state.resolved;

    if (!record) {
      return null
    }
    return (
      <Card
        className={classes.root}
      >
        <CardHeader
          avatar={<Avatar>
            <ResolvedIcon />
          </Avatar>}
          subheader={`Created ${moment(record.created_at).format('lll')} (Updated ${moment(record.updated_at).format('lll')})`}
          title={`Priority: ${record.priority}`}
        />
        <CardContent>
          <Typography
            variant="subheading"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              {record.due_at &&
                <p>
                  Due:
                  {' '}
                  {moment(record.due_at).format('llll')}
                  {' '}
                  (
                  {moment(record.due_at).fromNow()}
                  )
                </p>}
              {record.heading}
            </Linkify>
          </Typography>
          <Typography
            variant="subheading"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              <p>
                Assignor Note:
                {' '}
                {record.assignor_notes}
              </p>
            </Linkify>
          </Typography>

          <Divider
            className={classes.divider}
          />
          <Typography
            component="p"
            gutterBottom
          >
            <Linkify
              properties={{ target: '_blank' }}
            >
              <p>
                Task:
                {' '}
                <span
                  dangerouslySetInnerHTML={{ __html: record.task }}
                />
              </p>
            </Linkify>
          </Typography>

          <Divider
            className={classes.divider}
          />

          { record.resolved && (
            <>
              <Typography
                component="p"
                gutterBottom
              >
                <Linkify
                  properties={{ target: '_blank' }}
                >
                  Resolved
                  {' '}
                  {moment(record.resolved_at).format('llll')}
                  <p>
                    Resolution :
                    {' '}
                    {record.resolution}
                  </p>
                  {record.delayed_event &&
                    <p>
                      Delayed Event :
                      {' '}
                      {record.delayed_event}
                      {' - '}
                      {moment(record.delayed_event_at).format('llll')}
                      {' '}
                      (
                      {moment(record.delayed_event_at).fromNow()}
                      )
                    </p>}
                  <p>
                    Closing notes:
                    {' '}
                    <span
                      dangerouslySetInnerHTML={{ __html: record.notes }}
                    />
                  </p>
                </Linkify>
              </Typography>
            </>
          )}

          <Typography
            component="p"
            gutterBottom
          >
            Owner:
            {' '}
            {record._meta_owner_name}
          </Typography>

          <Typography
            component="p"
            gutterBottom
          >
            Assigned by:
            {' '}
            {record._meta_assignor_name}
          </Typography>

          <Typography
            component="p"
            gutterBottom
          >
            Resolved by:
            {' '}
            {record._meta_resolver_name}
          </Typography>

        </CardContent>
      </Card>
    );
  }
}


AssignmentCard.propTypes = {
  record: PropTypes.object, 
  inbound: PropTypes.bool,
  classes: PropTypes.object,
  showNotification: PropTypes.bool
}

const mapDispatchToProps = {
  showNotification
};

export default withStyles(style)(connect(null, mapDispatchToProps)(
  AssignmentCard,
));

