import PropTypes from 'prop-types';
import React from 'react';
import { required as requiredFn } from 'react-admin';
import { ReferenceInput } from '.';

export const StaffTeamSelectInput = ({ label, source, isRequired, validate, ...props }) => (
  <ReferenceInput
    label={label || "Staff Team"}
    optionText={team => team.long_name}
    reference="staff_teams"
    sort="name"
    source={source}
    validate={isRequired ? requiredFn() : validate}
  />
)

StaffTeamSelectInput.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    validate: PropTypes.func,
};

StaffTeamSelectInput.defaultProps = {
    sort: false,
    sortable: false,
    alwaysOn: true,
};

export default StaffTeamSelectInput;
