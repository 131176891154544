import PropTypes from 'prop-types';
import React from 'react';
import { required as requiredFn, SelectInput } from 'react-admin';
import countryCodes from '../data/countries';

const CountrySelectInput = ({isRequired, validate, ...props}) => (
  <SelectInput
    {...props}
    choices={countryCodes.choices}
    validate={isRequired ? requiredFn() : validate}
  />
);

CountrySelectInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  defaultValue: PropTypes.string,
  isRequired: PropTypes.bool,
  validate: PropTypes.func
};

CountrySelectInput.defaultProps = {
  label: 'Country',
  source: 'country_code',
  defaultValue: 'US',
  sort: false,
  sortable: false,
  alwaysOn: true
};

export default CountrySelectInput;
