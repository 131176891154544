import PropTypes from 'prop-types';
import React from 'react';
import { BooleanInput as ReactAdminBooleanInput, required as requiredFn } from 'react-admin';

export const BooleanInput = ({ isRequired, validate, ...props }) => (
  <ReactAdminBooleanInput
    {...props}
    validate={isRequired ? requiredFn() : validate}
  />
)

BooleanInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};

BooleanInput.defaultProps = {
  sortable: false,
  alwaysOn: true,
  defaultValue: false,
};

export default BooleanInput;

