import Icon from '@material-ui/icons/Speaker';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { CreateButton } from '../../../../buttons';
import { EditDialog } from '../../../../controllers';
import { FileField, TextField } from '../../../../fields';
import { FileInput, TextInput } from '../../../../inputs';

export default {
    Icon,
    dialogs: {
        edit: props => (
          <EditDialog
            {...props}
          >
            <TextInput
              source="description"
              required
            />
            <FileField
              label="Attachment"
              source="file"
              target="_blank"
            />
          </EditDialog>
        ),
        create: props => (
          <CreateButton
            fixedValue={{ note_id: props.parentResource.id }}
            {...props}
          >
            <TextInput
              source="description"
              fullWidth
            />
            <FileInput
              label="Attachment"
              source="file"
              required
            />
          </CreateButton>
        ),
    },
    routes: {
    },
    components: {
        Expand: props => (
          <SimpleShowLayout
            {...props}
          >
            <TextField
              source="description"
            />
          </SimpleShowLayout>
        ),
    },
    resources: {
    },
};
